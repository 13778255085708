import { useCallback } from "react";
import { useController, useForm } from "react-hook-form";
import { bootstrapDialog, create, show, useModal } from "@ebay/nice-modal-react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import Stack from "react-bootstrap/Stack";
import { useSnackbar } from "notistack";
import { UpdateLocaleMutationVariables } from "@/gql";
import { useUpdateLocaleMutation } from "@/graphql/translation";
import { locales } from "@/components/translation/contants";
import { Locale } from "@/types";

type Props = {
    locale: Locale;
};

const codeValidationRegex = /^[a-z]{2}(?:-[a-z]{2})?$/;

function UpdateLocaleModalComponent(props: Props) {
    const { locale } = props;
    const modal = useModal();
    const dialog = bootstrapDialog(modal);
    const { enqueueSnackbar } = useSnackbar();
    const [updateLocaleMutation, { loading }] = useUpdateLocaleMutation();
    const form = useForm<UpdateLocaleMutationVariables>({
        mode: "onChange",
        defaultValues: {
            gameId: locale.gameId,
            guid: locale.guid,
            name: locale.name,
            code: locale.code,
            isBaseLocale: locale.isBaseLocale,
        },
    });
    const nameController = useController({
        control: form.control,
        name: "name",
        rules: {
            min: 1,
            max: 255,
        },
    });
    const codeController = useController({
        control: form.control,
        name: "code",
        rules: {
            pattern: {
                message: "Code should match pattern `zz-zz`",
                value: codeValidationRegex,
            },
        },
    });
    const onSubmit = useCallback(
        async (variables: UpdateLocaleMutationVariables) => {
            const { errors } = await updateLocaleMutation({ variables });
            if (!errors) {
                enqueueSnackbar("Locale updated", {
                    variant: "success",
                });
                modal.resolve();
                modal.hide();
            } else {
                console.dir(errors);
                enqueueSnackbar("Couldn't update locale", {
                    variant: "error",
                    autoHideDuration: 3000,
                });
            }
        },
        [modal, enqueueSnackbar, updateLocaleMutation],
    );

    return (
        <Modal {...dialog}>
            <Modal.Header>
                <Modal.Title>Update locale</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={form.handleSubmit(onSubmit)}>
                    <Stack gap={4}>
                        <Form.Group>
                            <Form.Label>
                                Locale name (ex. Russian, American English, British English)
                            </Form.Label>
                            <Form.Control
                                {...nameController.field}
                                isInvalid={!!codeController.formState.errors.name}
                            />
                            {nameController.formState.errors.name && (
                                <Form.Control.Feedback type="invalid">
                                    {nameController.formState.errors.name?.message}
                                </Form.Control.Feedback>
                            )}
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Locale code (ex. ru-ru, en-gb, en-us)</Form.Label>
                            <Form.Select
                                {...codeController.field}
                                isInvalid={!!codeController.formState.errors.code}
                            >
                                {locales.map((locale) => (
                                    <option key={locale.language} value={locale.language}>
                                        {locale.language} - {locale.name}
                                    </option>
                                ))}
                            </Form.Select>
                            {codeController.formState.errors.code && (
                                <Form.Control.Feedback type="invalid">
                                    {codeController.formState.errors.code?.message}
                                </Form.Control.Feedback>
                            )}
                        </Form.Group>
                        <Form.Group>
                            <Form.Check
                                {...form.register("isBaseLocale")}
                                isInvalid={!!codeController.formState.errors.code}
                                label="This locale is primary locale"
                            />
                            {codeController.formState.errors.code && (
                                <Form.Control.Feedback type="invalid">
                                    {codeController.formState.errors.code?.message}
                                </Form.Control.Feedback>
                            )}
                        </Form.Group>
                        <Button type="submit" disabled={loading}>
                            {loading && <Spinner size="sm" />}
                            Update
                        </Button>
                    </Stack>
                </Form>
            </Modal.Body>
        </Modal>
    );
}

const UpdateLocaleModal = create<Props>(UpdateLocaleModalComponent);

export function showUpdateLocaleModal(props: Props): Promise<void> {
    return show(UpdateLocaleModal, props);
}
