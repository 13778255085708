import { gql } from "@apollo/client";
import { makeGqlMutationHook } from "@/graphql/util";
import { DeleteDeployDirectoryMutation, DeleteDeployDirectoryMutationVariables } from "@/gql";
import { DEPLOY_DIRECTORY_FRAGMENT } from "../fragments/deploy-directory.fragment";

export const useDeleteDeployDirectoryMutation = makeGqlMutationHook<
    DeleteDeployDirectoryMutation,
    DeleteDeployDirectoryMutationVariables
>(gql`
    ${DEPLOY_DIRECTORY_FRAGMENT}

    mutation deleteDeployDirectory($gameId: String!, $guid: String!) {
        deleteDeployDirectory(gameId: $gameId, guid: $guid) {
            ...DeployDirectory
        }
    }
`);
