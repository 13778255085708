import { memo, PropsWithChildren, useMemo } from "react";
import styled from "styled-components";
import cn from "classnames";
import { stringToRBG } from "@/utils/stringToRGB";

const InitialsAvatar = styled.div.attrs<{
    $width?: number;
    $height?: number;
    $bgColor: string;
    $inverse?: boolean;
}>((props) => ({
    $width: props.$width,
    $height: props.$height,
    $bgColor: props.$bgColor,
}))`
    user-select: none;
    background-color: ${(props) => `#${props.$bgColor}`};
    display: inline-block;
    box-sizing: border-box;
    width: ${(props) => (props.$width ? props.$width + "px" : "auto")};
    min-width: ${(props) => (props.$width ? props.$width + "px" : "auto")};
    height: ${(props) => (props.$height ? props.$height + "px" : "auto")};
    font-size: ${(props) => (props.$height ? `${props.$height / 3}px` : "33%")};

    & > div {
        color: ${(props) => (props.$inverse ? "#000" : "#fff")};
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-style: inherit;
        font-weight: inherit;
    }
`;

function getInitials(name: string): string {
    const sections = name.replace(/[()]/g, "").split(" ").slice(0, 6);

    return sections
        .map((section) => section.at(0))
        .filter(Boolean)
        .join("");
}

export interface Props {
    name: string;
    className?: string;
    width?: number;
    height?: number;
    inverse?: boolean;
}

export const Avatar = memo(function Avatar(props: PropsWithChildren<Props>) {
    const initials = useMemo(() => getInitials(props.name), [props.name]);
    const color = useMemo(() => stringToRBG(props.name), [props.name]);

    return (
        <InitialsAvatar
            aria-label={props.name}
            role="img"
            className={cn(props.className, "initials-avatar")}
            $width={props.width}
            $height={props.height}
            $bgColor={color}
            $inverse={props.inverse}
        >
            <div>{initials}</div>
            {props.children}
        </InitialsAvatar>
    );
});
