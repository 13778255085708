import { gql } from "@apollo/client";
import { makeGqlMutationHook } from "@/graphql/util";
import { CreateTranslationMutation, CreateTranslationMutationVariables } from "@/gql";
import { TRANSLATION_FRAGMENT } from "@/graphql/fragments/translation.fragment";

const SCHEMA = gql`
    ${TRANSLATION_FRAGMENT}

    mutation createTranslation(
        $gameId: String!
        $key: String!
        $description: String!
        $locStrings: [TranslationStringsDto!]
    ) {
        createTranslation(
            gameId: $gameId
            key: $key
            description: $description
            locStrings: $locStrings
        ) {
            ...Translation
        }
    }
`;

export const useCreateTranslationMutation = makeGqlMutationHook<
    CreateTranslationMutation,
    CreateTranslationMutationVariables
>(SCHEMA);
