import { createContext, PropsWithChildren, useContext, useMemo } from "react";

type TableContext = {
    showIds: boolean;
    showRawValues: boolean;
    readonly: boolean;
};

export const TableContext = createContext<TableContext>(undefined!);

export function TableContextProvider(
    props: PropsWithChildren<{
        showIds: boolean;
        showRawValues: boolean;
        readonly: boolean;
    }>,
) {
    const contextValue = useMemo<TableContext>(
        () => ({
            showIds: props.showIds,
            showRawValues: props.showRawValues,
            readonly: props.readonly,
        }),
        [props.showIds, props.showRawValues],
    );
    return <TableContext.Provider value={contextValue}>{props.children}</TableContext.Provider>;
}

export function useTableContext() {
    return useContext(TableContext);
}
