import { useCallback, useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import orderBy from "lodash/orderBy";
import { NestedFormSelect } from "@/components/common/forms/form-inputs/FormSelect";
import { useAvailableTemplates } from "@/store/games/slices";
import { entityToSelectOption } from "@/utils/entityToSelectOption";
import { TemplateType } from "@/gql";
import { ConnectForm } from "@/components/common/forms/ConnectForm";
import { TemplateParamCreationAttributes } from "@/components/forms/EditTemplateParamForm";
import { AdditionalParameterFields } from "../../types";
import { EntityRefSelector } from "./EntityRefSelector";

export const EntityRefParameterFields: AdditionalParameterFields = ({ gameId }) => {
    const availableTemplates = useAvailableTemplates();
    const entityRefOptions = useMemo(
        () =>
            orderBy(
                availableTemplates
                    .filter((v) => v.type !== TemplateType.InjectedComponent)
                    .map(entityToSelectOption),
                (v) => v.label,
            ),
        [availableTemplates],
    );
    const { setValue, getValues, watch } = useFormContext<TemplateParamCreationAttributes>();
    const [templateId, defaultValue] = watch(["meta.templateId", "meta.defaultValue"]);

    const onChange = useCallback(
        (value: string | null) => {
            setValue("meta.defaultValue", value);
        },
        [setValue],
    );
    useEffect(() => {
        if (templateId !== getValues("meta.templateId")) {
            setValue("meta.defaultValue", null);
        }
        if (templateId === null) {
            setValue("meta.templateId", entityRefOptions.at(0)?.value as string);
        }
    }, [entityRefOptions, getValues, setValue, templateId]);

    return (
        <ConnectForm>
            {() => (
                <Row className="gy-4">
                    <Col md={12}>
                        <NestedFormSelect
                            name="meta.templateId"
                            title="Referenced template id"
                            options={entityRefOptions}
                            disabled={entityRefOptions.length < 1}
                        />
                    </Col>
                    {templateId !== null && templateId !== undefined && (
                        <Col md={12}>
                            <EntityRefSelector
                                value={defaultValue}
                                gameId={gameId}
                                name="meta.defaultValue"
                                templateId={templateId}
                                onChange={onChange}
                            />
                        </Col>
                    )}
                </Row>
            )}
        </ConnectForm>
    );
};
