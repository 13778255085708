import { useDeployLockQuery } from "@/graphql/game";
import { DeployLockType } from "@/gql";

export function useDeployLock(gameId: string, lock: DeployLockType) {
    const deployLock = useDeployLockQuery({ gameId: gameId! });
    const isLockSet =
        deployLock.loading ||
        Boolean(
            deployLock.data?.deployLock &&
                (deployLock.data.deployLock.lockType === DeployLockType.Both ||
                    deployLock.data.deployLock.lockType === lock),
        );

    return [deployLock, isLockSet] as const;
}
