import { useMemo } from "react";
import DataTable, { ExpanderComponentProps, TableColumn } from "react-data-table-component";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import { DataType, TemplateMatchModel, TemplateParamMatchModel } from "@/gql";
import { TemplateParamExpanded } from "@/components/import-page/TemplateParamExpanded";

type Props = ExpanderComponentProps<TemplateMatchModel> & {
    templatesMap?: Record<string, TemplateMatchModel>;
    onSelect?: (guid: string) => void;
    isTemplateSelected?: (guid: string) => boolean;
    canSelect?: (guid: string) => boolean;
};

export function ImportTemplateExpandedRow(props: Props) {
    const columns = useMemo<TableColumn<TemplateParamMatchModel>[]>(
        () => [
            {
                name: "Template parameter",
                selector: (row) => row.sourceTemplateParam.name,
            },
            {
                name: "Template id",
                selector: (row) => row.sourceTemplateParam.guid,
            },
            {
                name: "Match id",
                selector: (row) => row.targetTemplateParam?.guid ?? "",
                format: (row) =>
                    row.targetTemplateParam ? row.targetTemplateParam.guid : "No match",
            },
            {
                name: "Parameter type",
                selector: (row) =>
                    row.sourceTemplateParam.type === DataType.List
                        ? `${row.sourceTemplateParam.type}<${row.sourceTemplateParam.subType}>`
                        : row.sourceTemplateParam.type,
            },
            {
                name: "Referenced template",
                selector: (row) => row.sourceTemplateParam.meta.templateId ?? "",
                format: (row) => {
                    if (!row.sourceTemplateParam.meta.templateId) return "";
                    if (!props.templatesMap?.[row.sourceTemplateParam.meta.templateId]) return "";
                    const sourceMatch =
                        props.templatesMap[row.sourceTemplateParam.meta.templateId]?.sourceMatch;

                    if (!sourceMatch) return "";

                    const templateName = sourceMatch.name;
                    const templateType = sourceMatch.type;

                    return (
                        <>
                            <div>{templateName}</div>
                            <small>{templateType}</small>
                        </>
                    );
                },
            },
            {
                name: "Actions",
                cell: (row) => {
                    if (!row.sourceTemplateParam.meta.templateId) return null;

                    const templateSelected = props.isTemplateSelected!(
                        row.sourceTemplateParam.meta.templateId,
                    );
                    const canSelect = props.canSelect!(row.sourceTemplateParam.meta.templateId);

                    return (
                        <>
                            {typeof props.onSelect === "function" && (
                                <Button
                                    size="sm"
                                    variant={templateSelected ? "outline-primary" : "primary"}
                                    disabled={!canSelect}
                                    onClick={() =>
                                        props.onSelect!(row.sourceTemplateParam.meta.templateId!)
                                    }
                                >
                                    {!canSelect
                                        ? "Cannot import"
                                        : templateSelected
                                        ? "Selected for import"
                                        : "Select for import"}
                                </Button>
                            )}
                        </>
                    );
                },
            },
        ],
        [props.isTemplateSelected, props.onSelect, props.templatesMap],
    );

    return (
        <Container fluid className="ps-5 pe-2 py-5 bg-body-secondary">
            {props.data.matchErrors.length > 0 && (
                <>
                    <h5>Why</h5>
                    <ul>
                        {props.data.matchErrors.map((error, index) => (
                            <li key={index} className="text-danger">
                                <p>{error}</p>
                            </li>
                        ))}
                    </ul>
                </>
            )}
            <h5>Template parameters</h5>
            <DataTable
                columns={columns}
                expandableRows
                expandableRowDisabled={(row) => row.matchErrors.length === 0}
                expandableRowsComponent={TemplateParamExpanded}
                striped
                data={props.data.templateParamsMatches}
            />
        </Container>
    );
}
