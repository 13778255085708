import { gql } from "@apollo/client";
import { makeGqlQueryLazyHook } from "@/graphql/util";
import { GetProjectDeployFlagsQuery, GetProjectDeployFlagsQueryVariables } from "@/gql";

const SCHEMA = gql`
    query getProjectDeployFlags($gameId: String!) {
        project: getGame(gameId: $gameId) {
            meta {
                deployFlags
            }
        }
        deployFlags {
            name
            value
            description
        }
    }
`;

export const useGetProjectDeployFlags = makeGqlQueryLazyHook<
    GetProjectDeployFlagsQuery,
    GetProjectDeployFlagsQueryVariables
>(SCHEMA, { fetchPolicy: "no-cache" });
