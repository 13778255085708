import { type ReactNode } from "react";
import Alert from "react-bootstrap/Alert";
import { SuspenseFallback } from "@/components/SuspenseFallback";

interface DataAwaiterProps<Data> {
    isLoading?: boolean;
    error?: Error | null;
    data?: Data;
    children: (data: Data) => ReactNode;
    className?: string;
}

export function DataAwaiter<Data>(props: DataAwaiterProps<Data>) {
    if (props.isLoading) return <SuspenseFallback />;

    if (!props.data) {
        return <span>Error: data is null</span>;
    }

    return (
        <>
            {props.error && (
                <Alert className="mb-3" variant="danger">
                    <Alert.Heading>Apollo error</Alert.Heading>
                    <span>{props.error.message}</span>
                </Alert>
            )}
            {props.children(props.data)}
        </>
    );
}
