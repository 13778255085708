import { cloneElement } from "react";
import { BLOCKED_CLASS_NAME } from "./RBAC.constants";
import { useHasPermissions } from "./RBAC.hooks";
import { RBACComponentProps } from "./RBAC.types";

export function RBACWrapper<P extends string = string>({
    requiredPermissions = [],
    children,
    fallback,
    blockedComponentPropsOverride = {},
    hideWhenBlocked = true,
    oneOf = false,
}: RBACComponentProps<P>) {
    const hasRequiredPermissions = useHasPermissions(requiredPermissions, oneOf);

    if (typeof children === "function") {
        return children({ hasRequiredPermissions });
    }

    if (hasRequiredPermissions) {
        return children;
    }

    if (fallback) {
        return <>{fallback}</>;
    }

    if (!hideWhenBlocked) {
        return cloneElement(children, {
            className: BLOCKED_CLASS_NAME,
            ...blockedComponentPropsOverride,
        });
    }

    return null;
}
