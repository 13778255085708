import { gql } from "@apollo/client";
import { SetNewPasswordMutation, SetNewPasswordMutationVariables } from "@/gql";
import { makeGqlMutationHook } from "../util";

const SET_NEW_PASSWORD = gql`
    mutation setNewPassword($token: String!, $password: String!) {
        result: setNewPassword(token: $token, password: $password) {
            isOk
            info
        }
    }
`;

export const useSetNewPassword = makeGqlMutationHook<
    SetNewPasswordMutation,
    SetNewPasswordMutationVariables
>(SET_NEW_PASSWORD);
