import { gql } from "@apollo/client";
import { DeployLockSetSubscription, DeployLockSetSubscriptionVariables } from "@/gql";
import { makeGqlSubscriptionHook } from "../util";
import { DEPLOY_LOCK_FRAGMENT } from "../fragments/deploy-lock.fragment";
import { USER_FRAGMENT } from "../fragments/user.fragment";

export const useDeployLockSetSubscription = makeGqlSubscriptionHook<
    DeployLockSetSubscription,
    DeployLockSetSubscriptionVariables
>(gql`
    ${DEPLOY_LOCK_FRAGMENT}
    ${USER_FRAGMENT}

    subscription deployLockSet($gameId: String!) {
        deployLockSet(gameId: $gameId) {
            ...DeployLock
            creator {
                ...User
            }
        }
    }
`);
