import { useCallback } from "react";
import { bootstrapDialog, create, NiceModalHocProps, show, useModal } from "@ebay/nice-modal-react";
import Form from "react-bootstrap/Form";
import { useForm, RegisterOptions } from "react-hook-form";
import Stack from "react-bootstrap/Stack";
import Modal from "react-bootstrap/Modal";
import { useSnackbar } from "notistack";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import { useGetLocales } from "@/graphql/translation";
import { CreateGlossaryEntryFromDeeplMutationVariables } from "@/gql";
import { useCreateGlossaryEntryFromDeeplMutation } from "@/graphql/translation/glossaries";

type Props = {
    gameId: string;
};

type FormData = CreateGlossaryEntryFromDeeplMutationVariables;

const leadingWhitespaceRegex = /^\s+/;
const trailingWhitespaceRegex = /\s+$/;
const specialSymbolRegex = /[\n\r\t\0]/;

const options: RegisterOptions<FormData, "baseGlossaryEntry.value"> = {
    required: true,
    validate: {
        value(value: string) {
            if (value.length === 0) return "Value must not be empty";
            if (value.match(leadingWhitespaceRegex)) return "Leading whitespace is not allowed";
            if (value.match(trailingWhitespaceRegex)) return "Trailing whitespace is not allowed";
            if (value.match(specialSymbolRegex))
                return "Value must not contain special control characters, i.e. line breaks";

            return;
        },
    },
};

const EditGlossaryEntryModal = create(function CreateGlossaryEntryFromDeeplModalComponent(
    props: Props,
) {
    const { gameId } = props;
    const modal = useModal();
    const dialog = bootstrapDialog(modal);
    const { enqueueSnackbar } = useSnackbar();
    const [createGlossaryEntryFromDeeplMutation, { loading: createGlossaryEntryFromDeeplLoading }] =
        useCreateGlossaryEntryFromDeeplMutation();
    const loading = createGlossaryEntryFromDeeplLoading;
    const getLocales = useGetLocales({ gameId });
    const form = useForm<FormData>({
        mode: "onChange",
        reValidateMode: "onChange",
        defaultValues: async () => {
            const locales = getLocales;
            if (!locales.data) {
                throw new Error(locales.error?.message);
            }

            const baseLocale = getLocales.data?.locales.find((locale) => locale.isBaseLocale);

            if (!baseLocale) {
                throw new Error("The project has no base locale");
            }

            return {
                gameId,
                baseGlossaryEntry: {
                    value: "",
                    localeId: baseLocale.guid,
                },
            };
        },
    });
    const onSubmit = useCallback(
        async (variables: FormData) => {
            const result = await createGlossaryEntryFromDeeplMutation({ variables });

            if (result.data) {
                enqueueSnackbar("Glossary entry created", {
                    variant: "success",
                });

                modal.resolve();
                modal.hide();
            } else {
                enqueueSnackbar("Could not create glossary entry", {
                    variant: "error",
                    autoHideDuration: 3000,
                });
            }
        },
        [createGlossaryEntryFromDeeplMutation, enqueueSnackbar, modal],
    );

    return (
        <Modal {...dialog}>
            <Modal.Header>
                <Modal.Title>Create glossary entry from DeepL</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={form.handleSubmit(onSubmit)}>
                    <Stack gap={2}>
                        <Form.Group>
                            <Form.Label>Base locale value</Form.Label>
                            <Form.Control
                                {...form.register("baseGlossaryEntry.value", options)}
                                isInvalid={Boolean(form.formState.errors.baseGlossaryEntry?.value)}
                            />
                            <Form.Control.Feedback type="invalid">
                                {form.formState.errors.baseGlossaryEntry?.value?.message}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Button type="submit" disabled={loading || !form.formState.isValid}>
                            {loading && <Spinner size="sm" />} Create
                        </Button>
                    </Stack>
                </Form>
            </Modal.Body>
        </Modal>
    );
});

export function useCreateGlossaryEntryFromDeepl() {
    return useCallback((props: Props) => {
        return show<void, Props & NiceModalHocProps, Props>(EditGlossaryEntryModal, props);
    }, []);
}
