import { gql } from "@apollo/client";
import { GetArtifactsQuery, GetArtifactsQueryVariables } from "@/gql";
import { ARTIFACT_FRAGMENT } from "../fragments/artifact.fragment";
import { DEPLOY_DIRECTORY_FRAGMENT } from "../fragments/deploy-directory.fragment";
import { makeGqlQueryHook, makeGqlQueryLazyHook } from "../util";

const SCHEMA = gql`
    ${ARTIFACT_FRAGMENT}
    ${DEPLOY_DIRECTORY_FRAGMENT}

    query getArtifacts($gameId: String!) {
        artifacts(gameId: $gameId) {
            ...Artifact
            currentlyDeployed {
                ...DeployDirectory
            }
        }
    }
`;

export const useGetArtifacts = makeGqlQueryHook<GetArtifactsQuery, GetArtifactsQueryVariables>(
    SCHEMA,
);
export const useLazyGetArtifacts = makeGqlQueryLazyHook<
    GetArtifactsQuery,
    GetArtifactsQueryVariables
>(SCHEMA);
