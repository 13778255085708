import { FC } from "react";
import Container from "react-bootstrap/Container";
import styled from "styled-components";

const StyledContainer = styled(Container)`
    width: auto;
    max-width: 680px;
    padding: 0 15px;
`;

export const AppFooter: FC = () => {
    return (
        <footer className="footer mt-auto py-3 bg-light flex-grow-0">
            <StyledContainer>
                <span className="text-muted">Place sticky footer content here.</span>
            </StyledContainer>
        </footer>
    );
};
