import { gql } from "@apollo/client";
import { makeGqlMutationHook } from "@/graphql/util";
import { WizDeleteUserMutation, WizDeleteUserMutationVariables } from "@/gql";

const schema = gql`
    mutation wizDeleteUser($userId: String!, $newOwnerId: String!) {
        wizDeleteUser(userId: $userId, newOwnerId: $newOwnerId) {
            info
            isOk
        }
    }
`;

export const useDeleteUserMutation = makeGqlMutationHook<
    WizDeleteUserMutation,
    WizDeleteUserMutationVariables
>(schema);
