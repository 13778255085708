/** Этот компонент должен использоваться там, где нужно вывести
 * перечисление полей сущности, на которую ссылаемся
 * Например, в ячейке таблицы сущностей */
import { memo } from "react";
import Badge from "react-bootstrap/Badge";
import { gql } from "@apollo/client";
import { useAppSelector } from "@/hooks";
import { gameDetailsSelector } from "@/store/games/selectors";
import { DataAwaiter2 } from "@/components/common/DataAwaiter2";
import { TEMPLATE_FRAGMENT } from "@/graphql/fragments/template.fragment";
import { TEMPLATE_DISPLAY_FRAGMENT } from "@/graphql/fragments/template-display.fragment";
import { makeGqlQueryHook } from "@/graphql/util";
import { GetTemplateForRendererQuery, GetTemplateForRendererQueryVariables } from "@/gql";
import { TEMPLATE_PARAM_FRAGMENT } from "@/graphql/fragments/template-param.fragment";
import { RefRenderer } from "./RefRenderer";

interface EntityRefRendererProps {
    templateId: string;
    entityId: string;
    depth?: number;
}

const SCHEMA = gql`
    ${TEMPLATE_FRAGMENT}
    ${TEMPLATE_PARAM_FRAGMENT}
    ${TEMPLATE_DISPLAY_FRAGMENT}

    query getTemplateForRenderer($guid: String!, $gameId: String!) {
        template(guid: $guid, gameId: $gameId) {
            ...Template
            templateParams {
                ...TemplateParam
            }
        }
        templateDisplay(templateId: $guid, gameId: $gameId) {
            ...TemplateDisplay
        }
    }
`;

export const useGetTemplate = makeGqlQueryHook<
    GetTemplateForRendererQuery,
    GetTemplateForRendererQueryVariables
>(SCHEMA);

export const EntityRefRenderer = memo(function EntityRefRenderer(props: EntityRefRendererProps) {
    const { depth = 0 } = props;
    const { guid: gameId } = useAppSelector(gameDetailsSelector);
    const getTemplate = useGetTemplate({ guid: props.templateId, gameId });

    return (
        <DataAwaiter2 {...getTemplate}>
            {(data) => {
                if (props.entityId === "__new") {
                    return <Badge bg="info">Create new</Badge>;
                }

                if (!data.template) {
                    return <Badge bg="warning">No template</Badge>;
                }

                if (!data.template?.templateParams || data.template?.templateParams.length === 0) {
                    return <Badge bg="warning">No fields to display</Badge>;
                }

                return (
                    <RefRenderer
                        gameId={gameId}
                        entityId={props.entityId}
                        template={data.template}
                        templateDisplay={data.templateDisplay}
                        depth={depth}
                    />
                );
            }}
        </DataAwaiter2>
    );
});
