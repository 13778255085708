import { gql } from "@apollo/client";
import { GetEntityV2Query, GetEntityV2QueryVariables } from "@/gql";
import { makeGqlQueryHook, makeGqlQueryLazyHook } from "../util";

const GET_ENTITIES = gql`
    query getEntityV2($entityId: String!, $gameId: String!) {
        entity: entityV2(guid: $entityId, gameId: $gameId) {
            guid
            gameId
            export
            values {
                parameterId
                value
            }
        }
    }
`;

export const useGetEntityV2Query = makeGqlQueryHook<GetEntityV2Query, GetEntityV2QueryVariables>(
    GET_ENTITIES,
    { fetchPolicy: "network-only" },
);
export const useLazyGetEntityV2Query = makeGqlQueryLazyHook<
    GetEntityV2Query,
    GetEntityV2QueryVariables
>(GET_ENTITIES, { fetchPolicy: "network-only" });
