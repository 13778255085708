import { BaseSyntheticEvent, useCallback, useMemo } from "react";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import ToggleButton from "react-bootstrap/ToggleButton";
import { SortFunction } from "react-data-table-component";
import { AppDataTable } from "@/components/common/DataTable";
import { entriesPerPageOptions } from "@/constants";
import { usePagination } from "@/components/common/Table/EntitiesTable/hooks/usePagination";
import { EntitiesSortOrder, GetEntitiesTableDataQuery, TemplateType } from "@/gql";
import { useToggle } from "@/hooks";
import { OperableEntityTableData } from "@/types";
import { entityToOperableEntity } from "@/utils/entities";
import { TableContextProvider } from "@/components/common/Table/EntitiesTable2/contexts/TableContext";
import { useTableColumns } from "./hooks/useTableColumns";
import { useTableResize } from "./hooks/useTableResize";
import { LocalizationProvider } from "./contexts/LocalizationProvider";

type Props = {
    readonly: boolean;
    isDirty: boolean;
    onSubmit: (e: BaseSyntheticEvent) => void;
    onReset: () => void;
    onCreateEntity: () => void;
} & GetEntitiesTableDataQuery;

export function EntitiesTable2(props: Props) {
    const data = useMemo(() => props.entities.map(entityToOperableEntity), [props.entities]);
    const { fixedHeaderScrollHeight, tableContainerRef } = useTableResize();
    const { page, itemsPerPage, onPageChange, onRowsPerPageChange } = usePagination(props.template);
    const { columns } = useTableColumns(props);
    const { state: showIds, toggle: toggleShowIds } = useToggle(false);
    const { state: showRawValues, toggle: toggleShowRawValues } = useToggle(false);

    const sortFunction = useCallback<SortFunction<OperableEntityTableData>>(
        (rows, selector, sortDirection) => {
            return rows.sort((rowA, rowB) => {
                const aField = selector(rowA);
                const bField = selector(rowB);

                let comparison = 0;

                if (aField > bField) {
                    comparison = 1;
                } else if (aField < bField) {
                    comparison = -1;
                }

                return sortDirection === "desc" ? comparison * -1 : comparison;
            });
        },
        [],
    );

    const canCreateTemplate =
        props.template.type === TemplateType.Document ||
        (props.template.type === TemplateType.Singleton && data.length === 0);

    const actionsRow = useMemo(
        () => (
            <ButtonGroup>
                <ToggleButton
                    id="showIds"
                    type="checkbox"
                    checked={showIds}
                    value="showIds"
                    onChange={toggleShowIds}
                >
                    Show ids
                </ToggleButton>
                <ToggleButton
                    id="showRawValues"
                    type="checkbox"
                    checked={showRawValues}
                    value="showRawValues"
                    onChange={toggleShowRawValues}
                >
                    Show raw values
                </ToggleButton>
                {canCreateTemplate && (
                    <Button variant="success" onClick={props.onCreateEntity}>
                        Create entity
                    </Button>
                )}
                <Button variant="warning" disabled={!props.isDirty} onClick={props.onReset}>
                    Reset changes
                </Button>
                <Button variant="primary" type="submit" disabled={!props.isDirty}>
                    Save
                </Button>
            </ButtonGroup>
        ),
        [
            showIds,
            toggleShowIds,
            showRawValues,
            toggleShowRawValues,
            canCreateTemplate,
            props.onCreateEntity,
            props.isDirty,
            props.onReset,
        ],
    );

    return (
        <LocalizationProvider gameId={props.template.gameId}>
            <TableContextProvider
                showIds={showIds}
                showRawValues={showRawValues}
                readonly={props.readonly}
            >
                <div ref={tableContainerRef}>
                    <AppDataTable
                        className="rdt-table"
                        responsive
                        fixedHeader
                        fixedHeaderScrollHeight={fixedHeaderScrollHeight}
                        actions={actionsRow}
                        data={data}
                        keyField="guid"
                        columns={columns}
                        paginationDefaultPage={page}
                        paginationPerPage={itemsPerPage}
                        onChangePage={onPageChange}
                        onChangeRowsPerPage={onRowsPerPageChange}
                        paginationRowsPerPageOptions={entriesPerPageOptions}
                        striped
                        pagination
                        sortFunction={sortFunction}
                        defaultSortAsc={
                            props.templateDisplay.defaultSortOrder !== EntitiesSortOrder.Desc
                        }
                        defaultSortFieldId={props.templateDisplay.defaultSortAttribute}
                    />
                </div>
            </TableContextProvider>
        </LocalizationProvider>
    );
}
