import React, { memo } from "react";
import { Navigate } from "@tanstack/react-router";
import { RBACWrapper } from "./RBACWrapper";
import { RBACComponentProps } from "./RBAC.types";

export function withRBAC<T extends object, P extends string = string>(
    WrappedComponent: React.ComponentType<T>,
    rbacProps: Omit<RBACComponentProps<P>, "children">,
) {
    const ComponentWithRBAC = memo(function ComponentWithRBAC(props: T) {
        return (
            <RBACWrapper<P> {...rbacProps}>
                {({ hasRequiredPermissions }) => {
                    if (!hasRequiredPermissions) {
                        return <Navigate to="/403" replace />;
                    }
                    return <WrappedComponent {...props} />;
                }}
            </RBACWrapper>
        );
    });

    return ComponentWithRBAC;
}
