import { CSSProperties, useMemo } from "react";
import { Cell, flexRender } from "@tanstack/react-table";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import cn from "classnames";
import { getCommonPinningStyles } from "./utils";
import { ConditionalCellStyles } from "./types";

type Props<Data extends Record<string, any>> = {
    cell: Cell<Data, unknown>;
    conditionalCellStyles?: ConditionalCellStyles<Data>;
};

export function DragAlongCell<Data extends Record<string, any>>(props: Props<Data>) {
    const { cell } = props;
    const { isDragging, setNodeRef, transform } = useSortable({
        id: cell.column.id,
        disabled: cell.column.getIsPinned() !== false,
    });
    const className = useMemo(() => {
        return cn(
            ...(props.conditionalCellStyles
                ?.filter((style) => style.when(cell))
                .map((style) => style.classNames) ?? []),
        );
    }, [cell, props.conditionalCellStyles]);

    const style: CSSProperties = {
        opacity: isDragging ? 0.8 : 1,
        position: "relative",
        transform: CSS.Translate.toString(transform), // translate instead of transform to avoid squishing
        transition: "width transform 0.2s ease-in-out",
        // width: `calc(var(--header-${cell.column.id}-size) * 1px)`,
        width: cell.column.getSize(),
        minWidth: cell.column.columnDef.minSize,
        maxWidth:
            cell.column.columnDef.maxSize === Number.MAX_SAFE_INTEGER
                ? undefined
                : cell.column.columnDef.maxSize,
        zIndex: isDragging ? 1 : 0,
        ...getCommonPinningStyles(cell.column),
    };

    return (
        <td style={style} ref={setNodeRef} className={className}>
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
        </td>
    );
}
