import { PropsWithChildren, ReactNode, useCallback, useEffect, useMemo } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import {
    closestCenter,
    DndContext,
    DragEndEvent,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
} from "@dnd-kit/core";
import { SortableContext, sortableKeyboardCoordinates, useSortable } from "@dnd-kit/sortable";
import { restrictToParentElement } from "@dnd-kit/modifiers";
import { CSS } from "@dnd-kit/utilities";
import { FaBars, FaPlus, FaTimes } from "react-icons/fa";
import classNames from "classnames";
import Button from "react-bootstrap/Button";
import styled from "styled-components";
import Spinner from "react-bootstrap/Spinner";
import { AllowedListSubType } from "@/types";
import { DataType, TemplateParam } from "@/gql";
import { useTemplate2 } from "@/store/games/slices";
import { FieldProps } from "@/fields/types";
import { PrimitiveField } from "../Primitive/PrimitiveEditableTableCell";
import { BooleanField } from "../Boolean/EditableBooleanTableCell";
import { EntityRefField } from "../EntityRef/EntityRefTableCell";
import { InjectedRefField } from "../InjectedRef/InjectedRefTableCell";

export interface MovableCardProps<T = any> {
    id: T;
    onRemove: (id: T) => void;
}

const ItemsPanel = styled.div`
    max-height: 35vh;
    overflow-y: scroll;
`;

function MovableCard({ id, children, onRemove }: PropsWithChildren<MovableCardProps>) {
    const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
        id,
    });
    const style = useMemo(
        () => ({
            transform: CSS.Transform.toString(transform),
            transition,
        }),
        [transform, transition],
    );

    return (
        <div
            ref={setNodeRef}
            className={classNames("d-flex", "align-items-center", "gap-2", "p-1", {
                "opacity-25": isDragging,
            })}
            style={style}
        >
            <div className="p-2" {...attributes} {...listeners}>
                <FaBars size={14} className="align-middle" />
            </div>
            <div className="flex-grow-1">{children}</div>
            <Button onClick={() => onRemove(id)} size="sm" variant="danger">
                <FaTimes size={14} className="align-middle" />
            </Button>
        </div>
    );
}

type ListEditorProps = FieldProps<string[]> & {
    templateParam: TemplateParam;
};

type ArrayValue = { value: any };

export const ListEditor = (props: ListEditorProps) => {
    const templateId = props.templateParam.meta.templateId;
    if (!templateId) {
        throw new Error(`Parameter ${props.templateParam.name} doesn't reference a template`);
    }
    const [referencedTemplate, loading] = useTemplate2(templateId);
    const form = useForm<{ values: ArrayValue[] }>({
        values: {
            values: props.value?.map((value) => ({ value })) ?? [],
        },
    });
    const fieldArray = useFieldArray({
        control: form.control,
        name: "values",
        shouldUnregister: true,
    });

    const onChange = useCallback(() => {
        const values = form.watch("values");
        props.onChange(values.map((v) => v.value));
    }, [form, props]);

    // Тут и дальше какой-то магический буллшит для списка зависимостей хука
    // Работает? Не трогай
    useEffect(() => {
        onChange();
    }, [fieldArray.fields]);

    const values = form.watch("values");
    const valueIds = useMemo(() => fieldArray.fields.map((value) => value.id), [values]);
    const subType = props.templateParam.subType as AllowedListSubType;
    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        }),
    );

    const onRemove = useCallback(
        (index: number) => {
            fieldArray.remove(index);
        },
        [fieldArray],
    );

    const subtypeDefaultValue = useMemo(() => {
        switch (subType) {
            case DataType.Integer:
            case DataType.Float:
                return 0;
            case DataType.String:
            case DataType.LocalizedString:
                return "";
            case DataType.Boolean:
                return false;
            case DataType.EntityRef:
            case DataType.InjectedRef:
                return null;
            default:
                throw new Error(`${subType} is not an allowed subtype`);
        }
    }, [subType]);

    const onAdd = useCallback(() => {
        fieldArray.append({ value: subtypeDefaultValue });
    }, [fieldArray, subtypeDefaultValue]);

    const handleDragEnd = useCallback(
        (event: DragEndEvent) => {
            const { active, over } = event;

            if (active.id !== over?.id) {
                const oldIndex = fieldArray.fields.findIndex((value) => value.id === active.id);
                const newIndex = fieldArray.fields.findIndex((value) => value.id === over?.id);

                fieldArray.move(oldIndex, newIndex);
            }
        },
        [fieldArray],
    );

    const renderSubtypeField = useCallback(
        (subType: DataType, value: any, index: number): ReactNode | never => {
            const commonProps = {
                value,
                readonly: props.readonly,
                onChange: (value: any) => fieldArray.update(index, { value }),
            };

            switch (subType) {
                case DataType.Integer:
                case DataType.Float:
                    return (
                        <PrimitiveField
                            {...commonProps}
                            gameId={props.gameId}
                            templateParam={props.templateParam}
                            dataType={subType}
                            focused
                        />
                    );
                case DataType.String:
                case DataType.LocalizedString:
                    return (
                        <PrimitiveField
                            {...commonProps}
                            gameId={props.gameId}
                            templateParam={props.templateParam}
                            dataType={subType}
                            focused
                        />
                    );
                case DataType.Boolean:
                    return (
                        <BooleanField
                            gameId={props.gameId}
                            templateParam={props.templateParam}
                            {...commonProps}
                        />
                    );
                case DataType.EntityRef:
                    if (loading) return <Spinner size="sm" />;

                    if (!referencedTemplate) {
                        throw new Error("EntityRef: Referenced template is missing");
                    }
                    if (!props.templateParam.meta.templateId) {
                        throw new Error("EntityRef: No template to reference");
                    }
                    return (
                        <EntityRefField
                            {...commonProps}
                            gameId={props.gameId}
                            templateParam={props.templateParam}
                            templateId={props.templateParam.meta.templateId}
                            contextEntity={props.contextEntity}
                        />
                    );
                case DataType.InjectedRef:
                    if (loading) return <Spinner size="sm" />;

                    if (!referencedTemplate) {
                        throw new Error("InjectedRef: Referenced template is missing");
                    }
                    if (!props.templateParam.meta.templateId) {
                        throw new Error("InjectedRef: No template to reference");
                    }
                    return (
                        <InjectedRefField
                            {...commonProps}
                            gameId={props.gameId}
                            templateParam={props.templateParam}
                            templateId={props.templateParam.meta.templateId}
                            contextEntity={props.contextEntity}
                        />
                    );
                default:
                    throw new Error(`${subType} is not an allowed subtype`);
            }
        },
        [
            fieldArray,
            props.contextEntity,
            props.gameId,
            props.readonly,
            props.templateParam,
            referencedTemplate,
        ],
    );

    if (form.formState.isLoading) return <Spinner size="sm" />;

    return (
        <div className="d-grid gap-2">
            <ItemsPanel>
                <DndContext
                    sensors={sensors}
                    collisionDetection={closestCenter}
                    onDragEnd={handleDragEnd}
                    modifiers={[restrictToParentElement]}
                >
                    <SortableContext items={valueIds}>
                        {fieldArray.fields.map((field, index) => (
                            <MovableCard
                                key={field.id}
                                id={field.id}
                                onRemove={() => onRemove(index)}
                            >
                                {renderSubtypeField(subType, field.value, index)}
                            </MovableCard>
                        ))}
                    </SortableContext>
                </DndContext>
            </ItemsPanel>
            <Button onClick={onAdd} size="sm">
                <FaPlus size={12} />
                Add
            </Button>
        </div>
    );
};
