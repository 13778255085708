import { gql } from "@apollo/client";
import { RemoveTemplateParamMutation, RemoveTemplateParamMutationVariables } from "@/gql";
import { makeGqlMutationHook } from "../util";
import { IDENTIFIER_FRAGMENT } from "../fragments/identifier.fragment";

const REMOVE_TEMPLATE_PARAM = gql`
    ${IDENTIFIER_FRAGMENT}

    mutation removeTemplateParam($guid: String!, $gameId: String!) {
        result: removeTemplateParam(guid: $guid, gameId: $gameId) {
            ...Identifier
        }
    }
`;

export const useRemoveTemplateParamMutation = makeGqlMutationHook<
    RemoveTemplateParamMutation,
    RemoveTemplateParamMutationVariables
>(REMOVE_TEMPLATE_PARAM);
