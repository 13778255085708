import { ChangeEventHandler, forwardRef, useCallback, useMemo } from "react";
import Form from "react-bootstrap/Form";
import { dayjs } from "@/utils/dayjs";

type Props = {
    value: number;
    onChange: (newValue: number) => void;
    disabled?: boolean;
};

export const TimestampInput = forwardRef(function TimestampInput(props: Props) {
    const valueDayjsInstance = useMemo(() => dayjs.utc(props.value ?? 0), [props.value]);
    const date = useMemo(() => valueDayjsInstance.format("YYYY-MM-DD"), [valueDayjsInstance]);
    const time = useMemo(() => valueDayjsInstance.format("HH:mm"), [valueDayjsInstance]);
    const onChange = useCallback(
        (date: string, time: string) => {
            props.onChange(dayjs.utc(`${date}T${time}`).valueOf());
        },
        [props],
    );
    const onDateChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
        (event) => onChange(event.target.value, time),
        [onChange, time],
    );
    const onTimeChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
        (event) => onChange(date, event.target.value),
        [date, onChange],
    );

    return (
        <div className="d-flex gap-1">
            <Form.Control
                size="sm"
                type="date"
                value={date}
                onChange={onDateChange}
                disabled={props.disabled}
            />
            <Form.Control
                size="sm"
                type="time"
                value={time}
                onChange={onTimeChange}
                disabled={props.disabled}
            />
        </div>
    );
});
