import { useCallback, useLayoutEffect, useMemo, useRef } from "react";
import { useForm, Validate } from "react-hook-form";
import { bootstrapDialog, create, NiceModalHocProps, show, useModal } from "@ebay/nice-modal-react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Stack from "react-bootstrap/Stack";
import { FieldPathValue } from "react-hook-form/dist/types/path";

type FormData = {
    query: string;
};

type Props = {
    validate?: Validate<FieldPathValue<FormData, "query">, FormData>;
};

export function SearchPromptModalComponent(props: Props) {
    const modal = useModal();
    const dialog = useMemo(() => bootstrapDialog(modal), [modal]);
    const form = useForm<FormData>({
        defaultValues: { query: "" },
        mode: "onChange",
        reValidateMode: "onChange",
    });
    const onSubmit = useCallback(
        (data: FormData) => {
            modal.resolve(data);
            return modal.hide();
        },
        [modal],
    );

    return (
        <Modal backdrop keyboard {...dialog}>
            <Modal.Header>
                <Modal.Title>Find entity by id</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Stack as={Form} gap={2} onSubmit={form.handleSubmit(onSubmit)}>
                    <Form.Group>
                        <Form.Control
                            placeholder="Search..."
                            {...form.register("query", {
                                required: true,
                                // minLength: { message: "Minimum length is", value: 3 },
                                validate: props.validate,
                            })}
                            isInvalid={Boolean(form.formState.errors.query)}
                        />
                        {form.formState.errors.query && (
                            <Form.Control.Feedback type="invalid">
                                {form.formState.errors.query.message}
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>
                    <div>
                        <Button type="submit">Search</Button>
                    </div>
                </Stack>
            </Modal.Body>
        </Modal>
    );
}

const SearchPromptModal = create(SearchPromptModalComponent);

export function useSearchPromptModal(onSubmit: (data: FormData) => void, props?: Props) {
    const callbackRef = useRef(onSubmit);
    const propsRef = useRef(props);

    useLayoutEffect(() => {
        callbackRef.current = onSubmit;
        propsRef.current = props;
    });

    return useCallback(async () => {
        const formData = await show<FormData, Props & NiceModalHocProps, Props>(
            SearchPromptModal,
            propsRef.current,
        );
        await callbackRef.current(formData);
    }, []);
}
