import { gql } from "@apollo/client";
import { TEMPLATE_FRAGMENT } from "@/graphql/fragments/template.fragment";
import { makeGqlQueryHook, makeGqlQueryLazyHook } from "@/graphql/util";
import { GetTemplateQuery, GetTemplateQueryVariables } from "@/gql";
import { TEMPLATE_DISPLAY_FRAGMENT } from "@/graphql/fragments/template-display.fragment";
import { TEMPLATE_PARAM_FRAGMENT } from "@/graphql/fragments/template-param.fragment";

const SCHEMA = gql`
    ${TEMPLATE_FRAGMENT}
    ${TEMPLATE_PARAM_FRAGMENT}
    ${TEMPLATE_DISPLAY_FRAGMENT}

    query getTemplate($guid: String!, $gameId: String!) {
        template(guid: $guid, gameId: $gameId) {
            ...Template
            templateParams {
                ...TemplateParam
            }
            templateDisplay {
                ...TemplateDisplay
            }
        }
    }
`;

export const useGetTemplate = makeGqlQueryHook<GetTemplateQuery, GetTemplateQueryVariables>(SCHEMA);
export const useLazyGetTemplate = makeGqlQueryLazyHook<GetTemplateQuery, GetTemplateQueryVariables>(
    SCHEMA,
);
