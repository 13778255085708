import { useCallback } from "react";
import { createRoute } from "@tanstack/react-router";
import { FormProvider, useForm } from "react-hook-form";
import Form from "react-bootstrap/Form";
import Stack from "react-bootstrap/Stack";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import { useSnackbar } from "notistack";
import { ExportFormProps } from "@/components/spreadsheets/types";
import { EmailsList } from "@/components/spreadsheets/EmailsList";
import { ExportTemplates } from "@/components/spreadsheets/ExportTemplates";
import { ExportToSpreadsheetMutationVariables, ExportToSpreadsheetTemplate } from "@/gql";
import { useExportToSpreadsheet } from "@/graphql/templates";
import { useModal } from "@/hooks";
import { ExportResultModal } from "@/pages/template/ExportResultModal";
import { useAuth } from "@/hooks/useAuth";
import { templatePageRoute } from "@/pages/template/Template";

export default function TemplateExportToSpreadsheet() {
    const { templateId, gameId } = exportTemplateToSpreadsheetRoute.useParams();
    const { user } = useAuth();
    const [exportToSpreadsheet, { loading }] = useExportToSpreadsheet();
    const Modal = useModal(ExportResultModal);
    const { enqueueSnackbar } = useSnackbar();

    const form = useForm<ExportFormProps>({
        mode: "onChange",
        defaultValues: {
            gameId,
            templateId,
            templates: {
                [templateId!]: {},
            },
            emails: user ? [{ value: user.email }] : [],
        },
        shouldFocusError: true,
    });
    const onSubmit = useCallback(
        async (data: ExportFormProps) => {
            const variables: ExportToSpreadsheetMutationVariables = {
                gameId: data.gameId,
                templateId: data.templateId,
                emails: data.emails.map((v) => v.value),
                templates: [],
            };

            variables.templates = Object.entries(data.templates).map<ExportToSpreadsheetTemplate>(
                ([templateId, tparams]) => ({
                    templateId,
                    templateParamIds: Object.entries(tparams)
                        .filter(([_, shouldSelect]) => shouldSelect)
                        .map(([tParamId]) => tParamId),
                }),
            );

            const result = await exportToSpreadsheet({
                variables,
            });

            if (!result.data) {
                if (Array.isArray(result.errors)) {
                    const message = `Error during export:\n${result.errors
                        .map((e) => e.message)
                        .join("\n")}`;
                    enqueueSnackbar(message, {
                        variant: "error",
                        autoHideDuration: 3000,
                    });
                }

                enqueueSnackbar("Unknown error during export", {
                    variant: "error",
                    autoHideDuration: 3000,
                });
            }

            if (result.data?.exportToSpreadsheet.errors) {
                result.data?.exportToSpreadsheet.errors.forEach((error) => {
                    enqueueSnackbar(error, {
                        variant: "error",
                        autoHideDuration: 5000,
                    });
                });
            } else {
                Modal.openModal2({
                    spreadsheetId: result!.data!.exportToSpreadsheet.spreadsheetId!,
                });
            }
        },
        [Modal, exportToSpreadsheet, enqueueSnackbar],
    );

    return (
        <Form onSubmit={form.handleSubmit(onSubmit)} className="overflow-y-scroll">
            <FormProvider {...form}>
                <Stack gap={3}>
                    <ExportTemplates disabled={loading} />
                    <EmailsList className="w-50" disabled={loading} />
                    <hr className="bg-dark" />
                    <Button type="submit" disabled={!form.formState.isValid || loading}>
                        {loading && <Spinner size="sm" />} Export
                    </Button>
                </Stack>
            </FormProvider>
            <Modal.modal />
        </Form>
    );
}

export const exportTemplateToSpreadsheetRoute = createRoute({
    getParentRoute: () => templatePageRoute,
    path: "export",
    component: TemplateExportToSpreadsheet,
});
