import { gql } from "@apollo/client";
import { memo, useCallback, useMemo } from "react";
import { Helmet } from "react-helmet-async";
import { FormProvider, SubmitErrorHandler, SubmitHandler, useForm } from "react-hook-form";
import { ErrorBoundary } from "react-error-boundary";
import isEmpty from "lodash/isEmpty";
import { useSnackbar } from "notistack";
import { createRoute } from "@tanstack/react-router";
import { Permission, GetEntitiesTableDataQuery, GetEntitiesTableDataQueryVariables } from "@/gql";
import { useHasPermissions, withRBAC } from "@/components/rbac";
import { DataAwaiter2 } from "@/components/common/DataAwaiter2";
import { EntitiesTable2 } from "@/components/common/Table/EntitiesTable2";
import { makeGqlQueryHook } from "@/graphql/util";
import { TEMPLATE_FRAGMENT } from "@/graphql/fragments/template.fragment";
import { TEMPLATE_DISPLAY_FRAGMENT } from "@/graphql/fragments/template-display.fragment";
import { TEMPLATE_PARAM_FRAGMENT } from "@/graphql/fragments/template-param.fragment";
import { ENTITY_FRAGMENT } from "@/graphql/fragments/entity.fragment";
import { ENTITY_PARAM_FRAGMENT } from "@/graphql/fragments/entity-param.fragment";
import { EntityTableValues } from "@/components/common/Table/EntitiesTable2/types";
import { useTableEntities } from "@/components/common/Table/EntitiesTable2/hooks/useTableEntities";
import { EntityParameterChangeV2 } from "@/types";
import { useChangeEntityActionV2 } from "@/store/entities/actions";
import { TableFallbackRenderer } from "@/components/common/Table/EntitiesTable2/components/TableFallbackRenderer";
import { ExpansionContextProvider } from "@/components/common/Table/EntitiesTable2/contexts/ExpansionContext";
import { showEditEntityModal } from "@/components/modals2/EditEntityModal";
import { templatePageRoute } from "@/pages/template/Template";

const useGetEntitiesTableData = makeGqlQueryHook<
    GetEntitiesTableDataQuery,
    GetEntitiesTableDataQueryVariables
>(gql`
    ${TEMPLATE_FRAGMENT}
    ${TEMPLATE_PARAM_FRAGMENT}
    ${TEMPLATE_DISPLAY_FRAGMENT}
    ${ENTITY_FRAGMENT}
    ${ENTITY_PARAM_FRAGMENT}

    query getEntitiesTableData($gameId: String!, $templateId: String!) {
        template(guid: $templateId, gameId: $gameId) {
            ...Template
            templateParams {
                ...TemplateParam
            }
        }
        templateDisplay(templateId: $templateId, gameId: $gameId) {
            ...TemplateDisplay
        }
        entities: getTemplateEntities(gameId: $gameId, templateId: $templateId) {
            ...EntityFragment
            entityParameters {
                ...EntityParamFragment
            }
        }
    }
`);

const EntitiesList2 = withRBAC(
    function EntitiesList2() {
        const { templateId, gameId } = entitiesList2Route.useParams();
        const getEntitiesTableData = useGetEntitiesTableData({
            gameId: gameId!,
            templateId: templateId!,
        });

        return (
            <>
                <Helmet title="New entities table" />
                <DataAwaiter2 {...getEntitiesTableData}>
                    {(data) => {
                        return (
                            <TableComponent
                                template={data.template}
                                entities={data.entities}
                                templateDisplay={data.templateDisplay}
                            />
                        );
                    }}
                </DataAwaiter2>
            </>
        );
    },
    {
        requiredPermissions: [Permission.DataRead, Permission.DataWrite],
        oneOf: true,
    },
);

export default EntitiesList2;

type Props = GetEntitiesTableDataQuery;

const TableComponent = memo(function TableComponent(props: Props) {
    const canWrite = useHasPermissions([Permission.DataWrite]);
    const templateId = props.template.guid;
    const gameId = props.template.gameId;
    const allEntities = useTableEntities(templateId, gameId);
    const [changeEntityAction] = useChangeEntityActionV2();
    const { enqueueSnackbar } = useSnackbar();
    const formMethods = useForm<EntityTableValues>({
        defaultValues: {
            entities: allEntities,
        },
        values: {
            entities: allEntities,
        },
        mode: "onChange",
        reValidateMode: "onChange",
        shouldUnregister: false,
    });

    const onCreateEntity = useCallback(async () => {
        await showEditEntityModal({
            gameId,
            templateId,
        });
    }, [gameId, templateId]);
    const onSubmitValid = useCallback<SubmitHandler<EntityTableValues>>(
        async (data) => {
            const entityChanges: Record<string, boolean> = {};
            const entityParameterChanges: EntityParameterChangeV2[] = [];
            const changes = formMethods.formState.dirtyFields;

            if (!changes.entities) return;

            Object.entries(changes.entities).forEach(([guid, entity]) => {
                if (entity.export !== undefined && data.entities[guid]?.export !== undefined) {
                    entityChanges[guid] = data.entities[guid]!.export;
                }

                if (!isEmpty(entity.values)) {
                    Object.keys(entity.values).forEach((paramId) => {
                        const value = data.entities[guid]!.values[paramId];

                        if (value === undefined) return;

                        entityParameterChanges.push({
                            disabled: false,
                            entityGuid: guid,
                            templateParameterGuid: paramId,
                            value: Array.isArray(value) ? value.map(({ value }) => value) : value,
                        });
                    });
                }
            });

            await changeEntityAction({
                gameId: props.template.gameId,
                entityChanges,
                entityParameterChanges,
            });

            enqueueSnackbar("Saved successfully", {
                variant: "success",
            });
        },
        [
            changeEntityAction,
            formMethods.formState.dirtyFields,
            props.template.gameId,
            enqueueSnackbar,
        ],
    );
    const onReset = useCallback(() => formMethods.reset(), [formMethods]);
    const onSubmitFail = useCallback<SubmitErrorHandler<EntityTableValues>>(
        (errors) => {
            enqueueSnackbar("Cannot save changes\nThere are some validation errors to review", {
                variant: "error",
                autoHideDuration: 3000,
            });
            console.dir(errors);
        },
        [enqueueSnackbar],
    );
    const onSubmit = useMemo(
        () => formMethods.handleSubmit(onSubmitValid, onSubmitFail),
        [formMethods, onSubmitFail, onSubmitValid],
    );

    return (
        <ErrorBoundary fallbackRender={TableFallbackRenderer}>
            <form onSubmit={onSubmit}>
                <ExpansionContextProvider>
                    <FormProvider {...formMethods}>
                        <EntitiesTable2
                            template={props.template}
                            entities={props.entities}
                            templateDisplay={props.templateDisplay}
                            readonly={!canWrite}
                            isDirty={formMethods.formState.isDirty}
                            onReset={onReset}
                            onSubmit={onSubmit}
                            onCreateEntity={onCreateEntity}
                        />
                    </FormProvider>
                </ExpansionContextProvider>
            </form>
        </ErrorBoundary>
    );
});

export const entitiesList2Route = createRoute({
    getParentRoute: () => templatePageRoute,
    path: "entities2",
    component: EntitiesList2,
});
