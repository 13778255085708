import { ChangeEventHandler, memo, useCallback } from "react";
import Form from "react-bootstrap/Form";
import { useAddChangeRecord } from "@/components/common/Table/EntitiesTable/contexts/TableCellContext";
import { DisableEntityParameterBtn } from "@/fields/components/DisableEntityParameterBtn";
import { FieldProps, TableCell } from "../../types";

export const EditableBooleanTableCell: TableCell = memo(function EditableBooleanTableCell(props) {
    const { value, addChangeRecord } = useAddChangeRecord<string>(props);
    const onChange = useCallback(
        (value: string | null) => {
            addChangeRecord(value);
        },
        [addChangeRecord],
    );

    return (
        <div className="d-flex align-items-start">
            <DisableEntityParameterBtn {...props} />
            <BooleanField
                gameId={props.gameId}
                value={value}
                onChange={onChange}
                readonly={props.readonly}
                templateParam={props.templateParam}
                contextEntity={props.tableCellProps.row}
            />
        </div>
    );
});

export function BooleanField(props: FieldProps<string>) {
    const onChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
        (event) => {
            props.onChange(String(event.currentTarget.checked));
        },
        [props],
    );

    return (
        <Form.Check
            type="switch"
            // yes, the conversion to string is necessary
            checked={String(props.value) === "true"}
            onChange={onChange}
            disabled={props.readonly}
        />
    );
}
