import { useCallback, useState } from "react";
import { DataAwaiter2 } from "@/components/common/DataAwaiter2";
import { useGetTranslations } from "@/graphql/translation";
import { TranslationsTable } from "@/components/translation/TranslationsTable";
import { TranslationsTablePaginationOptions } from "@/components/translation/types";

type Props = {
    gameId: string;
};

export function Translation(props: Props) {
    const { gameId } = props;
    const [pagination, setPagination] = useState<TranslationsTablePaginationOptions>(() => ({
        query: "",
        skip: 0,
        take: 50,
    }));
    const getTranslations = useGetTranslations({ gameId, ...pagination });
    const onPaginationChange = useCallback((data: Partial<TranslationsTablePaginationOptions>) => {
        setPagination((prevState) => ({
            query: data.query ?? prevState.query,
            take: data.take ?? prevState.take,
            skip: data.skip ?? prevState.skip,
        }));
    }, []);
    const refetch = useCallback(() => getTranslations.refetch(), [getTranslations]);

    return (
        <DataAwaiter2 {...getTranslations} loading={false}>
            {(data) => (
                <TranslationsTable
                    loading={getTranslations.loading}
                    gameId={gameId}
                    data={data}
                    total={data.translationsTotal}
                    onRefetch={refetch}
                    pagination={pagination}
                    onPaginationChange={onPaginationChange}
                />
            )}
        </DataAwaiter2>
    );
}
