import { useSortable } from "@dnd-kit/sortable";
import { ReactNode, useMemo } from "react";
import { CSS } from "@dnd-kit/utilities";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { FaGripVertical } from "react-icons/fa";

type Props<T extends Record<string, any>> = {
    id: string;
    data: T;
    width: number;
    children: (data: T) => ReactNode;
};

export function EditEntityFormDisplaySortableItem<T extends Record<string, any>>(props: Props<T>) {
    const { id, width, data, children } = props;
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
        id,
    });

    const style = useMemo(
        () => ({
            transform: CSS.Transform.toString(transform),
            transition,
        }),
        [transform, transition],
    );

    return (
        <Col id={id} key={id} md={width} className="p-1" ref={setNodeRef} style={style}>
            <div className="p-1 border rounded border-1 bg-gradient-secondary d-flex flex-nowrap align-items-center justify-content-between">
                {children(data)}
                <Button
                    as="span"
                    variant="link"
                    className="flex-grow-0 p-1"
                    {...attributes}
                    {...listeners}
                >
                    <FaGripVertical />
                </Button>
            </div>
        </Col>
    );
}
