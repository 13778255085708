import { useForm } from "react-hook-form";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import Alert from "react-bootstrap/Alert";
import { FormInput } from "@/components/common/forms/form-inputs/FormInput";
import { CreateUploadMutationVariables } from "@/gql";

export type AssetRawData = Omit<CreateUploadMutationVariables, "file"> & { file: FileList | null };

type Props = {
    isLoading: boolean;
    error: Error | null;
    gameId: string;
    assetData?: AssetRawData;
    onSubmit: (data: AssetRawData) => void;
};

export function EditAssetForm(props: Props) {
    const { handleSubmit, control, register, formState } = useForm<AssetRawData>({
        defaultValues: {
            name: "",
            description: "",
            gameId: props.gameId,
            file: null,
        },
    });

    return (
        <Form onSubmit={handleSubmit(props.onSubmit)}>
            <Row className="align-content-center justify-content-start g-4">
                <Col xs={12} md={6}>
                    <FormInput
                        name="name"
                        control={control}
                        title="Asset name"
                        rules={{
                            required: true,
                            min: 3,
                            max: 250,
                        }}
                    />
                </Col>
                <Col xs={12} md={6}>
                    <FormInput control={control} name="description" title="Asset description" />
                </Col>
                <Col md={12}>
                    <input type="file" {...register("file", { required: true })} />
                </Col>
            </Row>

            <Row className="mt-3">
                <Col>
                    <Button
                        type="submit"
                        color="danger"
                        disabled={props.isLoading || !formState.isValid}
                    >
                        {props.isLoading ? (
                            <Spinner size="sm" animation="border" />
                        ) : (
                            "Upload asset"
                        )}
                    </Button>
                </Col>
            </Row>

            {props.error ? (
                <Row className="mt-3">
                    <Col>
                        <Alert variant="danger">
                            <Alert.Heading>Failed to upload asset</Alert.Heading>
                            <p>{props.error.message}</p>
                        </Alert>
                    </Col>
                </Row>
            ) : null}
        </Form>
    );
}
