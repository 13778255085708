import { ReactNode, useMemo } from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Button, { ButtonProps } from "react-bootstrap/Button";
import NavItem from "react-bootstrap/NavItem";
import classNames from "classnames";
import { Permission } from "@/gql";
import { RBACWrapper } from "@/components/rbac";

type Props = {
    permissions: Permission[];
    oneOf?: boolean;
    children: ReactNode | (() => ReactNode);
} & ButtonProps;

export function NavbarButton(props: Props) {
    const { permissions, oneOf, className, name, children, ...rest } = props;
    const renderTooltip = useMemo(() => <Tooltip id="button-tooltip">{name}</Tooltip>, [props]);

    return (
        <RBACWrapper requiredPermissions={permissions} oneOf={oneOf}>
            <NavItem as="li">
                <OverlayTrigger
                    placement="right"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltip}
                >
                    {({ ref, ...triggerHandler }) => (
                        <Button
                            ref={ref}
                            {...triggerHandler}
                            {...rest}
                            aria-current="page"
                            className={classNames("nav-link", className)}
                        >
                            {typeof children === "function" ? children() : children}
                        </Button>
                    )}
                </OverlayTrigger>
            </NavItem>
        </RBACWrapper>
    );
}
