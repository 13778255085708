import { ComponentProps, useMemo } from "react";
import isEqual from "lodash/isEqual";
import { TableCell } from "@/fields/types";
import { useEntityChanges } from "@/store/entities/slices";

export function useAddChangeRecord<T = any>(props: ComponentProps<TableCell>) {
    const { addChangeRecord } = useEntityChanges();

    return useMemo(() => {
        const disabled = Boolean(
            Reflect.get(props.tableCellProps.row.enabledState, props.templateParam.guid),
        );
        const value = Reflect.has(props.tableCellProps.row.values, props.templateParam.guid)
            ? (props.tableCellProps.row.values[props.templateParam.guid] as T)
            : (props.templateParam.meta.defaultValue as T);

        return {
            disabled,
            value,
            setDisabled: () => {
                const newState = !Reflect.get(
                    props.tableCellProps.row.enabledState,
                    props.templateParam.guid,
                );

                addChangeRecord({
                    value,
                    disabled: newState,
                    templateParameterId: props.templateParam.guid,
                    entityId: props.tableCellProps.row.guid,
                });
            },
            addChangeRecord: (newValue: T | null) => {
                if (!isEqual(newValue, value))
                    addChangeRecord({
                        value: newValue,
                        disabled,
                        templateParameterId: props.templateParam.guid,
                        entityId: props.tableCellProps.row.guid,
                    });
            },
        };
    }, [
        addChangeRecord,
        props.tableCellProps.row.enabledState,
        props.tableCellProps.row.guid,
        props.tableCellProps.row.values,
        props.templateParam.guid,
        props.templateParam.meta.defaultValue,
    ]);
}
