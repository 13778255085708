import { gql } from "@apollo/client";
import { GAME_INFO_FRAGMENT } from "@/graphql/fragments/game-info.fragment";
import { makeGqlQueryHook } from "@/graphql/util";
import { WizGetGamesQuery } from "@/gql";

const schema = gql`
    ${GAME_INFO_FRAGMENT}

    query wizGetGames {
        wizGetGames {
            ...GameInfo
        }
    }
`;

export const useGetGamesQuery = makeGqlQueryHook<WizGetGamesQuery>(schema);
