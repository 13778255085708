import { gql } from "@apollo/client";
import {
    AddTemplateParametersToConsumerMutationVariables,
    AddTemplateParametersToConsumerMutation,
} from "@/gql";
import { CONSUMER_FRAGMENT } from "@/graphql/fragments/consumer.fragment";
import { makeGqlMutationHook } from "../util";

const SCHEMA = gql`
    ${CONSUMER_FRAGMENT}

    mutation addTemplateParametersToConsumer(
        $consumerId: String!
        $gameId: String!
        $templateId: String!
    ) {
        result: addTemplateParametersToConsumer(
            consumerId: $consumerId
            gameId: $gameId
            templateId: $templateId
        ) {
            ...Consumer
        }
    }
`;

export const useAddTemplateParametersToConsumerMutation = makeGqlMutationHook<
    AddTemplateParametersToConsumerMutation,
    AddTemplateParametersToConsumerMutationVariables
>(SCHEMA);
