import { gql } from "@apollo/client";
import { UpdateTemplateParamMutation, UpdateTemplateParamMutationVariables } from "@/gql";
import { JOB_FRAGMENT } from "@/graphql/fragments/job.fragment";
import { makeGqlMutationHook } from "../util";

const UPDATE_TEMPLATE_PARAMS = gql`
    ${JOB_FRAGMENT}

    mutation updateTemplateParam(
        $gameId: String!
        $templateId: String!
        $consumers: [String!]! = []
        $description: String
        $guid: String!
        $isGlobal: Boolean = false
        $meta: JSON!
        $name: String!
        $subType: DataType
        $type: DataType!
    ) {
        result: updateTemplateParam(
            gameId: $gameId
            templateId: $templateId
            consumers: $consumers
            description: $description
            guid: $guid
            isGlobal: $isGlobal
            meta: $meta
            name: $name
            subType: $subType
            type: $type
        ) {
            ...Job
        }
    }
`;

export const useUpdateTemplateParamMutation = makeGqlMutationHook<
    UpdateTemplateParamMutation,
    UpdateTemplateParamMutationVariables
>(UPDATE_TEMPLATE_PARAMS);
