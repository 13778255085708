import { gql } from "@apollo/client";
import { CONSUMER_FRAGMENT } from "./consumer.fragment";
import { TEMPLATE_FRAGMENT } from "./template.fragment";
import { TEMPLATE_PARAM_FRAGMENT } from "./template-param.fragment";

export const CONSUMER_POPULATED_FRAGMENT = gql`
    ${CONSUMER_FRAGMENT}
    ${TEMPLATE_FRAGMENT}
    ${TEMPLATE_PARAM_FRAGMENT}

    fragment ConsumerPopulated on Consumer {
        guid
        name
        description
        gameId
        ...Consumer
        templates {
            ...Template
        }
        templateParams {
            ...TemplateParam
        }
    }
`;
