import { lazy, Suspense, useMemo } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { SuspenseFallback } from "@/components/SuspenseFallback";
import { stringify } from "@/utils/stringify";

const MonacoEditor = lazy(async () => {
    const ReactMonacoEditor = await import("react-monaco-editor");
    return { default: ReactMonacoEditor.MonacoDiffEditor };
});

type Props = {
    from: object;
    to: object;
    stringifyFn?: (data: any) => string;
};

function Stringify(data: any) {
    return stringify(data, "__typename");
}

export function Diff(props: Props) {
    const stringifyFn = typeof props.stringifyFn === "function" ? props.stringifyFn : Stringify;
    const original = useMemo(() => stringifyFn(props.from), [props.from, stringifyFn]);
    const value = useMemo(() => stringifyFn(props.to), [props.to, stringifyFn]);
    console.dir({ original, value });

    return (
        <div className="w-100 h-100">
            <ErrorBoundary fallback={<span>error</span>}>
                <Suspense fallback={<SuspenseFallback />}>
                    <MonacoEditor
                        language="json"
                        original={original ?? ""}
                        value={value ?? ""}
                        options={{
                            renderSideBySide: true,
                            readOnly: true,
                            find: {
                                seedSearchStringFromSelection: "always",
                                autoFindInSelection: "always",
                                loop: true,
                                addExtraSpaceOnTop: true,
                                cursorMoveOnType: true,
                            },
                        }}
                    />
                </Suspense>
            </ErrorBoundary>
        </div>
    );
}
