import { gql } from "@apollo/client";
import { ENTITY_FRAGMENT } from "@/graphql/fragments/entity.fragment";
import { ENTITY_PARAM_FRAGMENT } from "@/graphql/fragments/entity-param.fragment";
import { makeGqlQueryHook, makeGqlQueryLazyHook } from "@/graphql/util";
import { GetEntityQuery, GetEntityQueryVariables } from "@/gql";

const SCHEMA = gql`
    ${ENTITY_FRAGMENT}
    ${ENTITY_PARAM_FRAGMENT}

    query getEntity($guid: String, $gameId: String!) {
        entity(guid: $guid, gameId: $gameId) {
            ...EntityFragment
            entityParameters {
                ...EntityParamFragment
            }
        }
    }
`;

export const useGetEntity = makeGqlQueryHook<GetEntityQuery, GetEntityQueryVariables>(SCHEMA);
export const useLazyGetEntity = makeGqlQueryLazyHook<GetEntityQuery, GetEntityQueryVariables>(
    SCHEMA,
);
