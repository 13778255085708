import { ChangeEventHandler, ClipboardEventHandler, useCallback, useState } from "react";
import { createRoute } from "@tanstack/react-router";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Spinner from "react-bootstrap/Spinner";
import { useSnackbar } from "notistack";
import { useImportFromSpreadsheet } from "@/graphql/templates";
import { templatePageRoute } from "@/pages/template/Template";
import { googleDocsUrlRegExp } from "@/pages/template/constants";

export default function TemplateImportFromSpreadsheet() {
    const { gameId, templateId } = importTemplateFromSpreadsheetRoute.useParams();
    const { enqueueSnackbar } = useSnackbar();
    const [importFromSpreadsheet, { loading }] = useImportFromSpreadsheet();
    const [spreadsheetId, setSpreadsheetId] = useState("");
    const onChange = useCallback<ChangeEventHandler<HTMLInputElement>>((e) => {
        setSpreadsheetId(e.target.value);
    }, []);
    const onImport = useCallback(async () => {
        const { data, errors } = await importFromSpreadsheet({
            variables: {
                gameId: gameId!,
                templateId: templateId!,
                spreadsheetId,
            },
        });

        if (Array.isArray(errors)) {
            const message = `Import from spreadsheet failed:\n${errors
                .map((e) => e.message)
                .join("\n")}`;
            enqueueSnackbar(message, {
                variant: "error",
                autoHideDuration: 3000,
            });
            return;
        }

        if (data) {
            enqueueSnackbar("Import from spreadsheet job has been put into queue", {
                variant: "success",
            });
        }
    }, [gameId, importFromSpreadsheet, enqueueSnackbar, spreadsheetId, templateId]);
    const onPaste = useCallback<ClipboardEventHandler<HTMLInputElement>>((event) => {
        event.preventDefault();
        const data = event.clipboardData.getData("text/plain");
        const result = googleDocsUrlRegExp.exec(data);

        if (result === null) {
            setSpreadsheetId(data);
            return;
        }

        const documentId = result.groups?.documentId;
        if (documentId) {
            setSpreadsheetId(documentId);
        }
    }, []);

    return (
        <Container fluid>
            <Row>
                <Col md={6}>
                    <p>
                        To import paste spreadsheet id or whole document url into the input below
                        and press Import
                    </p>
                    <p className="d-flex align-items-center">
                        https://docs.google.com/spreadsheets/d/
                        <Form.Control
                            disabled={loading}
                            size="sm"
                            type="text"
                            placeholder="Spreadsheet id"
                            value={spreadsheetId}
                            onChange={onChange}
                            onPaste={onPaste}
                        />
                        /edit
                    </p>
                    <Button disabled={loading} onClick={onImport}>
                        {loading && <Spinner size="sm" />} Import
                    </Button>
                </Col>
            </Row>
        </Container>
    );
}

export const importTemplateFromSpreadsheetRoute = createRoute({
    getParentRoute: () => templatePageRoute,
    path: "import",
    component: TemplateImportFromSpreadsheet,
});
