import { ForwardedRef, forwardRef } from "react";
import Button from "react-bootstrap/Button";
import { FieldProps } from "@/fields/types";
import { useCreateComponent } from "@/hooks/useCreateComponent";
import { EntityRefRenderer } from "../../components/EntityRefRenderer";

export const InjectedRefICTableCell = forwardRef(function InjectedRefICTableCell(
    props: Omit<FieldProps<string>, "templateParam" | "readonly"> & {
        readonly?: boolean;
        clearable?: boolean;
        templateId: string;
    },
    ref: ForwardedRef<HTMLDivElement>,
) {
    const onCreateOption = useCreateComponent(props);

    return props.value !== null ? (
        <EntityRefRenderer ref={ref} entityId={props.value} templateId={props.templateId} />
    ) : (
        <div ref={ref}>
            <Button size="sm" onClick={onCreateOption}>
                create
            </Button>
        </div>
    );
});
