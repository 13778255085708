import { memo } from "react";
import { Link } from "@tanstack/react-router";
import styled from "styled-components";
import Alert from "react-bootstrap/Alert";
import { useAppSelector } from "@/hooks";
import { DataType, Maybe } from "@/gql";
import { templatesMapSelector } from "@/store/games/selectors";

type Props = {
    type: DataType;
    subType?: Maybe<DataType>;
    gameId: string;
    templateId?: Maybe<string>;
    className?: string;
};

const ErrorBadge = styled(Alert)`
    text-transform: initial;
`;

export const DatatypeRenderer = memo(function DatatypeRenderer({
    type,
    subType,
    gameId,
    templateId,
    className,
}: Props) {
    const templatesMap = useAppSelector(templatesMapSelector);

    if ((type === DataType.EntityRef || type === DataType.InjectedRef) && templateId) {
        if (templatesMap[templateId]) {
            return (
                <Link to={`/${gameId}/templates/${templateId}/entities`} className={className}>
                    {type}&lt;{templatesMap[templateId]?.name}&gt;
                </Link>
            );
        }
        return <ErrorBadge variant="danger">Missing referenced template {templateId}</ErrorBadge>;
    }

    if (type === DataType.List) {
        if ((subType === DataType.EntityRef || subType === DataType.InjectedRef) && templateId) {
            if (templatesMap[templateId]) {
                return (
                    <Link to={`/${gameId}/templates/${templateId}/entities`} className={className}>
                        {type}&lt;{subType}&lt;{templatesMap[templateId]?.name}&gt;&gt;
                    </Link>
                );
            }
            return (
                <ErrorBadge variant="danger">Missing referenced template {templateId}</ErrorBadge>
            );
        }
        return (
            <span className={className}>
                {type}&lt;{subType}&gt;
            </span>
        );
    }

    return <span className={className}>{type}</span>;
});
