import { gql } from "@apollo/client";

export const ENTITY_TABLE_DATA_FRAGMENT = gql`
    fragment EntityTableData on EntityTableData {
        guid
        gameId
        export
        values {
            parameterId
            shouldOmit
            value
        }
    }
`;
