import { memo } from "react";
import { useEntitiesTableFormContext } from "../../hooks/useEntitiesTableFormContext";
import { EntityRefRenderer } from "../../components/EntityRefRenderer";
import { useCellContext } from "../../contexts/CellContext";
import { InputProps } from "../../types";
import { ErrorBadge } from "../../components/ErrorBadge";

export const EntityRefFieldComponent = memo(function EntityRefFieldComponent(props: InputProps) {
    const { templateParam } = useCellContext();
    const templateId = templateParam.meta.templateId;
    const { watch } = useEntitiesTableFormContext();
    const entityId = watch(props.name) as string;

    if (!templateId) {
        return <ErrorBadge messageCode="MISSING_REFD_TEMPLATE" />;
    }

    return <EntityRefRenderer entityId={entityId} templateId={templateId} />;
});
