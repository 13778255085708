import { gql } from "@apollo/client";
import { LiftDeployLockMutation, LiftDeployLockMutationVariables } from "@/gql";
import { makeGqlMutationHook } from "../util";
import { DEPLOY_LOCK_FRAGMENT } from "../fragments/deploy-lock.fragment";

export const useLiftDeployLockMutation = makeGqlMutationHook<
    LiftDeployLockMutation,
    LiftDeployLockMutationVariables
>(gql`
    ${DEPLOY_LOCK_FRAGMENT}

    mutation liftDeployLock($gameId: String!) {
        liftDeployLock(gameId: $gameId) {
            ...DeployLock
        }
    }
`);
