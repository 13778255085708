import { ChangeEvent, ForwardedRef, forwardRef, useCallback, useMemo } from "react";
import Form from "react-bootstrap/Form";
import styled from "styled-components";
import InputGroup from "react-bootstrap/InputGroup";

type Props = {
    onChange: (value: number) => void;
    value: number;
    readonly?: boolean;
};

type TimeUnit = "ms" | "ss" | "mm" | "hh" | "dd";

const FormControl = styled(Form.Control)`
    flex-basis: 3.5rem;
`;

type Def = { max: number; msecs: number };

const ms: Def = { max: 1000, msecs: 1 };
const ss: Def = { max: 60, msecs: 1000 };
const mm: Def = { max: 60, msecs: 1000 * 60 };
const hh: Def = { max: 24, msecs: 1000 * 60 * 60 };
const dd: Def = { max: 365, msecs: 1000 * 60 * 60 * 24 };

export const DurationPicker = forwardRef(function DurationPicker(
    props: Props,
    ref: ForwardedRef<HTMLInputElement>,
) {
    const state = useMemo(() => {
        let remn = props.value;

        const days = Math.floor(remn / dd.msecs);
        remn = remn % dd.msecs;

        const hours = Math.floor(remn / hh.msecs);
        remn = remn % hh.msecs;

        const minutes = Math.floor(remn / mm.msecs);
        remn = remn % mm.msecs;

        const seconds = Math.floor(remn / ms.max);
        remn = remn % ms.max;

        const mseconds = remn;

        return { ms: mseconds, ss: seconds, mm: minutes, hh: hours, dd: days };
    }, [props.value]);
    const updateValue = useCallback(
        (newValue: number, unit: TimeUnit) => {
            if (props.readonly) return;

            const msNew = (unit === "ms" ? newValue : state.ms) * ms.msecs;
            const ssNew = (unit === "ss" ? newValue : state.ss) * ss.msecs;
            const mmNew = (unit === "mm" ? newValue : state.mm) * mm.msecs;
            const hhNew = (unit === "hh" ? newValue : state.hh) * hh.msecs;
            const ddNew = (unit === "dd" ? newValue : state.dd) * dd.msecs;

            const newState = msNew + ssNew + mmNew + hhNew + ddNew;
            console.log(
                "newState: %s msNew: %s ssNew: %s mmNew: %s hhNew: %s ddNew: %s",
                newState,
                msNew,
                ssNew,
                mmNew,
                hhNew,
                ddNew,
            );

            props.onChange(newState);
        },
        [props, state.ms, state.ss, state.mm, state.hh, state.dd],
    );

    const clampMin = useCallback((value: number, min: number) => {
        return isNaN(min) ? value : Math.max(min, value);
    }, []);

    const clampMax = useCallback((value: number, max: number) => {
        return isNaN(max) ? value : Math.min(max, value);
    }, []);

    const changeHandler = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            const unit = event.target.name as TimeUnit;
            if (!isNaN(event.target.valueAsNumber) && unit) {
                const newValue = clampMax(
                    clampMin(event.target.valueAsNumber, parseInt(event.target.min)),
                    parseInt(event.target.max),
                );
                console.log(newValue);
                event.target.value = String(newValue);
                updateValue(newValue, unit);
            }
        },
        [clampMax, clampMin, updateValue],
    );

    return (
        <div className="d-flex gap-1 flex-wrap align-items-center">
            <Form.Control type="hidden" ref={ref} value={props.value} />
            <InputGroup size="sm" className="w-auto">
                <FormControl
                    placeholder="dd"
                    type="number"
                    defaultValue={state.dd}
                    min="0"
                    max={dd.max - 1}
                    step="1"
                    name="dd"
                    onChange={changeHandler}
                    disabled={props.readonly}
                />
                <InputGroup.Text>d</InputGroup.Text>
            </InputGroup>
            <InputGroup size="sm" className="w-auto">
                <FormControl
                    placeholder="HH"
                    type="number"
                    defaultValue={state.hh}
                    min="0"
                    max={hh.max - 1}
                    step="1"
                    name="hh"
                    onChange={changeHandler}
                    disabled={props.readonly}
                />
                <InputGroup.Text>h</InputGroup.Text>
            </InputGroup>
            <InputGroup size="sm" className="w-auto">
                <FormControl
                    placeholder="MM"
                    type="number"
                    size="sm"
                    defaultValue={state.mm}
                    min="0"
                    max={mm.max - 1}
                    step="1"
                    name="mm"
                    onChange={changeHandler}
                    disabled={props.readonly}
                />
                <InputGroup.Text>m</InputGroup.Text>
            </InputGroup>
            <InputGroup size="sm" className="w-auto">
                <FormControl
                    placeholder="SS"
                    type="number"
                    size="sm"
                    defaultValue={state.ss}
                    min="0"
                    max={ss.max - 1}
                    step="1"
                    name="ss"
                    onChange={changeHandler}
                    disabled={props.readonly}
                />
                <InputGroup.Text>s</InputGroup.Text>
            </InputGroup>
            <InputGroup size="sm" className="w-auto">
                <FormControl
                    placeholder="ms"
                    type="number"
                    size="sm"
                    defaultValue={state.ms}
                    min="0"
                    max={ms.max - 1}
                    step="1"
                    name="ms"
                    onChange={changeHandler}
                    disabled={props.readonly}
                />
                <InputGroup.Text>ms</InputGroup.Text>
            </InputGroup>
        </div>
    );
});
