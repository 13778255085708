export const interfaceBlocks = {
    kind: "category",
    toolboxitemId: "catInterfaces",
    colour: 290,
    name: "Interfaces",
    contents: [
        {
            kind: "block",
            type: "procedures_defreturn",
            extraState: {
                hasStatements: false,
            },
            fields: {
                NAME: "doSomething",
            },
        },
        {
            kind: "block",
            type: "procedures_defreturn",
            extraState: {
                params: [{ name: "x" }, { name: "y" }],
                hasStatements: false,
            },
            fields: {
                NAME: "doSomething",
            },
        },
    ],
};
