import styled from "styled-components";
import cn from "classnames";

type Props = {
    isOpen: boolean;
    onClick: () => void;
};

const ButtonComponent = styled.button`
    position: relative;
    width: 30px;
    height: 30px;
    padding: 0;
    cursor: pointer;
    border: none;
    outline: none;
    background: transparent;

    &::before,
    &::after,
    & span {
        background: var(--bs-primary);
    }

    &.unstable {
        &::before,
        &::after,
        & span {
            background: var(--bs-white);
        }
    }

    &::before,
    &::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        height: 3px;
        pointer-events: none;
        transition: transform 0.25s;
        transform-origin: 50% 50%;
    }

    & span {
        position: absolute;
        left: 0;
        overflow: hidden;
        width: 100%;
        height: 3px;
        text-indent: 200%;
        transition: opacity 0.25s;
    }

    &::before {
        transform: translate3d(0, -10px, 0) scale3d(0.8, 1, 1);
    }

    &::after {
        transform: translate3d(0, 10px, 0) scale3d(0.8, 1, 1);
    }

    &.open span {
        opacity: 0;
    }

    &.open::before {
        transform: rotate3d(0, 0, 1, 45deg);
    }

    &.open::after {
        transform: rotate3d(0, 0, 1, -45deg);
    }
`;

export function MenuButton(props: Props) {
    return (
        <ButtonComponent
            className={cn({ unstable: window.env.isUnstable, open: props.isOpen })}
            onClick={props.onClick}
        >
            <span>Menu</span>
        </ButtonComponent>
    );
}
