import { gql } from "@apollo/client";
import { JOB_FRAGMENT } from "@/graphql/fragments/job.fragment";
import { makeGqlSubscriptionHook } from "@/graphql/util";
import { JobStatusSubscription, JobStatusSubscriptionVariables } from "@/gql";

const SCHEMA = gql`
    ${JOB_FRAGMENT}

    subscription jobStatus($eventTypes: [QueueType!]) {
        jobStatus(eventTypes: $eventTypes) {
            ...Job
        }
    }
`;

export const useJobStatusSubscription = makeGqlSubscriptionHook<
    JobStatusSubscription,
    JobStatusSubscriptionVariables
>(SCHEMA);
