import { ChangeEventHandler, memo, useCallback, useMemo, useRef, useState } from "react";
import Form from "react-bootstrap/Form";
import Badge from "react-bootstrap/Badge";
import { useAddChangeRecord } from "@/components/common/Table/EntitiesTable/contexts/TableCellContext";
import { useGlobalEnum } from "@/store/games/slices";
import { useClickOutside } from "@/hooks/useClickOutside";
import { DisableEntityParameterBtn } from "@/fields/components/DisableEntityParameterBtn";
import { TableCell } from "../../types";
import { EnumTableCell } from "./EnumTableCell";

export const EnumEditableTableCell: TableCell = memo(function EnumEditableTableCell(props) {
    if (!props.templateParam.meta.enumId) {
        throw new Error(`Enum parameter ${props.templateParam.name} doesn't reference a enum`);
    }

    const { value, addChangeRecord } = useAddChangeRecord(props);
    const wrapperRef = useRef<HTMLDivElement | null>(null);
    const [isFocused, setIsFocused] = useState(false);
    const showEditComponent = useCallback(() => setIsFocused(true), [setIsFocused]);
    const hideEditComponent = useCallback(() => setIsFocused(false), [setIsFocused]);
    useClickOutside(wrapperRef, hideEditComponent, isFocused);

    const enumDef = useGlobalEnum(props.templateParam.meta.enumId);
    const onChange = useCallback<ChangeEventHandler<HTMLSelectElement>>(
        (event) => addChangeRecord(event.currentTarget.value),
        [addChangeRecord],
    );
    const selectOptions = useMemo(() => {
        if (!enumDef?.meta.enum?.enumValues) return [];

        const enumValues = enumDef.meta.enum?.enumValues;

        return enumValues.map((option) => {
            const optionKey = option.name ?? `_key-${option.value}-${option.name}`;

            return (
                <option key={optionKey} value={option.value}>
                    {option.name}
                </option>
            );
        });
    }, [enumDef]);

    if (!enumDef) return <Badge bg="danger">Missing enum definition</Badge>;

    return (
        <div className="d-flex align-items-center" ref={wrapperRef} onClick={showEditComponent}>
            {!props.readonly && <DisableEntityParameterBtn {...props} />}
            {!isFocused || props.readonly ? (
                <EnumTableCell {...props} />
            ) : (
                <Form.Select defaultValue={value} onChange={onChange}>
                    {selectOptions}
                </Form.Select>
            )}
        </div>
    );
});
