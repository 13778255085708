import { PropsWithChildren } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import classNames from "classnames";
import { useToggle } from "@/hooks";

type Props = {
    label?: string;
    className?: string;
};

export function Collapse(props: PropsWithChildren<Props>) {
    const { state, toggle } = useToggle(false);

    return (
        <div className={classNames("d-flex", "flex-wrap", props.className)}>
            <a
                href="#"
                className="d-block w-100"
                onClick={(event) => {
                    event.preventDefault();
                    toggle();
                }}
            >
                {state ? <FaChevronUp /> : <FaChevronDown />} {props.label ?? "Toggle"}
            </a>
            {state && <div className="w-100 mt-4">{props.children}</div>}
        </div>
    );
}
