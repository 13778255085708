import { useCallback, useEffect, useMemo, useState } from "react";
import Select, { components, GroupBase, SelectComponentsConfig, SingleValue } from "react-select";
import { TranslationsQuery } from "@/gql";
import { TranslationRenderer } from "@/components/common/LocalizationRenderer";
import { useGetTranslation, useGetTranslations } from "@/graphql/translation";
import { DataAwaiter2 } from "@/components/common/DataAwaiter2";

type Props = {
    gameId: string;
    value: string | null;
    onChange: (value: string | null) => void;
    className?: string;
};

type Option = TranslationsQuery["translations"][number];

const selectComponents: SelectComponentsConfig<Option, false, GroupBase<Option>> = {
    SingleValue: (props) => (
        <components.SingleValue {...props}>
            <TranslationRenderer data={props.data} />
        </components.SingleValue>
    ),
    Option: (props) => (
        <components.Option {...props}>
            <TranslationRenderer data={props.data} />
        </components.Option>
    ),
};

export function TranslationSelect(props: Props) {
    // const [value, setValue] = useState<Option | null>(null);
    const [options, setOptions] = useState<Option[]>(() => []);
    const value = useMemo(
        () => options.find((option) => option.guid === props.value),
        [options, props.value],
    );
    const getTranslation = useGetTranslation({
        gameId: props.gameId,
        guid: props.value ?? "",
    });
    const { refetch } = useGetTranslations({
        gameId: props.gameId,
        take: 20,
    });
    const onChange = useCallback(
        (newValue: SingleValue<Option>) => {
            newValue && props.onChange(newValue.guid);
        },
        [props],
    );
    const onFilter = useCallback(
        async (query: string) => {
            const { data } = await refetch({
                gameId: props.gameId,
                query,
            });
            if (data.translations) {
                setOptions(() => data.translations);
            }
        },
        [props.gameId, refetch],
    );
    const onInputChange = useCallback(
        (newValue: string) => {
            onFilter(newValue);
            return newValue;
        },
        [onFilter],
    );

    useEffect(() => {
        refetch();
    }, []);

    return (
        <DataAwaiter2 {...getTranslation}>
            {(data) => (
                <Select<Option, false>
                    className={props.className}
                    components={selectComponents}
                    onInputChange={onInputChange}
                    options={options}
                    value={value}
                    filterOption={() => true}
                    defaultValue={data.translation}
                    onChange={onChange}
                />
            )}
        </DataAwaiter2>
    );
}
