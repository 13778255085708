import { ComponentProps, PropsWithChildren, type ReactNode, useMemo } from "react";
import Modal from "react-bootstrap/Modal";

export interface BaseModalProps<Result = void>
    extends Pick<ComponentProps<typeof Modal>, "backdrop" | "keyboard" | "size"> {
    isOpen: boolean;
    toggle: (result?: Result) => void;
    title?: string | ReactNode;
    header?: string | (() => ReactNode);
    footer?: () => ReactNode;
    className?: string;
    contentClassName?: string;
}

export function BaseModal<Result>({
    isOpen,
    toggle,
    title,
    header,
    footer,
    keyboard,
    backdrop,
    size,
    children,
    className,
    contentClassName,
}: PropsWithChildren<BaseModalProps<Result>>) {
    const headerRenderer = useMemo(() => {
        if (title !== undefined) {
            return (
                <Modal.Header closeButton onHide={toggle}>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
            );
        }
        if (typeof header === "function") {
            return (
                <Modal.Header closeButton onHide={toggle}>
                    <Modal.Title>{header()}</Modal.Title>
                </Modal.Header>
            );
        }

        return null;
    }, [title, header, toggle]);
    return (
        <Modal
            enforceFocus={false}
            show={isOpen}
            onHide={toggle}
            keyboard={keyboard}
            backdrop={backdrop}
            size={size}
            contentClassName={contentClassName}
        >
            {headerRenderer}
            <Modal.Body className={className}>{children}</Modal.Body>
            {typeof footer === "function" ? <Modal.Footer>{footer()}</Modal.Footer> : null}
        </Modal>
    );
}
