import { FaTrash } from "react-icons/fa";
import Button from "react-bootstrap/Button";
import styled from "styled-components";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import { FieldProps } from "@/fields/types";
import { EntityRefRenderer } from "@/components/common/EntityRefRenderer";
import { EditEntityBtn2 } from "@/components/common/EditEntityBtn2";
import { useCreateComponent } from "@/hooks/useCreateComponent";

const EntityRendererWrapper = styled.div`
    position: relative;
`;

const ActionsToolbar = styled(ButtonGroup)`
    position: absolute;
    display: flex;
    visibility: hidden;
    left: 0;
    transform: translateX(0);
    z-index: -1;
    will-change: transform;
    transition:
        transform 0.1s ease-in-out,
        z-index 0.1s ease-in-out;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
    background-color: transparent;

    ${EntityRendererWrapper}:hover & {
        transform: translateX(-100%);
        z-index: 1;
        visibility: visible;
    }
`;

export function InjectedRefICTableCell(
    props: Omit<FieldProps<string>, "templateParam" | "readonly"> & {
        readonly?: boolean;
        clearable?: boolean;
        templateId: string;
    },
) {
    const onCreateOption = useCreateComponent(props);

    return (
        <EntityRendererWrapper>
            <ActionsToolbar vertical>
                {props.value !== null && (
                    <EditEntityBtn2
                        className="p-1"
                        variant="primary"
                        gameId={props.gameId}
                        entityId={props.value}
                    />
                )}
                {props.clearable && (
                    <Button className="float-start p-1" onClick={() => props.onChange(null)}>
                        <FaTrash />
                    </Button>
                )}
            </ActionsToolbar>
            {props.value !== null ? (
                <EntityRefRenderer entityId={props.value} templateId={props.templateId} />
            ) : (
                <Button size="sm" onClick={onCreateOption}>
                    create
                </Button>
            )}
        </EntityRendererWrapper>
    );
}
