import { useForm } from "react-hook-form";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import { FormInput } from "@/components/common/forms/form-inputs/FormInput";

export type ChangePassword = {
    oldPassword: string;
    newPassword: string;
    confirmNewPassword: string;
};

type Props = {
    onSubmit: (data: ChangePassword) => void;
    isLoading: boolean;
};

export function ChangePasswordForm(props: Props) {
    const { handleSubmit, control, formState } = useForm<ChangePassword>({
        defaultValues: {
            oldPassword: "",
            newPassword: "",
            confirmNewPassword: "",
        },
    });

    return (
        <Form onSubmit={handleSubmit(props.onSubmit)}>
            <Row className="gy-2 flex-column">
                <Col md={4}>
                    <FormInput
                        disabled={props.isLoading}
                        name="oldPassword"
                        title="Old password"
                        type="password"
                        control={control}
                        rules={{ required: true }}
                    />
                </Col>
                <Col md={4}>
                    <FormInput
                        disabled={props.isLoading}
                        name="newPassword"
                        title="New password"
                        control={control}
                        rules={{ required: true }}
                    />
                </Col>
                <Col md={4}>
                    <FormInput
                        disabled={props.isLoading}
                        name="confirmNewPassword"
                        title="New password confirmation"
                        control={control}
                        rules={{ required: true }}
                    />
                </Col>
                <Col>
                    <Button
                        type="submit"
                        color="danger"
                        disabled={!formState.isValid || !formState.isDirty}
                    >
                        {props.isLoading ? <Spinner size="sm" animation="border" /> : "Save"}
                    </Button>
                </Col>
            </Row>
        </Form>
    );
}
