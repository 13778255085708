import { gql } from "@apollo/client";
import { GLOSSARY_ENTRY_FRAGMENT } from "@/graphql/fragments/glossary.fragment";
import { makeGqlMutationHook } from "@/graphql/util";
import {
    CreateGlossaryEntryFromDeeplMutation,
    CreateGlossaryEntryFromDeeplMutationVariables,
} from "@/gql";

const SCHEMA = gql`
    ${GLOSSARY_ENTRY_FRAGMENT}

    mutation createGlossaryEntryFromDeepl(
        $gameId: String!
        $baseGlossaryEntry: RawGlossaryEntryDto!
    ) {
        createGlossaryEntryFromDeepl(gameId: $gameId, baseGlossaryEntry: $baseGlossaryEntry) {
            ...GlossaryEntry
        }
    }
`;

export const useCreateGlossaryEntryFromDeeplMutation = makeGqlMutationHook<
    CreateGlossaryEntryFromDeeplMutation,
    CreateGlossaryEntryFromDeeplMutationVariables
>(SCHEMA);
