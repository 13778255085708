import {
    DeploymentValidationErrorEvent,
    DeploymentValidationEvent,
    DeploymentValidationFailedEvent,
    DeployStep,
} from "@/gql";

type Props = {
    event:
        | DeploymentValidationEvent
        | DeploymentValidationFailedEvent
        | DeploymentValidationErrorEvent;
};

export function DeploymentValidationEventRenderer(props: Props) {
    switch (props.event.deployCode) {
        case DeployStep.DeploymentValidation:
            return <span>Consumer artifact validation</span>;
        case DeployStep.DeploymentValidationFailed:
            return (
                <>
                    <span>Consumer artifact validation failed</span>
                    {(props.event as DeploymentValidationFailedEvent).info.length > 0 && (
                        <>
                            <ul>
                                {(props.event as DeploymentValidationFailedEvent).info.map(
                                    (dataFile) => (
                                        <li key={dataFile.name}>
                                            <code>{dataFile.name}</code>: expected:{" "}
                                            {dataFile.expected}; received: {dataFile.received}
                                        </li>
                                    ),
                                )}
                            </ul>
                        </>
                    )}
                </>
            );
        case DeployStep.DeploymentValidationError:
            return <span>Consumer validation error: {props.event.error}</span>;
        default:
            return null;
    }
}
