import { FieldLabelSerializable, FieldDropdown, FieldTextInput, common } from "blockly";

export default function (registerBlock, { blocklyDeclarationsBus, luaGenerator }) {
    const defs = [];

    defs.push([
        "lk_enum_new",
        {
            def: {
                init: function () {
                    this.appendDummyInput().appendField(
                        new FieldLabelSerializable("Enum name"),
                        "ENUM_NAME",
                    );
                    this.appendDummyInput().appendField(
                        new FieldDropdown(() => this.generateEnumOptions(this)),
                        "ENUM_VALUE",
                    );
                    this.setInputsInline(true);
                    this.setOutput(true, "String");
                    this.setColour(230);
                    this.setTooltip("");
                    this.setHelpUrl("");
                },
                generateEnumOptions(block) {
                    const enumValue = block.getFieldValue("ENUM_NAME");
                    const targetEnum = blocklyDeclarationsBus.enumsMap.get(enumValue);
                    if (!targetEnum) {
                        // this.setEnabled(false);
                        return [["None", "None"]];
                    }
                    return targetEnum.values.map(({ key }) => [key, key]);
                },
            },
            codeGen: function (block) {
                const enumName = block.getFieldValue("ENUM_NAME");
                const enumValue = block.getFieldValue("ENUM_VALUE");
                const code = `${enumName}["${enumValue}"]`;
                return [code, luaGenerator.ORDER_NONE];
            },
        },
    ]);

    defs.push([
        "lk_enum_accessor",
        {
            def: {
                init: function () {
                    this.appendDummyInput("CHAIN")
                        .appendField("Enum: ")
                        .appendField(
                            new FieldDropdown(this.generateEnumOptions, this.validate),
                            "ENUM",
                        );
                    this.setInputsInline(true);
                    this.setColour(270);
                },
                validate: function (newValue) {
                    this.getSourceBlock().updateEnumValues(newValue);
                    return newValue;
                },
                updateEnumValues(newEnumValue) {
                    this.removeInput("ENUM_VALUE", true);

                    const selectedEnum = blocklyDeclarationsBus.enumsMap.get(newEnumValue);

                    if (!selectedEnum) {
                        this.setOutput(false);
                        return false;
                    }

                    const selectedEnumOptions = [
                        ...selectedEnum.values.map((value) => [value.key, value.key]),
                    ];

                    this.appendDummyInput("ENUM_VALUE").appendField(
                        new FieldDropdown(selectedEnumOptions),
                        "ENUM_VALUE",
                    );
                    this.setOutput(true, selectedEnum.type);
                },
                generateEnumOptions() {
                    return blocklyDeclarationsBus.enums.map((enumOption) => [
                        enumOption.name,
                        enumOption.name,
                    ]);
                },
                optionToDropdownOption(option) {
                    return [option, option];
                },
            },
            codeGen: function () {
                const enumName = this.getFieldValue("ENUM");
                const enumValue = this.getFieldValue("ENUM_VALUE");
                return [`${enumName}["${enumValue}"]`, luaGenerator.ORDER_ATOMIC];
            },
        },
    ]);

    defs.push([
        "lk_enum",
        {
            def: {
                init: function () {
                    this.appendStatementInput("FIELDS").setCheck(null).appendField("Enum");
                    this.setColour(270);
                    this.setTooltip("Enum");
                    this.setOutput(true);
                },
                onchange(_e) {
                    if (
                        this.isInFlyout ||
                        (this.workspace.isDragging && this.workspace.isDragging())
                    ) {
                        return; // Don't change state at the start of a drag.
                    }

                    let legal = true;
                    // Is the block nested in a procedure?
                    let block = this.getInput("FIELDS").connection.targetBlock();

                    while (block) {
                        if (this.ENUM_TYPES.includes(block.type)) {
                            block.setWarningText(null);
                            block.setEnabled(true);
                        } else {
                            block.setWarningText("This block is not a valid descendant of enum");
                            block.setEnabled(false);
                            legal = false;
                        }

                        block = block.nextConnection?.targetBlock() ?? null;
                    }

                    if (legal) {
                        this.setWarningText(null);
                    } else {
                        this.setWarningText("Enum has invalid descendants");
                    }
                },
                ENUM_TYPES: ["lk_enum", "lk_enum_keyvalue"],
            },
            codeGen: function (block) {
                const keyValues = luaGenerator.statementToCode(block, "FIELDS") ?? "";
                return [
                    `{${keyValues !== "" ? `\n${keyValues}` : " "}}`,
                    luaGenerator.ORDER_ATOMIC,
                ];
            },
        },
    ]);

    defs.push([
        "lk_enum_keyvalue",
        {
            def: {
                init() {
                    this.appendDummyInput()
                        .appendField("key:")
                        .appendField(new FieldTextInput("0"), "KEY");
                    this.appendValueInput("VALUE")
                        .setCheck(["Number", "String"])
                        .appendField("value");
                    this.setColour(160);
                    this.setInputsInline(true);
                    this.setOutput(null);
                    this.setPreviousStatement(true, "lk_enum_keyvalue");
                    this.setNextStatement(true, "lk_enum_keyvalue");
                    this.setMovable(true);
                    this.setOnChange(function () {
                        this.switchEnableCondition();
                    });
                    this.switchEnableCondition();
                },
                switchEnableCondition() {
                    const parent = this.getParent();
                    const isValidParent = Boolean(
                        this.isInFlyout ||
                            parent?.type === "lk_enum" ||
                            parent?.type === "lk_enum_keyvalue",
                    );
                    this.setEnabled(isValidParent);
                },
            },
            codeGen: function (block) {
                const key = block.getFieldValue("KEY");
                const value =
                    luaGenerator.valueToCode(block, "VALUE", luaGenerator.ORDER_NONE) ?? null;
                return `[${key}]=${value === "" ? null : value},\n`;
            },
        },
    ]);

    common.defineBlocks(defs.reduce((acc, [key, { def }]) => ({ ...acc, [key]: def }), {}));
    defs.forEach(([key, { codeGen }]) => (luaGenerator.forBlock[key] = codeGen));
}
