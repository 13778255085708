import { gql } from "@apollo/client";
import { makeGqlMutationHook } from "@/graphql/util";
import { UpdateTranslationMutation, UpdateTranslationMutationVariables } from "@/gql";
import { TRANSLATION_FRAGMENT } from "@/graphql/fragments/translation.fragment";

const SCHEMA = gql`
    ${TRANSLATION_FRAGMENT}

    mutation updateTranslation(
        $gameId: String!
        $guid: String!
        $key: String!
        $description: String!
        $locStrings: [TranslationStringsDto!]
    ) {
        updateTranslation(
            gameId: $gameId
            guid: $guid
            key: $key
            description: $description
            locStrings: $locStrings
        ) {
            ...Translation
        }
    }
`;

export const useUpdateTranslationMutation = makeGqlMutationHook<
    UpdateTranslationMutation,
    UpdateTranslationMutationVariables
>(SCHEMA);
