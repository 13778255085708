import { DataType } from "@/gql";
import { DataTypeField } from "../../types";
import { ScriptAdditionalParameters } from "./ScriptAdditionalParameters";
import { ScriptTableCell } from "./ScriptTableCell";
import { ScriptFormField } from "./ScriptFormField";

export const ScriptDataType: DataTypeField = {
    dataType: DataType.Script,
    defaultValue: () => ({ blocks: { blocks: [] } }),
    tableCell: ScriptTableCell,
    formField: ScriptFormField,
    additionalParameterFields: ScriptAdditionalParameters,
    formFieldDisplayOptions: { md: 12 },
};
