import { BaseModal } from "@/components/modals/BaseModal";
import { AssetRawData, EditAssetForm } from "@/components/forms/EditAssetForm";

type Props = {
    gameId: string;
    isLoading: boolean;
    error: Error | null;
    isOpen: boolean;
    toggle: () => void;
    asset?: AssetRawData;
    onSubmit: (data: AssetRawData) => void;
};

export function EditAssetModal(props: Props) {
    return (
        <BaseModal
            isOpen={props.isOpen}
            toggle={props.toggle}
            backdrop
            keyboard
            size="lg"
            title={props.asset ? "Edit asset" : "Create asset"}
        >
            <EditAssetForm
                gameId={props.gameId}
                isLoading={props.isLoading}
                error={props.error}
                assetData={props.asset}
                onSubmit={props.onSubmit}
            />
        </BaseModal>
    );
}
