import { useCallback } from "react";
import Button from "react-bootstrap/Button";
import { FaEdit } from "react-icons/fa";
import { ContextActionsProps } from "../../types";
import { useCellContext } from "../../contexts/CellContext";
import { useFieldController } from "../../hooks/useFieldController";
import { showSelectTranslationModal } from "./SelectTranslationModal";

export function LocalizedStringContextActions(props: ContextActionsProps) {
    const { gameId } = useCellContext();
    const controller = useFieldController(props.name);
    const onEdit = useCallback(async () => {
        const value = await showSelectTranslationModal({
            gameId,
            value: controller.field.value as string,
        });

        controller.field.onChange(value);
    }, [gameId, controller.field]);

    return (
        <>
            <Button className="p-1" variant="primary" onClick={onEdit}>
                <FaEdit />
            </Button>
        </>
    );
}
