import { useCallback } from "react";
import { createRoute } from "@tanstack/react-router";
import { LoginPageLayout } from "@/layouts/LoginPageLayout";
import { PasswordResetForm } from "@/components/forms/PasswordResetForm";
import { RequestPasswordResetMutationVariables } from "@/gql";
import { useRequestPasswordReset } from "@/graphql/auth/request-password-reset.mutation";
import { rootRoute } from "@/pages/__root";

export default function PasswordReset() {
    const [requestPasswordReset] = useRequestPasswordReset();
    const onSubmit = useCallback(
        async (variables: RequestPasswordResetMutationVariables) => {
            const { data, errors } = await requestPasswordReset({ variables });
            if (!data) {
                throw errors;
            }
            return data;
        },
        [requestPasswordReset],
    );

    return (
        <LoginPageLayout>
            <PasswordResetForm onSubmit={onSubmit} />
        </LoginPageLayout>
    );
}

export const passwordResetRoute = createRoute({
    getParentRoute: () => rootRoute,
    path: "password-reset",
    component: PasswordReset,
});
