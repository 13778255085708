import { gql } from "@apollo/client";
import { makeGqlQueryLazyHook } from "@/graphql/util";
import {
    CheckTranslationKeyForUniquenessQuery,
    CheckTranslationKeyForUniquenessQueryVariables,
} from "@/gql";

const SCHEMA = gql`
    query checkTranslationKeyForUniqueness($gameId: String!, $key: String!) {
        checkTranslationKeyForUniqueness(gameId: $gameId, key: $key) {
            isOk
        }
    }
`;

export const useCheckTranslationKeyForUniqueness = makeGqlQueryLazyHook<
    CheckTranslationKeyForUniquenessQuery,
    CheckTranslationKeyForUniquenessQueryVariables
>(SCHEMA);
