import { useCallback, useMemo, useState } from "react";
import Select, { MultiValue } from "react-select";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import { UserFragment } from "@/gql";
import { useAddUserPermissionsAction } from "@/store/games/actions";
import { PermissionOption } from "@/components/roles-page/types";
import { useGetUser } from "@/graphql/auth";
import { permissionsList } from "@/components/roles-page/constants";
import { UserSelect } from "@/components/common/UserSelect";

interface AddUserFormProps {
    gameId: string;
    onSubmit?: () => void;
}

export function AddUserForm(props: AddUserFormProps) {
    const { gameId } = props;
    const { data: user } = useGetUser();
    const [addUserPermissionsAction] = useAddUserPermissionsAction();
    const userPermissions = useMemo((): MultiValue<PermissionOption> => {
        if (!user) return [];
        if (user.result.canDoWizardry) return permissionsList;

        const gamePermissions = user.result.permissions?.find(
            (permission) => permission.gameId === gameId,
        );

        if (!gamePermissions) return [];

        return gamePermissions.permissions.map((permission) => ({
            label: permission,
            value: permission,
        }));
    }, [gameId, user]);
    const [selectedUser, setSelectedUser] = useState<UserFragment>();
    const [selectedPermissions, setSelectedPermissions] = useState<PermissionOption[]>([]);
    const canCreate = useMemo(() => {
        return gameId && selectedUser?.guid && selectedPermissions.length > 0;
    }, [gameId, selectedPermissions.length, selectedUser?.guid]);
    const onCreate = useCallback(async () => {
        if (canCreate) {
            await addUserPermissionsAction({
                gameId,
                userId: selectedUser!.guid,
                permissions: selectedPermissions.map((permission) => permission.value),
            });
            typeof props.onSubmit === "function" && props.onSubmit();
        }
    }, [addUserPermissionsAction, canCreate, gameId, props, selectedPermissions, selectedUser]);
    const onPermissionsChange = useCallback((value: MultiValue<PermissionOption>) => {
        setSelectedPermissions([...value]);
    }, []);

    return (
        <Row>
            <Col>
                <div className="small">User</div>
                <UserSelect
                    gameId={gameId}
                    selectedUser={selectedUser}
                    onSetSelectedUser={setSelectedUser}
                />
            </Col>
            <Col>
                <div className="small">Permissions</div>
                <Select
                    isMulti={true}
                    defaultValue={selectedPermissions}
                    menuPortalTarget={document.body}
                    options={userPermissions}
                    onChange={onPermissionsChange}
                />
            </Col>
            <Col>
                <Button disabled={!canCreate} onClick={onCreate}>
                    Add user
                </Button>
            </Col>
        </Row>
    );
}
