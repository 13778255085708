import { useCallback, useDebugValue, useMemo } from "react";
import { AuthenticateUserMutationVariables, RegisterUserMutationVariables } from "@/gql";
import { useLogoutMutation } from "@/graphql/auth/logout";
import { useGetUser, useLoginMutation, useRegistrationMutation } from "@/graphql/auth";

export function useAuth() {
    useDebugValue("useAuth");
    const { data, loading, error, refetch } = useGetUser();
    const [logoutMutation] = useLogoutMutation();
    const [loginMutation] = useLoginMutation();
    const [registrationMutation] = useRegistrationMutation();
    const user = useMemo(() => data?.result, [data]);

    const auth = useCallback(
        async (user: AuthenticateUserMutationVariables | RegisterUserMutationVariables) => {
            if (
                "firstname" in user &&
                "lastname" in user &&
                user.firstname !== "" &&
                user.lastname !== ""
            ) {
                await registrationMutation({
                    variables: user,
                });
            } else {
                await loginMutation({
                    variables: user,
                });
            }
            await refetch();
        },
        [loginMutation, refetch, registrationMutation],
    );

    const logout = useCallback(async () => {
        try {
            window.localStorage.removeItem("token");
            await logoutMutation();
            window.location.reload();
        } catch (e) {
            console.error(e);
        }
    }, [logoutMutation]);

    return useMemo(
        () => ({ user, auth, logout, loading, error, refetch }),
        [user, auth, logout, loading, error, refetch],
    );
}
