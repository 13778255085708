import { useEffect } from "react";
import { Navigate, Outlet, createRoute } from "@tanstack/react-router";
import { Helmet } from "react-helmet-async";
import { AppBody } from "@/components/layout";
import { useAppSelector } from "@/hooks";
import { useLoadGameDetailsAction } from "@/store/games/actions";
import { AppBreadcrumbs } from "@/components/common/AppBreadcrumbs";
import { useHasPermissions } from "@/components/rbac";
import { Permission } from "@/gql";
import { useAuth } from "@/hooks/useAuth";
import { useGraphQlSubscriptions } from "@/hooks/useGraphQlSubscriptions";
import { SuspenseFallback } from "@/components/SuspenseFallback";
import { DeployIndicator } from "@/components/layout/DeployIndicator";
import { gameDetailsSelector } from "@/store/games/selectors";
import { startPage } from "@/pages/StartPage";

export function LayoutWithBreadcrumbs() {
    const { useParams } = layoutWithBreadcrumbs;
    const { gameId } = useParams();
    const { name: gameName, guid: gameStateGuid } = useAppSelector(gameDetailsSelector);
    const isGameLoaded = gameStateGuid !== "";
    const { user } = useAuth();
    const [loadGameDetailsAction] = useLoadGameDetailsAction();
    const userHasPermissions = useHasPermissions(
        [Permission.DataRead, Permission.DataWrite, Permission.CanDeploy],
        true,
    );

    useGraphQlSubscriptions();

    useEffect(() => {
        if (!gameId) return;
        loadGameDetailsAction({ gameId });
    }, [gameId]);
    // }, [dispatch, gameId, loadGameDetailsAction]);

    if (user === null) {
        return <Navigate to="/" />;
    }

    if (!isGameLoaded) {
        return <SuspenseFallback />;
    }

    if (!userHasPermissions) {
        return <Navigate to="/games" />;
    }

    return (
        <AppBody>
            <Helmet titleTemplate={`%s - ${gameName}`} defaultTitle={gameName} />
            <div className="mb-0">
                <DeployIndicator />
            </div>
            <AppBreadcrumbs />
            <Outlet />
        </AppBody>
    );
}

export const layoutWithBreadcrumbs = createRoute({
    getParentRoute: () => startPage,
    path: "$gameId",
    component: LayoutWithBreadcrumbs,
});
