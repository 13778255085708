import { Header } from "@tanstack/react-table";
import cn from "classnames";
import styled from "styled-components";

const Resizer = styled.div`
    position: absolute;
    top: 0;
    height: 100%;
    right: 0;
    width: 5px;
    background: rgba(0, 0, 0, 0.5);
    cursor: col-resize;
    user-select: none;
    touch-action: none;

    &.isResizing {
        background: blue;
        opacity: 1;
    }

    @media (hover: hover) {
        & {
            opacity: 0;
        }

        *:hover > & {
            opacity: 1;
        }
    }
`;

export function ResizeHandler<Data extends Record<string, any>>(props: Header<Data, unknown>) {
    return (
        <Resizer
            {...{
                onDoubleClick: () => props.column.resetSize(),
                onMouseDown: props.getResizeHandler(),
                onTouchStart: props.getResizeHandler(),
                className: cn("resizer", {
                    isResizing: props.column.getIsResizing(),
                }),
            }}
        />
    );
}
