import { FC } from "react";
import { Navigate, Outlet, useLocation, createRoute } from "@tanstack/react-router";
import { Helmet } from "react-helmet-async";
import { useAuth } from "@/hooks/useAuth";
import { JobsPanel } from "@/components/common/JobsPanel";
import Page404 from "@/pages/404";
import { rootRoute } from "@/pages/__root";

export const StartPage: FC = () => {
    const { user, loading, error } = useAuth();
    const location = useLocation();

    if (!loading && !error) {
        if (!user) {
            return <Navigate to="/" />;
        } else if (location.pathname === "/") {
            return <Navigate to="/games" />;
        }
    }

    return (
        <>
            <Helmet title="Eris" />
            <Outlet />
            {location.pathname !== "/" && <JobsPanel />}
        </>
    );
};

export default StartPage;
export const startPage = createRoute({
    getParentRoute: () => rootRoute,
    path: "/",
    component: StartPage,
    notFoundComponent: Page404,
});
