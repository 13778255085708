import { ChangeEvent } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import { DataType, TemplateParam } from "@/gql";
import Form from "../common/forms/Form";
import { NestedFormInput } from "../common/forms/form-inputs/FormInput";
import { AdditionalGlobalParameterFields } from "../common/AdditionalGlobalParameterFields";
import { TemplateParamCreationAttributes } from "./EditTemplateParamForm";

interface GlobalParamFormProps {
    type: DataType;
    param?: TemplateParam;
    isLoading: boolean;
    error: Error | null;
    onSubmit: (data: TemplateParamCreationAttributes) => void;
}

export function GlobalParamForm(props: GlobalParamFormProps) {
    const defaultValues: TemplateParamCreationAttributes = {
        guid: props.param?.guid ?? "",
        name: props.param?.name ?? "",
        meta: props.param?.meta ?? {
            displayName: "",
            defaultValue: null,
            isUnique: false,
            isGlobal: true,
            isRequired: false,
            templateId: null,
            enum: {
                enumValues: [],
                bitMask: false,
            },
            enumId: null,
            scriptScopes: [],
            isProtected: false,
        },
        subType: null,
        consumers: props.param?.consumers ?? [],
        type: props.type,
        description: props.param?.description ?? "",
    };

    return (
        <Form defaultValues={defaultValues} mode="onChange" onSubmit={props.onSubmit}>
            {(methods) => {
                return (
                    <Row className="align-content-center justify-content-start gy-4">
                        <Col xs={12} md={6}>
                            <NestedFormInput
                                name="name"
                                title="Parameter name"
                                rules={{
                                    required: true,
                                    min: 1,
                                    max: 250,
                                    pattern: {
                                        value: /^[A-Z][\w\d]*$/,
                                        message: "Must be camelcase and not end with spaces",
                                    },
                                    onChange: (event: ChangeEvent<HTMLInputElement>) => {
                                        methods.setValue("meta.displayName", event.target.value);
                                    },
                                }}
                            />
                        </Col>
                        <Col xs={12} md={6}>
                            <NestedFormInput
                                name="meta.displayName"
                                title="Displayed name"
                                rules={{ max: 240 }}
                            />
                        </Col>
                        <Col xs={12} md={6}>
                            <NestedFormInput
                                as="textarea"
                                name="description"
                                title="Parameter description"
                                rules={{ required: false }}
                            />
                        </Col>
                        <Col md={12}>
                            <AdditionalGlobalParameterFields dataType={props.type} />
                        </Col>
                        <Col md={12}>
                            <Button
                                type="submit"
                                color="danger"
                                disabled={props.isLoading || !methods.formState.isValid}
                            >
                                {props.isLoading ? (
                                    <Spinner size="sm" animation="border" />
                                ) : (
                                    "Save parameter"
                                )}
                            </Button>
                        </Col>
                    </Row>
                );
            }}
        </Form>
    );
}
