import { useMemo } from "react";
import { stringify } from "@/utils/stringify";
import { useEntitiesTableFormContext } from "../hooks/useEntitiesTableFormContext";

type Props = {
    name: `entities.${string}.values.${string}`;
};

export function TableCellRawValue(props: Props) {
    const formContext = useEntitiesTableFormContext();
    const cellValue = formContext.getValues(props.name);
    const rawValue = useMemo(() => stringify(cellValue), [cellValue]);

    return (
        <div className="small font-monospace p-1">
            value: <pre>{rawValue}</pre>
        </div>
    );
}
