import { gql } from "@apollo/client";
import { makeGqlQueryLazyHook } from "@/graphql/util";
import { GetTranslationFromDeeplQuery, GetTranslationFromDeeplQueryVariables } from "@/gql";

const SCHEMA = gql`
    query getTranslationFromDeepl($gameId: String!, $sourceLocaleId: String!, $value: String!) {
        getTranslationFromDeepl(gameId: $gameId, sourceLocaleId: $sourceLocaleId, value: $value)
    }
`;

export const useGetTranslationFromDeepl = makeGqlQueryLazyHook<
    GetTranslationFromDeeplQuery,
    GetTranslationFromDeeplQueryVariables
>(SCHEMA);
