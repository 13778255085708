import { useMemo } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import sortBy from "lodash/sortBy";
import { CategoryCard } from "@/components/common/CategoryCard";
import { TemplateCategoriesQuery } from "@/gql";
import { useCategoriesSidebarContext } from "@/components/layout/CategoriesSidebarContext";

type Props = {
    className?: string;
    data: TemplateCategoriesQuery["templateCategories"][number];
    onItemSelected: () => void;
};

export function TemplatesCategory(props: Props) {
    const { getIsPinned, togglePinned, getIsFavorite, toggleFavorite } =
        useCategoriesSidebarContext();
    const templates = useMemo(
        () =>
            sortBy(props.data.templates, (template) => {
                return getIsPinned(template.guid) ? -1 : 1;
            }),
        [getIsPinned, props.data.templates],
    );

    return (
        <Container
            as="section"
            id={`category-${props.data.name.replace(/\W/, "")}`}
            fluid
            className={props.className}
        >
            <div className="fs-4 mb-4">{props.data.name}</div>
            <Row className="d-flex flex-wrap g-2">
                {templates.map((template) => (
                    <Col key={template.guid} md={4}>
                        <CategoryCard
                            template={template}
                            onItemSelected={props.onItemSelected}
                            isPinned={getIsPinned(template.guid)}
                            onPin={() => togglePinned(template.guid)}
                            isFavorite={getIsFavorite(template.guid)}
                            onFavorite={() => toggleFavorite(template.guid)}
                        />
                    </Col>
                ))}
            </Row>
        </Container>
    );
}
