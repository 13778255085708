import { gql } from "@apollo/client";

export const VERSIONS_FILE_FRAGMENT = gql`
    fragment VersionsFile on VersionsData {
        artifactId
        dataObjects {
            id
            path
            type
            version
            bucketPath
            fileName
            size
        }
        dictionaries {
            id
            version
            name
            bucketPath
            checksum
            integrityHash
            checksumForDeploy
            checksumForConsumer
            sizeInBytes
        }
        minVersions {
            minVersionData
            maxVersionData
        }
    }
`;
