import { useMemo } from "react";

export type GenericSelectOption = {
    value: string;
    label: string;
};

export function useOptions<T extends { toString(): string }>(options: T[], currentValue?: T) {
    const selectOptions = useMemo(
        () =>
            options.map<GenericSelectOption>((v) => ({
                label: v.toString(),
                value: v.toString(),
            })),
        [options],
    );

    const defaultValue = useMemo(() => {
        return (
            selectOptions.find((v) => v.value === currentValue?.toString()) ?? selectOptions.at(0)
        );
    }, [currentValue, selectOptions]);

    return useMemo(
        () => ({
            selectOptions,
            defaultValue,
        }),
        [defaultValue, selectOptions],
    );
}
