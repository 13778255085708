import { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Alert from "react-bootstrap/Alert";
import { RequestPasswordResetMutation, RequestPasswordResetMutationVariables } from "@/gql";
import { FormInput } from "@/components/common/forms/form-inputs/FormInput";
import { ProgressButton } from "@/components/common/ProgressButton";

interface ResetPasswordFormProps {
    onSubmit: (
        data: RequestPasswordResetMutationVariables,
    ) => Promise<RequestPasswordResetMutation>;
}

export function PasswordResetForm(props: ResetPasswordFormProps) {
    const [submitted, setSubmitted] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<Error | null>(null);
    const { handleSubmit, control } = useForm<RequestPasswordResetMutationVariables>({
        defaultValues: {
            email: "",
        },
        mode: "all",
    });
    const onSubmit = useCallback(
        async (data: RequestPasswordResetMutationVariables) => {
            setIsLoading(true);
            const { result } = await props.onSubmit(data);
            if (!result.isOk) {
                setError(new Error(result.info ?? "Failed to request password reset"));
                return;
            }
            setSubmitted(true);
        },
        [props],
    );

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            {!submitted ? (
                <Row className="align-items-center gy-4">
                    <Col md={12}>
                        <p>
                            If account associated with this email exists you will receive a recovery
                            link
                        </p>
                    </Col>
                    <Col md={12}>
                        <FormInput
                            className="mb-3"
                            control={control}
                            name="email"
                            title="E-mail"
                            rules={{ maxLength: 240, required: true }}
                        />
                    </Col>
                    {error && (
                        <Col>
                            <Alert variant="danger">
                                <Alert.Heading>Error during password reset request</Alert.Heading>
                                <p>{error.message}</p>
                            </Alert>
                        </Col>
                    )}
                    <Col>
                        <ProgressButton
                            variant="primary"
                            type="submit"
                            loading={isLoading}
                            disabled={isLoading}
                        >
                            Request password reset
                        </ProgressButton>
                    </Col>
                </Row>
            ) : (
                <Row className="gy-4">
                    <Col md={12}>
                        <h3>Password request submitted</h3>
                    </Col>
                    <Col md={12}>
                        <p>
                            If account associated with this email exists you will receive a recovery
                            link
                        </p>
                    </Col>
                </Row>
            )}
        </Form>
    );
}
