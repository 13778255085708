import Col from "react-bootstrap/Col";
import Badge from "react-bootstrap/Badge";
import { ErrorBoundary } from "react-error-boundary";
import { fieldsDefinition } from "@/fields";
import { DataType, Template, TemplateParam } from "@/gql";
import { defaultDisplayOptions } from "../constants";
import { EditEntityFormFallback } from "./EditEntityFormFallback";

export function EditEntityFormField(props: {
    templateParameter: TemplateParam;
    index: number;
    gameId: string;
    template: Template;
}) {
    const { templateParameter, gameId, index, template } = props;

    const key = `${templateParameter.guid}-${index}`;
    const definition = fieldsDefinition[templateParameter.type];
    const displaySettings = templateParameter.meta.display;
    const Component = definition?.formField;
    const isRequired =
        templateParameter.meta.isRequired &&
        !(templateParameter.type === DataType.Boolean || templateParameter.type === DataType.List);
    const displayOptions = template.meta.listFieldsVerticallyInEditEntityModal
        ? { md: 12 }
        : typeof displaySettings?.editEntityWidth === "number"
          ? { md: displaySettings.editEntityWidth }
          : definition?.formFieldDisplayOptions ?? defaultDisplayOptions;

    return (
        <Col key={key} {...displayOptions}>
            {!Component ? (
                <Badge bg="danger" className="me-2">
                    {templateParameter.type} is not implemented
                </Badge>
            ) : (
                <ErrorBoundary
                    fallbackRender={(props) => (
                        <EditEntityFormFallback {...props} templateParameter={templateParameter} />
                    )}
                >
                    <Component
                        gameId={gameId}
                        required={isRequired}
                        templateParameter={templateParameter}
                        title={templateParameter.name}
                    />
                </ErrorBoundary>
            )}
        </Col>
    );
}
