import { forwardRef, type ReactNode, type ForwardedRef, type FormEvent } from "react";
import {
    useForm,
    FormProvider,
    type FieldValues,
    type UseFormReturn,
    type UseFormProps,
    type SubmitHandler,
} from "react-hook-form";
import BsForm from "react-bootstrap/Form";

interface FormProps<TFieldValues extends FieldValues = FieldValues> {
    onSubmit: SubmitHandler<TFieldValues>;
    children: ((methods: UseFormReturn<TFieldValues>) => ReactNode) | ReactNode;
}

const FormRefInner = function Form<TFieldValues extends FieldValues = FieldValues>(
    { children, ...props }: UseFormProps<TFieldValues> & FormProps<TFieldValues>,
    ref: ForwardedRef<HTMLFormElement | null>,
) {
    const methods = useForm<TFieldValues>(props);

    // prevent submit in nested form
    // https://github.com/react-hook-form/react-hook-form/issues/1005
    const onSubmit = async (event: FormEvent<HTMLFormElement>) => {
        // this part is for stopping parent forms to trigger their submit
        if (event) {
            // sometimes not true, e.g. React Native
            if (typeof event.preventDefault === "function") {
                event.preventDefault();
            }
            if (typeof event.stopPropagation === "function") {
                // prevent any outer forms from receiving the event too
                event.stopPropagation();
            }
        }

        return methods.handleSubmit(props.onSubmit)(event);
    };

    return (
        <FormProvider {...methods}>
            <BsForm onSubmit={onSubmit} ref={ref}>
                {typeof children === "function" ? children(methods) : children}
            </BsForm>
        </FormProvider>
    );
};

export default forwardRef(FormRefInner) as <TFieldValues extends FieldValues = FieldValues>(
    props: UseFormProps<TFieldValues> &
        FormProps<TFieldValues> & { ref?: ForwardedRef<HTMLFormElement> },
) => ReturnType<typeof FormRefInner>;

// Hacky af way to suppress ref warning

// export default forwardRef(FormRefInner) as <DataType extends FieldValues>(
//     p: UseFormProps<DataType> & FormProps<DataType> & { ref?: Ref<HTMLFormElement> },
// ) => ReactElement;
