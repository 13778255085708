self.MonacoEnvironment = {
    getWorker: function (workerId, label) {
        const getWorkerModule = (moduleUrl, label) => {
            return new Worker(self.MonacoEnvironment.getWorkerUrl(moduleUrl), {
                name: label,
                type: "module",
            });
        };

        switch (label) {
            case "json":
                return getWorkerModule(
                    "/monaco-editor/esm/vs/language/json/json.worker?worker",
                    label,
                );
            default:
                return getWorkerModule("/monaco-editor/esm/vs/editor/editor.worker?worker", label);
        }
    },
};
