import { memo, useMemo } from "react";
import Form from "react-bootstrap/Form";
import { DataType } from "@/gql";
import { useCellContext } from "../../contexts/CellContext";
import { InputProps } from "../../types";
import { useFieldController } from "../../hooks/useFieldController";

export const PrimitiveFieldComponent = memo(function PrimitiveFieldComponent(props: InputProps) {
    const { templateParam } = useCellContext();
    const { isRequired } = useCellContext();
    const controller = useFieldController(props.name);
    const { type, step } = useMemo(() => {
        const isNumericField =
            templateParam.type === DataType.Integer || templateParam.type === DataType.Float;
        return {
            type: isNumericField ? "number" : "text",
            step: templateParam.type === DataType.Float ? "0.001" : undefined,
        };
    }, [templateParam.type]);

    return (
        <Form.Control
            tabIndex={1}
            {...controller.field}
            value={controller.field.value as string}
            className={controller.field.disabled ? "form-control-plaintext" : undefined}
            size="sm"
            step={step}
            type={type}
            required={isRequired}
            isValid={controller.fieldState.error === undefined && !controller.field.disabled}
            isInvalid={controller.fieldState.invalid && !controller.field.disabled}
            placeholder={isRequired ? "Required" : ""}
        />
    );
});
