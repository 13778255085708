import { gql } from "@apollo/client";
import { CONSUMER_FRAGMENT } from "@/graphql/fragments/consumer.fragment";
import { makeGqlMutationHook } from "@/graphql/util";
import { CloneConsumerMutation, CloneConsumerMutationVariables } from "@/gql";

const SCHEMA = gql`
    ${CONSUMER_FRAGMENT}

    mutation cloneConsumer(
        $gameId: String!
        $guid: String!
        $name: String!
        $description: String!
    ) {
        cloneConsumer(gameId: $gameId, guid: $guid, name: $name, description: $description) {
            ...Consumer
        }
    }
`;

export const useCloneConsumerMutation = makeGqlMutationHook<
    CloneConsumerMutation,
    CloneConsumerMutationVariables
>(SCHEMA);
