import { Blocks, FieldTextInput, FieldDropdown, common } from "blockly";

// Taken from blockly/core/names.js
const NameType = {
    DEVELOPER_VARIABLE: "DEVELOPER_VARIABLE",
    VARIABLE: "VARIABLE",
    PROCEDURE: "PROCEDURE",
};

export default function (registerBlock, { luaGenerator }) {
    const defs = [];

    defs.push([
        "lk_table",
        {
            def: {
                init: function () {
                    this.appendStatementInput("FIELDS").setCheck(null).appendField("Table");
                    this.setColour(270);
                    this.setTooltip("Table");
                    this.setOutput(true);
                },
                onchange(_e) {
                    if (
                        this.isInFlyout ||
                        (this.workspace.isDragging && this.workspace.isDragging())
                    ) {
                        return; // Don't change state at the start of a drag.
                    }

                    let legal = true;
                    // Is the block nested in a procedure?
                    let block = this.getInput("FIELDS").connection.targetBlock();

                    while (block) {
                        if (this.ENUM_TYPES.includes(block.type)) {
                            block.setWarningText(null);
                            block.setEnabled(true);
                        } else {
                            block.setWarningText("This block is not a valid descendant of enum");
                            block.setEnabled(false);
                            legal = false;
                        }

                        block = block.nextConnection?.targetBlock() ?? null;
                    }

                    if (legal) {
                        this.setWarningText(null);
                    } else {
                        this.setWarningText("Enum has invalid descendants");
                    }
                },
                ENUM_TYPES: ["lk_table", "lk_table_keyvalue"],
            },
            codeGen: function (block) {
                const keyValues = luaGenerator.statementToCode(block, "FIELDS") ?? "";
                return [
                    `{${keyValues !== "" ? `\n${keyValues}\n` : " "}}`,
                    luaGenerator.ORDER_ATOMIC,
                ];
            },
        },
    ]);

    defs.push([
        "lk_table_method_return",
        {
            def: {
                ...Blocks["procedures_defreturn"],
                // callType_: 'procedures_defreturn',
                init() {
                    Blocks["procedures_defreturn"].init.call(this);
                    this.setPreviousStatement(true, null);
                },
            },
            codeGen: function (block) {
                // This gorgeous piece of code was copied from Blockly procedure generator
                let xfix1 = "";
                if (luaGenerator.STATEMENT_PREFIX) {
                    xfix1 += luaGenerator.injectId(luaGenerator.STATEMENT_PREFIX, block);
                }
                if (luaGenerator.STATEMENT_SUFFIX) {
                    xfix1 += luaGenerator.injectId(luaGenerator.STATEMENT_SUFFIX, block);
                }
                if (xfix1) {
                    xfix1 = luaGenerator.prefixLines(xfix1, luaGenerator.INDENT);
                }
                let loopTrap = "";
                if (luaGenerator.INFINITE_LOOP_TRAP) {
                    loopTrap = luaGenerator.prefixLines(
                        luaGenerator.injectId(luaGenerator.INFINITE_LOOP_TRAP, block),
                        luaGenerator.INDENT,
                    );
                }
                let branch = luaGenerator.statementToCode(block, "STACK");
                let returnValue =
                    luaGenerator.valueToCode(block, "RETURN", luaGenerator.ORDER_NONE) || "";
                let xfix2 = "";
                if (branch && returnValue) {
                    // After executing the function body, revisit this block for the return.
                    xfix2 = xfix1;
                }
                if (returnValue) {
                    returnValue = luaGenerator.INDENT + "return " + returnValue + "\n";
                } else if (!branch) {
                    branch = "";
                }
                const args = [];
                const variables = block.getVars();
                for (let i = 0; i < variables.length; i++) {
                    args[i] = luaGenerator.nameDB_.getName(variables[i], NameType.VARIABLE);
                }
                let code =
                    "function (" +
                    args.join(", ") +
                    ")\n" +
                    xfix1 +
                    loopTrap +
                    branch +
                    xfix2 +
                    returnValue +
                    "end\n";
                // code = luaGenerator.scrub_(block, code);
                return code;
            },
        },
    ]);

    defs.push([
        "lk_table_method_noreturn",
        {
            def: {
                ...Blocks["procedures_defnoreturn"],
                // callType_: 'lk_table_method_noreturn',
                init() {
                    Blocks["procedures_defnoreturn"].init.call(this);
                    this.setPreviousStatement(true, null);
                },
            },
            codeGen: luaGenerator["lk_table_method_noreturn"],
        },
    ]);

    defs.push([
        "lk_table_keyvalue",
        {
            def: {
                init() {
                    this.appendDummyInput()
                        .appendField("Table key:")
                        .appendField(new FieldTextInput("0"), "KEY");
                    this.appendDummyInput().appendField(
                        new FieldDropdown(
                            [
                                ["Property", "PROPERTY"],
                                ["Method", "METHOD"],
                            ],
                            this.validate,
                        ),
                        "TYPE",
                    );
                    this.appendValueInput("VALUE").setCheck(null).appendField("value");
                    this.setInputsInline(true);
                    this.setColour(160);
                    this.setOutput(null);
                    this.setPreviousStatement(true, null);
                    this.setNextStatement(true, null);
                    this.setMovable(true);
                },
                onchange(_e) {
                    // this.updateInputs();
                    this.switchEnableCondition();
                },
                validate(inputType) {
                    const block = this.getSourceBlock();
                    block.removeInput("VALUE", true);
                    if (inputType === "PROPERTY") {
                        block.appendValueInput("VALUE").setCheck(null).appendField("value");
                    } else if (inputType === "METHOD") {
                        block.appendStatementInput("VALUE").setCheck(null).appendField("value");
                    }
                },
                switchEnableCondition() {
                    const parent = this.getParent();
                    const isValidParent = Boolean(
                        this.isInFlyout ||
                            parent?.type === "lk_table" ||
                            parent?.type === "lk_table_keyvalue",
                    );
                    this.setEnabled(isValidParent);
                },
                updateInputs() {
                    const inputType = this.getFieldValue("TYPE");
                    this.removeInput("VALUE", true);
                    if (inputType === "PROPERTY") {
                        this.appendValueInput("VALUE").setCheck(null).appendField("value");
                    } else if (inputType === "METHOD") {
                        this.appendStatementInput("VALUE").setCheck(null).appendField("value");
                    }
                },
            },
            codeGen: function (block) {
                const key = block.getFieldValue("KEY");
                const inputType = this.getFieldValue("TYPE");
                let value = "";
                switch (inputType) {
                    case "METHOD":
                        value = luaGenerator.statementToCode(block, "VALUE");
                        break;
                    case "PROPERTY":
                        value =
                            luaGenerator.valueToCode(block, "VALUE", luaGenerator.ORDER_NONE) ??
                            null;
                        break;
                }
                return `[${key}]=${value === "" ? null : value},`;
            },
        },
    ]);

    common.defineBlocks(defs.reduce((acc, [key, { def }]) => ({ ...acc, [key]: def }), {}));
    defs.forEach(([key, { codeGen }]) => (luaGenerator.forBlock[key] = codeGen));
}
