import { useMemo } from "react";
import cn from "classnames";
import FormText from "react-bootstrap/FormText";
import { FormGroup } from "@/fields/components/FormGroup";
import { useEditEntityFormController } from "@/components/forms/EditEntityForm/hooks/useEditEntityFormController";
import { FormFieldProps } from "../../types";
import { AssetSelector } from "./AssetSelector";

export function AssetFormField(props: FormFieldProps) {
    const { field, fieldState } = useEditEntityFormController(props, "value");
    const className = useMemo(
        () => cn("form-control p-0", { "is-invalid": fieldState.error }),
        [fieldState.error],
    );

    return (
        <FormGroup {...props} fieldState={fieldState}>
            <AssetSelector
                className={className}
                clearable={!props.templateParameter.meta.isRequired}
                {...field}
            />
            {fieldState.error && (
                <FormText className="text-danger">{fieldState.error.message}</FormText>
            )}
        </FormGroup>
    );
}
