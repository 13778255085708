import { type ReactNode, useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import Alert from "react-bootstrap/Alert";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Variant } from "react-bootstrap/types";
import { useNavigate } from "@tanstack/react-router";
import { FormInput } from "@/components/common/forms/form-inputs/FormInput";
import { ProgressButton } from "@/components/common/ProgressButton";

type NewPasswordFormData = { password: string; passwordConfirmation: string };

interface Props {
    onSubmit: (password: string) => void;
}

export function NewPasswordForm(props: Props) {
    const [isLoading, setIsLoading] = useState(false);
    const [passwordsMatch, setPasswordsMatch] = useState(false);
    const navigate = useNavigate();
    const [messageToDisplay, setMessageToDisplay] = useState<{
        variant: Variant;
        message: string | ReactNode;
    } | null>(null);
    const { handleSubmit, control, getValues } = useForm({
        defaultValues: {
            password: "",
            passwordConfirmation: "",
        },
        mode: "all",
    });
    const passwordsCheck = useCallback(() => {
        const [password, passwordConfirmation] = getValues(["password", "passwordConfirmation"]);
        setPasswordsMatch(password.length > 0 && password === passwordConfirmation);
    }, [getValues]);
    const showSuccessMessage = useCallback(() => {
        setMessageToDisplay({
            message: (
                <span>
                    Password has been changed. You can{" "}
                    <Alert.Link onClick={() => navigate({ to: "/" })}>log in</Alert.Link> now
                </span>
            ),
            variant: "success",
        });
    }, [navigate]);
    const showErrorMessage = useCallback((error: Error) => {
        setMessageToDisplay({
            message: `Error happened: ${error.message}`,
            variant: "danger",
        });
    }, []);
    const onSubmit = useCallback(
        async (data: NewPasswordFormData) => {
            try {
                setIsLoading(true);
                await props.onSubmit(data.password);
                showSuccessMessage();
            } catch (e: any) {
                showErrorMessage(e);
            } finally {
                setIsLoading(false);
            }
        },
        [props, showErrorMessage, showSuccessMessage],
    );

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Row className="gy-2" md={12}>
                <Col>
                    <h3>Set new password</h3>
                </Col>
                <Col md={12}>
                    <FormInput
                        className="mb-3"
                        control={control}
                        title="password"
                        name="password"
                        type="password"
                        rules={{ maxLength: 240, required: true, onChange: passwordsCheck }}
                    />
                </Col>
                <Col md={12}>
                    <FormInput
                        className="mb-3"
                        control={control}
                        title="password confirmation"
                        name="passwordConfirmation"
                        type="password"
                        rules={{ maxLength: 240, required: true, onChange: passwordsCheck }}
                    />
                </Col>
                {messageToDisplay !== null && (
                    <Col>
                        <Alert variant={messageToDisplay.variant}>{messageToDisplay.message}</Alert>
                    </Col>
                )}
                <Col>
                    <ProgressButton
                        variant="primary"
                        type="submit"
                        disabled={!passwordsMatch}
                        loading={isLoading}
                    >
                        Set new password
                    </ProgressButton>
                </Col>
            </Row>
        </Form>
    );
}
