import { gql } from "@apollo/client";
import { LogoutMutation } from "@/gql";
import { makeGqlMutationHook } from "../util";

const SCHEMA = gql`
    mutation logout {
        result: logout {
            isOk
            info
        }
    }
`;

export const useLogoutMutation = makeGqlMutationHook<LogoutMutation>(SCHEMA);
