import { memo } from "react";
import { useCellContext } from "../../contexts/CellContext";
import { ErrorBadge } from "../../components/ErrorBadge";
import { EntityRefRenderer } from "../../components/EntityRefRenderer";
import { InputProps } from "../../types";
import { useFieldController } from "../../hooks/useFieldController";

export const InjectedRefReadonlyComponent = memo(function InjectedRefReadonlyComponent(
    props: InputProps,
) {
    const { refTemplateId } = useCellContext();
    const controller = useFieldController(props.name);

    if (!refTemplateId) return <ErrorBadge messageCode="MISSING_REFD_TEMPLATE" />;

    if (!controller.field.value) {
        return <ErrorBadge messageCode="NO_VALUE" />;
    }

    return (
        <EntityRefRenderer
            entityId={controller.field.value as string}
            templateId={refTemplateId}
            depth={0}
        />
    );
});
