import { useMemo } from "react";
import { TableColumn } from "react-data-table-component";
import { createRoute } from "@tanstack/react-router";
import { withRBAC } from "@/components/rbac/withRBAC";
import { ArtifactHistoryEntry, Permission } from "@/gql";
import { useGetArtifactsHistory } from "@/graphql/deploy";
import { DataAwaiter } from "@/components/common/DataAwaiter";
import { DateDisplay } from "@/components/common/DateDisplay";
import { AppDataTable } from "@/components/common/DataTable";
import { UserInfo } from "@/components/common/UserInfo";
import { artifactRoute } from "@/pages/Artifacts";
import { UserInfoAsync } from "@/components/common/UserInfoAsync";

function ArtifactsHistory() {
    const { gameId } = artifactsHistoryRoute.useParams();
    const { data, loading, error } = useGetArtifactsHistory({
        gameId: gameId!,
    });
    const columns = useMemo<TableColumn<ArtifactHistoryEntry>[]>(
        () => [
            {
                name: "Date",
                cell: (row) => <DateDisplay format="DD MMM YYYY HH:mm" date={row.createdAt} />,
            },
            {
                name: "Creator",
                cell: (row) => <UserInfoAsync userId={row.createdBy} />,
            },
            {
                name: "Status",
                cell: (row) => row.status,
            },
            {
                name: "Artifact id",
                cell: (row) =>
                    row.artifactId === null ? "Deleted artifact" : `#${row.artifactId}`,
            },
        ],
        [],
    );

    return (
        <DataAwaiter data={data} isLoading={loading} error={error}>
            {(data) => (
                <AppDataTable
                    columns={columns}
                    data={data.artifactHistoryEntries}
                    pagination
                    paginationPerPage={30}
                />
            )}
        </DataAwaiter>
    );
}

const ArtifactsHistoryRBAC = withRBAC(ArtifactsHistory, {
    requiredPermissions: [Permission.CanDeploy, Permission.DataRead, Permission.StructureRead],
});
export default ArtifactsHistoryRBAC;
export const artifactsHistoryRoute = createRoute({
    getParentRoute: () => artifactRoute,
    path: "history",
    component: ArtifactsHistoryRBAC,
});
