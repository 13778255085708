import Stack from "react-bootstrap/Stack";
import { TableCell } from "@/fields/types";
import { useAddChangeRecord } from "@/components/common/Table/EntitiesTable/contexts/TableCellContext";
import { DurationPicker } from "@/components/common/DurationInput";
import { DisableEntityParameterBtn } from "@/fields/components/DisableEntityParameterBtn";
import { CopyToClipboard } from "@/components/common/CopyToClipboard";

export const DurationTableCell: TableCell = function (props) {
    const { value, addChangeRecord, disabled } = useAddChangeRecord<number>(props);

    return (
        <div>
            <div className="d-flex align-items-center">
                <DisableEntityParameterBtn {...props} />
                <Stack gap={1}>
                    <DurationPicker
                        value={value}
                        readonly={props.readonly || disabled}
                        onChange={addChangeRecord}
                    />
                    <CopyToClipboard value={value} className="w-100 flex-grow-0" />
                </Stack>
            </div>
        </div>
    );
};
