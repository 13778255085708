import { gql } from "@apollo/client";
import { makeGqlMutationHook } from "@/graphql/util";
import { UpdateGameMutation, UpdateGameMutationVariables } from "@/gql";
import { GAME_FRAGMENT } from "@/graphql/fragments/game.fragment";

const SCHEMA = gql`
    ${GAME_FRAGMENT}

    mutation updateGame(
        $guid: String!
        $name: String!
        $description: String!
        $allowSyncFromOrigin: Boolean!
    ) {
        updateGame(
            guid: $guid
            name: $name
            description: $description
            allowSyncFromOrigin: $allowSyncFromOrigin
        ) {
            ...Game
        }
    }
`;

export const useUpdateGameMutation = makeGqlMutationHook<
    UpdateGameMutation,
    UpdateGameMutationVariables
>(SCHEMA);
