import { Selector } from "react-data-table-component";
import { CellRenderer } from "./index";

export function booleanCell<DataType>(selector: Selector<DataType>) {
    const returnValue: CellRenderer<DataType> = (row) => {
        return selector(row).toString();
    };

    return returnValue;
}
