import { type CSSProperties } from "react";
import { type Header } from "@tanstack/react-table";
import { CSS } from "@dnd-kit/utilities";
import { useSortable } from "@dnd-kit/sortable";
import Button from "react-bootstrap/Button";
import { FaArrowDown, FaArrowUp, FaGripVertical } from "react-icons/fa";
import { getCommonPinningStyles } from "./utils";
import { PinColumnButton } from "./PinColumnButton";
import { TableHeaderContent } from "./TableHeaderContent";
import { ResizeHandler } from "./ResizeHandler";

type Props<Data extends Record<string, any>> = {
    header: Header<Data, unknown>;
    pinnedColumns?: boolean;
    className?: string;
};

export function DraggableTableHeader<Data extends Record<string, any>>(props: Props<Data>) {
    const { header } = props;
    const { attributes, isDragging, listeners, setNodeRef, transform } = useSortable({
        id: header.column.id,
        disabled: header.column.getIsPinned() !== false,
    });
    const isAsc = header.column.getIsSorted() !== "desc";

    const style: CSSProperties = {
        opacity: isDragging ? 0.8 : 1,
        position: "relative",
        transform: CSS.Translate.toString(transform), // translate instead of transform to avoid squishing
        transition: "width transform 0.2s ease-in-out",
        whiteSpace: "nowrap",
        zIndex: isDragging ? 1 : 0,
        padding: 0,
        ...getCommonPinningStyles(header.column),
    };

    return (
        <th
            colSpan={header.colSpan}
            ref={setNodeRef}
            style={style}
            key={header.id}
            className={props.className}
        >
            <div className="d-flex gap-1 align-items-center p-2 border border-1 border-top-0 border-bottom-0">
                {props.pinnedColumns && header.column.getCanPin() && (
                    <PinColumnButton {...header} />
                )}
                <TableHeaderContent {...header} />
                {header.column.getCanResize() && <ResizeHandler {...header} />}
                {header.column.getCanSort() && (
                    <Button
                        size="sm"
                        variant={header.column.getIsSorted() !== false ? "primary" : "link"}
                        onClick={header.column.getToggleSortingHandler()}
                    >
                        {isAsc ? <FaArrowDown /> : <FaArrowUp />}
                    </Button>
                )}
                <Button size="sm" {...attributes} {...listeners} variant="link">
                    <FaGripVertical />
                </Button>
            </div>
        </th>
    );
}
