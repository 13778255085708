import { ComponentProps, lazy, Suspense } from "react";
import { SuspenseFallback } from "@/components/SuspenseFallback";

const BlocklyEditorLazy = lazy(async () => {
    const { BlocklyEditor } = await import("./BlocklyEditor");
    return { default: BlocklyEditor };
});

export function BlocklyEditor(props: ComponentProps<typeof BlocklyEditorLazy>) {
    return (
        <Suspense fallback={<SuspenseFallback />}>
            <BlocklyEditorLazy {...props} />
        </Suspense>
    );
}
