import { useCallback, useMemo, useState } from "react";
import { gql, useQuery } from "@apollo/client";
import {
    components,
    type SelectComponentsConfig,
    type GroupBase,
    OptionProps,
    ValueContainerProps,
    SingleValueProps,
    StylesConfig,
    OnChangeValue,
} from "react-select";
import Button from "react-bootstrap/Button";
import { bootstrapDialog, create, NiceModalHocProps, show, useModal } from "@ebay/nice-modal-react";
import Modal from "react-bootstrap/Modal";
import { BaseModal, BaseModalProps } from "@/components/modals/BaseModal";
import { User, GetUsersForSelectQuery } from "@/gql";
import { DataAwaiter2 } from "@/components/common/DataAwaiter2";
import { USER_FRAGMENT } from "@/graphql/fragments/user.fragment";
import { Select } from "@/components/common/Select";
import { UserInfo } from "@/components/common/UserInfo";

const schema = gql`
    ${USER_FRAGMENT}
    query getUsersForSelect {
        wizGetUsers {
            ...User
        }
    }
`;

type Option = GetUsersForSelectQuery["wizGetUsers"][number];
const styles: StylesConfig<Option> = {
    menuPortal: (base) => ({ ...base, zIndex: 10001 }),
};
const Components: SelectComponentsConfig<Option, false, GroupBase<Option>> = {
    Option: (props: OptionProps<Option>) => (
        <components.Option {...props}>
            <UserInfo user={props.data} />
        </components.Option>
    ),
    ValueContainer: (props: ValueContainerProps<Option>) => {
        return (
            <components.ValueContainer {...props}>
                {props.getValue().map((value) => (
                    <UserInfo key={value.guid} user={value} />
                ))}
            </components.ValueContainer>
        );
    },
    SingleValue: (props: SingleValueProps<Option>) => (
        <components.SingleValue {...props}>
            <UserInfo user={props.data} />
        </components.SingleValue>
    ),
};

const SelectUserModal = create(function SelectUserModal() {
    const modal = useModal();
    const dialog = useMemo(() => bootstrapDialog(modal), [modal]);
    const getUsers = useQuery<GetUsersForSelectQuery>(schema);
    const [user, setUser] = useState<User | null>(null);
    const onConfirm = useCallback(() => {
        modal.resolve(user);
        return modal.hide();
    }, [modal, user]);
    const onChange = useCallback((newValue: OnChangeValue<User, false>) => {
        setUser(newValue);
    }, []);

    return (
        <Modal {...dialog}>
            <Modal.Header>
                <Modal.Title>Select owner user</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <DataAwaiter2 {...getUsers}>
                    {(data) => (
                        <>
                            <Select<User, false>
                                styles={styles}
                                components={Components}
                                options={data.wizGetUsers}
                                defaultValue={user}
                                onChange={onChange}
                            />
                            <Button onClick={onConfirm}>Select</Button>
                        </>
                    )}
                </DataAwaiter2>
            </Modal.Body>
        </Modal>
    );
});

export function useSelectUserModal() {
    return useCallback(() => {
        return show<User, NiceModalHocProps, {}>(SelectUserModal);
    }, []);
}
