import FormText from "react-bootstrap/FormText";
import { DataType } from "@/gql";
import { FormFieldProps } from "@/fields/types";
import { useEditEntityFormController } from "@/components/forms/EditEntityForm/hooks/useEditEntityFormController";
import { FormGroup } from "@/fields/components/FormGroup";
import { useGlobalParameters } from "@/store/games/slices";
import { ScriptEditor } from "@/components/common/ScriptEditor";

export function ScriptFormField(props: FormFieldProps) {
    const scriptScopes = useGlobalParameters(DataType.Script);
    const availableEnums = useGlobalParameters(DataType.Enum);

    const { field, fieldState } = useEditEntityFormController(props, "value");

    return (
        <FormGroup {...props} fieldState={fieldState}>
            <ScriptEditor
                value={field.value}
                onChange={field.onChange}
                availableEnums={availableEnums}
                scriptScopes={scriptScopes}
            />
            {fieldState.error && (
                <FormText className="text-danger">{fieldState.error.message}</FormText>
            )}
        </FormGroup>
    );
}
