import { useCallback, useMemo } from "react";
import { bootstrapDialog, create, NiceModalHocProps, show, useModal } from "@ebay/nice-modal-react";
import Modal from "react-bootstrap/Modal";
import { UpdateConsumerMutationVariables } from "@/gql";
import { DataAwaiter2 } from "@/components/common/DataAwaiter2";
import { useGetConsumerForEdit } from "@/graphql/consumers";
import { EditConsumerForm } from "../forms/EditConsumerForm";

type Props = {
    gameId: string;
    consumerId?: string;
};

const EditConsumerModal = create(function EditConsumerModal(props: Props) {
    const { consumerId, gameId } = props;
    const modal = useModal();
    const dialog = useMemo(() => bootstrapDialog(modal), [modal]);
    const getConsumerForEdit = useGetConsumerForEdit({ gameId, consumerId });
    const onSubmit = useCallback(() => {
        modal.resolve();
        return modal.hide();
    }, [modal]);

    return (
        <Modal {...dialog} backdrop="static" keyboard={false} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>{props.consumerId ? "Edit consumer" : "Create consumer"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <DataAwaiter2 {...getConsumerForEdit}>
                    {(data) => (
                        <EditConsumerForm
                            gameId={gameId}
                            /* @ts-ignore */
                            consumer={data.consumer}
                            templates={data.templates}
                            onSubmit={onSubmit}
                        />
                    )}
                </DataAwaiter2>
            </Modal.Body>
        </Modal>
    );
});

export function useEditConsumerModal() {
    return useCallback((props: Props) => {
        return show<UpdateConsumerMutationVariables, NiceModalHocProps & Props, Props>(
            EditConsumerModal,
            props,
        );
    }, []);
}
