import { gql } from "@apollo/client";
import { JOB_FRAGMENT } from "@/graphql/fragments/job.fragment";
import { makeGqlMutationHook } from "@/graphql/util";
import { SyncGameFromOriginMutation, SyncGameFromOriginMutationVariables } from "@/gql";

const SCHEMA = gql`
    ${JOB_FRAGMENT}

    mutation syncGameFromOrigin($gameId: String!) {
        syncGameFromOrigin(gameId: $gameId) {
            ...Job
        }
    }
`;

export const useSyncGameFromOrigin = makeGqlMutationHook<
    SyncGameFromOriginMutation,
    SyncGameFromOriginMutationVariables
>(SCHEMA);
