import Alert from "react-bootstrap/Alert";
import Stack from "react-bootstrap/Stack";
import { Link } from "@tanstack/react-router";
import { GetEntityDetailsQuery } from "@/gql";
import { LocalizationProvider } from "@/components/common/Table/EntitiesTable/contexts/LocalizationProvider";
import { EditEntityForm } from "@/components/forms/EditEntityForm";

type Props = GetEntityDetailsQuery & { gameId: string; entityId: string };

export function EntityPage(props: Props) {
    if (!props.entity) return <Alert>No entity</Alert>;

    return (
        <Stack gap={4}>
            <h3>
                {props.entityId} - {props.entity.template!.name}
            </h3>
            {props.referencingParameters.length > 0 ? (
                <div>
                    <h4>Referenced by:</h4>
                    <ul>
                        {props.referencingParameters.map((param) => (
                            <li
                                key={`${param.guid}-${param.templateId}-${param.templateParameterId}`}
                            >
                                <Link
                                    to={`/${props.gameId}/templates/${param.templateId}/entities`}
                                >
                                    {param.templateName}.{param.templateParameterName}
                                </Link>{" "}
                                <Link to={`/${props.gameId}/entity/${param.guid}`}>
                                    {param.guid}
                                </Link>
                            </li>
                        ))}
                    </ul>
                </div>
            ) : (
                <div>
                    <h4>Referenced by:</h4>
                    Not referenced
                </div>
            )}
            <LocalizationProvider gameId={props.gameId}>
                <EditEntityForm
                    gameId={props.entity.gameId}
                    entityId={props.entity.guid}
                    templateId={props.entity.templateId}
                />
            </LocalizationProvider>
        </Stack>
    );
}
