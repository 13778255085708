import { DataType } from "@/gql";
import { DataTypeField } from "../../types";
import { LocalizedStringEditableTableCell } from "./LocalizedStringEditableTableCell";
import { LocalizedStringFormField } from "./LocalizedStringFormField";

export const LocalizedString: DataTypeField<string> = {
    defaultValue: () => "",
    dataType: [DataType.LocalizedString],
    tableCell: LocalizedStringEditableTableCell,
    formField: LocalizedStringFormField,
    tableCellDisplayOptions: {
        minSize: 400,
        maxSize: 400,
        enableSorting: false,
    },
};
