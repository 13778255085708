import { useController } from "react-hook-form";
import { DataType } from "@/gql";
import { FormFieldProps } from "@/fields/types";
import { useEditEntityFormContext } from "./useEditEntityFormContext";

const nonRequiredTypes = new Set([DataType.Boolean, DataType.List, DataType.Script]);

export function useEditEntityFormController(props: FormFieldProps, field: "value" | "disabled") {
    const name = `${props.templateParameter.guid}.${field}` as const;
    const form = useEditEntityFormContext();
    return useController({
        control: form.control,
        name,
        shouldUnregister: false,
        rules: {
            required: {
                message: "This field is required",
                value:
                    !name.endsWith("disabled") &&
                    props.templateParameter.meta.isRequired &&
                    !nonRequiredTypes.has(props.templateParameter.type),
            },
        },
    });
}
