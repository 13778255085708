import { type ReactNode, useMemo } from "react";
import { ApolloError } from "@apollo/client";
import { GraphQLError } from "graphql/error";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { DataAwaiter } from "../DataAwaiter";
import { AppDataTable, AppDataTableProps } from "../DataTable";

interface TablePageProps<DataType> extends AppDataTableProps<DataType> {
    actionsRow?: () => ReactNode | ReactNode[];
    error?: ApolloError | GraphQLError | null;
    isLoading?: boolean;
}

export function TablePage<DataType>(props: TablePageProps<DataType>) {
    const actionsRow = useMemo(() => {
        if (typeof props.actionsRow !== "function") return null;
        return <Row>{props.actionsRow()}</Row>;
    }, [props]);

    return (
        <Row>
            <Col>
                {actionsRow}
                <Row>
                    <Col>
                        <DataAwaiter
                            isLoading={props.isLoading}
                            error={props.error}
                            data={props.data}
                        >
                            {(games) => <AppDataTable columns={props.columns} data={games} />}
                        </DataAwaiter>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}
