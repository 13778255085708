import { ReactNode, useMemo } from "react";
import { Link, ToPathOption, AnyRouter, RegisteredRouter } from "@tanstack/react-router";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import NavItem from "react-bootstrap/NavItem";
import { Permission } from "@/gql";
import { RBACWrapper } from "@/components/rbac";
import { layoutWithBreadcrumbs } from "@/layouts/LayoutWithBreadcrumbs";

type Props<in out TRouter extends AnyRouter = RegisteredRouter, in out TTo extends string = ""> = {
    permissions: Permission[];
    oneOf?: boolean;
    href: ToPathOption<TRouter, typeof layoutWithBreadcrumbs.to, TTo>;
    name: string;
    children: ReactNode | (() => ReactNode);
};

export function NavbarItem(props: Props) {
    const renderTooltip = useMemo(
        () => <Tooltip id="button-tooltip">{props.name}</Tooltip>,
        [props],
    );

    return (
        <RBACWrapper requiredPermissions={props.permissions} oneOf={props.oneOf}>
            <NavItem as="li">
                <OverlayTrigger
                    placement="right"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltip}
                >
                    {({ ref, ...triggerHandler }) => (
                        <Link
                            ref={ref}
                            {...triggerHandler}
                            from={layoutWithBreadcrumbs.to}
                            to={props.href}
                            aria-current="page"
                            className="nav-link"
                            activeProps={{ className: "active" }}
                        >
                            {typeof props.children === "function"
                                ? props.children()
                                : props.children}
                        </Link>
                    )}
                </OverlayTrigger>
            </NavItem>
        </RBACWrapper>
    );
}
