import { gql } from "@apollo/client";

export const LOCALE_FRAGMENT = gql`
    fragment Locale on Locale {
        guid
        gameId
        code
        name
        isBaseLocale
    }
`;
