import { createRoute } from "@tanstack/react-router";
import { useGetGamesQuery } from "@/graphql/wizardry/games";
import { DataAwaiter2 } from "@/components/common/DataAwaiter2";
import { GamesList } from "@/components/wizardry/games/GamesList";
import { wizardryRoute } from "@/pages/wizardry/WizardryLayout";

export default function GamesWizardry() {
    const getGamesQuery = useGetGamesQuery();

    return (
        <DataAwaiter2 {...getGamesQuery}>
            {(data) => <GamesList data={data.wizGetGames} />}
        </DataAwaiter2>
    );
}
export const wizardryGamesRoute = createRoute({
    getParentRoute: () => wizardryRoute,
    path: "games",
    component: GamesWizardry,
});
