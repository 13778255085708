import { gql } from "@apollo/client";
import { GetArtifactQuery, GetArtifactQueryVariables } from "@/gql";
import { ARTIFACT_FRAGMENT } from "@/graphql/fragments/artifact.fragment";
import { makeGqlQueryHook } from "../util";

const SCHEMA = gql`
    ${ARTIFACT_FRAGMENT}

    query getArtifact($guid: String!, $gameId: String!) {
        artifact(guid: $guid, gameId: $gameId) {
            ...Artifact
            consumerArtifacts {
                guid
                gameId
                consumerId
                consumerName
                deployFlags {
                    name
                    value
                    description
                }
                dataFiles {
                    id
                    name
                    version
                }
            }
        }
    }
`;
// fetchPolicy: no-cache - это магическое исправление магического бага,
// когда результаты мистическим образом объединялись, искажаясь и выдавая на выходе какую-то чушь
export const useGetArtifact = makeGqlQueryHook<GetArtifactQuery, GetArtifactQueryVariables>(
    SCHEMA,
    {
        fetchPolicy: "no-cache",
    },
);
