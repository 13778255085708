import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import Stack from "react-bootstrap/Stack";
import FormText from "react-bootstrap/FormText";
import { useLocalization } from "@/fields/datatypes/LocalizedString/contexts/useLocalization";
import { FormFieldProps } from "@/fields/types";
import { CasedBadge } from "@/components/common/CasedBadge";
import { TranslationSelect } from "@/fields/datatypes/LocalizedString/TranslationSelect";
import { useEditEntityFormController } from "@/components/forms/EditEntityForm/hooks/useEditEntityFormController";
import { FormGroup } from "@/fields/components/FormGroup";

export function LocalizedStringFormField(props: FormFieldProps) {
    const { field, fieldState } = useEditEntityFormController(props, "value");
    const [isLegacyValue, isLoading, translation, safeValue] = useLocalization(field.value);

    return (
        <FormGroup {...props} fieldState={fieldState}>
            {isLoading && (
                <Button size="sm">
                    <Spinner />
                </Button>
            )}
            <Stack gap={2}>
                {isLegacyValue && (
                    <CasedBadge bg="warning">
                        Legacy value: {JSON.stringify(field.value)}
                    </CasedBadge>
                )}
                {!isLoading && !translation && (
                    <CasedBadge bg="warning">Translation with id {safeValue} not found</CasedBadge>
                )}
                <TranslationSelect
                    className="flex-grow-1"
                    gameId={props.templateParameter.gameId}
                    value={field.value}
                    onChange={field.onChange}
                />
            </Stack>
            {fieldState.error && (
                <FormText className="text-danger">{fieldState.error.message}</FormText>
            )}
        </FormGroup>
    );
}
