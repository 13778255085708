import { gql } from "@apollo/client";
import { USER_PERMISSIONS_FRAGMENT } from "@/graphql/fragments/user-permissions.fragment";
import { makeGqlMutationHook } from "@/graphql/util";
import {
    WizSetUserPermissionsForProjectMutation,
    WizSetUserPermissionsForProjectMutationVariables,
} from "@/gql";

const schema = gql`
    ${USER_PERMISSIONS_FRAGMENT}

    mutation wizSetUserPermissionsForProject(
        $userId: String!
        $gameId: String!
        $permissions: [Permission!]!
    ) {
        wizSetUserPermissionsForProject(
            userId: $userId
            gameId: $gameId
            permissions: $permissions
        ) {
            ...UserPermission
        }
    }
`;

export const useSetUserPermissionsForProject = makeGqlMutationHook<
    WizSetUserPermissionsForProjectMutation,
    WizSetUserPermissionsForProjectMutationVariables
>(schema);
