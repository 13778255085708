import { memo } from "react";
import { useCellContext } from "../../contexts/CellContext";
import { InputProps } from "../../types";
import { useFieldController } from "../../hooks/useFieldController";
import { AssetSelector } from "./AssetSelector";

export const AssetFieldComponent = memo(function AssetFieldComponent(props: InputProps) {
    const { templateParam } = useCellContext();
    const controller = useFieldController(props.name);
    return (
        <AssetSelector
            ref={controller.field.ref}
            clearable={!templateParam.meta.isRequired}
            name={controller.field.name}
            value={controller.field.value as string}
            onChange={controller.field.onChange}
            className={props.className}
        />
    );
});
