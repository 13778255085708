import { createContext, memo, type PropsWithChildren, useContext, useMemo } from "react";

type NestingContextProps = {
    depth: number;
    maxDepth: number;
    shouldExpand: boolean;
};

const MAX_NESTING_DEPTH = 3;

export const NestingContext = createContext<NestingContextProps>({
    depth: 0,
    maxDepth: MAX_NESTING_DEPTH,
    shouldExpand: false,
});

export function useNestingContext() {
    return useContext(NestingContext);
}

export const NestingContextProvider = memo(function NestingContextProvider(
    props: PropsWithChildren<{ shouldExpand?: boolean; depth?: number }>,
) {
    const nestingContext = useNestingContext();
    const value = useMemo<NestingContextProps>(
        () => ({
            maxDepth: MAX_NESTING_DEPTH,
            depth: nestingContext.depth + 1,
            shouldExpand: nestingContext.shouldExpand || Boolean(props.shouldExpand),
        }),
        [nestingContext.depth, props.shouldExpand, nestingContext.shouldExpand],
    );

    return <NestingContext.Provider value={value}>{props.children}</NestingContext.Provider>;
});
