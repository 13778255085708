import { memo } from "react";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import { TranslationRenderer } from "@/components/common/LocalizationRenderer";
import { CasedBadge } from "@/components/common/CasedBadge";
import { useLocalizationContext } from "../../contexts/LocalizationProvider";
import { useFieldController } from "../../hooks/useFieldController";
import { InputProps } from "../../types";
import { useLocalization } from "./contexts/useLocalization";

export const LocalizedStringFieldComponent = memo(function LocalizedStringFieldComponent(
    props: InputProps,
) {
    const { localesMap } = useLocalizationContext();
    const controller = useFieldController(props.name);
    const value = controller.field.value as string;
    const [isLegacyValue, isLoading, translation] = useLocalization(value);

    return (
        <div className="d-flex align-items-start gap-2">
            {isLoading && (
                <Button size="sm">
                    <Spinner size="sm" />
                </Button>
            )}
            {isLegacyValue ? (
                <CasedBadge bg="warning">Legacy value: {value}</CasedBadge>
            ) : translation ? (
                <TranslationRenderer
                    legacy={isLegacyValue}
                    data={translation}
                    localesMap={localesMap}
                />
            ) : (
                <CasedBadge>No value</CasedBadge>
            )}
        </div>
    );
});
