import { components, ValueContainerProps } from "react-select";
import { ReactNode } from "react";
import { EditEntityBtn2 } from "@/components/common/EditEntityBtn2";
import { EntityRefRenderer } from "@/components/common/EntityRefRenderer";
import { ActionsToolbar, EntityRendererWrapper } from "./components";
import { ComponentOption } from "./types";

export function ValueContainer(props: ValueContainerProps<ComponentOption>) {
    // Last child is assumed to be an input
    const valueInput = (props.children as ReactNode[])?.at(-1);

    return (
        <components.ValueContainer {...props}>
            {props.getValue().map((value) => (
                <EntityRendererWrapper key={value.guid}>
                    <ActionsToolbar vertical>
                        <EditEntityBtn2
                            className="p-1"
                            variant="primary"
                            gameId={value.gameId}
                            entityId={value.guid}
                        />
                    </ActionsToolbar>
                    <EntityRefRenderer
                        key={value.guid}
                        entityId={value.guid}
                        templateId={value.templateId}
                    />
                </EntityRendererWrapper>
            ))}
            {valueInput !== undefined && valueInput}
        </components.ValueContainer>
    );
}
