import { memo, useCallback, useMemo } from "react";
import { useFieldArray } from "react-hook-form";
import Button from "react-bootstrap/Button";
import { FaPlus } from "react-icons/fa";
import Badge from "react-bootstrap/Badge";
import {
    closestCenter,
    DndContext,
    DragEndEvent,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
} from "@dnd-kit/core";
import { restrictToParentElement } from "@dnd-kit/modifiers";
import { SortableContext, sortableKeyboardCoordinates } from "@dnd-kit/sortable";
import { MovableCard } from "@/components/common/MovableCard";
import { DataType } from "@/gql";
import { AllowedListSubType } from "@/types";
import { useTableContext } from "../../contexts/TableContext";
import { InputProps } from "../../types";
import { useFieldsDefinitions } from "../../hooks/useFieldsDefinitions";
import { useCellContext } from "../../contexts/CellContext";
import { useEntitiesTableFormContext } from "../../hooks/useEntitiesTableFormContext";
import { ItemsPanel } from "./List.components";

export const ListFieldComponent = memo(function ListFieldComponent(props: InputProps) {
    const { getDefinition } = useFieldsDefinitions();
    const cellContext = useCellContext();
    const tableContext = useTableContext();
    const subType = cellContext.subType as AllowedListSubType;
    const methods = useEntitiesTableFormContext();
    const watchArray = methods.watch(props.name) as { value: string }[];
    const fieldArrayMethods = useFieldArray({
        control: methods.control,
        name: props.name,
    });
    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        }),
    );
    const controlledFields = useMemo(
        () =>
            watchArray !== undefined
                ? fieldArrayMethods.fields.map((field, index) => {
                      return {
                          ...(field as { value: string; id: string }),
                          ...watchArray[index],
                      };
                  })
                : [],
        [fieldArrayMethods.fields, watchArray],
    );
    const valueIds = useMemo(() => controlledFields.map((value) => value.id), [controlledFields]);

    const onAdd = useCallback(() => {
        const newValue = getDefinition(subType).defaultValue(cellContext.templateParam);
        // @ts-ignore
        fieldArrayMethods.append([{ value: newValue }]);
    }, [cellContext.templateParam, fieldArrayMethods, getDefinition, subType]);

    const onRemove = useCallback(
        (index: number) => {
            fieldArrayMethods.remove(index);
        },
        [fieldArrayMethods],
    );
    const handleDragEnd = useCallback(
        (event: DragEndEvent) => {
            const { active, over } = event;

            if (active.id !== over?.id) {
                const oldIndex = controlledFields.findIndex((value) => value.id === active.id);
                const newIndex = controlledFields.findIndex((value) => value.id === over?.id);

                fieldArrayMethods.move(oldIndex, newIndex);
            }
        },
        [controlledFields, fieldArrayMethods],
    );

    return (
        <div className="d-grid gap-2">
            {controlledFields.length > 0 ? (
                <ItemsPanel>
                    <DndContext
                        sensors={sensors}
                        collisionDetection={closestCenter}
                        onDragEnd={handleDragEnd}
                        modifiers={[restrictToParentElement]}
                    >
                        <SortableContext items={valueIds} disabled={tableContext.readonly}>
                            {controlledFields.map((value, index) => (
                                <MovableCard
                                    key={value.id}
                                    id={value.id}
                                    className="d-flex flex-nowrap gap-1 justify-content-between align-items-start mb-1"
                                    onRemove={() => onRemove(index)}
                                >
                                    <div className="flex-grow-1">
                                        <ConditionalInput
                                            arrayField={props.name}
                                            index={index}
                                            subType={subType}
                                        />
                                    </div>
                                </MovableCard>
                            ))}
                        </SortableContext>
                    </DndContext>
                </ItemsPanel>
            ) : (
                <Badge bg="success">Empty list</Badge>
            )}
            {!tableContext.readonly && (
                <Button onClick={onAdd} size="sm" tabIndex={-1}>
                    <FaPlus size={12} />
                    Add
                </Button>
            )}
        </div>
    );
});

type Props2 = {
    subType: DataType;
    index: number;
    arrayField: `entities.${string}.values.${string}`;
};

function ConditionalInput(props: Props2) {
    const { getDefinition } = useFieldsDefinitions();
    const Component = getDefinition(props.subType).inputComponent;

    return <Component name={`${props.arrayField}.${props.index}.value`} />;
}
