import Button from "react-bootstrap/Button";
import classNames from "classnames";
import styled from "styled-components";
import Spinner from "react-bootstrap/Spinner";
import { useDeploy } from "@/hooks/useDeploy";
import { DeployResults } from "../common/Deploy/components/DeployResults";
import { BaseModal, BaseModalProps } from "./BaseModal";

const MessagesContainer = styled.section`
    max-height: 75vh;
    overflow: scroll;
`;

export function DeployModal({ isOpen, toggle }: BaseModalProps) {
    const { messages, deployFinished, hasErrors, deployInProgress } = useDeploy();

    return (
        <BaseModal
            isOpen={isOpen}
            toggle={toggle}
            title={<>Deploy {deployInProgress && <Spinner size="sm" />}</>}
            footer={() => <Button onClick={() => toggle()}>Ok</Button>}
            size="lg"
            contentClassName={classNames({
                "bg-gradient-warning": hasErrors,
                "bg-gradient-success": deployFinished,
            })}
        >
            <MessagesContainer>
                <DeployResults results={messages} />
            </MessagesContainer>
        </BaseModal>
    );
}
