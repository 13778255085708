import { memo, useCallback } from "react";
import { useTableContext } from "../../contexts/TableContext";
import { useCellContext } from "../../contexts/CellContext";
import { InputProps } from "../../types";
import { ErrorBadge } from "../../components/ErrorBadge";
import { useFieldController } from "../../hooks/useFieldController";
import { useEntitiesTableFormContext } from "../../hooks/useEntitiesTableFormContext";
import { InjectedRefICTableCell } from "./InjectedRefICTableCell";

export const InjectedRefFieldComponent = memo(function InjectedRefFieldComponent(
    props: InputProps,
) {
    const { readonly } = useTableContext();
    const { templateParam, contextEntity, gameId } = useCellContext();
    const { setValue } = useEntitiesTableFormContext();
    const controller = useFieldController(props.name);
    const templateId = templateParam.meta.templateId;
    const onChange = useCallback(
        (guid: string | null) => {
            setValue(controller.field.name, guid);
        },
        [controller.field.name, setValue],
    );

    if (!templateId) return <ErrorBadge messageCode="MISSING_TEMPLATEID" />;

    return (
        <InjectedRefICTableCell
            gameId={gameId}
            ref={controller.field.ref}
            value={controller.field.value as string}
            onChange={onChange}
            templateId={templateId}
            readonly={readonly}
            clearable={!templateParam.meta.isRequired}
            contextEntity={contextEntity}
        />
    );
});
