import { memo } from "react";
import { InView } from "react-intersection-observer";
import Badge from "react-bootstrap/Badge";
import Spinner from "react-bootstrap/Spinner";
import { useAddChangeRecord } from "@/components/common/Table/EntitiesTable/contexts/TableCellContext";
import { EntityRefRenderer } from "@/components/common/EntityRefRenderer";
import { useTemplate2 } from "@/store/games/slices";
import { DisableEntityParameterBtn } from "@/fields/components/DisableEntityParameterBtn";
import { FieldProps, TableCell } from "../../types";
import { EntityRefSelector } from "./EntityRefSelector";

export const EntityRefTableCell: TableCell = memo(function EntityRefTableCell(props) {
    const templateId = props.templateParam.meta.templateId;
    const { value, addChangeRecord } = useAddChangeRecord(props);

    if (!templateId) {
        return <Badge bg="danger">Missing reference template</Badge>;
    }

    if (props.readonly) {
        return <EntityRefRenderer entityId={value} templateId={templateId} />;
    }

    return (
        <div className="d-flex align-items-start">
            <DisableEntityParameterBtn {...props} />
            <EntityRefField
                gameId={props.gameId}
                templateParam={props.templateParam}
                contextEntity={props.tableCellProps.row}
                clearable={!props.templateParam.meta.isRequired}
                value={value}
                onChange={addChangeRecord}
                templateId={templateId}
                readonly={props.readonly}
            />
        </div>
    );
});

export function EntityRefField(
    props: FieldProps<string> & { clearable?: boolean; templateId: string },
) {
    const [template, loading] = useTemplate2(props.templateId);

    if (loading) return <Spinner size="sm" />;

    if (!template) {
        return <Badge bg="danger">Missing referenced template</Badge>;
    }

    return (
        <InView triggerOnce trackVisibility={false}>
            {({ inView, ref, entry }) => (
                <div ref={ref}>
                    {inView && (
                        <EntityRefSelector
                            gameId={props.templateParam.gameId}
                            contextEntity={props.contextEntity}
                            clearable={props.clearable}
                            value={props.value}
                            onChange={props.onChange}
                            templateId={props.templateId}
                            creatable
                        />
                    )}
                </div>
            )}
        </InView>
    );
}
