import { FaEye, FaEyeSlash } from "react-icons/fa";
import styled from "styled-components";
import ToggleButton from "react-bootstrap/ToggleButton";
import { ALLOW_DISABLING_PARAMETERS, useProjectHasFlag } from "@/store/games/slices";
import { useEditEntityFormController } from "@/components/forms/EditEntityForm/hooks/useEditEntityFormController";
import { FormFieldProps } from "@/fields/types";

const DisableButton = styled(ToggleButton)`
    width: 1.5rem;
    min-width: 1.5rem;
    height: 1.5rem;
    min-height: 1.5rem;
    line-height: 1;
    padding: 0;
    vertical-align: middle;
`;

type Props = FormFieldProps & {
    className?: string;
};

export function EditEntityFormDisableValueBtn(props: Props) {
    const isFeatureEnabled = useProjectHasFlag(ALLOW_DISABLING_PARAMETERS);
    const { field } = useEditEntityFormController(props, "disabled");

    if (props.templateParameter.meta.isRequired || props.templateParameter.meta.isProtected)
        return null;

    return (
        <DisableButton
            id={field.name}
            value={field.name}
            type="checkbox"
            ref={field.ref}
            checked={field.value}
            name={field.name}
            onChange={field.onChange}
            onBlur={field.onBlur}
            title={props.title}
            disabled={field.disabled || !isFeatureEnabled}
        >
            {!field.value ? <FaEye /> : <FaEyeSlash />}
        </DisableButton>
    );
}
