import { useLayoutEffect, useMemo } from "react";
import mapValues from "lodash/mapValues";
import { useAppSelector } from "@/hooks";
import { useLoadEntities } from "@/store/entities/actions";
import { entitiesSelector } from "@/store/entities/selectors";
import { FormEntity2 } from "../types";

export function useTableEntities(templateId: string, gameId: string): Record<string, FormEntity2> {
    const allEntities = useAppSelector(entitiesSelector);
    const [loadEntities] = useLoadEntities();

    useLayoutEffect(() => {
        loadEntities({ templateId, gameId });
    }, [templateId, gameId, loadEntities]);

    return useMemo(
        () =>
            mapValues(allEntities, (entity): FormEntity2 => {
                // New table is not supported
                // @ts-ignore
                const entityParameters = entity.entityParameters.map((entityParameter) => {
                    if (Array.isArray(entityParameter.value)) {
                        return {
                            ...entityParameter,
                            // New table is not supported
                            // @ts-ignore
                            value: entityParameter.value.map((value) => ({ value })),
                        };
                    }
                    return entityParameter;
                });
                const values = Object.fromEntries(
                    // New table is not supported
                    // @ts-ignore
                    entityParameters.map((param) => [param.templateParameterId, param.value]) ?? [],
                );

                return {
                    guid: entity.guid,
                    // New table is not supported
                    // @ts-ignore
                    templateId: entity.templateId,
                    export: entity.export,
                    entityParameters: entityParameters,
                    values,
                };
            }),
        [allEntities],
    );
}
