import { useCallback, useState } from "react";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";
import { useForm } from "react-hook-form";
import { useNavigate } from "@tanstack/react-router";
import { FormInput } from "../common/forms/form-inputs/FormInput";

export interface LoginFormData {
    email: string;
    password: string;
    firstname: string;
    lastname: string;
}

export interface LoginFormProps {
    isLoading?: boolean;
    onSubmit: (data: LoginFormData & { login: boolean }) => void;
    error?: Error | null;
}

export function LoginForm({
    onSubmit: originalOnSubmit,
    isLoading = false,
    error: authError,
}: LoginFormProps) {
    const navigate = useNavigate();
    const [isLoginMode, setLoginMode] = useState(true);
    const { handleSubmit, control } = useForm<LoginFormData>({
        defaultValues: {
            firstname: "",
            lastname: "",
            email: "",
            password: "",
        },
        mode: "all",
    });
    const onPasswordReset = useCallback(() => {
        return navigate({ to: "/password-reset" });
    }, [navigate]);

    const onSubmit = useCallback(
        (data: LoginFormData) => {
            originalOnSubmit({ ...data, login: isLoginMode });
        },
        [isLoginMode, originalOnSubmit],
    );

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Row className="align-items-center">
                <Col md={12}>
                    <h1 className="h3 mb-3 fw-normal">Please sign in</h1>
                </Col>

                {!isLoginMode && (
                    <>
                        <Col md={12}>
                            <FormInput
                                className="mb-3"
                                control={control}
                                title="First name"
                                name="firstname"
                                rules={{ maxLength: 240, required: true }}
                            />
                        </Col>
                        <Col md={12}>
                            <FormInput
                                className="mb-3"
                                control={control}
                                title="Last name"
                                name="lastname"
                                rules={{ maxLength: 240, required: true }}
                            />
                        </Col>
                    </>
                )}
                <Col md={12}>
                    <FormInput
                        className="mb-3"
                        control={control}
                        name="email"
                        title="E-mail"
                        rules={{ maxLength: 240, required: true }}
                    />
                </Col>
                <Col md={12}>
                    <FormInput
                        className="mb-3"
                        control={control}
                        title="password"
                        name="password"
                        type="password"
                        rules={{ maxLength: 240, required: true }}
                    />
                    <Button variant="link" size="sm" onClick={onPasswordReset}>
                        Reset password?
                    </Button>
                </Col>
                <Col md={12} className="mb-4">
                    {authError ? <Alert variant="danger">{authError.message}</Alert> : null}
                </Col>
                <Col>
                    <Button variant="link" onClick={() => setLoginMode(!isLoginMode)}>
                        {isLoginMode ? "Switch to registration" : "Switch to Login"}
                    </Button>
                </Col>
                <Col>
                    <Button variant="primary" type="submit">
                        {isLoading ? <Spinner animation="border" /> : null}
                        {isLoginMode ? "Sign in" : "Register"}
                    </Button>
                </Col>
            </Row>
        </Form>
    );
}
