import { components, SingleValueProps } from "react-select";
import { EntityRefRenderer } from "@/components/common/EntityRefRenderer";
import { ComponentOption } from "./types";

export function SingleValue(props: SingleValueProps<ComponentOption>) {
    return (
        <components.SingleValue {...props}>
            <EntityRefRenderer entityId={props.data.guid} templateId={props.data.templateId} />
        </components.SingleValue>
    );
}
