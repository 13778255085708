import { FieldLabelSerializable, FieldDropdown, common } from "blockly";

export default function (registerBlock, { luaGenerator }) {
    const defs = [];

    defs.push([
        "lk_another_accessor",
        {
            def: {
                init: function () {
                    this.outputType = null;
                    this.functionArguments = [];
                    this.appendDummyInput("FUNCTION_NAME").appendField(
                        new FieldLabelSerializable(),
                        "FUNCTION_NAME",
                    );
                    this.updateInputs();
                    this.setInputsInline(false);
                    this.setColour(270);
                    this.setOutput(false);
                },
                saveExtraState() {
                    return {
                        outputType: this.outputType,
                        functionArguments: this.functionArguments,
                    };
                },
                loadExtraState(state) {
                    this.outputType = state.outputType ?? null;
                    this.functionArguments = state.functionArguments ?? [];
                    this.updateInputs();
                },
                updateInputs() {
                    this.setOutput(true, this.outputType);
                    this.functionArguments.forEach((functionArgument) => {
                        this.appendValueInput(functionArgument.name.toUpperCase())
                            .setCheck(functionArgument.type)
                            .appendField(functionArgument.name);
                    });
                },
            },
            codeGen: function (block) {
                const functionName = this.getFieldValue("FUNCTION_NAME");
                const functionArguments = this.functionArguments
                    .map((functionArgument) => {
                        return luaGenerator.valueToCode(
                            block,
                            functionArgument.name.toUpperCase(),
                            luaGenerator.ORDER_NONE,
                        );
                    })
                    .join(", ");
                return `${functionName}(${functionArguments})`;
            },
        },
    ]);

    defs.push([
        "lk_property_accessor",
        {
            def: {
                init() {
                    this.typeName = null;
                    this.properties = [];
                    this.appendDummyInput().appendField("Get");
                    this.setColour(270);
                },
                saveExtraState() {
                    return { typeName: this.typeName, properties: this.properties };
                },
                loadExtraState(state) {
                    this.typeName = state.typeName;
                    this.properties = state.properties;
                    this.updateBlockInterface();
                },
                updateBlockInterface() {
                    // This will be overridden after dropdown is created
                    this.setOutput(true, null);
                    this.appendValueInput("VALUE_SOCKET")
                        .setCheck(this.typeName)
                        .appendField(this.typeName);
                    this.appendDummyInput("DROPDOWN_SOCKET").appendField(
                        new FieldDropdown(
                            this.properties.map((property) => [property.name, property.name]),
                            this._validateDropdownValue,
                        ),
                        "PROPERTIES_DROPDOWN",
                    );
                },
                _validateDropdownValue(newDropdownValue) {
                    const sourceBlock = this.getSourceBlock();
                    const selectedValue =
                        sourceBlock.properties.find(
                            (property) => property.name === newDropdownValue,
                        ) ?? null;
                    sourceBlock.setOutput(true, selectedValue.type);
                },
            },
            codeGen(block) {
                const valueSocketValue = luaGenerator.valueToCode(
                    block,
                    "VALUE_SOCKET",
                    luaGenerator.ORDER_NONE,
                );
                const propertyToGet = this.getFieldValue("PROPERTIES_DROPDOWN");
                return [`${valueSocketValue}["${propertyToGet}"]`, luaGenerator.ORDER_NONE];
            },
        },
    ]);

    defs.push([
        "lk_method_accessor",
        {
            def: {
                init() {
                    this.typeName = null;
                    this.methodArguments = [];
                    this.returnType = null;
                    this.appendDummyInput().appendField(
                        new FieldLabelSerializable("MethodName"),
                        "METHOD_NAME",
                    );
                    this.setColour(270);
                },
                saveExtraState() {
                    return {
                        typeName: this.typeName,
                        returnType: this.returnType,
                        methodArguments: this.methodArguments,
                    };
                },
                loadExtraState(state) {
                    this.typeName = state.typeName ?? null;
                    this.returnType = state.returnType ?? null;
                    this.methodArguments = state.methodArguments ?? [];
                    this.updateBlockInterface();
                },
                updateBlockInterface() {
                    if (this.returnType === undefined) {
                        this.setOutput(false);
                    } else {
                        this.setOutput(true, this.returnType);
                    }
                    this.appendValueInput("VALUE_SOCKET")
                        .setCheck(this.typeName)
                        .appendField(this.typeName);
                    this.methodArguments.forEach((methodArgument) => {
                        this.appendValueInput(methodArgument.name)
                            .setCheck(methodArgument.type)
                            .appendField(methodArgument.name);
                    });
                },
            },
            codeGen(block) {
                const methodName = this.getFieldValue("METHOD_NAME");
                const valueSocketValue = luaGenerator.valueToCode(
                    block,
                    "VALUE_SOCKET",
                    luaGenerator.ORDER_NONE,
                );
                const argumentsValues = this.methodArguments
                    .map((methodArgument) =>
                        luaGenerator.valueToCode(
                            block,
                            methodArgument.name,
                            luaGenerator.ORDER_NONE,
                        ),
                    )
                    .join(", ");
                const functionArguments =
                    this.methodArguments.length > 0
                        ? `${valueSocketValue}, ${argumentsValues}`
                        : valueSocketValue;
                return [
                    `${this.typeName}.${methodName}(${functionArguments})`,
                    luaGenerator.ORDER_NONE,
                ];
            },
        },
    ]);

    common.defineBlocks(defs.reduce((acc, [key, { def }]) => ({ ...acc, [key]: def }), {}));
    defs.forEach(([key, { codeGen }]) => (luaGenerator.forBlock[key] = codeGen));
}
