import { gql } from "@apollo/client";
import { LOCALE_FRAGMENT } from "@/graphql/fragments/locale.fragment";
import { makeGqlMutationHook } from "@/graphql/util";
import { CreateLocaleMutation, CreateLocaleMutationVariables } from "@/gql";

const SCHEMA = gql`
    ${LOCALE_FRAGMENT}

    mutation createLocale(
        $gameId: String!
        $name: String!
        $code: String!
        $isBaseLocale: Boolean!
    ) {
        createLocale(gameId: $gameId, name: $name, code: $code, isBaseLocale: $isBaseLocale) {
            ...Locale
        }
    }
`;

export const useCreateLocaleMutation = makeGqlMutationHook<
    CreateLocaleMutation,
    CreateLocaleMutationVariables
>(SCHEMA);
