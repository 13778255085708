import { FC, useCallback } from "react";
import { CreateGameMutationVariables } from "@/gql";
import { useCreateGameMutation } from "@/graphql/game";
import { CreateGameForm } from "../forms/CreateGameForm";
import { BaseModal } from "./BaseModal";

interface CreateGameModalProps {
    isOpen: boolean;
    toggle: () => void;
    onGameCreated?: () => void;
}

export const CreateGameModal: FC<CreateGameModalProps> = ({ isOpen, toggle, onGameCreated }) => {
    const [createGame, { error, loading }] = useCreateGameMutation();
    const onSubmit = useCallback(
        async (data: CreateGameMutationVariables) => {
            await createGame({ variables: data });
            typeof onGameCreated === "function" && onGameCreated();
            toggle();
        },
        [createGame, onGameCreated, toggle],
    );

    return (
        <BaseModal isOpen={isOpen} toggle={toggle} backdrop keyboard title="Create game">
            <CreateGameForm isLoading={loading} onSubmit={onSubmit} error={error} />
        </BaseModal>
    );
};
