import { useCallback } from "react";
import Stack from "react-bootstrap/Stack";
import Spinner from "react-bootstrap/Spinner";
import { gql, useMutation } from "@apollo/client";
import { JOB_FRAGMENT } from "@/graphql/fragments/job.fragment";
import { CleanupEntitiesMutation, CleanupEntitiesMutationVariables } from "@/gql";
import { ConfirmationButton } from "@/components/common/ConfirmationButton";

const SCHEMA = gql`
    ${JOB_FRAGMENT}

    mutation cleanupEntities {
        cleanupEntities {
            ...Job
        }
    }
`;

export function CleanupICs() {
    const [cleanupEntities, { loading }] = useMutation<
        CleanupEntitiesMutation,
        CleanupEntitiesMutationVariables
    >(SCHEMA);
    const onCleanup = useCallback(async () => {
        await cleanupEntities();
    }, [cleanupEntities]);

    return (
        <Stack gap={4}>
            <h4>Cleanup dangling entity parameters</h4>
            <p>
                This operation removes all entities of type InjectedComponent that are not
                referenced by any other entity. This is a long operation (from approx. 20 minutes to
                a few hours)
            </p>
            <ConfirmationButton onConfirm={onCleanup}>
                {loading && <Spinner size="sm" />} Perform a cleanup
            </ConfirmationButton>
        </Stack>
    );
}
