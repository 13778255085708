import { gql } from "@apollo/client";
import { SetDeployLockMutation, SetDeployLockMutationVariables } from "@/gql";
import { makeGqlMutationHook } from "../util";
import { DEPLOY_LOCK_FRAGMENT } from "../fragments/deploy-lock.fragment";

export const useSetDeployLockMutation = makeGqlMutationHook<
    SetDeployLockMutation,
    SetDeployLockMutationVariables
>(gql`
    ${DEPLOY_LOCK_FRAGMENT}

    mutation setDeployLock(
        $gameId: String!
        $description: String!
        $expiresAt: DateTime!
        $lockType: DeployLockType!
    ) {
        setDeployLock(
            gameId: $gameId
            description: $description
            expiresAt: $expiresAt
            lockType: $lockType
        ) {
            ...DeployLock
        }
    }
`);
