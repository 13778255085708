import { FormEventHandler, ReactNode, useCallback, useMemo, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { bootstrapDialog, create, NiceModalHocProps, show, useModal } from "@ebay/nice-modal-react";
import styled from "styled-components";

export interface ConfirmationModalProps {
    title?: string | ReactNode | ReactNode[];
    prompt: string | ReactNode | ReactNode[];
    confirmButtonText?: string;
    confirmationPhrase?: string;
    disableConfirm?: boolean;
}

const ModalFooter = styled(Modal.Footer)`
    flex-wrap: nowrap;

    & > .btn {
        flex-basis: 50%;
    }
`;

export function ConfirmationModalComponent(props: ConfirmationModalProps) {
    const modal = useModal();
    const dialog = bootstrapDialog(modal);
    const [confirmationPhrase, setConfirmationPhrase] = useState("");
    const isDisabled = useMemo((): boolean => {
        if (!props.confirmationPhrase) return false;

        return confirmationPhrase !== props.confirmationPhrase;
    }, [confirmationPhrase, props.confirmationPhrase]);
    const onConfirmationInput = useCallback<FormEventHandler<HTMLInputElement>>((event) => {
        setConfirmationPhrase(event.currentTarget.value);
    }, []);
    const onConfirm = useCallback(() => {
        modal.resolve(true);
        modal.hide();
    }, [modal]);
    const onCancel = useCallback(() => {
        modal.resolve(false);
        modal.hide();
    }, [modal]);

    return (
        <Modal backdrop keyboard {...dialog}>
            <Modal.Header>
                <Modal.Title>{props.title ?? "Confirmation needed"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row className="justify-content-around g-4">
                    <Col md={12}>
                        <p>{props.prompt}</p>
                    </Col>
                    {props.confirmationPhrase && (
                        <>
                            <Col md={12}>
                                <p>
                                    Please type following to confirm: &nbsp;
                                    <code>{props.confirmationPhrase}</code>
                                </p>
                            </Col>
                            <Col md={12}>
                                <Form.Control
                                    defaultValue={confirmationPhrase}
                                    onInput={onConfirmationInput}
                                />
                            </Col>
                        </>
                    )}
                </Row>
            </Modal.Body>
            <ModalFooter>
                <Button
                    variant="danger"
                    onClick={onConfirm}
                    disabled={isDisabled || props.disableConfirm}
                >
                    {props.disableConfirm ? "Confirm disabled" : props.confirmButtonText ?? "Ok"}
                </Button>
                <Button onClick={onCancel}>Cancel</Button>
            </ModalFooter>
        </Modal>
    );
}

const ConfirmationModal = create(ConfirmationModalComponent);

export function useShowConfirmationModal() {
    return useCallback((props: ConfirmationModalProps) => {
        return show<boolean, ConfirmationModalProps & NiceModalHocProps, ConfirmationModalProps>(
            ConfirmationModal,
            props,
        );
    }, []);
}
