import {
    DeployStep,
    VersionsFileGenerationEvent,
    VersionsFileGenerationFailedEvent,
    VersionsFileGenerationFinishedEvent,
} from "@/gql";

type Props = {
    event:
        | VersionsFileGenerationEvent
        | VersionsFileGenerationFailedEvent
        | VersionsFileGenerationFinishedEvent;
};

export function VersionsFileGenerationEventRenderer(props: Props) {
    switch (props.event.deployCode) {
        case DeployStep.VersionsFileGeneration:
            return <span>Versions file generation</span>;
        case DeployStep.VersionsFileGenerationFailed:
            return <span>Versions file generation</span>;
        case DeployStep.VersionsFileGenerationFinished:
            return <span>Versions file generation</span>;
        default:
            return null;
    }

    return null;
}
