import { gql } from "@apollo/client";
import { TEMPLATE_CATEGORY_FRAGMENT } from "@/graphql/fragments/template-category.fragment";
import { makeGqlMutationHook } from "@/graphql/util";
import { CreateTemplateCategoryMutation, CreateTemplateCategoryMutationVariables } from "@/gql";

const schema = gql`
    ${TEMPLATE_CATEGORY_FRAGMENT}

    mutation createTemplateCategory($name: String!, $gameId: String!) {
        createCategory(name: $name, gameId: $gameId) {
            ...TemplateCategory
        }
    }
`;

export const useCreateTemplateCategory = makeGqlMutationHook<
    CreateTemplateCategoryMutation,
    CreateTemplateCategoryMutationVariables
>(schema);
