import { gql } from "@apollo/client";
import { TEMPLATE_DISPLAY_FRAGMENT } from "./template-display.fragment";
import { CONSUMER_FRAGMENT } from "./consumer.fragment";
import { TEMPLATE_PARAM_DETAILED_FRAGMENT } from "./template-param.fragment";

export const TEMPLATE_SHORT_FRAGMENT = gql`
    fragment TemplateShort on Template {
        guid
        name
        description
        gameId
    }
`;

export const TEMPLATE_FRAGMENT = gql`
    fragment Template on Template {
        guid
        name
        description
        gameId
        lockedAt
        lockedBy
        lockReason
        meta {
            entitiesPerPage
            defaultSortOrder
            listFieldsVerticallyInEditEntityModal
        }
        type
    }
`;

export const TEMPLATE_DETAILED_FRAGMENT = gql`
    ${TEMPLATE_FRAGMENT}
    ${TEMPLATE_PARAM_DETAILED_FRAGMENT}
    ${CONSUMER_FRAGMENT}
    ${TEMPLATE_DISPLAY_FRAGMENT}

    fragment TemplateDetailed on Template {
        ...Template
        consumers {
            ...Consumer
        }
        templateParams {
            ...TemplateParamDetailed
        }
        templateDisplay {
            ...TemplateDisplay
        }
    }
`;
