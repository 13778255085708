import { PropsWithChildren } from "react";
import styled from "styled-components";
import Card from "react-bootstrap/Card";

const Main = styled.main`
    height: 100vh;
    display: flex;
    align-items: center;
    background-color: #f5f5f5;
    text-align: center;
`;

const SignInCard = styled(Card)`
    width: 100%;
    max-width: 380px;
    padding: 80px 30px 60px;
    margin: auto;
    box-shadow: 0 15px 35px rgba(50, 50, 93, 0.1), 0 5px 15px rgba(0, 0, 0, 0.07);
`;

export function LoginPageLayout(props: PropsWithChildren) {
    return (
        <Main>
            <SignInCard>{props.children}</SignInCard>
        </Main>
    );
}
