import { JSXElementConstructor, useCallback } from "react";
import { useModal } from "./useModal";

type ModalProps<Result> = {
    isOpen: boolean;
    toggle: (result?: Result | null) => void;
};

export function useDialog<Result, ComponentProps extends ModalProps<Result> = ModalProps<Result>>(
    ModalComponent: JSXElementConstructor<ComponentProps>,
) {
    const { modal, openModal, closeModal } = useModal(ModalComponent);
    const open = useCallback(
        (modalProps: Omit<ComponentProps, "isOpen" | "toggle">) => {
            return new Promise<Result | null>((resolve, reject) => {
                // @ts-ignore
                return openModal({
                    ...modalProps,
                    isOpen: true,
                    toggle: (result) => {
                        if (result || result === null) {
                            resolve(result);
                        } else {
                            reject();
                        }
                        closeModal();
                    },
                });
            });
        },
        [closeModal, openModal],
    );

    return { modal, open };
}
