import { useDraggable } from "@dnd-kit/core";
import cn from "classnames";
import { useMemo } from "react";
import { CSS } from "@dnd-kit/utilities";
import Button from "react-bootstrap/Button";
import { FaGripVertical } from "react-icons/fa";
import { Template } from "@/gql";
import { Avatar } from "@/components/common/Avatar";

type Props = {
    template: Template;
};

export function CategoryTemplate(props: Props) {
    const { isDragging, setNodeRef, attributes, listeners, transform, over } = useDraggable({
        id: props.template.guid,
        data: props.template,
    });
    const style = useMemo(() => ({ transform: CSS.Translate.toString(transform) }), [transform]);

    return (
        <div
            className={cn(
                "d-flex flex-column card p-3",
                "d-flex flex-column shadow border border-1",
                {
                    "border-primary": isDragging,
                    "opacity-75": isDragging,
                    "z-1": isDragging,
                    "z-2": over,
                },
            )}
            ref={setNodeRef}
            style={style}
        >
            <div className="d-flex flex-nowrap align-items-center fs-5">
                <Avatar
                    width={24}
                    height={24}
                    className="rounded-circle"
                    name={props.template.type}
                />
                <div
                    className="overflow-hidden ms-1 flex-grow-1"
                    style={{
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                    }}
                    title={props.template.name}
                >
                    <span>{props.template.name}</span>
                </div>
                <Button variant="link" size="sm" {...attributes} {...listeners}>
                    <FaGripVertical />
                </Button>
            </div>
            <p
                className="fs-6 overflow-hidden mb-0"
                style={{
                    minHeight: "3.4rem",
                    maxHeight: "3.4rem",
                    WebkitLineClamp: 4,
                    WebkitBoxOrient: "vertical",
                }}
                title={props.template?.description ?? "No description"}
            >
                {props.template?.description ?? "&nbsp;"}
            </p>
        </div>
    );
}
