import Placeholder from "react-bootstrap/Placeholder";
import { ReactNode, useCallback } from "react";
import { DataType } from "@/gql";
import { useFieldsDefinitions } from "../../hooks/useFieldsDefinitions";
import { useCellContext } from "../../contexts/CellContext";
import { PlaceholderProps } from "../../types";
import { useEntitiesTableFormContext } from "../../hooks/useEntitiesTableFormContext";

export function ListPlaceholderField(props: PlaceholderProps) {
    const { getDefinition } = useFieldsDefinitions();
    const { subType } = useCellContext();
    const formContext = useEntitiesTableFormContext();
    const value = formContext.watch(props.name);
    const renderSubtypeField = useCallback(
        (subType: DataType, value: any, index: number): ReactNode | never => {
            const fieldDef = getDefinition(subType);
            return (
                <fieldDef.placeholderComponent name={`${props.name}.${index}.value`} key={index} />
            );
        },
        [getDefinition, props.name],
    );

    return (
        <div className="d-grid gap-2">
            {((value as { value: string }[]) ?? []).map((value, index) =>
                renderSubtypeField(subType!, value, index),
            )}
            <Placeholder.Button size="sm" />
        </div>
    );
}
