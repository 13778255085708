import { useCallback, useMemo } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useFormContext } from "react-hook-form";
import { NestedFormSelect, SelectOption } from "@/components/common/forms/form-inputs/FormSelect";
import { useGlobalParameters } from "@/store/games/slices";
import { DataType } from "@/gql";
import { entityToSelectOption } from "@/utils/entityToSelectOption";
import { ConnectForm } from "@/components/common/forms/ConnectForm";

export function AdditionalEnumParameterFields() {
    const { watch } = useFormContext();
    const globalEnums = useGlobalParameters(DataType.Enum);
    const availableEnums = useMemo(() => globalEnums.map(entityToSelectOption), [globalEnums]);
    const [selectedDefaultValue, defaultValue] = watch(["meta.enumId", "meta.defaultValue"]);
    const getEnumValues = useCallback(
        (selectedEnumId: string): SelectOption[] => {
            const selectedEnum = globalEnums.find((enumDef) => enumDef.guid === selectedEnumId);
            if (!selectedEnum) return [];
            return (
                selectedEnum.meta.enum?.enumValues.map((def) => ({
                    value: def.value,
                    label: def.name,
                })) ?? []
            );
        },
        [globalEnums],
    );
    const enumValues = useMemo(
        () => getEnumValues(selectedDefaultValue),
        [getEnumValues, selectedDefaultValue],
    );

    return (
        <ConnectForm>
            {() => (
                <Row className="gy-4">
                    <Col>
                        <NestedFormSelect
                            name="meta.enumId"
                            title="Enum"
                            options={availableEnums}
                        />
                    </Col>
                    <Col>
                        <NestedFormSelect
                            name="meta.defaultValue"
                            title="Default value"
                            value={defaultValue}
                            options={enumValues}
                            disabled={enumValues.length === 0}
                        />
                    </Col>
                </Row>
            )}
        </ConnectForm>
    );
}
