import { gql } from "@apollo/client";
import { makeGqlQueryLazyHook } from "@/graphql/util";
import { GetArtifactVersionsFileQuery, GetArtifactVersionsFileQueryVariables } from "@/gql";

const SCHEMA = gql`
    query getArtifactVersionsFile($gameId: String!, $guid: String!) {
        artifactVersionsFile(gameId: $gameId, guid: $guid)
    }
`;

export const useLazyGetArtifactVersionsFile = makeGqlQueryLazyHook<
    GetArtifactVersionsFileQuery,
    GetArtifactVersionsFileQueryVariables
>(SCHEMA);
