import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { NestedFormSelect, SelectOption } from "@/components/common/forms/form-inputs/FormSelect";
import { useGetAssets } from "@/graphql/assets";
import { ConnectForm } from "@/components/common/forms/ConnectForm";
import { AdditionalParameterFields } from "../../types";

export const AssetParameterFields: AdditionalParameterFields = (props) => {
    const methods = useFormContext();
    const availableAssets = useGetAssets({ gameId: props.gameId });
    const options = useMemo(() => {
        if (!availableAssets || !availableAssets.data) return [];

        return availableAssets.data.result.map<SelectOption>((asset) => ({
            value: asset.guid,
            key: asset.guid,
            disabled: false,
            label: asset.name,
        }));
    }, [availableAssets]);

    useEffect(() => {
        if (options.length > 0) {
            methods.setValue("meta.defaultValue", options[0] ? options[0].value : null);
        }
    }, [methods, options]);

    if (!options) {
        return <span>Loading</span>;
    }

    return (
        <ConnectForm>
            {() => {
                return (
                    <Row>
                        <Col>
                            <NestedFormSelect
                                name="meta.defaultValue"
                                title="Default value"
                                options={options}
                                disabled={options.length < 1}
                            />
                        </Col>
                    </Row>
                );
            }}
        </ConnectForm>
    );
};
