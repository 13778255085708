import { gql } from "@apollo/client";
import { DeployArtifactMutation, DeployArtifactMutationVariables } from "@/gql";
import { makeGqlMutationHook } from "../util";

const SCHEMA = gql`
    mutation deployArtifact($gameId: String!, $artifactId: String!, $deployDirectoryId: String!) {
        deploy(gameId: $gameId, artifactId: $artifactId, deployDirectoryId: $deployDirectoryId) {
            success
        }
    }
`;

export const useDeployArtifactMutation = makeGqlMutationHook<
    DeployArtifactMutation,
    DeployArtifactMutationVariables
>(SCHEMA);
