import { gql } from "@apollo/client";

export const GLOSSARY_ENTRY_FRAGMENT = gql`
    fragment GlossaryEntry on GlossaryEntry {
        guid
        gameId
    }
`;

export const GLOSSARY_ENTRY_VALUE_FRAGMENT = gql`
    fragment GlossaryEntryValue on GlossaryEntryValue {
        guid
        gameId
        localeId
        glossaryEntryId
        value
    }
`;
