import Button from "react-bootstrap/Button";
import { FaList } from "react-icons/fa";
import { useCallback } from "react";
import { EditEntityBtn2 } from "@/components/common/EditEntityBtn2";
import { useModal } from "@/hooks";
import { ContextActionsProps } from "../../types";
import { useCellContext } from "../../contexts/CellContext";
import { useEntitiesTableFormContext } from "../../hooks/useEntitiesTableFormContext";
import { EntityRefSelectorModal } from "./EntityRefSelectorModal";

export function EntityRefContextActions(props: ContextActionsProps) {
    const { templateParam, gameId } = useCellContext();
    const EntityRefSelector = useModal(EntityRefSelectorModal);
    const methods = useEntitiesTableFormContext();
    const fieldValue = methods.watch(props.name);
    const onComponentSelect = useCallback(() => {
        EntityRefSelector.openModal2({
            name: props.name,
            title: "Select component",
        });
    }, [EntityRefSelector, props.name]);

    return (
        <>
            {templateParam.meta.templateId && fieldValue ? (
                <EditEntityBtn2
                    className="p-1"
                    variant="primary"
                    gameId={gameId}
                    entityId={fieldValue as string}
                />
            ) : null}
            <Button onClick={onComponentSelect} size="sm" title="Select component">
                <FaList />
            </Button>
            <EntityRefSelector.modal />
        </>
    );
}
