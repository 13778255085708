import { useCallback, useMemo, useState } from "react";
import DataTable, { type ConditionalStyles, type TableColumn } from "react-data-table-component";
import Alert from "react-bootstrap/Alert";
import keyBy from "lodash/keyBy";
import sortBy from "lodash/sortBy";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import { useSnackbar } from "notistack";
import { useGetTemplatesForImport, useImportEntitiesFrom } from "@/graphql/game";
import { DataAwaiter2 } from "@/components/common/DataAwaiter2";
import { TemplateMatchModel } from "@/gql";
import { ConfirmationButton } from "@/components/common/ConfirmationButton";
import { useModal } from "@/hooks";
import { ImportResultsModal } from "@/components/modals/ImportResultsModal";
import { ImportTemplateExpandedRow } from "./ImportTemplateExpandedRow";

interface Props {
    exportGameId: string;
    importGameId: string;
}

export function ImportSummary(props: Props) {
    const getTemplatesForImport = useGetTemplatesForImport({
        sourceGameId: props.exportGameId,
        targetGameId: props.importGameId,
    });
    const importResultsModal = useModal(ImportResultsModal);
    const [importEntitiesFrom, { loading }] = useImportEntitiesFrom();
    const { enqueueSnackbar } = useSnackbar();
    const displayedData = useMemo(() => {
        if (!getTemplatesForImport.data) return [];

        return sortBy(getTemplatesForImport.data.getTemplatesForImport, (v) => v.sourceMatch.name);
    }, [getTemplatesForImport.data]);
    const templatesMap = useMemo(() => {
        return keyBy(getTemplatesForImport.data?.getTemplatesForImport, (v) => v.sourceMatch.guid);
    }, [getTemplatesForImport.data?.getTemplatesForImport]);
    const [selectedTemplates, setSelectedTemplates] = useState<string[]>([]);
    const selectedTemplatesSet = useMemo(() => new Set(selectedTemplates), [selectedTemplates]);
    const isTemplateSelected = useCallback(
        (guid: string) => {
            return selectedTemplatesSet.has(guid);
        },
        [selectedTemplatesSet],
    );
    const canSelect = useCallback(
        (guid: string) => {
            if (!getTemplatesForImport.data) return [];

            const match = getTemplatesForImport.data.getTemplatesForImport.find(
                (match) => match.sourceMatch.guid === guid,
            );

            return Boolean(match?.canBeImported);
        },
        [getTemplatesForImport.data],
    );
    const onTemplateSelect = useCallback((templateId: string) => {
        setSelectedTemplates((prevState) => {
            if (prevState.includes(templateId)) {
                return prevState.filter((v) => v !== templateId);
            } else {
                return [...prevState, templateId];
            }
        });
    }, []);
    const onDoImport = useCallback(async () => {
        const result = await importEntitiesFrom({
            variables: {
                targetGameId: props.importGameId,
                sourceGameId: props.exportGameId,
                templateIds: selectedTemplates,
            },
        });
        enqueueSnackbar("Import done", {
            variant: "success",
        });
        importResultsModal.openModal2({
            data: result.data!.importEntitiesFrom,
        });
    }, [
        importResultsModal,
        importEntitiesFrom,
        props.exportGameId,
        props.importGameId,
        selectedTemplates,
        enqueueSnackbar,
    ]);
    const contextActions = useMemo(() => {
        const selectedCount = selectedTemplates.length;
        const text =
            selectedCount === 0
                ? "Import"
                : selectedCount === 1
                ? `Import ${selectedCount} template`
                : `Import ${selectedCount} templates`;

        return (
            <ConfirmationButton
                disabled={selectedCount === 0 || loading}
                variant="danger"
                onConfirm={onDoImport}
                prompt="Are you sure you want to import entities for selected templates? This action cannot be undone"
            >
                {loading && <Spinner size="sm" />}
                {text}
            </ConfirmationButton>
        );
    }, [loading, onDoImport, selectedTemplates.length]);
    const rowStyles = useMemo<ConditionalStyles<TemplateMatchModel>[]>(
        () => [
            {
                when: (row) => !isTemplateSelected(row.sourceMatch.guid) && !row.targetMatch,
                classNames: ["bg-warning-subtle"],
            },
            {
                when: (row) => !row.canBeImported,
                classNames: ["bg-danger-subtle"],
            },
            {
                when: (row) => isTemplateSelected(row.sourceMatch.guid),
                classNames: ["bg-success-subtle"],
            },
        ],
        [isTemplateSelected],
    );
    const columns = useMemo<TableColumn<TemplateMatchModel>[]>(
        () => [
            {
                name: "Template",
                selector: (row) => row.sourceMatch.name,
            },
            {
                name: "Template id",
                selector: (row) => row.sourceMatch.guid,
            },
            {
                name: "Template type",
                selector: (row) => row.sourceMatch.type,
            },
            {
                name: "Match id",
                selector: (row) => row.targetMatch?.guid ?? "",
                format: (row) => (row.targetMatch ? row.targetMatch.guid : "No match"),
            },
            {
                cell: (row) => (
                    <>
                        <Button
                            disabled={!row.canBeImported}
                            variant={
                                selectedTemplatesSet.has(row.sourceMatch.guid)
                                    ? "outline-primary"
                                    : "primary"
                            }
                            size="sm"
                            onClick={() => onTemplateSelect(row.sourceMatch.guid)}
                        >
                            {!row.canBeImported
                                ? "Cannot import"
                                : selectedTemplatesSet.has(row.sourceMatch.guid)
                                ? "Selected for import"
                                : "Select for import"}
                        </Button>
                    </>
                ),
            },
        ],
        [onTemplateSelect, selectedTemplatesSet],
    );

    if (getTemplatesForImport.data?.getTemplatesForImport.length === 0) {
        return (
            <Alert variant="danger">
                <Alert.Heading>No matching templates found</Alert.Heading>
            </Alert>
        );
    }

    return (
        <>
            <DataAwaiter2 {...getTemplatesForImport}>
                {() => (
                    <DataTable
                        data={displayedData}
                        columns={columns}
                        striped
                        contextMessage={{
                            plural: "templates",
                            singular: "template",
                            message: "selected",
                        }}
                        conditionalRowStyles={rowStyles}
                        actions={contextActions}
                        contextActions={contextActions}
                        expandableRows
                        expandableRowsComponent={ImportTemplateExpandedRow}
                        expandableRowsComponentProps={{
                            templatesMap,
                            onSelect: (guid: string) => onTemplateSelect(guid),
                            isTemplateSelected,
                            canSelect,
                        }}
                    />
                )}
            </DataAwaiter2>
            <importResultsModal.modal />
        </>
    );
}
