import { gql } from "@apollo/client";
import { makeGqlMutationHook } from "@/graphql/util";
import { ImportTranslationsMutation, ImportTranslationsMutationVariables } from "@/gql";

const SCHEMA = gql`
    mutation importTranslations($gameId: String!, $spreadsheetId: String!) {
        importTranslationsFromSpreadsheet(gameId: $gameId, spreadsheetId: $spreadsheetId) {
            isOk
            info
        }
    }
`;
export const useImportTranslations = makeGqlMutationHook<
    ImportTranslationsMutation,
    ImportTranslationsMutationVariables
>(SCHEMA);
