import { DataType } from "@/gql";
import { DataTypeField } from "../../types";
import { InjectedRefParameterFields } from "./InjectedRefParameterFields";
import { InjectedRefTableCell } from "./InjectedRefTableCell";
import { InjectedRefFormField } from "./InjectedRefFormField";
import { InjectedRefDisplayField } from "./InjectedRefDisplayField";

export const InjectedRefDataType: DataTypeField = {
    dataType: DataType.InjectedRef,
    defaultValue: () => null,
    formField: InjectedRefFormField,
    fieldDisplay: InjectedRefDisplayField,
    tableCell: InjectedRefTableCell,
    additionalParameterFields: InjectedRefParameterFields,
    tableCellDisplayOptions: {
        minSize: 450,
        maxSize: 550,
        enableSorting: false,
    },
};
