import { type ToolboxInfo } from "blockly/core/utils/toolbox";
import { logicBlocks } from "./logic";
import { loopBlocks } from "./loops";
import { mathBlocks } from "./math";
import { textBlocks } from "./text";
import { listBlocks } from "./lists";
import { colorBlocks } from "./colors";
import { separatorBlock } from "./separator";
import { variablesBlocks } from "./variables";
import { functionBlocks } from "./functions";
import { interfaceBlocks } from "./interfaces";

export function defaultToolbox(): ToolboxInfo {
    return {
        kind: "categoryToolbox",
        contents: [
            {
                kind: "category",
                name: "Tables",
                colour: "270",
                contents: [
                    { kind: "block", type: "lk_table" },
                    { kind: "block", type: "lk_table_keyvalue" },
                    { kind: "block", type: "lk_table_method_return" },
                    { kind: "block", type: "lk_table_method_noreturn" },
                ],
            },
            {
                kind: "category",
                name: "Enums",
                colour: "270",
                contents: [
                    { kind: "block", type: "lk_enum_accessor" },
                    { kind: "block", type: "lk_enum" },
                    { kind: "block", type: "lk_enum_keyvalue" },
                ],
            },
            { kind: "sep" },
            logicBlocks,
            loopBlocks,
            mathBlocks,
            textBlocks,
            listBlocks,
            colorBlocks,
            interfaceBlocks,
            separatorBlock,
            variablesBlocks,
            functionBlocks,
        ],
    };
}
