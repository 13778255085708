import { useCallback, useMemo } from "react";
import { useSnackbar } from "notistack";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Stack from "react-bootstrap/Stack";
import { useSetEditLockModal } from "@/components/modals2/SetEditLockModal";
import { useLockTemplate, useUnlockTemplate } from "@/graphql/templates";
import { ProgressButton } from "@/components/common/ProgressButton";
import { useTemplateContext } from "@/pages/template/TemplateProvider";
import { ConfirmationButton } from "@/components/common/ConfirmationButton";
import { useAuth } from "@/hooks/useAuth";
import { UserInfoAsync } from "@/components/common/UserInfoAsync";
import { DataAwaiter2 } from "@/components/common/DataAwaiter2";

export function EditLockButton() {
    const { enqueueSnackbar } = useSnackbar();
    const { user } = useAuth();
    const template = useTemplateContext();
    const setEditLockModal = useSetEditLockModal();
    const [unlockTemplate, { loading: unlockTemplateLoading }] = useUnlockTemplate();
    const [lockTemplate, { loading: lockTemplateLoading }] = useLockTemplate();
    const loading = unlockTemplateLoading || lockTemplateLoading || template.loading;
    const isLocked =
        template.data?.template &&
        template.data?.template?.lockedBy &&
        template.data?.template?.lockedAt &&
        template.data?.template?.lockReason;
    const cannotUnlock = template.data?.template.lockedBy !== user?.guid && !user?.canDoWizardry;

    const onLock = useCallback(async () => {
        const { reason } = await setEditLockModal();
        const { data } = await template.observable.result();
        const lockTemplateResult = await lockTemplate({
            variables: {
                reason,
                templateId: data.template.guid,
                gameId: data.template.gameId,
            },
        });

        if (!lockTemplateResult.data) {
            enqueueSnackbar("Failed to set lock", { variant: "error" });
            return;
        }

        await template.refetch();
    }, [enqueueSnackbar, lockTemplate, setEditLockModal, template]);
    const onUnlock = useCallback(async () => {
        const { data } = await template.observable.result();

        const unlockTemplateResult = await unlockTemplate({
            variables: {
                templateId: data.template.guid,
                gameId: data.template.gameId,
            },
        });

        if (!unlockTemplateResult.data) {
            enqueueSnackbar("Failed to unlock", { variant: "error" });
            return;
        }

        await template.refetch();
    }, [enqueueSnackbar, template, unlockTemplate]);
    const renderTooltip = useMemo(() => {
        if (!isLocked) {
            return (
                <Tooltip>
                    <span>No edit lock set</span>
                </Tooltip>
            );
        }

        return (
            <Tooltip>
                <Stack className="text-start">
                    <div>
                        <span className="fw-bold">Locked by:</span>
                        <br />
                        {user && template.data!.template.lockedBy === user.guid ? (
                            "You"
                        ) : (
                            <UserInfoAsync userId={template.data!.template.lockedBy!} />
                        )}
                    </div>
                    <div>
                        <span className="fw-bold">Reason:</span>
                        <br />
                        {template.data!.template.lockReason}
                    </div>
                </Stack>
            </Tooltip>
        );
    }, [isLocked, template.data, user]);

    return (
        <DataAwaiter2 {...template}>
            {() =>
                isLocked ? (
                    <OverlayTrigger
                        placement="bottom"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltip}
                    >
                        {({ ref, ...triggerHandler }) => (
                            <ConfirmationButton
                                ref={ref}
                                {...triggerHandler}
                                disabled={loading || cannotUnlock}
                                variant="secondary"
                                onConfirm={onUnlock}
                                title="Unlock template"
                                confirmButtonText="Unlock template"
                                prompt="This template will become available for edit by all other users. Please confirm that you want to release the lock"
                            >
                                Unlock
                            </ConfirmationButton>
                        )}
                    </OverlayTrigger>
                ) : (
                    <ProgressButton variant="secondary" onClick={onLock} loading={loading}>
                        Lock
                    </ProgressButton>
                )
            }
        </DataAwaiter2>
    );
}
