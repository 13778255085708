import { gql } from "@apollo/client";
import { makeGqlMutationHook } from "@/graphql/util";
import { CreateDeployDirectoryMutation, CreateDeployDirectoryMutationVariables } from "@/gql";
import { DEPLOY_DIRECTORY_FRAGMENT } from "../fragments/deploy-directory.fragment";

export const useCreateDeployDirectoryMutation = makeGqlMutationHook<
    CreateDeployDirectoryMutation,
    CreateDeployDirectoryMutationVariables
>(gql`
    ${DEPLOY_DIRECTORY_FRAGMENT}

    mutation createDeployDirectory(
        $gameId: String!
        $name: String!
        $path: String!
        $variant: Variant
    ) {
        createDeployDirectory(gameId: $gameId, name: $name, path: $path, variant: $variant) {
            ...DeployDirectory
        }
    }
`);
