import { useController, useFormContext } from "react-hook-form";
import { string } from "zod";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { FaTrash } from "react-icons/fa";
import { useMemo } from "react";
import { ExportFormProps } from "./types";

const emailValidator = string().min(1).email();

function validator(value: unknown) {
    const result = emailValidator.safeParse(value);

    if (!result.success) {
        return result.error.errors.at(0)!.message;
    }
    return true;
}

type Props = {
    index: number;
    onRemove: () => void;
    disabled?: boolean;
};

export function EmailInput(props: Props) {
    const { control, register } = useFormContext<ExportFormProps>();
    const controller = useController<ExportFormProps>({
        name: `emails.${props.index}.value`,
        control,
        rules: {
            validate: validator,
        },
    });
    const fieldRegistration = useMemo(
        () =>
            register(`emails.${props.index}.value`, {
                validate: validator,
            }),
        [props.index, register],
    );
    return (
        <Form.Group as={Col} className="d-flex align-items-center gap-2">
            <div className="d-flex flex-column w-100">
                <Form.Control {...fieldRegistration} isInvalid={!controller.formState.isValid} />
                {controller.fieldState.error && (
                    <Form.Control.Feedback type="invalid">
                        {controller.fieldState.error.message}
                    </Form.Control.Feedback>
                )}
            </div>
            <Button size="sm" onClick={props.onRemove} disabled={props.disabled}>
                <FaTrash />
            </Button>
        </Form.Group>
    );
}
