import Modal from "react-bootstrap/Modal";
import { useModal, bootstrapDialog, show, create, NiceModalHocProps } from "@ebay/nice-modal-react";
import { useController, useForm } from "react-hook-form";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Stack from "react-bootstrap/Stack";
import { useCallback } from "react";

type Props = {
    guid: string;
    name: string;
    description: string;
    // onSubmit: (data: CloneConsumerFormData) => void;
};

export type CloneConsumerFormData = {
    guid: string;
    name: string;
    description: string;
};

function CloneConsumerModalComponent(props: Props) {
    const modal = useModal();
    const dialog = bootstrapDialog(modal);
    const form = useForm<CloneConsumerFormData>({
        mode: "onChange",
        defaultValues: {
            guid: props.guid,
            name: `${props.name} (1)`,
            description: props.description,
        },
    });

    const nameController = useController({
        name: "name",
        control: form.control,
        rules: {
            min: 3,
            max: 255,
            required: true,
        },
    });
    const descriptionController = useController({
        name: "description",
        control: form.control,
        rules: {
            max: 255,
        },
    });
    const onSubmit = useCallback(
        (data: CloneConsumerFormData) => {
            modal.resolve(data);
            modal.hide();
        },
        [modal],
    );

    return (
        <Modal {...dialog}>
            <Modal.Header closeButton>Clone consumer</Modal.Header>
            <Modal.Body>
                <Form onSubmit={form.handleSubmit(onSubmit)}>
                    <Stack gap={4}>
                        <Form.Group>
                            <Form.Label>Consumer name</Form.Label>
                            <Form.Control
                                {...nameController.field}
                                isInvalid={nameController.fieldState.invalid}
                            />
                            {nameController.fieldState.isDirty &&
                                nameController.fieldState.invalid! && (
                                    <Form.Control.Feedback type="valid">
                                        Looks good!
                                    </Form.Control.Feedback>
                                )}
                            {nameController.fieldState.isDirty &&
                                nameController.fieldState.invalid &&
                                nameController.fieldState.error && (
                                    <Form.Control.Feedback type="invalid">
                                        {nameController.fieldState.error?.message}
                                    </Form.Control.Feedback>
                                )}
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Consumer description</Form.Label>
                            <Form.Control
                                {...descriptionController.field}
                                as="textarea"
                                isInvalid={descriptionController.fieldState.invalid}
                            />
                            {descriptionController.fieldState.isDirty &&
                                descriptionController.fieldState.invalid! && (
                                    <Form.Control.Feedback type="valid">
                                        Looks good!
                                    </Form.Control.Feedback>
                                )}
                            {descriptionController.fieldState.isDirty &&
                                descriptionController.fieldState.invalid &&
                                descriptionController.fieldState.error && (
                                    <Form.Control.Feedback type="invalid">
                                        {descriptionController.fieldState.error?.message}
                                    </Form.Control.Feedback>
                                )}
                        </Form.Group>
                        <Form.Group>
                            <Button type="submit" disabled={!form.formState.isValid}>
                                Clone
                            </Button>
                        </Form.Group>
                    </Stack>
                </Form>
            </Modal.Body>
        </Modal>
    );
}

const CloneConsumerModal = create(CloneConsumerModalComponent);

export function useShowCloneConsumerModal() {
    return useCallback(
        (props: Props) =>
            show<CloneConsumerFormData, NiceModalHocProps & Props, Props>(
                CloneConsumerModal,
                props,
            ),
        [],
    );
}
