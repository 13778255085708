import { useMemo } from "react";
import Badge from "react-bootstrap/Badge";
import classNames from "classnames";
import { useEntityById } from "@/store/entities/slices";
import { Template, TemplateDisplay, TemplateParam } from "@/gql";
import { FieldRenderer } from "@/components/common/EntityRefRenderer/FieldRenderer";

export function RefRenderer(props: {
    entityId: string;
    gameId: string;
    template: Omit<Template, "templateDisplay" | "consumers">;
    templateDisplay?: TemplateDisplay;
    depth: number;
}) {
    const { gameId, entityId, template, templateDisplay, depth } = props;
    const entity = useEntityById(entityId, gameId);
    const paramsMap = entity?.values ?? {};
    const templateParametersToDisplay = useMemo(() => {
        const refDisplayNotSet = !templateDisplay?.refFieldsOrder.length;
        const refFieldsOrder = templateDisplay?.refFieldsOrder ?? [];
        const templateParams: TemplateParam[] = template?.templateParams ?? [];

        if (refDisplayNotSet) return templateParams;

        return templateParams.filter((tp) => {
            return refFieldsOrder.includes(tp.guid);
        });
    }, [templateDisplay?.refFieldsOrder, template?.templateParams]);

    if (!entityId) {
        return null;
    }

    if (!entity && entityId) {
        return <Badge bg="danger">Missing referenced entity</Badge>;
    }

    return (
        <>
            <div
                className={classNames(
                    "align-items-start",
                    "d-flex",
                    "flex-nowrap",
                    "flex-column",
                    `depth-${depth}`,
                )}
            >
                <span className="small font-monospace">{entityId}</span>
                <div className="d-flex gap-1 flex-column px-2 py-1">
                    {templateParametersToDisplay.map((templateParam) => (
                        <div key={templateParam.guid}>
                            <FieldRenderer
                                templateParam={templateParam}
                                value={paramsMap[templateParam.guid]}
                            />
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
}
