import { CSSProperties, forwardRef, MouseEventHandler, useCallback, useMemo } from "react";
import { DraggableAttributes } from "@dnd-kit/core";
import { SyntheticListenerMap } from "@dnd-kit/core/dist/hooks/utilities";
import { Link } from "@tanstack/react-router";
import cn from "classnames";
import Button from "react-bootstrap/Button";
import { FaStar, FaThumbtack } from "react-icons/fa";
import { Avatar } from "@/components/common/Avatar";
import { TemplateCategoriesQuery } from "@/gql";

type Props = {
    template: TemplateCategoriesQuery["templateCategories"][number]["templates"][number];
    onItemSelected?: () => void;
    canMove?: boolean;
    isPinned?: boolean;
    onPin?: () => void;
    isFavorite: boolean;
    onFavorite: () => void;
    className?: string;
    attributes?: DraggableAttributes;
    listeners?: SyntheticListenerMap;
    style?: CSSProperties;
};

export const CategoryCard = forwardRef<HTMLDivElement, Props>(function CategoryCard(props, ref) {
    const uri = useMemo(
        () => `/${props.template.gameId}/templates/${props.template.guid}/entities`,
        [props.template.gameId, props.template.guid],
    );
    const isActive = location.pathname === uri;

    const onPin = useCallback<MouseEventHandler<HTMLButtonElement>>(
        (e) => {
            e.preventDefault();
            e.stopPropagation();
            typeof props.onPin === "function" && props.onPin();
        },
        [props],
    );
    const onFavorite = useCallback<MouseEventHandler<HTMLButtonElement>>(
        (e) => {
            e.preventDefault();
            e.stopPropagation();
            typeof props.onFavorite === "function" && props.onFavorite();
        },
        [props],
    );

    return (
        <div
            className={cn("d-flex flex-column card p-3", props.className, {
                "bg-primary": isActive,
                "text-white": isActive,
            })}
            ref={ref}
            style={props.style}
        >
            <div className="d-flex flex-nowrap align-items-center fs-5">
                <Avatar
                    width={24}
                    height={24}
                    className="rounded-circle"
                    name={props.template.type}
                />
                <Link
                    to={uri}
                    className="overflow-hidden ms-1 flex-grow-1 text-reset"
                    style={{
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                    }}
                    title={props.template.name}
                    onClick={props.onItemSelected}
                >
                    {props.template.name}
                </Link>
                <Button
                    size="sm"
                    variant="link"
                    className={cn("p-2 d-flex align-items-middle", {
                        "text-yellow": props.isFavorite,
                        "text-muted": !props.isFavorite,
                    })}
                    onClick={onFavorite}
                >
                    <FaStar width={32} height={32} />
                </Button>
                {props.onPin && (
                    <Button
                        size="sm"
                        className={cn("p-2 d-flex align-items-middle", {
                            "text-light": !props.isPinned,
                        })}
                        variant="link"
                        onClick={onPin}
                    >
                        <FaThumbtack width={32} height={32} />
                    </Button>
                )}
                {/* <Button variant="link" size="sm" onClick={onNavigation}>*/}
                {/*    <FaExternalLinkAlt />*/}
                {/* </Button>*/}
                {/* {props.canMove && (*/}
                {/*    <Button variant="link" size="sm" {...props.attributes} {...props.listeners}>*/}
                {/*        <FaGripVertical />*/}
                {/*    </Button>*/}
                {/* )}*/}
            </div>
            <p
                className="fs-6 overflow-hidden mb-0"
                style={{
                    minHeight: "3.4rem",
                    maxHeight: "3.4rem",
                    WebkitLineClamp: 4,
                    WebkitBoxOrient: "vertical",
                }}
                title={props.template?.description ?? "No description"}
            >
                {props.template?.description ?? "&nbsp;"}
            </p>
        </div>
    );
});
