import { useFormContext } from "react-hook-form";
import Stack from "react-bootstrap/Stack";
import cn from "classnames";
import { DataAwaiter2 } from "@/components/common/DataAwaiter2";
import { useGetTemplate } from "@/graphql/templates";
import { ExportFormProps } from "./types";
import { SelectableTemplateParam } from "./SelectableTemplateParam";

type Props = {
    templateId: string;
    disabled?: boolean;
};

export function TemplateSelect(props: Props) {
    const { getValues } = useFormContext<ExportFormProps>();
    const gameId = getValues("gameId");
    const getTemplate = useGetTemplate({ guid: props.templateId, gameId });

    return (
        <DataAwaiter2 {...getTemplate}>
            {({ template }) => (
                <section className="p-2 border rounded">
                    <h5>{template.name}</h5>
                    <Stack className="overflow-y-scroll">
                        {template.templateParams!.map((tp, index) => (
                            <SelectableTemplateParam
                                key={tp.guid}
                                className={cn("align-items-center", {
                                    "bg-body-secondary": index % 2 === 0,
                                })}
                                templateParam={tp}
                            />
                        ))}
                    </Stack>
                </section>
            )}
        </DataAwaiter2>
    );
}
