import { JSXElementConstructor, useCallback, useMemo, useState } from "react";

export function useModal<ComponentProps extends object>(
    ModalComponent: JSXElementConstructor<ComponentProps>,
    initialMode = false,
) {
    const [isModalOpen, setIsModalOpen] = useState(initialMode);
    const [modalProps, setModalProps] = useState<ComponentProps | null>(null);

    const modal = useCallback(() => {
        if (isModalOpen && modalProps !== null) {
            return <ModalComponent {...modalProps} />;
        }

        return null;
    }, [ModalComponent, modalProps, isModalOpen]);
    const openModal = useCallback(
        (modalProps: ComponentProps) => {
            setIsModalOpen(true);
            setModalProps(modalProps);
        },
        [setIsModalOpen, setModalProps],
    );
    const closeModal = useCallback(() => {
        setIsModalOpen(false);
        setModalProps(null);
    }, [setIsModalOpen, setModalProps]);
    const openModal2 = useCallback(
        (modalProps: Omit<ComponentProps, "isOpen" | "toggle">) => {
            // @ts-ignore
            openModal({
                ...modalProps,
                isOpen: true,
                toggle: closeModal,
            });
        },
        [closeModal, openModal],
    );

    return useMemo(
        () => ({ modal, openModal, closeModal, openModal2 }),
        [closeModal, modal, openModal, openModal2],
    );
}
