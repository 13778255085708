import Stack from "react-bootstrap/Stack";
import { useFieldArray, useFormContext } from "react-hook-form";
import Button from "react-bootstrap/Button";
import { FaPlus } from "react-icons/fa";
import { type ZodType, string, array, object } from "zod";
import { useCallback } from "react";
import { ExportFormProps } from "./types";
import { EmailInput } from "./EmailInput";

type Props = {
    className?: string;
    disabled?: boolean;
};

const emailValidator = string().min(1).email();
const emailsListValidator = array(
    object({
        value: emailValidator,
    }),
).min(1);

export function EmailsList(props: Props) {
    const { control } = useFormContext<ExportFormProps>();
    const validator = useCallback(
        (validator: ZodType) => (value: any) => {
            const result = validator.safeParse(value);

            if (!result.success) {
                return result.error.errors.map((v) => v.message);
            }
            return true;
        },
        [],
    );
    const { fields, append, remove } = useFieldArray<ExportFormProps>({
        name: "emails",
        control,
        rules: {
            minLength: 1,
            validate: validator(emailsListValidator),
        },
    });
    const onAppend = useCallback(() => append({ value: "" }), [append]);

    return (
        <section className={props.className}>
            <h4>Emails</h4>
            <small>Emails that will have access to spreadsheet granted</small>
            <Stack gap={3}>
                {fields.map((field, index) => (
                    <EmailInput
                        key={field.id}
                        disabled={props.disabled}
                        index={index}
                        onRemove={() => remove(index)}
                    />
                ))}
                <Button disabled={props.disabled} onClick={onAppend}>
                    <FaPlus /> Add
                </Button>
            </Stack>
        </section>
    );
}
