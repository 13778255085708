import { useCallback } from "react";
import { OperableEntityTableData } from "@/types";
import { showCloneEntitiesModal } from "@/components/modals2/CloneEntitiesModal";
import { useCloneEntityMutation } from "@/graphql/entities";

export function useCloneEntities(onComplete?: () => void) {
    const [cloneEntityMutation] = useCloneEntityMutation();
    return useCallback(
        async (row: OperableEntityTableData) => {
            const variables = await showCloneEntitiesModal();
            await cloneEntityMutation({
                variables: {
                    guid: row.guid,
                    gameId: row.gameId,
                    count: +variables.copies,
                },
            });
            typeof onComplete === "function" && onComplete();
        },
        [cloneEntityMutation, onComplete],
    );
}
