import { gql } from "@apollo/client";

export const DEPLOY_LOCK_FRAGMENT = gql`
    fragment DeployLock on DeployLock {
        guid
        gameId
        description
        expiresAt
        createdBy
        lockType
    }
`;
