import { useCallback, useMemo } from "react";
import { bootstrapDialog, create, show, useModal } from "@ebay/nice-modal-react";
import Modal from "react-bootstrap/Modal";
import { JobsTable } from "@/components/common/JobsTable";
import { QueueType } from "@/gql";
import { useGetJobs, useJobStatusSubscription } from "@/graphql/queues";
import { DataAwaiter2 } from "@/components/common/DataAwaiter2";

const eventTypes = [
    QueueType.CloneProject,
    QueueType.DeployArtifact,
    QueueType.GenerateArtifact,
    QueueType.RemoveOrphanedICs,
    QueueType.RemoveProject,
    QueueType.SyncFromOrigin,
    QueueType.UpdateTemplateParam,
    QueueType.ImportSpreadsheet,
];

const JobsModal = create(function JobsModal() {
    const modal = useModal();
    const dialog = useMemo(() => bootstrapDialog(modal), [modal]);
    const getJobs = useGetJobs({ eventTypes });

    useJobStatusSubscription({
        async onData() {
            await getJobs.refetch({ eventTypes });
        },
    });

    return (
        <Modal {...dialog} fullscreen>
            <Modal.Header closeButton>
                <Modal.Title>Jobs</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>This list shows all jobs across all projects</p>
                <DataAwaiter2 {...getJobs}>
                    {(data) => <JobsTable jobs={data.getJobs} />}
                </DataAwaiter2>
            </Modal.Body>
        </Modal>
    );
});

export function useShowJobsModal() {
    return useCallback(() => show(JobsModal), []);
}
