import { gql } from "@apollo/client";
import { CheckPasswordResetTokenQuery, CheckPasswordResetTokenQueryVariables } from "@/gql";
import { makeGqlQueryLazyHook } from "../util";

const CHECK_PASSWORD_RESET_TOKEN = gql`
    query checkPasswordResetToken($token: String!) {
        result: checkPasswordResetToken(token: $token) {
            isOk
            info
        }
    }
`;

export const useCheckPasswordResetToken = makeGqlQueryLazyHook<
    CheckPasswordResetTokenQuery,
    CheckPasswordResetTokenQueryVariables
>(CHECK_PASSWORD_RESET_TOKEN);
