import { gql } from "@apollo/client";
import { makeGqlQueryHook } from "@/graphql/util";
import { GetTemplatesQuery, GetTemplatesQueryVariables } from "@/gql";
import { TEMPLATE_SHORT_FRAGMENT } from "@/graphql/fragments/template.fragment";
import { TEMPLATE_PARAM_SHORT_FRAGMENT } from "@/graphql/fragments/template-param.fragment";

const SCHEMA = gql`
    ${TEMPLATE_SHORT_FRAGMENT}
    ${TEMPLATE_PARAM_SHORT_FRAGMENT}

    query getTemplates($gameId: String!) {
        templates(gameId: $gameId) {
            ...TemplateShort
            templateParams {
                ...TemplateParamShort
            }
        }
    }
`;

export const useGetTemplatesQuery = makeGqlQueryHook<GetTemplatesQuery, GetTemplatesQueryVariables>(
    SCHEMA,
);
