import { gql } from "@apollo/client";
import { GLOSSARY_ENTRY_FRAGMENT } from "@/graphql/fragments/glossary.fragment";
import { makeGqlMutationHook } from "@/graphql/util";
import { UpdateGlossaryEntryMutation, UpdateGlossaryEntryMutationVariables } from "@/gql";

const SCHEMA = gql`
    ${GLOSSARY_ENTRY_FRAGMENT}

    mutation updateGlossaryEntry(
        $guid: String!
        $gameId: String!
        $glossaryEntries: [RawGlossaryEntryDto!]!
    ) {
        updateGlossaryEntry(guid: $guid, gameId: $gameId, glossaryEntries: $glossaryEntries) {
            ...GlossaryEntry
        }
    }
`;

export const useUpdateGlossaryEntryMutation = makeGqlMutationHook<
    UpdateGlossaryEntryMutation,
    UpdateGlossaryEntryMutationVariables
>(SCHEMA);
