import { gql } from "@apollo/client";
import { DeleteArtifactMutation, DeleteArtifactMutationVariables } from "@/gql";
import { makeGqlMutationHook } from "../util";

const SCHEMA = gql`
    mutation deleteArtifact($guid: String!, $gameId: String!) {
        deleteArtifact(guid: $guid, gameId: $gameId) {
            guid
        }
    }
`;

export const useDeleteArtifactMutation = makeGqlMutationHook<
    DeleteArtifactMutation,
    DeleteArtifactMutationVariables
>(SCHEMA);
