import { memo } from "react";
import { useAddChangeRecord } from "@/components/common/Table/EntitiesTable/contexts/TableCellContext";
import { AssetRenderer } from "@/components/common/AssetRenderer";
import { DisableEntityParameterBtn } from "@/fields/components/DisableEntityParameterBtn";
import { FieldProps, TableCell } from "../../types";
import { AssetSelector } from "./AssetSelector";

export const AssetTableCell: TableCell = memo(function AssetTableCell(props) {
    const { value, addChangeRecord } = useAddChangeRecord(props);

    if (props.readonly) {
        if (value === null) return <span>No asset selected</span>;
        return <AssetRenderer guid={value} gameId={props.templateParam.gameId} />;
    }

    return (
        <div className="d-flex align-items-center">
            <DisableEntityParameterBtn {...props} />
            <AssetField
                gameId={props.gameId}
                templateParam={props.templateParam}
                contextEntity={props.tableCellProps.row}
                clearable={!props.templateParam.meta.isRequired}
                value={value}
                onChange={addChangeRecord}
                readonly={props.readonly}
            />
        </div>
    );
});

export function AssetField(props: FieldProps<string> & { clearable?: boolean }) {
    return (
        <AssetSelector
            className="w-100"
            clearable={props.clearable}
            value={props.value}
            onChange={props.onChange}
        />
    );
}
