import { memo, useCallback, useMemo, useState } from "react";
import Button from "react-bootstrap/Button";
import { BaseModal } from "@/components/modals/BaseModal";
import { useAddChangeRecord } from "@/components/common/Table/EntitiesTable/contexts/TableCellContext";
import { ScriptEditor } from "@/components/common/ScriptEditor";
import { useGlobalParameters } from "@/store/games/slices";
import { DataType } from "@/gql";
import { DisableEntityParameterBtn } from "@/fields/components/DisableEntityParameterBtn";
import { TableCell } from "../../types";

export const ScriptTableCell: TableCell = memo(function ScriptTableCell(props) {
    const { value, addChangeRecord } = useAddChangeRecord(props);
    const [isEditMode, setIsEditMode] = useState(false);
    const switchToEditMode = useCallback(() => setIsEditMode(true), []);
    const switchToDisplayMode = useCallback(() => setIsEditMode(false), []);
    const scriptScopesIds = new Set(props.templateParam.meta.scriptScopes);
    const availableScriptScopes = useGlobalParameters(DataType.Script);
    const scriptScopes = availableScriptScopes.filter((value) => scriptScopesIds.has(value.guid));
    const availableEnums = useGlobalParameters(DataType.Enum);
    const valueAsString = useMemo(() => JSON.stringify(value), [value]);

    if (props.readonly) {
        return <code>{valueAsString}</code>;
    }

    return (
        <div className="d-flex align-items-top">
            <DisableEntityParameterBtn {...props} />

            {isEditMode ? (
                <BaseModal isOpen toggle={switchToDisplayMode} size="xl">
                    <ScriptEditor
                        value={value}
                        onChange={addChangeRecord}
                        scriptScopes={scriptScopes}
                        availableEnums={availableEnums}
                    />
                </BaseModal>
            ) : (
                <Button size="sm" variant="info" onClick={switchToEditMode}>
                    Edit script
                </Button>
            )}
        </div>
    );
});
