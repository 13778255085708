import { useCallback, useMemo } from "react";
import Modal from "react-bootstrap/Modal";
import { bootstrapDialog, create, NiceModalHocProps, show, useModal } from "@ebay/nice-modal-react";
import omit from "lodash/omit";
import { useSnackbar } from "notistack";
import { TemplateParam } from "@/gql";
import {
    useCreateTemplateParamMutation,
    useLazyGetTemplateParamQuery,
    useUpdateTemplateParamMutation,
} from "@/graphql/template-params";
import {
    EditTemplateParamForm,
    TemplateParamCreationAttributes,
} from "../forms/EditTemplateParamForm";

type TemplateParamModalComponentProps = {
    gameId: string;
    templateId: string;
    templateParam?: TemplateParam;
};

export type UseTemplateParamModalProps = Omit<TemplateParamModalComponentProps, "templateParam"> & {
    templateParamId?: string;
};

export function TemplateParamModalComponent(props: TemplateParamModalComponentProps) {
    const { templateParam, templateId, gameId } = props;
    const modal = useModal();
    const dialog = useMemo(() => bootstrapDialog(modal), [modal]);
    const isCreateMode = templateParam === undefined;

    const [
        createTemplateParameter,
        { error: createTemplateParameterError, loading: createTemplateParameterLoading },
    ] = useCreateTemplateParamMutation();
    const [
        updateTemplateParameter,
        { error: updateTemplateParameterError, loading: updateTemplateParameterLoading },
    ] = useUpdateTemplateParamMutation();
    const error = useMemo((): Error | null => {
        if (!createTemplateParameterError && !updateTemplateParameterError) return null;

        return new Error(
            createTemplateParameterError?.message || updateTemplateParameterError?.message,
        );
    }, [createTemplateParameterError, updateTemplateParameterError]);

    const onSubmit = useCallback(
        async (attributes: TemplateParamCreationAttributes) => {
            let promise;
            const variablesData = {
                ...attributes,
                gameId,
                templateId,
                consumers: attributes.consumers?.map((consumer) => consumer.guid) ?? [],
            };

            if (isCreateMode) {
                promise = createTemplateParameter({
                    variables: omit(variablesData, "guid"),
                });
            } else {
                promise = updateTemplateParameter({
                    variables: variablesData,
                });
            }

            const { data } = await promise;
            if (data) {
                modal.resolve(true);
                return modal.hide();
            }
        },
        [createTemplateParameter, gameId, isCreateMode, modal, templateId, updateTemplateParameter],
    );
    const isLoading = createTemplateParameterLoading || updateTemplateParameterLoading;

    return (
        <Modal backdrop keyboard {...dialog}>
            <Modal.Header>
                <Modal.Title>
                    {isCreateMode ? "Create template param" : "Edit template param"}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <EditTemplateParamForm
                    templateId={templateId}
                    gameId={gameId}
                    onSubmit={onSubmit}
                    isLoading={isLoading}
                    error={error}
                    templateParam={props.templateParam}
                />
            </Modal.Body>
        </Modal>
    );
}

const TemplateParamModal = create(TemplateParamModalComponent);

export function useShowTemplateParamModal() {
    const [getTemplateParam] = useLazyGetTemplateParamQuery();
    const { enqueueSnackbar } = useSnackbar();

    return useCallback(
        async (props: UseTemplateParamModalProps) => {
            if (!props.templateParamId) {
                return show<
                    void,
                    TemplateParamModalComponentProps & NiceModalHocProps,
                    TemplateParamModalComponentProps
                >(TemplateParamModal, props);
            }

            const { data } = await getTemplateParam({
                variables: { guid: props.templateParamId, gameId: props.gameId },
            });

            if (!data) {
                enqueueSnackbar("Could not load template parameter for editing", {
                    variant: "error",
                });
                return;
            }

            return show<
                void,
                TemplateParamModalComponentProps & NiceModalHocProps,
                TemplateParamModalComponentProps
            >(TemplateParamModal, { ...props, templateParam: data.templateParam });
        },
        [enqueueSnackbar, getTemplateParam],
    );
}
