import { DataTypeField } from "@/fields/types";
import { DataType } from "@/gql";
import { DurationTableCell } from "./DurationTableCell";
import { DurationFormInput } from "./DurationFormInput";

export const DurationDataType: DataTypeField = {
    defaultValue: () => 0,
    dataType: [DataType.Duration],
    tableCell: DurationTableCell,
    formField: DurationFormInput,
    tableCellDisplayOptions: {
        minSize: 300,
    },
};
