import { ChangeEvent, useCallback, useMemo } from "react";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import BsForm from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";
import merge from "lodash/merge";
import { FormProvider, useForm } from "react-hook-form";
import { Consumer, DataType, Maybe, TemplateParam, TemplateParamMeta, TemplateType } from "@/gql";
import { fieldsDefinition } from "@/fields";
import {
    useAvailableConsumers,
    useAvailableTemplates,
    useGlobalParameters,
} from "@/store/games/slices";
import { NestedFormSelect } from "../common/forms/form-inputs/FormSelect";
import { NestedFormInput } from "../common/forms/form-inputs/FormInput";
import { NestedFormReactSelect } from "../common/forms/form-inputs/FormReactSelect";

export interface EditTemplateParamFormProps {
    gameId: string;
    templateId: string;
    templateParam?: TemplateParam;
    isLoading: boolean;
    error: Error | null;
    onSubmit: (data: TemplateParamCreationAttributes) => void;
}

export type TemplateParamCreationAttributes = {
    guid: string;
    name: string;
    description: string;
    type: DataType;
    subType?: Maybe<DataType>;
    meta: TemplateParamMeta;
    consumers: Maybe<Omit<Consumer, "game" | "templates" | "templateParams">[]>;
};

const availableParamTypes = Object.entries(DataType).map(([name, value]) => ({
    value,
    label: name,
}));

export function EditTemplateParamForm(props: EditTemplateParamFormProps) {
    const { gameId, templateParam } = props;
    const availableConsumers = useAvailableConsumers();
    const availableTemplates = useAvailableTemplates();
    // const defaultAvailableTemplate = availableTemplates[0]?.guid ?? null;
    const globalEnums = useGlobalParameters(DataType.Enum);
    const defaultEnumId = useMemo(() => {
        if (globalEnums.length > 0 && globalEnums[0]) {
            return globalEnums[0].guid;
        }
        return null;
    }, [globalEnums]);
    const isProtected =
        Boolean(templateParam?.meta.isProtected) && templateParam?.name !== "_Description";

    const form = useForm<TemplateParamCreationAttributes>({
        defaultValues: () =>
            Promise.resolve(
                merge(
                    {
                        guid: "",
                        name: "",
                        meta: {
                            displayName: "",
                            defaultValue: null,
                            isUnique: false,
                            isRequired: true,
                            templateId: null,
                            isGlobal: false,
                            enum: {
                                enumValues: [],
                                bitMask: false,
                            },
                            enumId: defaultEnumId,
                            scriptScopes: [],
                            assetRef: null,
                            isProtected,
                        },
                        type: DataType.Integer,
                        subType: DataType.Integer,
                        description: "",
                        consumers: [],
                    },
                    props.templateParam,
                ),
            ),
        mode: "onChange",
    });
    const [templateId, selectedDataType] = form.watch(["meta.templateId", "type"]);

    const AdditionalParameterFields = useMemo(
        () => fieldsDefinition[selectedDataType]?.additionalParameterFields ?? null,
        [selectedDataType],
    );
    const selectedTemplateIsIC = useMemo(
        () =>
            availableTemplates.reduce(
                (acc, template) =>
                    acc === true
                        ? acc
                        : template.guid === templateId &&
                          template.type === TemplateType.InjectedComponent,
                false,
            ),
        [availableTemplates, templateId],
    );

    const changeSelectedDataType = useCallback(
        (newType: DataType) => {
            switch (newType) {
                case DataType.Enum:
                    form.setValue("meta.enumId", defaultEnumId);
                    form.setValue(
                        "meta.defaultValue",
                        globalEnums.find((v) => v.guid === defaultEnumId)?.meta?.enum?.enumValues[0]
                            ?.value ?? null,
                    );
            }
            form.setValue("type", newType);
        },
        [defaultEnumId, form, globalEnums],
    );

    if (!gameId) {
        throw new Error("url parameter gameId is not available in the current context");
    }

    return (
        <BsForm onSubmit={form.handleSubmit(props.onSubmit)}>
            <FormProvider {...form}>
                <Row className="align-content-center justify-content-start gy-4">
                    <Col md={12}>
                        <NestedFormInput
                            name="name"
                            title="Parameter name"
                            disabled={isProtected}
                            rules={{
                                required: true,
                                min: 1,
                                max: 250,
                                pattern: /^_?[A-Z][\w\d]*$/,
                                onChange: (event: ChangeEvent<HTMLInputElement>) => {
                                    form.setValue("meta.displayName", event.target.value);
                                },
                            }}
                        />
                    </Col>
                    <Col md={12}>
                        <NestedFormInput
                            name="meta.displayName"
                            title="Displayed name"
                            disabled={isProtected}
                            rules={{ max: 240 }}
                        />
                    </Col>
                    <Col md={12}>
                        <NestedFormInput
                            as="textarea"
                            name="description"
                            title="Parameter description"
                            disabled={isProtected}
                            rules={{ required: false }}
                        />
                    </Col>
                    <Col md={12}>
                        <NestedFormSelect
                            name="type"
                            title="Parameter type"
                            disabled={isProtected}
                            options={availableParamTypes}
                            rules={{
                                onChange: (event) => {
                                    form.setValue("meta.defaultValue", null);
                                    changeSelectedDataType(event.target.value);
                                },
                            }}
                        />
                    </Col>
                    {AdditionalParameterFields !== null && !isProtected && (
                        <Col md={12}>
                            <AdditionalParameterFields type={selectedDataType} gameId={gameId} />
                        </Col>
                    )}
                    <Col md={12}>
                        <NestedFormReactSelect
                            className="mb-2"
                            name="consumers"
                            getOptionLabel={(consumer) => consumer.name}
                            getOptionValue={(consumer) => consumer.guid}
                            title="Consumers"
                            options={availableConsumers}
                            isMulti
                            isClearable
                        />
                    </Col>
                    <Col md={12}>
                        <Row>
                            <Col md={6}>
                                <BsForm.Check
                                    {...form.register("meta.isRequired", {
                                        required: false,
                                    })}
                                    disabled={isProtected}
                                    id="edit_isRequired"
                                    label="Is required"
                                    type="checkbox"
                                />
                            </Col>
                            <Col md={6}>
                                <BsForm.Check
                                    {...form.register("meta.isUnique", { required: false })}
                                    id="edit_isUnique"
                                    label="Is unique"
                                    type="checkbox"
                                    disabled={selectedTemplateIsIC || isProtected}
                                />
                            </Col>
                        </Row>
                    </Col>
                    {props.error && (
                        <Col xs={12} md={12}>
                            <Alert variant="danger">
                                <Alert.Heading>{props.error.name}</Alert.Heading>
                                <span>{props.error.message}</span>
                            </Alert>
                        </Col>
                    )}
                    <Col xs={12} md={12}>
                        <Button
                            type="submit"
                            color="danger"
                            disabled={
                                props.isLoading ||
                                !form.formState.isDirty ||
                                !form.formState.isValid
                            }
                        >
                            {props.isLoading ? (
                                <Spinner size="sm" animation="border" />
                            ) : (
                                "Save parameter"
                            )}
                        </Button>
                    </Col>
                </Row>
            </FormProvider>
        </BsForm>
    );
}
