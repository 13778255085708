import { bootstrapDialog, create, show, useModal } from "@ebay/nice-modal-react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";
import { useCallback } from "react";
import Button from "react-bootstrap/Button";
import Stack from "react-bootstrap/Stack";
import Spinner from "react-bootstrap/Spinner";
import { useSnackbar } from "notistack";
import { ImportTranslationsMutationVariables } from "@/gql";
import { useImportGlossariesMutation } from "@/graphql/translation/glossaries";

type Props = {
    gameId: string;
};

function ImportGlossariesModalComponent(props: Props) {
    const { gameId } = props;
    const modal = useModal();
    const dialog = bootstrapDialog(modal);
    const [importGlossaries, { loading }] = useImportGlossariesMutation();
    const { enqueueSnackbar } = useSnackbar();
    const form = useForm<ImportTranslationsMutationVariables>({
        mode: "onChange",
        defaultValues: {
            spreadsheetId: "",
            gameId,
        },
    });
    const onSubmit = useCallback(
        async (variables: ImportTranslationsMutationVariables) => {
            await importGlossaries({ variables });
            enqueueSnackbar("Import successful", {
                variant: "success",
            });
            modal.resolve();
            return modal.hide();
        },
        [importGlossaries, modal, enqueueSnackbar],
    );

    return (
        <Modal {...dialog} size="lg">
            <Modal.Body>
                <Form onSubmit={form.handleSubmit(onSubmit)}>
                    <Stack gap={4}>
                        <p>To import paste spreadsheet id into the input below and press Import</p>
                        <p className="d-flex align-items-center">
                            https://docs.google.com/spreadsheets/d/
                            <Form.Control
                                size="sm"
                                type="text"
                                placeholder="Spreadsheet id"
                                {...form.register("spreadsheetId")}
                                disabled={loading}
                            />
                            /edit
                        </p>
                        <Button disabled={loading} type="submit">
                            {loading && <Spinner size="sm" />} Import
                        </Button>
                    </Stack>
                </Form>
            </Modal.Body>
        </Modal>
    );
}

const ImportGlossariesModal = create<Props>(ImportGlossariesModalComponent);

export function showImportGlossariesModal(props: Props) {
    return show(ImportGlossariesModal, props);
}
