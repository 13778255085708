import Placeholder from "react-bootstrap/Placeholder";
import Card from "react-bootstrap/Card";

export function FallbackPlaceholder() {
    return (
        <Placeholder as={Card.Text} animation="glow">
            <Placeholder xs={7} />
        </Placeholder>
    );
}
