import { gql } from "@apollo/client";

export const TEMPLATE_DISPLAY_FRAGMENT = gql`
    fragment TemplateDisplay on TemplateDisplay {
        gameId
        templateId
        fieldsOrder
        refFieldsOrder
        entitiesPerPage
        defaultSortAttribute
        defaultSortOrder
    }
`;
