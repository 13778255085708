import { createRoute } from "@tanstack/react-router";
import { useGetUsersQuery } from "@/graphql/wizardry/users";
import { DataAwaiter2 } from "@/components/common/DataAwaiter2";
import { UsersList } from "@/components/wizardry/users/UsersList";
import { wizardryRoute } from "@/pages/wizardry/WizardryLayout";

export default function UsersWizardry() {
    const getUsersQuery = useGetUsersQuery();

    return (
        <DataAwaiter2 {...getUsersQuery}>
            {(data) => <UsersList data={data.wizGetUsers} />}
        </DataAwaiter2>
    );
}
export const wizardryUsersRoute = createRoute({
    getParentRoute: () => wizardryRoute,
    path: "users",
    component: UsersWizardry,
});
