import ProgressBar from "react-bootstrap/ProgressBar";
import { useCallback, useMemo } from "react";
import { Variant } from "react-bootstrap/types";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import { FaLock } from "react-icons/fa";
import { useSnackbar } from "notistack";
import { useDeploy } from "@/hooks/useDeploy";
import { useModal } from "@/hooks";
import { DeployModal } from "@/components/modals/DeployModal";
import { useHasPermissions } from "@/components/rbac";
import { DeployLockType, Permission } from "@/gql";
import { showDeployLockModal } from "@/components/modals2/DeployLockModal";
import { useDeployLockLiftedSubscription, useDeployLockSetSubscription } from "@/graphql/game";
import { useDeployLock } from "@/hooks/useDeployLock";
import { layoutWithBreadcrumbs } from "@/layouts/LayoutWithBreadcrumbs";

export function DeployIndicator() {
    const { gameId } = layoutWithBreadcrumbs.useParams();
    const { startBuild, deployStarted, deployFailed, deployFinished, deployInProgress } =
        useDeploy();
    const { enqueueSnackbar } = useSnackbar();
    useDeployLockSetSubscription({
        variables: { gameId },
        onData: (data) => {
            const {
                data: { data: subscriptionData },
            } = data;
            const creator = subscriptionData?.deployLockSet.creator;
            if (creator) {
                enqueueSnackbar(
                    `Deploy lock has been set by ${creator.firstname} ${creator.lastname}`,
                    {
                        variant: "warning",
                    },
                );
            }
            deployLock.refetch();
        },
    });
    useDeployLockLiftedSubscription({
        variables: { gameId },
        onData: () => {
            enqueueSnackbar("Deploy lock has been lifted", {
                variant: "warning",
            });
            deployLock.refetch();
        },
    });
    const [deployLock, deployLockSet] = useDeployLock(gameId!, DeployLockType.Build);
    const canDeploy = useHasPermissions([Permission.CanDeploy]) && !deployLockSet;
    const { modal: DeployResultsModal, openModal2: openModal } = useModal(DeployModal);
    const variant = useMemo((): Variant | undefined => {
        if (deployFailed || deployLockSet) return "danger";
        if (deployFinished) return "success";
        if (deployStarted) return "warning";
        return "info";
    }, [deployFailed, deployFinished, deployLockSet, deployStarted]);
    const onShowDeployLockModal = useCallback(async () => {
        await showDeployLockModal({ gameId });
    }, [gameId]);

    return (
        <div className="d-flex gap-2 flex-nowrap align-items-center">
            <div className="flex-grow-1 d-flex align-items-center" onClick={() => openModal({})}>
                <ProgressBar
                    className="flex-grow-1 mb-auto"
                    variant={variant}
                    animated={deployInProgress}
                    now={100}
                    label="Click to see progress log"
                />
            </div>
            <ButtonGroup>
                <Button
                    variant="danger"
                    onClick={startBuild}
                    disabled={deployInProgress || !canDeploy}
                >
                    Build
                </Button>
                <Button
                    title="Lock deploy"
                    variant={deployLockSet ? "danger" : "success"}
                    disabled={deployLock.loading}
                    className="px-3"
                    onClick={onShowDeployLockModal}
                >
                    <FaLock />
                </Button>
            </ButtonGroup>
            <DeployResultsModal />
        </div>
    );
}
