import { FC } from "react";
import { TableColumn } from "react-data-table-component";
import { TemplateParam } from "@/gql";
import { OperableEntityTableData, OperableTemplateEntity } from "@/types";
import { InputProps } from "./types";
import { CellContextProvider } from "./contexts/CellContext";
import { TableCell } from "./components/TableCell";
import { NestingContextProvider } from "./components/EntityRefRenderer/context";

export function withTemplateParam(
    CellComponent: FC<InputProps>,
    props: {
        templateParam: TemplateParam;
    },
): TableColumn<OperableEntityTableData>["cell"] {
    return function WrappedTableCell(row) {
        return (
            <CellContextProvider row={row} templateParam={props.templateParam}>
                <NestingContextProvider>
                    <TableCell />
                </NestingContextProvider>
            </CellContextProvider>
        );
    };
}
