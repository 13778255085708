import { gql } from "@apollo/client";
import { makeGqlQueryHook, makeGqlQueryLazyHook } from "@/graphql/util";
import { GetLocalesQuery, GetLocalesQueryVariables } from "@/gql";

const SCHEMA = gql`
    query getLocales($gameId: String!) {
        locales(gameId: $gameId) {
            guid
            gameId
            code
            name
            isBaseLocale
        }
    }
`;

export const useGetLocales = makeGqlQueryHook<GetLocalesQuery, GetLocalesQueryVariables>(SCHEMA);
export const useLazyGetLocales = makeGqlQueryLazyHook<GetLocalesQuery, GetLocalesQueryVariables>(
    SCHEMA,
);
