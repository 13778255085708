import { useAppDispatch } from "@/hooks/index";
import { usePermissionsChangedSubscription } from "@/graphql/permissions";
import { UPDATE_USER_PERMISSIONS } from "@/store/games/slice";

export function useGraphQlSubscriptions() {
    const dispatch = useAppDispatch();
    usePermissionsChangedSubscription({
        onData: (options) => {
            if (options.data.data) {
                dispatch(UPDATE_USER_PERMISSIONS(options.data.data.result));
            }
        },
    });
}
