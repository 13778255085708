import { useCallback, useMemo } from "react";
import { bootstrapDialog, create, NiceModalHocProps, show, useModal } from "@ebay/nice-modal-react";
import Modal from "react-bootstrap/Modal";

type Props = {
    fileName: string;
    data: string;
};

type ModalProps = Omit<Props, "data"> & {
    data: () => Promise<string>;
};

const FilePreviewModal = create(function FilePreviewModal(props: Props) {
    const modal = useModal();
    const dialog = useMemo(() => bootstrapDialog(modal), [modal]);

    return (
        <Modal {...dialog} title={`Previewing ${props.fileName}`} size="xl">
            <Modal.Body>
                <pre>{props.data}</pre>
            </Modal.Body>
        </Modal>
    );
});

export function useFilePreviewModal() {
    return useCallback(async (props: ModalProps) => {
        const { data, ...rest } = props;
        const modalProps = {
            ...rest,
            data: await data(),
        };

        await show<void, NiceModalHocProps & Props, Props>(FilePreviewModal, modalProps);
    }, []);
}
