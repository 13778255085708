import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import { ReactNode, useCallback, useMemo } from "react";
import { ControllerFieldState } from "react-hook-form";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { FormFieldProps } from "@/fields/types";
import { useEditEntityFormController } from "@/components/forms/EditEntityForm/hooks/useEditEntityFormController";
import { EditEntityFormDisableValueBtn } from "@/fields/components/EditEntityFormDisableValueBtn";

type Props = FormFieldProps & {
    children: ReactNode | ReactNode[];
    fieldState: ControllerFieldState;
};

export function FormGroup(props: Props) {
    const { children, fieldState } = props;
    const error = useMemo(() => {
        if (!fieldState.error) return null;
        switch (fieldState.error.type) {
            case "required":
                return "This field is required";
            default:
                return fieldState.error.message || "Invalid value";
        }
    }, [fieldState.error]);

    return (
        <Form.Group>
            <Form.Label className="d-flex gap-1">
                <span>{props.title}</span>
                <EditEntityFormDisableValueBtn {...props} />
            </Form.Label>
            {children}
            {props.required && (
                <Col md={12}>
                    <Form.Text muted>Required</Form.Text>
                </Col>
            )}
            {error && <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>}
        </Form.Group>
    );
}
