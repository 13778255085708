import { useCallback, useMemo } from "react";
import DataTable, { TableColumn } from "react-data-table-component";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import { UserInfo } from "@/components/common/UserInfo";
import {
    useBlockUserMutation,
    useCreateUserMutation,
    useGetUsersQuery,
    useUnblockUserMutation,
} from "@/graphql/wizardry/users";
import { booleanCell } from "@/components/common/DataTable/datatype-cells/boolean";
import { UserInfoExpanded } from "@/components/wizardry/users/UserInfoExpanded";
import { useDialog } from "@/hooks/useDialog";
import { CreateUserDialog } from "@/components/wizardry/users/CreateUserDialog";
import { WizCreateUserMutationVariables } from "@/gql";
import { User } from "./types";

type Props = {
    data: User[];
};

export function UsersList(props: Props) {
    const { refetch, loading: refetchLoading } = useGetUsersQuery();
    const [createUser, { loading: createUserLoading }] = useCreateUserMutation();
    const [blockUserMutation, { loading: blockLoading }] = useBlockUserMutation();
    const [unblockUserMutation, { loading: unblockLoading }] = useUnblockUserMutation();
    const loading = refetchLoading || blockLoading || unblockLoading || createUserLoading;
    const newUserDialog = useDialog<WizCreateUserMutationVariables>(CreateUserDialog);
    const onCreateUser = useCallback(async () => {
        const result = await newUserDialog.open({});
        if (!result) return;

        await createUser({
            variables: result,
        });
        await refetch();
    }, [createUser, newUserDialog, refetch]);
    const blockUser = useCallback(
        async (userId: string) => {
            await blockUserMutation({
                variables: { userId },
            });
            await refetch();
        },
        [blockUserMutation, refetch],
    );
    const unblockUser = useCallback(
        async (userId: string) => {
            await unblockUserMutation({
                variables: { userId },
            });
            await refetch();
        },
        [refetch, unblockUserMutation],
    );
    const actions = useMemo(
        () => (
            <ButtonGroup>
                <Button onClick={onCreateUser}>Create user</Button>
            </ButtonGroup>
        ),
        [onCreateUser],
    );
    const columns = useMemo<TableColumn<User>[]>(
        () => [
            {
                name: "User",
                cell: (row) => <UserInfo user={row} />,
            },
            {
                name: "Has access disabled",
                cell: booleanCell((row) => row.isDisabled),
            },
            {
                name: "Actions",
                cell: (row) => (
                    <>
                        {!row.isDisabled && (
                            <Button
                                disabled={loading}
                                variant="danger"
                                onClick={() => blockUser(row.guid)}
                            >
                                Block
                            </Button>
                        )}
                        {row.isDisabled && (
                            <Button
                                disabled={loading}
                                variant="danger"
                                onClick={() => unblockUser(row.guid)}
                            >
                                Unblock
                            </Button>
                        )}
                    </>
                ),
            },
        ],
        [blockUser, loading, unblockUser],
    );

    return (
        <>
            <DataTable
                data={props.data}
                columns={columns}
                actions={actions}
                expandableRowsComponent={UserInfoExpanded}
                expandableRows
                striped
                fixedHeader
            />
            <newUserDialog.modal />
        </>
    );
}
