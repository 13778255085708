import { MutableRefObject, useEffect, useState } from "react";

export function useFocusState(elementRef: MutableRefObject<HTMLElement | null>) {
    const [focused, setFocused] = useState(false);

    useEffect(() => {
        const savedReference = elementRef;

        function onFocus() {
            setFocused(true);
        }

        function onBlur() {
            setFocused(false);
        }

        savedReference.current?.addEventListener("focus", onFocus);
        savedReference.current?.addEventListener("blur", onBlur);
        return () => {
            savedReference.current?.removeEventListener("focus", onFocus);
            savedReference.current?.removeEventListener("blur", onBlur);
        };
    }, [elementRef]);

    return focused;
}
