import { useCallback } from "react";
import { useCopyToClipboard } from "usehooks-ts";
import { useSnackbar } from "notistack";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import { FaCopy } from "react-icons/fa";

type Props = {
    prefix?: string;
    value: string | number;
    className?: string;
};

export function CopyToClipboard(props: Props) {
    const [, copy] = useCopyToClipboard();
    const { enqueueSnackbar } = useSnackbar();
    const onCopy = useCallback(async () => {
        await copy(String(props.value));
        enqueueSnackbar("Copied!", { variant: "success" });
    }, [copy, enqueueSnackbar, props.value]);

    return (
        <InputGroup size="sm" className={props.className}>
            {props.prefix && <InputGroup.Text>{props.prefix}</InputGroup.Text>}
            <Form.Control value={props.value} disabled />
            <Button onClick={onCopy}>
                <FaCopy />
            </Button>
        </InputGroup>
    );
}
