import { useMemo } from "react";
import FormText from "react-bootstrap/FormText";
import { FormSelect, SelectOption } from "@/components/common/forms/form-inputs/FormSelect";
import { useGlobalEnum } from "@/store/games/slices";
import { useEditEntityFormController } from "@/components/forms/EditEntityForm/hooks/useEditEntityFormController";
import { FormGroup } from "@/fields/components/FormGroup";
import { FormFieldProps } from "../../types";

export function EnumFormField(props: FormFieldProps) {
    if (!props.templateParameter.meta.enumId) {
        throw new Error(`Enum parameter ${props.templateParameter.name} doesn't reference a enum`);
    }

    const { field, fieldState } = useEditEntityFormController(props, "value");
    const enumDef = useGlobalEnum(props.templateParameter.meta.enumId);
    const enumOptions = useMemo<SelectOption[]>(() => {
        if (!enumDef) return [];

        return (
            enumDef.meta.enum?.enumValues.map((value) => ({
                value: value.value,
                label: value.name,
            })) ?? []
        );
    }, [enumDef]);

    return (
        <FormGroup {...props} fieldState={fieldState}>
            <FormSelect options={enumOptions} {...field} />
            {fieldState.error && (
                <FormText className="text-danger">{fieldState.error.message}</FormText>
            )}
        </FormGroup>
    );
}
