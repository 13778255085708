import { useCallback, useMemo } from "react";
import {
    OptionProps,
    SingleValueProps,
    components,
    OnChangeValue,
    ValueContainerProps,
    StylesConfig,
} from "react-select";
import AsyncSelect from "react-select/async";
import { useSnackbar } from "notistack";
import { useLazyGetAssets } from "@/graphql/assets";
import { AssetRenderer } from "@/components/common/AssetRenderer";
import { layoutWithBreadcrumbs } from "@/layouts/LayoutWithBreadcrumbs";

interface ComponentOption {
    readonly name: string;
    readonly value: string;
    readonly gameId: string;
}

const Option = (props: OptionProps<ComponentOption>) => (
    <components.Option {...props}>
        <AssetRenderer guid={props.data.value} gameId={props.data.gameId} />
    </components.Option>
);

const ValueContainer = (props: ValueContainerProps<ComponentOption>) => {
    return (
        <components.ValueContainer {...props}>
            {props.getValue().map((value) => (
                <AssetRenderer key={value.value} guid={value.value} gameId={value.gameId} />
            ))}
        </components.ValueContainer>
    );
};

const SingleValue = (props: SingleValueProps<ComponentOption>) => (
    <components.SingleValue {...props}>
        <AssetRenderer guid={props.data.value} gameId={props.data.gameId} />
    </components.SingleValue>
);

interface Props {
    value: string;
    onChange: (value: string) => void;
    name?: string;
    className?: string;
    clearable?: boolean;
}

const selectStyles: StylesConfig<ComponentOption, false> = {
    menuPortal: (baseStyles) => ({
        ...baseStyles,
        zIndex: 10001,
    }),
};

const selectComponents = {
    Option,
    SingleValue,
    ValueContainer,
};

export function AssetSelector(props: Props) {
    const { gameId } = layoutWithBreadcrumbs.useParams();
    const { name, value, onChange, clearable = false } = props;
    const [getAssetsQuery] = useLazyGetAssets();
    const { enqueueSnackbar } = useSnackbar();
    const defaultValue = useMemo(
        () => (value ? { name: "%name%", gameId: gameId!, value } : null),
        [gameId, value],
    );
    const loadOptions = useCallback(async (): Promise<ComponentOption[]> => {
        if (!gameId) {
            throw new Error("route parameter gameId is not available in current context");
        }

        const { data } = await getAssetsQuery({ variables: { gameId } });

        if (!data) {
            enqueueSnackbar("Failed to load assets", {
                variant: "error",
                autoHideDuration: 3000,
            });
            return [];
        }

        return data.result.map((asset) => {
            return {
                value: asset.guid,
                name: asset.name,
                gameId: asset.gameId,
            };
        });
    }, [gameId, getAssetsQuery, enqueueSnackbar]);

    const onSelectChange = useCallback(
        (newValue: OnChangeValue<ComponentOption, false>) => {
            onChange(newValue?.value ?? "");
        },
        [onChange],
    );

    return (
        <AsyncSelect
            isMulti={false}
            className={props.className}
            isClearable={clearable}
            cacheOptions
            defaultValue={defaultValue}
            name={name}
            menuPortalTarget={document.body}
            defaultOptions
            styles={selectStyles}
            loadOptions={loadOptions}
            components={selectComponents}
            onChange={onSelectChange}
        />
    );
}
