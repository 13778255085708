import { memo, PropsWithChildren, useMemo } from "react";
import { ErrorBoundary } from "react-error-boundary";
import cn from "classnames";
import Form from "react-bootstrap/Form";
import { useFieldsDefinitions } from "../hooks/useFieldsDefinitions";
import { useTableContext } from "../contexts/TableContext";
import { CellContextProvider, useCellContext } from "../contexts/CellContext";
import { useEntitiesTableFormContext } from "../hooks/useEntitiesTableFormContext";
import { TableCellWrapper } from "./TableCell.components";
import { CellFallbackRenderer } from "./CellFallbackRenderer";
import { TableCellContextActions } from "./TableCellContextActions";
import { TableCellRawValue } from "./TableCellRawValue";

export const TableCell = memo(function TableCell(props: PropsWithChildren<{ className?: string }>) {
    const formContext = useEntitiesTableFormContext();
    const { showIds, showRawValues, readonly } = useTableContext();
    const { getDefinition } = useFieldsDefinitions();
    const { row, templateParam, type, contextEntity } = useCellContext();
    const fieldDefinition = getDefinition(type);
    const CellComponent = readonly
        ? fieldDefinition.readonlyComponent
        : fieldDefinition.inputComponent;
    const name = `entities.${contextEntity.guid}.values.${templateParam.guid}` as const;
    const exported = formContext.watch(`entities.${row.guid}.export`);
    const fieldState = formContext.getFieldState(name);
    const rootClassName = useMemo(
        () =>
            cn("d-flex flex-column p-1 h-100 bg-opacity-25", props.className, {
                "bg-yellow": fieldState.isDirty,
                "bg-red": fieldState.invalid,
                "text-muted": !exported,
                "text-decoration-line-through": !exported,
            }),
        [fieldState.invalid, fieldState.isDirty, exported, props.className],
    );

    return (
        <TableCellWrapper className={rootClassName}>
            {showIds && (
                <div className="small font-monospace p-1">
                    <div>tpid: {templateParam.guid}</div>
                    <div>eid: {row.guid}</div>
                </div>
            )}
            {showRawValues && <TableCellRawValue name={name} />}
            <ErrorBoundary fallbackRender={CellFallbackRenderer}>
                {/* <InView triggerOnce>*/}
                {/*    {({ inView, ref }) => (*/}
                <div
                    // ref={ref}
                    className={cn("w-100", {
                        error: fieldState.error,
                    })}
                >
                    {/* {inView ? (*/}
                    <CellComponent name={name} />
                    {/* ) : (*/}
                    {/*    <fieldDefinition.placeholderComponent name={name} />*/}
                    {/* )}*/}
                    {fieldState.error && (
                        <Form.Control.Feedback type="invalid">
                            {fieldState.error.message}
                        </Form.Control.Feedback>
                    )}
                    {!readonly && <TableCellContextActions name={name} />}
                </div>
                {/* )}*/}
                {/* </InView>*/}
            </ErrorBoundary>
        </TableCellWrapper>
    );
});
