import { DataType } from "@/gql";
import { createDatatype } from "../../createDatatype";
import { EnumFieldComponent } from "../../datatypes/enum/EnumFieldComponent";
import { EnumFallbackComponent } from "./EnumFallbackComponent";

export const enumField = createDatatype<number | null>({
    type: DataType.Enum,
    defaultValue: (templateParam) => {
        return templateParam?.meta.enum?.enumValues[0]?.value ?? null;
    },
    inputComponent: EnumFieldComponent,
    placeholderComponent: EnumFallbackComponent,
    tableCellDisplayOptions: {
        minWidth: "250px",
    },
});
