import FormText from "react-bootstrap/FormText";
import { TimestampInput } from "@/fields/datatypes/Timestamp/TimestampInput";
import { FormFieldProps } from "@/fields/types";
import { FormGroup } from "@/fields/components/FormGroup";
import { useEditEntityFormController } from "@/components/forms/EditEntityForm/hooks/useEditEntityFormController";

export function TimestampFormField(props: FormFieldProps) {
    const { field, fieldState } = useEditEntityFormController(props, "value");

    return (
        <FormGroup {...props} fieldState={fieldState}>
            <TimestampInput {...field} disabled={props.readonly} />
            {fieldState.error && (
                <FormText className="text-danger">{fieldState.error.message}</FormText>
            )}
        </FormGroup>
    );
}
