import {
    ArtifactGenerationFailedEvent,
    ArtifactGenerationFinishedEvent,
    ArtifactGenerationStartedEvent,
    DeployStep,
} from "@/gql";

interface DeployEventRendererProps {
    event:
        | ArtifactGenerationFailedEvent
        | ArtifactGenerationFinishedEvent
        | ArtifactGenerationStartedEvent;
}

export function ArtifactEventRenderer(props: DeployEventRendererProps) {
    switch (props.event.deployCode) {
        case DeployStep.ArtifactGenerationStarted:
            return <span>Artifact generation started</span>;
        case DeployStep.ArtifactGenerationFinished:
            return <span>Artifact generation finished</span>;
        case DeployStep.ArtifactGenerationFailed:
            return <span>Artifact generation failed</span>;
        default:
            return <span>Incorrect event code</span>;
    }
}
