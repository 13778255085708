import { gql } from "@apollo/client";
import { RegisterUserMutation, RegisterUserMutationVariables } from "@/gql";
import { makeGqlMutationHook } from "../util";
import { AUTH_USER_FRAGMENT } from "../fragments/user.fragment";

const REGISTER = gql`
    ${AUTH_USER_FRAGMENT}

    mutation registerUser(
        $email: String!
        $password: String!
        $firstname: String!
        $lastname: String!
    ) {
        result: register(
            email: $email
            password: $password
            firstname: $firstname
            lastname: $lastname
        ) {
            ...AuthUser
        }
    }
`;

export const useRegistrationMutation = makeGqlMutationHook<
    RegisterUserMutation,
    RegisterUserMutationVariables
>(REGISTER);
