import { gql } from "@apollo/client";
import { GetEntitiesV2Query, GetEntitiesV2QueryVariables } from "@/gql";
import { makeGqlQueryHook, makeGqlQueryLazyHook } from "../util";

const GET_ENTITIES = gql`
    query getEntitiesV2(
        $templateId: String!
        $gameId: String!
        $take: Float
        $skip: Float
        $query: String
        $descending: Boolean
        $sortAttribute: String
    ) {
        getTemplateEntitiesV2(
            templateId: $templateId
            gameId: $gameId
            take: $take
            skip: $skip
            query: $query
            descending: $descending
            sortAttribute: $sortAttribute
        ) {
            total
            entities {
                guid
                gameId
                export
                values {
                    parameterId
                    shouldOmit
                    value
                }
            }
        }
    }
`;

export const useGetEntitiesV2Query = makeGqlQueryHook<
    GetEntitiesV2Query,
    GetEntitiesV2QueryVariables
>(GET_ENTITIES, { fetchPolicy: "network-only" });
export const useLazyGetEntitiesV2Query = makeGqlQueryLazyHook<
    GetEntitiesV2Query,
    GetEntitiesV2QueryVariables
>(GET_ENTITIES, { fetchPolicy: "network-only" });
