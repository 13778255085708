import { DataType, TemplateParam } from "@/gql";
import { SelectableRefParam } from "./SelectableRefParam";
import { SelectablePlainParam } from "./SelectablePlainParam";

type Props = {
    templateParam: TemplateParam;
    className?: string;
    disabled?: boolean;
};

export function SelectableTemplateParam(props: Props) {
    const isRef =
        props.templateParam.type === DataType.InjectedRef ||
        props.templateParam.type === DataType.EntityRef ||
        props.templateParam.subType === DataType.InjectedRef ||
        props.templateParam.subType === DataType.EntityRef;
    if (!isRef) {
        return <SelectablePlainParam {...props} />;
    } else if (props.templateParam.meta.templateId) {
        return <SelectableRefParam {...props} />;
    } else {
        throw new Error(
            `Parameter ${props.templateParam.name} is a ref but doesn't reference anything`,
        );
    }
}
