import Stack from "react-bootstrap/Stack";
import { useGetAsset } from "@/graphql/assets";
import { DataAwaiter2 } from "@/components/common/DataAwaiter2";

type Props = {
    readonly guid: string;
    readonly gameId: string;
};

export function AssetRenderer(props: Props) {
    const asset = useGetAsset({ guid: props.guid, gameId: props.gameId });

    return (
        <DataAwaiter2 {...asset}>
            {(data) => (
                <Stack title={data.result.filename}>
                    <span>name: {data.result.name}</span>
                    <span>
                        guid: <code className="fst-italic">{data.result.guid}</code>
                    </span>
                    <span>
                        hash: <code>{data.result.hash}</code>
                    </span>
                </Stack>
            )}
        </DataAwaiter2>
    );
}
