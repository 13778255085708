import { useCallback, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { SingleValue } from "react-select";
import { Artifact } from "@/gql";
import { useGetArtifact } from "@/graphql/deploy";
import { DataAwaiter } from "@/components/common/DataAwaiter";
import { Select } from "@/components/common/Select";
import { ArtifactDetails } from "@/components/deploy";
import { ConsumerArtifact } from "./types";

type Props = {
    artifact: Artifact;
};

export function DeployedVersions(props: Props) {
    const [selectedConsumerArtifact, setSelectedConsumerArtifact] =
        useState<ConsumerArtifact | null>(null);
    const { data, loading, error } = useGetArtifact({
        guid: props.artifact.guid,
        gameId: props.artifact.gameId,
    });
    const onSelectChange = useCallback((newValue: SingleValue<ConsumerArtifact>) => {
        setSelectedConsumerArtifact(newValue);
    }, []);

    return (
        <DataAwaiter data={data} isLoading={loading} error={error}>
            {(data) => (
                <Row className="g-4">
                    <Col md={6}>
                        <Select<ConsumerArtifact, false>
                            isMulti={false}
                            isClearable={false}
                            getOptionValue={(v) => v.guid}
                            getOptionLabel={(v) => v.consumerName}
                            options={data.artifact?.consumerArtifacts ?? []}
                            onChange={onSelectChange}
                            value={selectedConsumerArtifact}
                        />
                    </Col>
                    {selectedConsumerArtifact && (
                        <>
                            <Col md={8}>
                                <ArtifactDetails
                                    deployedOn={props.artifact.currentlyDeployed}
                                    consumerArtifact={selectedConsumerArtifact}
                                />
                            </Col>
                            <Col md={4}>
                                <div className="px-3 py-2 mb-4">
                                    <p className="fs-6 fw-bold">Artifact generation options</p>
                                    <ul className="">
                                        {selectedConsumerArtifact.deployFlags.length > 0 ? (
                                            selectedConsumerArtifact.deployFlags.map((flag) => (
                                                <li key={flag.value}>
                                                    <div>
                                                        {flag.name}{" "}
                                                        <span className="small text-muted">
                                                            ({flag.value})
                                                        </span>
                                                    </div>
                                                    <div className="small text-muted">
                                                        {flag.description}
                                                    </div>
                                                </li>
                                            ))
                                        ) : (
                                            <span>no deploy flags</span>
                                        )}
                                    </ul>
                                </div>
                            </Col>
                        </>
                    )}
                </Row>
            )}
        </DataAwaiter>
    );
}
