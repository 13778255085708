import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import BsForm from "react-bootstrap/Form";
import { ConnectForm } from "@/components/common/forms/ConnectForm";
import { AdditionalParameterFields } from "../../types";

export const AdditionalBooleanFields: AdditionalParameterFields = () => {
    return (
        <ConnectForm>
            {(methods) => (
                <Row>
                    <Col>
                        <BsForm.Check
                            {...methods.register("meta.defaultValue")}
                            type="switch"
                            id="default-value-switch"
                            label="Default value"
                        />
                    </Col>
                </Row>
            )}
        </ConnectForm>
    );
};
