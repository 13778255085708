import { DataType } from "@/gql";
import { createDatatype } from "../../createDatatype";
import { BooleanFieldComponent } from "../../datatypes/boolean/BooleanFieldComponent";

export const booleanField = createDatatype<string>({
    type: DataType.Boolean,
    defaultValue: () => "false",
    validator: () => true,
    inputComponent: BooleanFieldComponent,
    tableCellDisplayOptions: {
        maxWidth: "50px",
        center: true,
    },
});
