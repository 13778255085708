import { gql } from "@apollo/client";

export const ENTITY_PARAM_FRAGMENT = gql`
    fragment EntityParamFragment on TemplateEntityParam {
        value
        templateParameterId
        guid
        shouldOmit
        entityId
        gameId
    }
`;
