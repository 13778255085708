import { FaWandMagicSparkles } from "react-icons/fa6";
import { useGetUserById } from "@/graphql/auth";
import { DataAwaiter2 } from "./DataAwaiter2";

type Props = {
    userId: string;
    className?: string;
};

export function UserInfoAsync(props: Props) {
    const getUserById = useGetUserById({ userId: props.userId });

    return (
        <DataAwaiter2 {...getUserById}>
            {(data) => (
                <span className={props.className}>
                    {data.user.firstname} {data.user.lastname} &lt;{data.user.email}&gt;{" "}
                    {data.user.canDoWizardry && (
                        <FaWandMagicSparkles size={18} style={{ color: "var(--bs-yellow)" }} />
                    )}
                </span>
            )}
        </DataAwaiter2>
    );
}
