import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Helmet } from "react-helmet-async";
import { createRoute } from "@tanstack/react-router";
import { UserDropdown } from "@/components/layout/UserDropdown";
import { EditUserSection } from "@/components/profile/EditUserSection";
import { EditEmailSection } from "@/components/profile/EditEmailSection";
import { EditPasswordSection } from "@/components/profile/EditPasswordSection";
import { startPage } from "@/pages/StartPage";

export default function Profile() {
    return (
        <Container className="py-4">
            <Helmet title="Profile" />
            <Row>
                <Col className="ms-auto flex-grow-1"></Col>
                <Col md={2} className="flex-grow-0">
                    <UserDropdown />
                </Col>
            </Row>
            <hr />

            <EditUserSection />
            <hr />
            <EditEmailSection />
            <hr />
            <EditPasswordSection />
        </Container>
    );
}

export const profilePageRoute = createRoute({
    getParentRoute: () => startPage,
    path: "profile",
    component: Profile,
});
