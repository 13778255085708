import { gql } from "@apollo/client";
import { USER_FRAGMENT } from "@/graphql/fragments/user.fragment";
import { makeGqlMutationHook } from "@/graphql/util";
import { WizBlockUserMutation, WizBlockUserMutationVariables } from "@/gql";

const schema = gql`
    ${USER_FRAGMENT}

    mutation wizBlockUser($userId: String!) {
        wizBlockUser(userId: $userId) {
            ...User
        }
    }
`;

export const useBlockUserMutation = makeGqlMutationHook<
    WizBlockUserMutation,
    WizBlockUserMutationVariables
>(schema);
