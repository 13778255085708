import { DataTypeField } from "@/fields/types";
import { DataType } from "@/gql";
import { AssetTableCell } from "./AssetTableCell";
import { AssetFormField } from "./AssetFormField";
import { AssetParameterFields } from "./AssetParameterFields";

export const AssetDataType: DataTypeField = {
    dataType: DataType.Asset,
    defaultValue: () => null,
    formField: AssetFormField,
    tableCell: AssetTableCell,
    additionalParameterFields: AssetParameterFields,
    tableCellDisplayOptions: {
        minSize: 350,
        enableSorting: false,
    },
};
