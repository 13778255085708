import { type FC, memo, useCallback, useState } from "react";
import Button from "react-bootstrap/Button";
import { useGlobalParameters } from "@/store/games/slices";
import { DataType } from "@/gql";
import { BaseModal } from "@/components/modals/BaseModal";
import { ScriptEditor } from "@/components/common/ScriptEditor";
import { InputProps } from "../../types";
import { useCellContext } from "../../contexts/CellContext";
import { useFieldController } from "../../hooks/useFieldController";

export const ScriptFieldComponent: FC<InputProps> = memo(function ScriptTableCell(props) {
    const { templateParam } = useCellContext();
    const controller = useFieldController(props.name);
    const [isEditMode, setIsEditMode] = useState(false);
    const switchToEditMode = useCallback(() => setIsEditMode(true), []);
    const switchToDisplayMode = useCallback(() => setIsEditMode(false), []);
    const [scriptScopesIds] = useState(() => new Set(templateParam.meta.scriptScopes));
    const availableScriptScopes = useGlobalParameters(DataType.Script);
    const scriptScopes = availableScriptScopes.filter((value) => scriptScopesIds.has(value.guid));
    const availableEnums = useGlobalParameters(DataType.Enum);

    return isEditMode ? (
        <BaseModal isOpen toggle={switchToDisplayMode} size="xl">
            <ScriptEditor
                value={controller.field.value as any}
                onChange={controller.field.onChange}
                scriptScopes={scriptScopes}
                availableEnums={availableEnums}
            />
        </BaseModal>
    ) : (
        <Button ref={controller.field.ref} size="sm" variant="info" onClick={switchToEditMode}>
            Edit script
        </Button>
    );
});
