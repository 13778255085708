import { gql } from "@apollo/client";
import { GetProjectPermissionsQuery, GetProjectPermissionsQueryVariables } from "@/gql";
import { USER_PERMISSIONS_FRAGMENT } from "../fragments/user-permissions.fragment";
import { USER_FRAGMENT } from "../fragments/user.fragment";
import { makeGqlQueryHook } from "../util";

const SCHEMA = gql`
    ${USER_PERMISSIONS_FRAGMENT}
    ${USER_FRAGMENT}

    query getProjectPermissions($gameId: String!) {
        projectPermissions(gameId: $gameId) {
            ...UserPermission
            user {
                ...User
            }
        }
    }
`;

export const useGetProjectPermissions = makeGqlQueryHook<
    GetProjectPermissionsQuery,
    GetProjectPermissionsQueryVariables
>(SCHEMA);
