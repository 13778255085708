import { useCallback, useMemo, useState } from "react";
import keyBy from "lodash/keyBy";
import isEqual from "lodash/isEqual";
import unionWith from "lodash/unionWith";
import sortBy from "lodash/sortBy";
import { useGetArtifactsForDiff } from "@/graphql/deploy";
import { ConsumerArtifact, EntitiesFile } from "@/gql";

type Props = {
    gameId: string;
    idA?: string | null;
    idB?: string | null;
};

type DiffEntry = {
    text: string;
    status: "unchanged" | "changed" | "removed" | "added";
    dataFrom: any;
    dataTo: any;
};

export function useArtifactDiff(props: Props) {
    const { idA, idB, gameId } = props;
    const { data, loading, error } = useGetArtifactsForDiff({ gameId, idA, idB });
    const [selectedConsumerArtifactId, setSelectedConsumerArtifactId] = useState<string | null>(
        null,
    );
    const [selectedDiffEntry, setSelectedDiffEntry] = useState<DiffEntry | null>(null);

    const consumerArtifacts = useMemo(() => {
        return data?.getArtifactsForDiff[0]?.consumerArtifacts ?? [];
    }, [data?.getArtifactsForDiff]);

    const selectedConsumerArtifact = useMemo((): [
        ConsumerArtifact | null,
        ConsumerArtifact | null,
    ] => {
        return [
            data?.getArtifactsForDiff[0]?.consumerArtifacts!.find(
                (ca) => ca.consumerId === selectedConsumerArtifactId,
            ) ?? null,
            data?.getArtifactsForDiff[1]?.consumerArtifacts!.find(
                (ca) => ca.consumerId === selectedConsumerArtifactId,
            ) ?? null,
        ];
    }, [data?.getArtifactsForDiff, selectedConsumerArtifactId]);

    const versionsFileDiff = useMemo((): DiffEntry | null => {
        if (
            !selectedConsumerArtifact[0]?.versionsFile &&
            !selectedConsumerArtifact[1]?.versionsFile
        )
            return null;

        return {
            text: "versions.json",
            status: isEqual(
                selectedConsumerArtifact[0]?.versionsFile,
                selectedConsumerArtifact[1]?.versionsFile,
            )
                ? "unchanged"
                : "changed",
            dataFrom: selectedConsumerArtifact[0]?.versionsFile,
            dataTo: selectedConsumerArtifact[1]?.versionsFile,
        };
    }, [selectedConsumerArtifact]);

    const manifestFileDiff = useMemo((): DiffEntry | null => {
        if (
            !selectedConsumerArtifact[0]?.versionsFile &&
            !selectedConsumerArtifact[1]?.versionsFile
        )
            return null;

        return {
            text: "manifest.json",
            status: isEqual(
                selectedConsumerArtifact[0]?.metadataFile,
                selectedConsumerArtifact[1]?.metadataFile,
            )
                ? "unchanged"
                : "changed",
            dataFrom: selectedConsumerArtifact[0]?.metadataFile,
            dataTo: selectedConsumerArtifact[1]?.metadataFile,
        };
    }, [selectedConsumerArtifact]);

    const dataFileStatus = useCallback(
        (a: EntitiesFile | null, b: EntitiesFile | null): DiffEntry["status"] => {
            if (!a) return "added";
            if (!b) return "removed";
            if (a.version === b.version) return "unchanged";
            return "changed";
        },
        [],
    );

    const dataFilesDiff = useMemo((): DiffEntry[] => {
        const dataFilesSetFrom = keyBy(selectedConsumerArtifact[0]?.dataFiles, "id");
        const dataFilesSetTo = keyBy(selectedConsumerArtifact[1]?.dataFiles, "id");
        return sortBy(
            unionWith(
                selectedConsumerArtifact[0]?.dataFiles,
                selectedConsumerArtifact[1]?.dataFiles,
                (a, b) => a.name === b.name,
            ).map(
                (v): DiffEntry => ({
                    text: v.name,
                    status: dataFileStatus(
                        dataFilesSetFrom[v.id] ?? null,
                        dataFilesSetTo[v.id] ?? null,
                    ),
                    dataFrom: dataFilesSetFrom[v.id],
                    dataTo: dataFilesSetTo[v.id],
                }),
            ),
            ["text"],
        );
    }, [dataFileStatus, selectedConsumerArtifact]);

    const diffSummary = useMemo(
        (): DiffEntry[] => [versionsFileDiff, manifestFileDiff, ...dataFilesDiff].filter(Boolean),
        [dataFilesDiff, manifestFileDiff, versionsFileDiff],
    );

    return {
        data,
        loading,
        error,
        consumerArtifacts,
        diffSummary,
        selectedDiffEntry,
        setSelectedDiffEntry,
        selectedConsumerArtifactId,
        setSelectedConsumerArtifactId,
    };
}
