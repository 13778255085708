import { FieldValues, useFormContext, UseFormReturn } from "react-hook-form";
import { ReactElement } from "react";

interface ConnectFormProps<TFieldValues extends FieldValues = FieldValues> {
    children: (methods: UseFormReturn<TFieldValues>) => ReactElement | null;
}

export function ConnectForm<TFieldValues extends FieldValues = FieldValues>({
    children,
}: ConnectFormProps<TFieldValues>) {
    const methods = useFormContext<TFieldValues>();

    return children({ ...methods });
}
