import { useCallback, useMemo } from "react";
import NiceModal, { bootstrapDialog, useModal } from "@ebay/nice-modal-react";
import Modal from "react-bootstrap/Modal";
import classNames from "classnames";
import { useController, useForm } from "react-hook-form";
import Form from "react-bootstrap/Form";
import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup";
import isNil from "lodash/isNil";
import Stack from "react-bootstrap/Stack";
import Button from "react-bootstrap/Button";
import ToggleButton from "react-bootstrap/ToggleButton";
import { DataAwaiter2 } from "@/components/common/DataAwaiter2";
import {
    useDeployLockQuery,
    useLiftDeployLockMutation,
    useSetDeployLockMutation,
} from "@/graphql/game";
import { DeployLockQuery, DeployLockType } from "@/gql";
import { ConfirmationButton } from "@/components/common/ConfirmationButton";
import { UserInfo } from "@/components/common/UserInfo";
import { dayjs } from "@/utils/dayjs";

type Props = {
    gameId: string;
};

type DeployForm = {
    description: string;
    expiresAt: string;
    lockType: DeployLockType;
};

function DeployLockModalComponent(props: Props) {
    const deployLock = useDeployLockQuery({ gameId: props.gameId });

    return (
        <DataAwaiter2 {...deployLock}>
            {(data) => <DeployLockModal2 data={data} {...props} />}
        </DataAwaiter2>
    );
}

function DeployLockModal2(props: { data: DeployLockQuery } & Props) {
    const modal = useModal();
    const dialog = bootstrapDialog(modal);
    const { refetch } = useDeployLockQuery({ gameId: props.gameId });
    const [setDeployLockMutation] = useSetDeployLockMutation();
    const [liftDeployLockMutation] = useLiftDeployLockMutation();
    const isFormDisabled = !isNil(props.data.deployLock);
    const dateToInputDate = useCallback((date: Date) => {
        return dayjs(date).format("YYYY-MM-DDTHH:mm");
    }, []);
    const defaultValues = useMemo<DeployForm>(
        () => ({
            description: props.data.deployLock?.description ?? "",
            expiresAt: dateToInputDate(props.data.deployLock?.expiresAt ?? new Date()),
            lockType: props.data.deployLock?.lockType ?? DeployLockType.Both,
        }),
        [
            dateToInputDate,
            props.data.deployLock?.description,
            props.data.deployLock?.expiresAt,
            props.data.deployLock?.lockType,
        ],
    );
    const form = useForm<DeployForm>({
        mode: "onChange",
        defaultValues,
        disabled: isFormDisabled,
        shouldUnregister: true,
    });
    const lockTypeController = useController({
        name: "lockType",
        control: form.control,
    });
    const onDeployLockSet = useCallback(
        async (data: { description: string; expiresAt: Date; lockType: DeployLockType }) => {
            await setDeployLockMutation({
                variables: {
                    gameId: props.gameId,
                    expiresAt: data.expiresAt,
                    description: data.description,
                    lockType: data.lockType,
                },
                onCompleted: () => refetch(),
            });
        },
        [props.gameId, refetch, setDeployLockMutation],
    );
    const onDeployLockLift = useCallback(async () => {
        await liftDeployLockMutation({
            variables: { gameId: props.gameId },
            onCompleted: () => refetch(),
        });
    }, [liftDeployLockMutation, props.gameId, refetch]);
    const onSubmit = useCallback(
        (data: DeployForm) =>
            onDeployLockSet({
                expiresAt: dayjs(data.expiresAt, "YYYY-MM-DDTHH:mm").toDate(),
                description: data.description,
                lockType: data.lockType,
            }),
        [onDeployLockSet],
    );

    return (
        <Modal
            {...dialog}
            contentClassName={classNames({
                "bg-gradient-warning": isFormDisabled,
                "bg-gradient-success": !isFormDisabled,
            })}
        >
            <Modal.Header closeButton>
                <Modal.Title>{isFormDisabled ? "Deploy locked" : "Lock deploy"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={form.handleSubmit(onSubmit)}>
                    <Stack gap={2}>
                        <Form.Group>
                            <Form.Label>Reason</Form.Label>
                            <Form.Control
                                {...form.register("description", {
                                    disabled: isFormDisabled,
                                    required: true,
                                })}
                                as="textarea"
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Expires at</Form.Label>
                            <Form.Control
                                {...form.register("expiresAt", {
                                    disabled: isFormDisabled,
                                    required: true,
                                })}
                                type="datetime-local"
                            />
                        </Form.Group>
                        <ToggleButtonGroup {...lockTypeController.field} type="radio">
                            {Object.values(DeployLockType).map((value) => (
                                <ToggleButton
                                    key={value}
                                    id={`lock-type-${value}`.toLowerCase()}
                                    type="radio"
                                    value={value}
                                >
                                    {value}
                                </ToggleButton>
                            ))}
                        </ToggleButtonGroup>
                        {props.data.deployLock?.creator && (
                            <Form.Group className="mt-4">
                                Lock set by <UserInfo user={props.data.deployLock.creator} />
                            </Form.Group>
                        )}
                        <Form.Group className="mt-4">
                            <div className="d-flex gap-2 justify-content-end">
                                <Button
                                    type="submit"
                                    variant="danger"
                                    disabled={!form.formState.isValid || form.formState.disabled}
                                >
                                    Lock
                                </Button>
                                <ConfirmationButton
                                    variant="warning"
                                    disabled={!isFormDisabled}
                                    onConfirm={onDeployLockLift}
                                >
                                    Release
                                </ConfirmationButton>
                            </div>
                        </Form.Group>
                    </Stack>
                </Form>
            </Modal.Body>
        </Modal>
    );
}

const DeployLockModal = NiceModal.create(DeployLockModalComponent);

export function showDeployLockModal(props: Props) {
    return NiceModal.show(DeployLockModal, props);
}
