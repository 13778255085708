import NiceModal, { bootstrapDialog, useModal } from "@ebay/nice-modal-react";
import Modal from "react-bootstrap/Modal";
import { useForm, Validate } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import Form from "react-bootstrap/Form";
import Stack from "react-bootstrap/Stack";
import Button from "react-bootstrap/Button";
import { useCallback } from "react";
import { DeployDirectory, Variant } from "@/gql";

type Props = {
    deployDirectory?: DeployDirectory;
};

type DeployDirectoryForm = {
    guid: string;
    name: string;
    path: string;
    description: string;
    variant: Variant;
};

function DeployDirectoryModalComponent(props: Props) {
    const modal = useModal();
    const dialog = bootstrapDialog(modal);

    const form = useForm<DeployDirectoryForm>({
        mode: "onChange",
        defaultValues: {
            guid: props.deployDirectory?.guid ?? "",
            name: props.deployDirectory?.name ?? "",
            path: props.deployDirectory?.path ?? "",
            description: props.deployDirectory?.description ?? "",
            variant: props.deployDirectory?.variant ?? Variant.Success,
        },
        criteriaMode: "all",
    });
    const {
        formState: { errors, isValid },
    } = form;
    const pathValidator = useCallback<Validate<string, DeployDirectoryForm>>((value) => {
        if (value.includes("/")) {
            return "Path must not contain slashes (/)";
        }

        if (value.length < 2) {
            return "path must be minimum 1 symbol long";
        }

        if (value.length > 32) {
            return "path must be maximum 32 symbols long";
        }

        return true;
    }, []);
    const onSubmit = useCallback(
        (data: DeployDirectoryForm) => {
            modal.resolve(data);
            return modal.hide();
        },
        [modal],
    );

    return (
        <Modal {...dialog}>
            <Modal.Header closeButton>
                <Modal.Title>
                    {props.deployDirectory ? "Edit deploy directory" : "Create deploy directory"}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={form.handleSubmit(onSubmit)}>
                    <Stack gap={2}>
                        <Form.Group controlId="name">
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                                {...form.register("name", {
                                    required: true,
                                    min: 1,
                                    max: 255,
                                })}
                            />
                        </Form.Group>
                        <Form.Group controlId="name">
                            <Form.Label>Description</Form.Label>
                            <Form.Control
                                {...form.register("description", {
                                    max: 1024,
                                })}
                                as="textarea"
                            />
                        </Form.Group>
                        <Form.Group controlId="path">
                            <Form.Label>Path</Form.Label>
                            <Form.Control
                                {...form.register("path", {
                                    required: {
                                        message: "This field is required",
                                        value: true,
                                    },
                                    validate: pathValidator,
                                })}
                                isInvalid={Boolean(errors.path)}
                            />
                            <ErrorMessage
                                errors={errors}
                                name="path"
                                render={({ message }) => (
                                    <Form.Control.Feedback type="invalid">
                                        {message}
                                    </Form.Control.Feedback>
                                )}
                            />
                            <Form.Text>
                                This is a segment of a path. It must not contain slashes
                            </Form.Text>
                        </Form.Group>
                        <Form.Group controlId="variant">
                            <Form.Label>Variant</Form.Label>
                            <Form.Select
                                {...form.register("variant", {
                                    required: true,
                                })}
                            >
                                {Object.entries(Variant).map(([key, value]) => (
                                    <option key={key} value={value}>
                                        {key}
                                    </option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mt-4">
                            <div className="d-flex gap-2 justify-content-end">
                                <Button type="submit" variant="danger" disabled={!isValid}>
                                    {props.deployDirectory ? "Save" : "Create"}
                                </Button>
                            </div>
                        </Form.Group>
                    </Stack>
                </Form>
            </Modal.Body>
        </Modal>
    );
}

const DeployDirectoryModal = NiceModal.create(DeployDirectoryModalComponent);

export function showDeployDirectoryModal(props: Props): Promise<DeployDirectoryForm> {
    return NiceModal.show(DeployDirectoryModal, props);
}
