import { useCallback, useMemo } from "react";
import DataTable, { type ExpanderComponentProps, TableColumn } from "react-data-table-component";
import { type MultiValue } from "react-select";
import { RolesSelect } from "@/components/common/RolesSelect";
import { permissionsList } from "@/components/roles-page/constants";
import { PermissionOption } from "@/components/roles-page/types";
import { useSetUserPermissionsForProject } from "@/graphql/wizardry/users";
import { User, UserPermission } from "./types";

export function UserInfoExpanded(props: ExpanderComponentProps<User>) {
    const [setUserPermissionsForProject] = useSetUserPermissionsForProject();
    const onPermissionsChange = useCallback(
        async (value: MultiValue<PermissionOption>, userId: string, gameId: string) => {
            await setUserPermissionsForProject({
                variables: {
                    userId,
                    gameId,
                    permissions: value.map((v) => v.value),
                },
            });
        },
        [setUserPermissionsForProject],
    );
    const columns = useMemo<TableColumn<UserPermission>[]>(
        () => [
            {
                name: "Project name",
                selector: (row) => row.game!.name,
            },
            {
                name: "Project permissions",
                cell: (row) => {
                    const permissionsValue = row.permissions.map<PermissionOption>((p) => ({
                        value: p,
                        label: p,
                        isDisabled: false,
                    }));
                    return (
                        <RolesSelect
                            userId={row.userId}
                            options={permissionsList}
                            onChange={(value) => onPermissionsChange(value, row.userId, row.gameId)}
                            value={permissionsValue}
                        />
                    );
                },
            },
        ],
        [onPermissionsChange],
    );

    return (
        <div className="p-2 bg-body-secondary">
            <DataTable data={props.data.userPermissions!} columns={columns} striped fixedHeader />
        </div>
    );
}
