import { gql } from "@apollo/client";
import { ASSET_FRAGMENT } from "@/graphql/fragments/asset.fragment";
import { GetAssetsQuery, GetAssetsQueryVariables } from "@/gql";
import { makeGqlQueryHook, makeGqlQueryLazyHook } from "../util";

const SCHEMA = gql`
    ${ASSET_FRAGMENT}

    query getAssets($gameId: String!) {
        result: getAssets(gameId: $gameId) {
            ...Asset
        }
    }
`;

export const useGetAssets = makeGqlQueryHook<GetAssetsQuery, GetAssetsQueryVariables>(SCHEMA);
export const useLazyGetAssets = makeGqlQueryLazyHook<GetAssetsQuery, GetAssetsQueryVariables>(
    SCHEMA,
);
