import { ChangeEvent, useCallback, useMemo, useState } from "react";
import { useDebounceCallback } from "usehooks-ts";
import { useForm } from "react-hook-form";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import { ConsumerFragment, GetTemplatesQuery, Maybe, UpdateConsumerMutationVariables } from "@/gql";
import { useCreateConsumerMutation, useUpdateConsumerMutation } from "@/graphql/consumers";
import { ProgressButton } from "@/components/common/ProgressButton";
import { FormInput } from "../common/forms/form-inputs/FormInput";

interface EditConsumerProps {
    gameId: string;
    consumer?: Maybe<
        ConsumerFragment & { templates: { guid: string }[]; templateParams: { guid: string }[] }
    >;
    templates: GetTemplatesQuery["templates"];
    onSubmit: () => void;
}

export function EditConsumerForm(props: EditConsumerProps) {
    const { consumer, gameId, templates } = props;
    const [
        createConsumerMutation,
        { loading: createLoading, error: createError, reset: resetCreate },
    ] = useCreateConsumerMutation();
    const [
        updateConsumerMutation,
        { loading: updateLoading, error: updateError, reset: resetUpdate },
    ] = useUpdateConsumerMutation();
    const loading = createLoading || updateLoading;
    const error = createError || updateError;

    const updateConsumerAction = useCallback(
        async (data: UpdateConsumerMutationVariables) => {
            try {
                await updateConsumerMutation({ variables: data });
            } catch (e) {
                console.error(e);
            }
        },
        [updateConsumerMutation],
    );

    const createConsumerAction = useCallback(
        async (data: UpdateConsumerMutationVariables) => {
            try {
                await createConsumerMutation({ variables: { ...data, gameId } });
            } catch (e) {
                console.error(e);
            }
        },
        [createConsumerMutation, gameId],
    );
    const [searchQuery, setSearchQuery] = useState("");
    const onSearchQueryChange = useDebounceCallback((e: ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(() => e.target.value.toLowerCase());
    }, 300);
    const templatesFiltered = useMemo(() => {
        if (!templates) return [];

        return templates
            .filter((t) => t.name.toLowerCase().includes(searchQuery))
            .map((t) => ({
                ...t,
                templateParams: t.templateParams?.filter((tp) =>
                    tp.name.toLowerCase().includes(searchQuery),
                ),
            }));
    }, [searchQuery, templates]);
    const form = useForm<UpdateConsumerMutationVariables>({
        defaultValues: {
            gameId: consumer?.gameId ?? "",
            name: consumer?.name ?? "",
            description: consumer?.description ?? "",
            guid: consumer?.guid ?? "",
            templates: consumer?.templates?.map((v) => v.guid) ?? [],
            templateParameters: consumer?.templateParams?.map((v) => v.guid) ?? [],
        },
        mode: "onChange",
    });
    const onSelectAll = useCallback(() => {
        const templatesSelected: string[] = [];
        const templateParamsSelected: string[] = [];

        templates?.forEach((template) => {
            templatesSelected.push(template.guid);
            template.templateParams?.forEach((templateParam) => {
                templateParamsSelected.push(templateParam.guid);
            });
        });

        form.setValue("templates", templatesSelected);
        form.setValue("templateParameters", templateParamsSelected);
    }, [form, templates]);
    const onSubmit = useCallback(
        async (data: UpdateConsumerMutationVariables) => {
            if (consumer) {
                resetUpdate();
                await updateConsumerAction(data);
            } else {
                resetCreate();
                await createConsumerAction(data);
            }
            return props.onSubmit();
        },
        [consumer, createConsumerAction, props, resetCreate, resetUpdate, updateConsumerAction],
    );

    return (
        <Form onSubmit={form.handleSubmit(onSubmit)}>
            <Row className="align-content-center justify-content-start g-4">
                <Col xs={12} md={6}>
                    <FormInput
                        name="name"
                        control={form.control}
                        title="Consumer name"
                        rules={{
                            required: { value: true, message: "This value is required" },
                            min: { value: 3, message: "3 characters min" },
                            max: { value: 250, message: "250 characters max" },
                            pattern: {
                                value: /^[A-Z][\w\d]+$/,
                                message: "Must be camelcase and not end with spaces",
                            },
                        }}
                    />
                </Col>
                <Col xs={12} md={6}>
                    <FormInput
                        control={form.control}
                        name="description"
                        title="Consumer description"
                    />
                </Col>
                <Col xs={12} md={6}>
                    <div>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Filter</Form.Label>
                            <Form.Control
                                type="text"
                                className="me-auto"
                                defaultValue={searchQuery}
                                onInput={onSearchQueryChange}
                            />
                        </Form.Group>
                    </div>
                </Col>
                <Col xs={12} md={6}>
                    <div className="mt-4">
                        <Button onClick={onSelectAll}>Select all</Button>
                    </div>
                </Col>
                <Row className="my-3">
                    <Col>
                        <ProgressButton
                            type="submit"
                            color="danger"
                            loading={loading}
                            disabled={loading || !form.formState.isValid}
                        >
                            Save consumer
                        </ProgressButton>
                    </Col>
                </Row>

                <Col xs={12} md={12} className="d-flex flex-wrap overflow-scroll h-50">
                    {templatesFiltered.map((template) => (
                        <div key={template.guid} className="d-flex flex-column w-50 mb-4">
                            <div className="text-center">
                                <Form.Label className="d-flex">
                                    <Form.Check
                                        className="me-1"
                                        {...form.register("templates")}
                                        value={template.guid}
                                        type="checkbox"
                                    />
                                    {template.name}
                                </Form.Label>
                            </div>
                            <div className="ps-4">
                                {template.templateParams?.map((tp) => (
                                    <div key={tp.guid}>
                                        <Form.Label className="d-flex">
                                            <Form.Check
                                                className="me-1"
                                                {...form.register("templateParameters")}
                                                value={tp.guid}
                                                type="checkbox"
                                            />
                                            {tp.name}
                                        </Form.Label>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </Col>
            </Row>

            {error && (
                <Row className="mt-3">
                    <Col>
                        <Alert variant="danger">
                            <Alert.Heading>Failed to create template</Alert.Heading>
                            <p>{error.message}</p>
                        </Alert>
                    </Col>
                </Row>
            )}
        </Form>
    );
}
