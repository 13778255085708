import { useCallback } from "react";
import { bootstrapDialog, create, NiceModalHocProps, show, useModal } from "@ebay/nice-modal-react";
import Form from "react-bootstrap/Form";
import { useForm, RegisterOptions } from "react-hook-form";
import Stack from "react-bootstrap/Stack";
import Modal from "react-bootstrap/Modal";
import { useSnackbar } from "notistack";
import Badge from "react-bootstrap/Badge";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import { GlossaryEntryTransformed } from "@/components/translation/types";
import { useGetLocales } from "@/graphql/translation";
import { UpdateGlossaryEntryMutationVariables } from "@/gql";
import {
    useCreateGlossaryEntryMutation,
    useUpdateGlossaryEntryMutation,
} from "@/graphql/translation/glossaries";

type Props = {
    gameId: string;
    entry?: GlossaryEntryTransformed;
};

const leadingWhitespaceRegex = /^\s+/;
const trailingWhitespaceRegex = /\s+$/;
const specialSymbolRegex = /[\n\r\t\0]/;

const options: RegisterOptions<GlossaryEntryTransformed, `values.${string}`> = {
    required: true,
    validate: {
        value(value: string) {
            if (value.length === 0) return "Value must not be empty";
            if (value.match(leadingWhitespaceRegex)) return "Leading whitespace is not allowed";
            if (value.match(trailingWhitespaceRegex)) return "Trailing whitespace is not allowed";
            if (value.match(specialSymbolRegex))
                return "Value must not contain special control characters, i.e. line breaks";

            return;
        },
    },
};

function EditGlossaryEntryModalComponent(props: Props) {
    const { entry, gameId } = props;
    const modal = useModal();
    const dialog = bootstrapDialog(modal);
    const { enqueueSnackbar } = useSnackbar();
    const [createGlossaryEntryMutation, { loading: createGlossaryEntryLoading }] =
        useCreateGlossaryEntryMutation();
    const [updateGlossaryEntryMutation, { loading: updateGlossaryEntryLoading }] =
        useUpdateGlossaryEntryMutation();
    const loading = createGlossaryEntryLoading || updateGlossaryEntryLoading;
    const getLocales = useGetLocales({ gameId });
    const form = useForm<GlossaryEntryTransformed>({
        mode: "onChange",
        reValidateMode: "onChange",
        defaultValues: async () => {
            const locales = getLocales;

            if (!locales.data) {
                throw new Error(locales.error?.message);
            }

            const values = locales.data.locales.reduce(
                (acc, locale) => {
                    acc[locale.guid] = acc[locale.guid] ?? "";
                    return acc;
                },
                entry?.values ?? {},
            );

            return {
                gameId: entry?.gameId ?? gameId,
                guid: entry?.guid ?? "",
                values,
            };
        },
    });
    const onSubmit = useCallback(
        async (formData: GlossaryEntryTransformed) => {
            const variables: UpdateGlossaryEntryMutationVariables = {
                guid: formData.guid,
                gameId: formData.gameId,
                glossaryEntries: Object.entries(formData.values).map(([localeId, value]) => ({
                    localeId,
                    value,
                })),
            };

            const result = await (entry
                ? updateGlossaryEntryMutation({ variables })
                : createGlossaryEntryMutation({ variables }));

            if (result.data) {
                enqueueSnackbar(entry ? "Glossary entry updated" : "Glossary entry created", {
                    variant: "success",
                });

                modal.resolve();
                modal.hide();
            } else {
                enqueueSnackbar(
                    entry ? "Could not update glossary entry" : "Could not create glossary entry",
                    {
                        variant: "error",
                        autoHideDuration: 3000,
                    },
                );
            }
        },
        [createGlossaryEntryMutation, enqueueSnackbar, entry, modal, updateGlossaryEntryMutation],
    );

    return (
        <Modal {...dialog}>
            <Modal.Header>
                <Modal.Title>{entry ? "Edit glossary entry" : "Create glossary entry"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={form.handleSubmit(onSubmit)}>
                    <Stack gap={2}>
                        {getLocales.data?.locales.map((locale) => (
                            <Form.Group key={locale.guid}>
                                <Form.Label>
                                    {locale.name} ({locale.code})
                                    {locale.isBaseLocale && (
                                        <>
                                            {" "}
                                            <Badge>Base locale</Badge>
                                        </>
                                    )}
                                </Form.Label>
                                <Form.Control
                                    {...form.register(`values.${locale.guid}`, options)}
                                    isInvalid={Boolean(form.formState.errors.values?.[locale.guid])}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {form.formState.errors.values?.[locale.guid]?.message}
                                </Form.Control.Feedback>
                            </Form.Group>
                        ))}
                        <Button type="submit" disabled={loading || !form.formState.isValid}>
                            {loading && <Spinner size="sm" />} {entry ? "Update" : "Create"}
                        </Button>
                    </Stack>
                </Form>
            </Modal.Body>
        </Modal>
    );
}

const EditGlossaryEntryModal = create(EditGlossaryEntryModalComponent);

export function showEditGlossaryEntryModal(props: Props) {
    return show<void, Props & NiceModalHocProps, Props>(EditGlossaryEntryModal, props);
}
