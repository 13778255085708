import { bootstrapDialog, create, NiceModalHocProps, show, useModal } from "@ebay/nice-modal-react";
import { useCallback, useMemo } from "react";
import Modal from "react-bootstrap/Modal";
import { SubmitHandler, useController, useForm } from "react-hook-form";
import Form from "react-bootstrap/Form";
import Stack from "react-bootstrap/Stack";
import ToggleButton from "react-bootstrap/ToggleButton";
import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup";
import cn from "classnames";
import { useSnackbar } from "notistack";
import { ProgressButton } from "@/components/common/ProgressButton";
import { useBulkUpdateFromDeeplMutation, useGetLocales } from "@/graphql/translation";
import { DataAwaiter2 } from "@/components/common/DataAwaiter2";
import { BulkUpdateFromDeeplMutationVariables } from "@/gql";
import { useBulkUpdateFromDeeplFail } from "@/components/translation/modals/BulkUpdateFromDeeplFail";

type BulkUpdateFromDeeplForm = {
    locales: string[];
    keys: string;
};

type Props = {
    gameId: string;
};

const BulkUpdateFromDeepl = create(function BulkUpdateFromDeepl(props: Props) {
    const { gameId } = props;
    const { enqueueSnackbar } = useSnackbar();
    const locales = useGetLocales({ gameId });
    const [bulkUpdateFromDeepl, { loading: bulkUpdateFromDeeplLoading }] =
        useBulkUpdateFromDeeplMutation();
    const bulkUpdateFromDeeplFail = useBulkUpdateFromDeeplFail();
    const modal = useModal();
    const dialog = useMemo(() => bootstrapDialog(modal), [modal]);
    const onSubmit = useCallback<SubmitHandler<BulkUpdateFromDeeplForm>>(
        async (data) => {
            const variables: BulkUpdateFromDeeplMutationVariables = {
                gameId,
                locales: data.locales,
                keys: data.keys.trim().split("\n"),
            };
            const result = await bulkUpdateFromDeepl({ variables });
            if (result.errors) {
                enqueueSnackbar("Failed to update", { variant: "error" });
                console.dir(result.errors);
                return;
            }

            if (result.data?.bulkUpdateFromDeepl && !result.data.bulkUpdateFromDeepl.ok) {
                return bulkUpdateFromDeeplFail(result.data?.bulkUpdateFromDeepl);
            } else {
                enqueueSnackbar("Updated", { variant: "success" });
                modal.resolve();
                return modal.hide();
            }
        },
        [bulkUpdateFromDeepl, bulkUpdateFromDeeplFail, enqueueSnackbar, gameId, modal],
    );
    const form = useForm<BulkUpdateFromDeeplForm>({
        mode: "onChange",
        reValidateMode: "onChange",
        async defaultValues() {
            const result = await locales.refetch();

            return {
                locales:
                    result.data?.locales
                        .filter((locale) => !locale.isBaseLocale)
                        .map((locale) => locale.guid) ?? [],
                keys: "",
            };
        },
    });
    const keysController = useController({
        control: form.control,
        name: "keys",
        rules: {
            minLength: {
                value: 1,
                message: "Must be one locale minimum",
            },
            required: {
                value: true,
                message: "This field is required",
            },
        },
    });
    const localesController = useController({
        control: form.control,
        name: "locales",
        rules: {
            minLength: {
                value: 1,
                message: "Must be one locale minimum",
            },
            required: {
                value: true,
                message: "This field is required",
            },
        },
    });
    const loading = bulkUpdateFromDeeplLoading || locales.loading || form.formState.isLoading;

    return (
        <Modal {...dialog} size="lg">
            <Modal.Header>
                <Modal.Title>Bulk update from Deepl</Modal.Title>
            </Modal.Header>
            <DataAwaiter2 {...locales}>
                {(data) => (
                    <Modal.Body>
                        <Form onSubmit={form.handleSubmit(onSubmit)}>
                            <Stack gap={4}>
                                <Form.Group>
                                    <Form.Label>Locales</Form.Label>
                                    <ToggleButtonGroup
                                        type="checkbox"
                                        size="sm"
                                        className={cn("flex-wrap", {
                                            "is-invalid": localesController.fieldState.invalid,
                                        })}
                                        {...localesController.field}
                                    >
                                        {data?.locales.map((value) =>
                                            value.isBaseLocale ? null : (
                                                <ToggleButton
                                                    key={value.guid}
                                                    id={`locale-${value.guid}`.toLowerCase()}
                                                    value={value.guid}
                                                    disabled={loading}
                                                >
                                                    {value.name}
                                                </ToggleButton>
                                            ),
                                        )}
                                    </ToggleButtonGroup>
                                    {form.formState.errors.locales && (
                                        <Form.Control.Feedback type="invalid">
                                            {form.formState.errors.locales.message}
                                        </Form.Control.Feedback>
                                    )}
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Localization keys</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        {...keysController.field}
                                        isInvalid={Boolean(keysController.fieldState.invalid)}
                                        disabled={loading}
                                    />
                                    {form.formState.errors.keys && (
                                        <Form.Control.Feedback type="invalid">
                                            {form.formState.errors.keys.message}
                                        </Form.Control.Feedback>
                                    )}
                                </Form.Group>
                                <div>
                                    <ProgressButton
                                        type="submit"
                                        loading={loading}
                                        disabled={loading || !form.formState.isValid}
                                    >
                                        Update
                                    </ProgressButton>
                                </div>
                            </Stack>
                        </Form>
                    </Modal.Body>
                )}
            </DataAwaiter2>
        </Modal>
    );
});

export function useBulkUpdateFromDeepl() {
    return useCallback(async (props: Props) => {
        return show<void, NiceModalHocProps & Props, Props>(BulkUpdateFromDeepl, props);
    }, []);
}
