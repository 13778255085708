import { gql } from "@apollo/client";
import { makeGqlQueryLazyHook } from "@/graphql/util";
import { GetArtifactEntitiesFileQuery, GetArtifactEntitiesFileQueryVariables } from "@/gql";

const SCHEMA = gql`
    query getArtifactEntitiesFile($gameId: String!, $guid: String!, $name: String!) {
        artifactEntitiesFile(gameId: $gameId, guid: $guid, name: $name) {
            name
            id
            version
            data
        }
    }
`;

export const useLazyGetArtifactEntitiesFile = makeGqlQueryLazyHook<
    GetArtifactEntitiesFileQuery,
    GetArtifactEntitiesFileQueryVariables
>(SCHEMA);
