import { useCallback, useMemo } from "react";
import { bootstrapDialog, create, NiceModalHocProps, show, useModal } from "@ebay/nice-modal-react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { SubmitErrorHandler, SubmitHandler, useController, useForm } from "react-hook-form";
import styled from "styled-components";

const ModalFooter = styled(Modal.Footer)`
    flex-wrap: nowrap;

    & > .btn {
        flex-basis: 50%;
    }
`;

type FormData = {
    reason: string;
};

const SetEditLockModal = create(function SetEditLockModal() {
    // const { enqueueSnackbar } = useSnackbar();
    const modal = useModal();
    const dialog = useMemo(() => bootstrapDialog(modal), [modal]);
    const form = useForm<FormData>({
        mode: "onChange",
        reValidateMode: "onChange",
        defaultValues: {
            reason: "",
        },
    });
    const reasonController = useController({
        control: form.control,
        name: "reason",
        rules: {
            required: {
                value: true,
                message: "This field is required",
            },
            minLength: {
                value: 3,
                message: "Minimum 3 characters",
            },
        },
    });
    const onSubmit = useCallback<SubmitHandler<FormData>>(
        (data) => {
            modal.resolve(data);
            return modal.hide();
        },
        [modal],
    );
    const onSubmitFail = useCallback<SubmitErrorHandler<FormData>>((errors) => {
        console.error(errors);
        // enqueueSnackbar(errors.root.message, { variant: "error" });
    }, []);
    const handleSubmit = form.handleSubmit(onSubmit, onSubmitFail);
    // const onConfirm = useCallback<MouseEventHandler<HTMLButtonElement>>((event) => {
    //     return handleSubmit(event);
    // }, [handleSubmit]);
    const onCancel = useCallback(() => {
        modal.reject();
    }, [modal]);

    return (
        <Modal {...dialog} keyboard={false} backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>Lock template</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <p>
                        The template will be locked for editing by other users until you release the
                        lock. Users with wizard permissions are able to unlock it too.
                    </p>
                    <Form.Group>
                        <Form.Label>Specify a reason for blocking:</Form.Label>
                        <Form.Control as="textarea" {...reasonController.field}></Form.Control>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <ModalFooter>
                <Button onClick={onCancel} variant="secondary">
                    Cancel
                </Button>
                <Button
                    onClick={handleSubmit}
                    disabled={!form.formState.isValid || form.formState.isSubmitting}
                >
                    Lock template
                </Button>
            </ModalFooter>
        </Modal>
    );
});

export function useSetEditLockModal() {
    return useCallback(() => {
        return show<FormData, NiceModalHocProps, never>(SetEditLockModal);
    }, []);
}
