import { useCallback } from "react";
import {
    bootstrapDialog,
    create,
    register,
    show,
    unregister,
    useModal,
} from "@ebay/nice-modal-react";
import Modal from "react-bootstrap/Modal";
import { UpdateTemplateEntitiesMutation } from "@/gql";
import { LocalizationProvider } from "@/components/common/Table/EntitiesTable/contexts/LocalizationProvider";
import { EditEntityForm } from "@/components/forms/EditEntityForm";

type Props = {
    entityId?: string;
    gameId: string;
    templateId: string;
};

let createEntityModalId = 0;

const getModalId = (id?: string) =>
    id ? `edit_entity_${id}` : `create_entity_${++createEntityModalId}`;

export function EditEntityModalComponent(props: Props) {
    const { gameId, entityId, templateId } = props;
    const modal = useModal();
    const dialog = bootstrapDialog(modal);
    const onSubmit = useCallback(
        async (result: any) => {
            modal.resolve(result);
            modal.hide();
        },
        [modal],
    );

    return (
        <Modal
            {...dialog}
            backdrop="static"
            keyboard={false}
            size="xl"
            title="Edit entity"
            enforceFocus={false}
        >
            <Modal.Header closeButton>
                <Modal.Title>Edit entity</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <LocalizationProvider gameId={props.gameId}>
                    <EditEntityForm
                        onSubmit={onSubmit}
                        gameId={gameId}
                        entityId={entityId}
                        templateId={templateId}
                    />
                </LocalizationProvider>
            </Modal.Body>
        </Modal>
    );
}

export const EditEntityModal = create<Props>(EditEntityModalComponent);

export async function showEditEntityModal(props: Props) {
    /**
     * Тут происходит магия для избежания возникновения бага
     * Баг: мы рисуем ОДНУ И ТУ ЖЕ МОДАЛКУ, но с разными аргументами.
     * Форма внутри модалки на изменения пропов не реагирует
     *
     * Костыль: при каждом открытии модалки мы регистрируем одну и ту же,
     * но под новым ID и дополнительно сбрасываем форму при открытии
     */
    const modalId = getModalId(props.entityId);
    register(modalId, EditEntityModal, props);
    const result = await show<UpdateTemplateEntitiesMutation["result"][number], Props>(
        modalId,
        props,
    );
    unregister(modalId);
    return result;
}
