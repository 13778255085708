import { useMemo } from "react";
import Badge from "react-bootstrap/Badge";
import isNil from "lodash/isNil";
import { FieldDisplay } from "@/fields/types";
import { useGlobalEnum } from "@/store/games/slices";

export function EnumDisplayField(props: FieldDisplay<string>) {
    if (!props.templateParam.meta.enumId) {
        throw new Error(`Enum parameter ${props.templateParam.name} doesn't reference a enum`);
    }

    const value = parseInt(props.value);
    const globalEnum = useGlobalEnum(props.templateParam.meta.enumId);
    const selectedEnumValue = useMemo(
        () => globalEnum?.meta.enum?.enumValues.find((enumPair) => enumPair.value === value),
        [globalEnum, value],
    );
    if (isNil(value) || !globalEnum || isNil(selectedEnumValue))
        return <Badge bg="danger">Incorrect enum value</Badge>;
    const displayedValue = `${globalEnum.name}.${selectedEnumValue.name}`;
    return <span className="font-monospace">{displayedValue}</span>;
}
