import { gql } from "@apollo/client";
import { makeGqlMutationHook } from "@/graphql/util";
import { GLOSSARY_ENTRY_FRAGMENT } from "@/graphql/fragments/glossary.fragment";
import { RemoveGlossaryEntryMutation, RemoveGlossaryEntryMutationVariables } from "@/gql";

const SCHEMA = gql`
    ${GLOSSARY_ENTRY_FRAGMENT}

    mutation removeGlossaryEntry($gameId: String!, $guid: String!) {
        deleteGlossary(gameId: $gameId, guid: $guid) {
            ...GlossaryEntry
        }
    }
`;

export const useRemoveGlossaryEntryMutation = makeGqlMutationHook<
    RemoveGlossaryEntryMutation,
    RemoveGlossaryEntryMutationVariables
>(SCHEMA);
