import { gql } from "@apollo/client";
import { LOCALE_FRAGMENT } from "@/graphql/fragments/locale.fragment";
import { makeGqlMutationHook } from "@/graphql/util";
import { RemoveLocaleMutation, RemoveLocaleMutationVariables } from "@/gql";

const SCHEMA = gql`
    ${LOCALE_FRAGMENT}

    mutation removeLocale($gameId: String!, $guid: String!) {
        removeLocale(gameId: $gameId, guid: $guid) {
            ...Locale
        }
    }
`;

export const useRemoveLocaleMutation = makeGqlMutationHook<
    RemoveLocaleMutation,
    RemoveLocaleMutationVariables
>(SCHEMA);
