import { useCallback, useMemo, useState } from "react";
import { type WorkspaceSvg } from "blockly/core/workspace_svg";
import { type ToolboxItemInfo } from "blockly/core/utils/toolbox";
import { createRoute } from "@tanstack/react-router";
import { BlocklyEditor } from "@/components/common/BlocklyEditor";
import { defaultToolbox } from "@/components/common/Blockly/blocks";
import { blocklyDeclarationsBus } from "@/components/common/Blockly/custom-blocks";
import { ScriptEditorEnumDefinition, ScriptScope } from "@/types";
import { layoutWithBreadcrumbs } from "@/layouts/LayoutWithBreadcrumbs";

const enums: ScriptEditorEnumDefinition[] = [
    {
        name: "PlayerState",
        type: "Number",
        values: [
            { key: "ALIVE", value: 0 },
            { key: "DEAD", value: 1 },
            { key: "RESURRECTING", value: 2 },
        ],
    },
    {
        name: "SomeMode",
        type: "String",
        values: [
            { key: "MODE1", value: "MODE1" },
            { key: "MODE2", value: "MODE2" },
            { key: "MODE3", value: "MODE3" },
            { key: "MODE4", value: "MODE4" },
        ],
    },
];

const scriptScopes: ScriptScope[] = [
    {
        name: "Vec3f",
        functions: [
            {
                name: "dotProduct",
                arguments: [{ name: "rhv", type: "Vec3f" }],
                returnType: "Number",
            },
            {
                name: "length",
                arguments: [],
                returnType: "Number",
            },
        ],
        properties: [
            { name: "x", type: "Number" },
            { name: "y", type: "Number" },
            { name: "z", type: "Number" },
        ],
    },
    {
        name: "Game",
        functions: [
            { name: "GetPlayer", arguments: [], returnType: "IPlayer" },
            { name: "GetWorld", arguments: [], returnType: "IWorld" },
        ],
        children: [
            {
                name: "IPlayer",
                functions: [
                    { name: "GetInventory", arguments: [], returnType: "IInventory" },
                    { name: "GetPosition", arguments: [], returnType: "Vector4" },
                    {
                        name: "GetAttribute",
                        arguments: [{ name: "attribute", type: "String" }],
                        returnType: "IAttributeValue",
                    },
                ],
                properties: [
                    { name: "health", type: "Number" },
                    { name: "stamina", type: "Number" },
                    { name: "magicka", type: "Number" },
                    { name: "name", type: "String" },
                ],
                children: [
                    {
                        name: "IInventory",
                        functions: [
                            {
                                name: "AddItem",
                                arguments: [
                                    { name: "itemId", type: "String" },
                                    { name: "count", type: "Number" },
                                ],
                                returnType: "Number",
                            },
                            {
                                name: "RemoveItem",
                                arguments: [
                                    { name: "itemId", type: "String" },
                                    { name: "count", type: "Number" },
                                ],
                                returnType: "Number",
                            },
                        ],
                    },
                ],
            },
            {
                name: "IWorld",
                functions: [{ name: "DummyFn", arguments: [], returnType: undefined }],
            },
        ],
    },
];

export default function BlocklySandbox() {
    const [data, setData] = useState(() => ({}));

    const namespaceToCategory = useCallback((scriptScope: ScriptScope): ToolboxItemInfo => {
        const namespaceFunctions =
            scriptScope.functions?.map((functionDef) => ({
                kind: "block",
                type: "lk_method_accessor",
                extraState: {
                    typeName: scriptScope.name,
                    returnType: functionDef.returnType,
                    methodArguments: functionDef.arguments,
                },
                fields: {
                    METHOD_NAME: functionDef.name,
                },
            })) ?? [];
        const namespaceProperties =
            Array.isArray(scriptScope.properties) && scriptScope.properties.length > 0
                ? [
                      {
                          kind: "block",
                          type: "lk_property_accessor",
                          extraState: {
                              typeName: scriptScope.name,
                              properties: scriptScope.properties,
                          },
                          fields: {
                              TYPE_NAME: scriptScope.name,
                          },
                      },
                  ]
                : [];
        return {
            kind: "category",
            colour: "290",
            name: scriptScope.name,
            contents: [
                ...namespaceFunctions,
                ...namespaceProperties,
                ...(scriptScope.children?.map(namespaceToCategory) ?? []),
            ],
        };
    }, []);

    const enumsFlyoutCallback = useCallback(() => {
        const blockList: ToolboxItemInfo[] = [];

        enums.forEach((enumDef) => {
            blockList.push({
                kind: "block",
                type: "lk_enum_new",
                fields: {
                    ENUM_NAME: enumDef.name,
                    ENUM_VALUE: enumDef.values[0]?.key,
                },
            });
        });

        return blockList;
    }, []);

    const onWorkspaceCreate = useCallback(
        (workspace: WorkspaceSvg) => {
            enums.forEach((enumDef) => {
                blocklyDeclarationsBus.registerEnum(enumDef);
            });
            workspace.registerToolboxCategoryCallback("CUSTOM_ENUMS", enumsFlyoutCallback);
        },
        [enumsFlyoutCallback],
    );

    const toolbox = useMemo(() => {
        const customToolbox = defaultToolbox();
        customToolbox.contents = [
            {
                kind: "category",
                name: "Custom enums",
                custom: "CUSTOM_ENUMS",
            },
            {
                kind: "category",
                name: "Custom accessors",
                contents: scriptScopes.map(namespaceToCategory),
            },
            ...customToolbox.contents,
        ];

        return customToolbox;
    }, [namespaceToCategory]);

    return (
        <BlocklyEditor
            data={data}
            onChange={setData}
            toolbox={toolbox}
            onWorkspaceCreate={onWorkspaceCreate}
        />
    );
}

export const blocklyRoute = createRoute({
    getParentRoute: () => layoutWithBreadcrumbs,
    path: "blockly",
    component: BlocklySandbox,
});
