import { useCallback } from "react";
import { FaAlignJustify } from "react-icons/fa";
import styled from "styled-components";
import Button from "react-bootstrap/Button";
import { useShowJobsModal } from "@/components/modals2/JobsModal";

const Jobs = styled.div`
    position: fixed;
    bottom: 0;
    left: auto;
    right: 0;
    z-index: 1000;
`;

export function JobsPanel() {
    const showJobsModal = useShowJobsModal();
    const onShowJobsModal = useCallback(async () => {
        await showJobsModal();
    }, [showJobsModal]);

    return (
        <Jobs className="p-1 px-2 bg-white shadow d-flex justify-content-end align-middle gap-4">
            <Button size="sm" title="Deploy jobs" onClick={onShowJobsModal}>
                <FaAlignJustify /> Jobs
            </Button>
        </Jobs>
    );
}
