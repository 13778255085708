export const textBlocks = {
    kind: "category",
    name: "Text",
    toolboxitemId: "catText",
    colour: 160,
    contents: [
        { kind: "block", type: "text" },
        { kind: "block", type: "text_join" },
        {
            kind: "block",
            type: "text_append",
            inputs: {
                TEXT: {
                    block: { type: "text" },
                },
            },
        },
        {
            kind: "block",
            type: "text_length",
            inputs: {
                VALUE: {
                    block: {
                        type: "text",
                        fields: { TEXT: "abc" },
                    },
                },
            },
        },
        {
            kind: "block",
            type: "text_isEmpty",
            inputs: {
                VALUE: {
                    shadow: {
                        type: "text",
                        fields: { TEXT: "abc" },
                    },
                },
            },
        },
        {
            kind: "block",
            type: "text_indexOf",
            inputs: {
                VALUE: {
                    block: {
                        type: "variables_get",
                        fields: { VAR: "text" },
                    },
                },
                FIND: {
                    block: {
                        type: "text",
                        fields: { TEXT: "abc" },
                    },
                },
            },
        },
        {
            kind: "block",
            type: "text_charAt",
            inputs: {
                VALUE: {
                    block: {
                        type: "variables_get",
                        fields: { VAR: "text" },
                    },
                },
            },
        },
        {
            kind: "block",
            type: "text_getSubstring",
            inputs: {
                STRING: {
                    block: {
                        type: "variables_get",
                        fields: { VAR: "text" },
                    },
                },
            },
        },
        {
            kind: "block",
            type: "text_changeCase",
            inputs: {
                TEXT: {
                    shadow: {
                        type: "text",
                        fields: { TEXT: "text" },
                    },
                },
            },
        },
        {
            kind: "block",
            type: "text_trim",
            inputs: {
                TEXT: {
                    shadow: {
                        type: "text",
                        fields: { TEXT: "text" },
                    },
                },
            },
        },
        {
            kind: "block",
            type: "text_print",
            inputs: {
                TEXT: {
                    shadow: {
                        type: "text",
                        fields: { TEXT: "text" },
                    },
                },
            },
        },
        {
            kind: "block",
            type: "text_prompt_ext",
            inputs: {
                TEXT: {
                    shadow: {
                        type: "text",
                        fields: { TEXT: "text" },
                    },
                },
            },
        },
    ],
};
