import { gql } from "@apollo/client";
import { GetArtifactsForDiffQuery, GetArtifactsForDiffQueryVariables } from "@/gql";
import { ARTIFACT_DETAILED_FRAGMENT } from "@/graphql/fragments/artifact.fragment";
import { makeGqlQueryHook } from "../util";

const SCHEMA = gql`
    ${ARTIFACT_DETAILED_FRAGMENT}

    query getArtifactsForDiff($gameId: String!, $idA: String, $idB: String) {
        getArtifactsForDiff(gameId: $gameId, a: $idA, b: $idB) {
            ...ArtifactDetailed
        }
    }
`;

export const useGetArtifactsForDiff = makeGqlQueryHook<
    GetArtifactsForDiffQuery,
    GetArtifactsForDiffQueryVariables
>(SCHEMA, {
    fetchPolicy: "no-cache",
});
