import {
    DeployStep,
    ManifestGenerationEvent,
    ManifestGenerationFailedEvent,
    ManifestGenerationFinishedEvent,
} from "@/gql";

interface ManifestGenerationEventRendererProps {
    event:
        | ManifestGenerationEvent
        | ManifestGenerationFailedEvent
        | ManifestGenerationFinishedEvent;
}

export function ManifestGenerationEventRenderer(props: ManifestGenerationEventRendererProps) {
    switch (props.event.deployCode) {
        case DeployStep.ManifestGeneration:
            return <span>Generating manifest</span>;
        case DeployStep.ManifestGenerationFinished:
            return <span>Manifest generated successfully</span>;
        case DeployStep.ManifestGenerationFailed:
            return <span>Manifest generation failed</span>;
        default:
            return <span>Incorrect manifest renderer event code</span>;
    }
}
