import { gql } from "@apollo/client";
import { makeGqlQueryHook } from "@/graphql/util";
import { GetDeployDirectoriesQuery, GetDeployDirectoriesQueryVariables } from "@/gql";
import { DEPLOY_DIRECTORY_FRAGMENT } from "../fragments/deploy-directory.fragment";

export const useDeployDirectoriesQuery = makeGqlQueryHook<
    GetDeployDirectoriesQuery,
    GetDeployDirectoriesQueryVariables
>(gql`
    ${DEPLOY_DIRECTORY_FRAGMENT}

    query getDeployDirectories($gameId: String!) {
        deployDirectories(gameId: $gameId) {
            ...DeployDirectory
        }
    }
`);
