import { useLayoutEffect, useRef, useState } from "react";
import { useDebounceCallback } from "usehooks-ts";

const paddingOffset = 16;

export function useTableResize() {
    const tableContainerRef = useRef<HTMLDivElement | null>(null);
    const [fixedHeaderScrollHeight, setFixedHeaderScrollHeight] = useState("100vh");

    const resizeContainer = useDebounceCallback(() => {
        const container2 = tableContainerRef.current;
        const container = container2?.querySelector<HTMLDivElement>(".rdt-table");
        if (!container || !container2 || typeof window === "undefined") return;

        const siblingsHeight = Array.from(container.parentElement!.children).reduce(
            (acc, element) => {
                if (element === container) return acc;
                return acc + element.clientHeight;
            },
            0,
        );

        const height =
            ((window.innerHeight - container.offsetTop - siblingsHeight - paddingOffset) /
                window.innerHeight) *
            100;
        setFixedHeaderScrollHeight(height.toFixed(2) + "vh");
    }, 100);

    useLayoutEffect(() => {
        const ref = tableContainerRef.current;
        if (!ref) return;

        resizeContainer();

        ref.addEventListener("resize", resizeContainer);
        window.addEventListener("resize", resizeContainer);
        return () => {
            ref.removeEventListener("resize", resizeContainer);
            window.removeEventListener("resize", resizeContainer);
        };
    }, [resizeContainer]);

    return { tableContainerRef, fixedHeaderScrollHeight };
}
