import { useMemo } from "react";
import Form from "react-bootstrap/Form";
import FormText from "react-bootstrap/FormText";
import { FormFieldProps } from "@/fields/types";
import { DataType } from "@/gql";
import { FormGroup } from "@/fields/components/FormGroup";
import { useEditEntityFormController } from "@/components/forms/EditEntityForm/hooks/useEditEntityFormController";

export function PrimitiveFieldInput(props: FormFieldProps) {
    const { templateParameter: templateParam } = props;
    const { field, fieldState } = useEditEntityFormController(props, "value");

    const formFieldAdditionalProps = useMemo(() => {
        const isNumericField =
            templateParam.type === DataType.Integer || templateParam.type === DataType.Float;
        return {
            type: isNumericField ? "number" : "text",
            step: templateParam.type === DataType.Float ? "0.001" : undefined,
        };
    }, [templateParam.type]);

    return (
        <FormGroup {...props} fieldState={fieldState}>
            <Form.Control
                {...field}
                {...formFieldAdditionalProps}
                isInvalid={fieldState.error !== undefined}
            />
            {fieldState.error && (
                <FormText className="text-danger">{fieldState.error.message}</FormText>
            )}
        </FormGroup>
    );
}
