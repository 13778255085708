import { DataType } from "@/gql";
import { DataTypeField } from "../../types";
import { EntityRefParameterFields } from "./EntityRefParameterFields";
import { EntityRefTableCell } from "./EntityRefTableCell";
import { EntityRefFormField } from "./EntityRefFormField";
import { EntityRefDisplayField } from "./EntityRefDisplayField";

export const EntityRefDataType: DataTypeField = {
    dataType: DataType.EntityRef,
    defaultValue: () => null,
    formField: EntityRefFormField,
    fieldDisplay: EntityRefDisplayField,
    tableCell: EntityRefTableCell,
    additionalParameterFields: EntityRefParameterFields,
    tableCellDisplayOptions: {
        minSize: 450,
        maxSize: 550,
        enableSorting: false,
    },
};
