import styled from "styled-components";
import Spinner from "react-bootstrap/Spinner";

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const StyledSpinner = styled(Spinner)`
    width: 50px;
    height: 50px;
`;

export function SuspenseFallback() {
    return (
        <Wrapper>
            <StyledSpinner animation="border" />
        </Wrapper>
    );
}
