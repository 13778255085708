import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Alert from "react-bootstrap/Alert";
import { useSnackbar } from "notistack";
import { createRoute } from "@tanstack/react-router";
import { useAssignDefaultCategoryMutation } from "@/graphql/wizardry/games";
import { ConfirmationButton } from "@/components/common/ConfirmationButton";
import { useFixEntitiesChangeHistory } from "@/graphql/entities/fixEntitiesChangeHistory.mutation";
import { WizTranslationsMigrationForm } from "@/pages/wizardry/WizTranslatiosMigrationForm";
import { CleanupArtifacts } from "@/pages/wizardry/PandorasBoxWizardry/CleanupArtifacts";
import { CleanupICs } from "@/pages/wizardry/PandorasBoxWizardry/CleanupICs";
import { wizardryRoute } from "@/pages/wizardry/WizardryLayout";

export default function PandorasBoxWizardry() {
    const { enqueueSnackbar } = useSnackbar();
    const [assignDefaultCategoryMutation] = useAssignDefaultCategoryMutation();
    const [fixEntitiesChangeHistory] = useFixEntitiesChangeHistory({
        onCompleted(data) {
            enqueueSnackbar(
                data.wizFixEntitiesChangeHistory.info ?? data.wizFixEntitiesChangeHistory.isOk
                    ? "success"
                    : "failure",
                {
                    variant: data.wizFixEntitiesChangeHistory.isOk ? "success" : "error",
                },
            );
        },
    });

    return (
        <Container className="mb-5">
            <Row>
                <Col>
                    <Alert variant="warning" className="alert-secondary">
                        <Alert.Heading>Before you continue</Alert.Heading>
                        <p>
                            This section allows to perform actions that cannot be reverted.{" "}
                            <span className="fw-bold">Use with caution</span>
                        </p>
                    </Alert>
                </Col>
            </Row>
            <hr className="border-dark" />
            <Row>
                <Col md={12}>
                    <h4>Create default category</h4>
                    <p>
                        Create default templates category for each project (aka game) and assign all
                        templates of said project to it.
                    </p>
                    <p>
                        This doesn&lsquo;t do anything for projects that already have template
                        categories
                    </p>
                    <ConfirmationButton
                        prompt="Are you sure?"
                        confirmationPhrase="CONFIRM"
                        onConfirm={assignDefaultCategoryMutation}
                    >
                        Confirmation button
                    </ConfirmationButton>
                </Col>
            </Row>
            <hr className="border-dark" />
            <Row>
                <Col md={12}>
                    <h4>Fix entities history</h4>
                    <p>
                        Old history entries of type EntityUpdated had more info than necessary and
                        revert payload was incorrect
                    </p>
                    <p>
                        This is usually a one-time job and most like doesn&lsquo;t need to be done
                        by the time you see this
                    </p>
                    <ConfirmationButton
                        prompt="Are you sure?"
                        confirmationPhrase="CONFIRM"
                        onConfirm={fixEntitiesChangeHistory}
                    >
                        Confirmation button
                    </ConfirmationButton>
                </Col>
            </Row>
            <hr className="border-dark" />
            <Row>
                <Col md={12}>
                    <h4>Migrate translations</h4>
                    <p>
                        This allows to migrate legacy translation to the new one. This does the
                        following:
                    </p>
                    <ol>
                        <li>
                            Values for parameters of LocalizedString type are moved to Translations
                            table
                        </li>
                        <li>
                            Old value of the parameter is replaced with a reference to the new
                            translation entry
                        </li>
                    </ol>
                    <p>This is a one-time job</p>
                    <WizTranslationsMigrationForm />
                </Col>
            </Row>
            <hr className="border-dark" />
            <Row>
                <Col md={12}>
                    <CleanupArtifacts />
                </Col>
            </Row>
            <hr className="border-dark" />
            <Row>
                <Col>
                    <CleanupICs />
                </Col>
            </Row>
        </Container>
    );
}

export const pandorasBoxWizardry = createRoute({
    getParentRoute: () => wizardryRoute,
    path: "pandoras-box",
    component: PandorasBoxWizardry,
});
