import { gql } from "@apollo/client";
import { GetGlobalParametersQuery, GetGlobalParametersQueryVariables } from "@/gql";
import { TEMPLATE_PARAM_FRAGMENT } from "../fragments/template-param.fragment";
import { makeGqlQueryHook } from "../util";

const SCHEMA = gql`
    ${TEMPLATE_PARAM_FRAGMENT}

    query getGlobalParameters($gameId: String!, $type: DataType) {
        parameters: getGlobalParameters(gameId: $gameId, type: $type) {
            ...TemplateParam
        }
    }
`;

export const useGetGlobalParameters = makeGqlQueryHook<
    GetGlobalParametersQuery,
    GetGlobalParametersQueryVariables
>(SCHEMA);
