import { FC, useCallback } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { FaPlus } from "react-icons/fa";
import { Helmet } from "react-helmet-async";
import { createRoute } from "@tanstack/react-router";
import { CreateGameModal } from "@/components/modals/CreateGameModal";
import { useModal } from "@/hooks";
import { GameCard } from "@/components/games-page/GameCard";
import { UserDropdown } from "@/components/layout/UserDropdown";
import { useGetGamesQuery } from "@/graphql/game";
import { DataAwaiter2 } from "@/components/common/DataAwaiter2";
import { useJobStatusSubscription } from "@/graphql/queues";
import { QueueType } from "@/gql";
import { startPage } from "@/pages/StartPage";

const eventTypes = [QueueType.RemoveProject, QueueType.CloneProject, QueueType.SyncFromOrigin];

export const GamesPage: FC = () => {
    const getGamesQuery = useGetGamesQuery();
    const { modal: Modal, openModal2 } = useModal(CreateGameModal);
    useJobStatusSubscription({
        variables: { eventTypes },
        onData() {
            return getGamesQuery.refetch();
        },
    });
    const onCreateGame = useCallback(() => {
        openModal2({
            onGameCreated: getGamesQuery.refetch,
        });
    }, [getGamesQuery, openModal2]);

    return (
        <Container className="py-4">
            <Helmet title="Projects" />
            <Row>
                <Col md={2} className="flex-grow-0">
                    <Button onClick={onCreateGame} color="success">
                        <FaPlus className="align-middle me-1" />
                        <span className="me-1">Create</span>
                    </Button>
                </Col>
                <Col className="ms-auto flex-grow-1"></Col>
                <Col md={2} className="flex-grow-0 text-end">
                    <UserDropdown />
                </Col>
            </Row>
            <Row>
                <Col>
                    <hr />
                </Col>
            </Row>
            <DataAwaiter2 {...getGamesQuery}>
                {(data) => (
                    <Row className="gy-4">
                        {data.result.map((game) => (
                            <Col md={4} key={game.guid}>
                                <GameCard game={game} onRefetch={getGamesQuery.refetch} />
                            </Col>
                        ))}
                    </Row>
                )}
            </DataAwaiter2>
            <Modal />
        </Container>
    );
};

export default GamesPage;
export const gamesRoute = createRoute({
    getParentRoute: () => startPage,
    path: "games",
    component: GamesPage,
});
