import { DataType } from "@/gql";
import { createDatatype } from "../../createDatatype";
import { ReadonlyAssetField } from "./ReadonlyAssetField";
import { AssetFieldComponent } from "./AssetFieldComponent";

export const assetField = createDatatype<string | null>({
    type: DataType.Asset,
    defaultValue: () => null,
    inputComponent: AssetFieldComponent,
    readonlyComponent: ReadonlyAssetField,
    tableCellDisplayOptions: {
        minWidth: "350px",
    },
});
