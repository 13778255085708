import { useCallback, useEffect, useMemo } from "react";
import { FormProvider, useForm, type SubmitErrorHandler } from "react-hook-form";
import keyBy from "lodash/keyBy";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import sortBy from "lodash/sortBy";
import { useSnackbar } from "notistack";
import { fieldsDefinition } from "@/fields";
import { EntityTableData, TemplateEntityParam } from "@/gql";
import {
    useCreateEntityMutation,
    useGetEntityForEdit,
    useUpdateTemplateEntitiesMutation,
} from "@/graphql/entities";
import { useAppDispatch } from "@/hooks";
import { ADD_ENTITY, REPLACE_ENTITY } from "@/store/entities/slice";
import { type EditEntityForm } from "./types";
import { EditEntityFormField } from "./components/EditEntityFormField";

export type Props = {
    templateId: string;
    entityId?: string;
    gameId: string;
    onSubmit?: (data: EntityTableData) => void;
};

export function EditEntityForm(props: Props) {
    const { entityId, gameId, templateId } = props;
    const dispatch = useAppDispatch();

    const { enqueueSnackbar } = useSnackbar();
    const getEntityForEdit = useGetEntityForEdit({ gameId, guid: entityId, templateId });
    const [updateTemplateEntitiesMutation, { loading: updateTemplateEntitiesLoading }] =
        useUpdateTemplateEntitiesMutation();
    const [createTemplateEntityMutation, { loading: createTemplateEntityLoading }] =
        useCreateEntityMutation();
    const loading =
        getEntityForEdit.loading || updateTemplateEntitiesLoading || createTemplateEntityLoading;

    const form = useForm<EditEntityForm>({
        mode: "onChange",
        reValidateMode: "onChange",
        defaultValues: async () => {
            const loadedEntity = await getEntityForEdit.refetch();

            const entityParams: Record<string, TemplateEntityParam> = keyBy(
                loadedEntity.data?.entity?.entityParameters ?? {},
                "templateParameterId",
            );

            const result = Object.fromEntries(
                loadedEntity.data?.template.templateParams!.map((templateParam) => {
                    const disabled = Boolean(entityParams[templateParam.guid]?.shouldOmit);
                    const value =
                        templateParam.guid in entityParams
                            ? entityParams[templateParam.guid]!.value
                            : templateParam.meta.defaultValue ??
                              fieldsDefinition[templateParam.type].defaultValue(templateParam);
                    return [templateParam.guid, { disabled, value }];
                }) ?? [],
            );

            return result;
        },
        resetOptions: {
            keepDefaultValues: false,
        },
        shouldUnregister: true,
    });
    useEffect(() => form.reset(), [form, entityId]);
    const templateParamsSorted = useMemo(() => {
        const templateParams = getEntityForEdit.data?.template.templateParams ?? [];
        return sortBy(templateParams, (tp) => tp.meta.display?.editEntityOrder ?? -1);
    }, [getEntityForEdit.data?.template.templateParams]);

    const createTemplateEntity = useCallback(
        async (data: EditEntityForm) => {
            const result = await createTemplateEntityMutation({
                variables: {
                    gameId,
                    templateId,
                    templateEntityParams: Object.entries(data).map(([key, value]) => ({
                        templateParameterId: key,
                        disabled: value.disabled,
                        value: value.value,
                    })),
                },
            });

            if (result.data?.result) {
                dispatch(ADD_ENTITY(result.data.result));
                if (typeof props.onSubmit === "function") props.onSubmit(result.data.result);
            }
        },
        [createTemplateEntityMutation, dispatch, gameId, props, templateId],
    );
    const updateTemplateEntities = useCallback(
        async (entitiesData: EditEntityForm) => {
            if (!entityId) throw new Error();

            const entityForEdit = await getEntityForEdit.refetch();

            console.dir(entitiesData);

            const entityParametersChange = Object.entries(entitiesData)
                .map(([key, value]) => {
                    const entityParameter = entityForEdit.data.entity?.entityParameters?.find(
                        (param) => param.templateParameterId === key,
                    );

                    if (!entityParameter) return null;

                    return {
                        entityGuid: entityId,
                        entityParameterGuid: entityParameter.guid,
                        disabled: value.disabled,
                        value: value.value,
                    };
                })
                .filter(Boolean);

            const { data } = await updateTemplateEntitiesMutation({
                variables: {
                    gameId: props.gameId,
                    entityParametersChange,
                },
            });

            if (!data) {
                enqueueSnackbar("Failed to save changes", {
                    variant: "error",
                    autoHideDuration: 3000,
                });
                return;
            }

            data.result.forEach((newEntity) => {
                dispatch(REPLACE_ENTITY(newEntity));
            });

            const updatedEntity = data.result.find((entity) => entity.guid === entityId);
            if (updatedEntity) {
                if (typeof props.onSubmit === "function") props.onSubmit(updatedEntity);
            }
        },
        [
            entityId,
            getEntityForEdit,
            updateTemplateEntitiesMutation,
            props,
            enqueueSnackbar,
            dispatch,
        ],
    );

    const onSubmit = useCallback(
        async (data: EditEntityForm) => {
            const hasEntity = Boolean(getEntityForEdit.data?.entity);
            hasEntity ? await updateTemplateEntities(data) : await createTemplateEntity(data);
        },
        [createTemplateEntity, getEntityForEdit.data?.entity, updateTemplateEntities],
    );
    const onSubmitFailed = useCallback<SubmitErrorHandler<EditEntityForm>>((errors, event) => {
        console.error(errors);
        console.dir(event);
    }, []);
    const isFormDirty = useMemo(() => {
        return form.formState.isDirty && Object.keys(form.formState.dirtyFields).length > 0;
    }, [form.formState]);

    if (form.formState.isLoading || !getEntityForEdit.data) {
        return <Spinner />;
    }

    return (
        <Form onSubmit={form.handleSubmit(onSubmit, onSubmitFailed)}>
            <Row className="flex-row-reverse mb-2 mt--4 position-relative">
                <Col className="ms-auto text-end bottom-50">
                    {isFormDirty ? (
                        <span className="text-danger small">the form has unsaved changes</span>
                    ) : (
                        <span>&nbsp;</span>
                    )}
                </Col>
            </Row>
            <FormProvider {...form}>
                {form.formState.isLoading || !getEntityForEdit.data ? (
                    <Spinner />
                ) : (
                    <Row className="align-items-stretch gy-4">
                        {templateParamsSorted.map((templateParameter, index) => (
                            <EditEntityFormField
                                key={templateParameter.guid}
                                templateParameter={templateParameter}
                                gameId={gameId}
                                index={index}
                                template={getEntityForEdit.data!.template}
                            />
                        ))}
                        <Col xs={12} md={12}>
                            <Button type="submit" color="danger" disabled={loading || !isFormDirty}>
                                {loading ? <Spinner size="sm" animation="border" /> : "Save"}
                            </Button>
                        </Col>
                    </Row>
                )}
            </FormProvider>
        </Form>
    );
}
