import { lazy, Suspense } from "react";
import { SuspenseFallback } from "@/components/SuspenseFallback";
import { DataType } from "@/gql";
import { ConnectForm } from "../forms/ConnectForm";

const ScriptScopeEditor = lazy(async () => {
    const { ScriptScopeEditor } = await import("../ScriptScopeEditor");
    return { default: ScriptScopeEditor };
});
const EnumEditor = lazy(async () => {
    const { EnumEditor } = await import("../EnumEditor");
    return { default: EnumEditor };
});

interface AdditionalGlobalParameterFieldsProps {
    dataType: DataType;
}

export function AdditionalGlobalParameterFields(props: AdditionalGlobalParameterFieldsProps) {
    return (
        <Suspense fallback={<SuspenseFallback />}>
            <ConnectForm>
                {(methods) => {
                    switch (props.dataType) {
                        case DataType.Enum:
                            return <EnumEditor {...methods.register("meta.enum")} />;
                        case DataType.Script:
                            return <ScriptScopeEditor {...methods.register("meta.scriptScope")} />;
                        default:
                            return null;
                    }
                }}
            </ConnectForm>
        </Suspense>
    );
}
