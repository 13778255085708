import Button from "react-bootstrap/Button";
import { createRoute, useRouter } from "@tanstack/react-router";
import { useCallback } from "react";
import { startPage } from "@/pages/StartPage";

export function Page403() {
    const router = useRouter();
    const goBack = useCallback(() => router.history.back(), [router.history]);

    return (
        <div className="jumbotron">
            <h1 className="display-4">Forbidden!</h1>
            <p className="lead">You have no permissions to view this page.</p>
            <hr className="my-4" />
            <p>If you think this is a mistake, ask project owner to review your permission</p>
            <Button onClick={goBack}>Go back</Button>
        </div>
    );
}

export default Page403;
export const page403Route = createRoute({
    getParentRoute: () => startPage,
    path: "403",
    component: Page403,
});
