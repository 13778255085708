import { useCallback } from "react";
import { useForm } from "react-hook-form";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import Alert from "react-bootstrap/Alert";
import Container from "react-bootstrap/Container";
import { useSnackbar } from "notistack";
import { Game, UpdateGameMutationVariables } from "@/gql";
import { FormInput } from "@/components/common/forms/form-inputs/FormInput";
import { useUpdateGameMutation } from "@/graphql/game";
import { useLoadGameDetailsAction } from "@/store/games/actions";

type Props = {
    gameDetails: Pick<
        Game,
        "guid" | "name" | "description" | "allowSyncFromOrigin" | "sourceGameId"
    >;
    onSubmit?: (data: UpdateGameMutationVariables) => void;
};

export function UpdateGameForm(props: Props) {
    const [updateGame, { error, loading }] = useUpdateGameMutation();

    const [loadGameDetailsAction] = useLoadGameDetailsAction();
    const { enqueueSnackbar } = useSnackbar();
    const { handleSubmit, control, register } = useForm<UpdateGameMutationVariables>({
        defaultValues: props.gameDetails,
        values: props.gameDetails,
    });
    const onSubmit = useCallback(
        async (data: UpdateGameMutationVariables) => {
            await updateGame({
                variables: data,
            });
            await loadGameDetailsAction({
                gameId: data.guid,
            });
            enqueueSnackbar("Project updated", { variant: "success" });
            typeof props.onSubmit === "function" && props.onSubmit(data);
        },
        [loadGameDetailsAction, props, enqueueSnackbar, updateGame],
    );

    return (
        <Container as={Form} onSubmit={handleSubmit(onSubmit)}>
            <Row className="mb-4">
                <Col xs={12} md={6} className="px-0">
                    <FormInput
                        name="name"
                        control={control}
                        title="Game name"
                        rules={{
                            required: true,
                            min: 3,
                            max: 250,
                        }}
                    />
                </Col>
            </Row>
            <Row className="mb-4">
                <Col xs={12} md={6} className="px-0">
                    <FormInput
                        name="description"
                        control={control}
                        title="Game description"
                        as="textarea"
                        rules={{
                            max: 1024,
                        }}
                    />
                </Col>
            </Row>
            {props.gameDetails.sourceGameId && (
                <Row className="mb-4">
                    <Col xs={12} md={6} className="px-0">
                        <Form.Check
                            label="Allow sync from origin"
                            {...register("allowSyncFromOrigin")}
                        />
                    </Col>
                </Row>
            )}
            <Row className="mb-4">
                {error && (
                    <Col md={12}>
                        <Alert variant="danger">
                            <Alert.Heading>Failed to update game</Alert.Heading>
                            <p>{error.message}</p>
                        </Alert>
                    </Col>
                )}
                <Col md={12}>
                    <Button type="submit" color="danger" disabled={loading}>
                        {loading ? <Spinner size="sm" animation="border" /> : "Update"}
                    </Button>
                </Col>
            </Row>
        </Container>
    );
}
