import { MouseEventHandler, useCallback } from "react";
import styled from "styled-components";
import Button from "react-bootstrap/Button";
import { FaThumbtack } from "react-icons/fa";
import cn from "classnames";

type Props = {
    name: string;
    pinned: boolean;
    onPin: () => void;
};

const NameBlock = styled.span`
    line-break: anywhere;
    word-wrap: break-word;
`;

export function HeaderCell(props: Props) {
    const onClick = useCallback<MouseEventHandler>(
        (e) => {
            e.preventDefault();
            e.stopPropagation();

            props.onPin();
        },
        [props],
    );

    return (
        <div className="d-flex justify-content-between align-items-center">
            <Button
                variant="link"
                size="sm"
                onClick={onClick}
                className={cn({
                    "text-dark": props.pinned,
                    "text-light": !props.pinned,
                })}
            >
                <FaThumbtack />
            </Button>
            <NameBlock
                dangerouslySetInnerHTML={{
                    __html: props.name,
                }}
            />
        </div>
    );
}
