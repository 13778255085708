import { useCallback } from "react";
import { Link, useNavigate, createRoute } from "@tanstack/react-router";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Helmet } from "react-helmet-async";
import { useReferencingTemplates } from "@/store/games/slices";
import { EditTemplateForm, EditTemplateFormData } from "@/components/forms/EditTemplateForm";
import { useAppSelector } from "@/hooks";
import { useRemoveTemplateAction, useUpdateTemplateAction } from "@/store/games/actions";
import { Permission, UpdateTemplateMutationVariables } from "@/gql";
import { gamesSelector } from "@/store/games/slice";
import { withRBAC } from "@/components/rbac";
import { templatePageRoute } from "@/pages/template/Template";
import { templatesListRoute } from "@/pages/TemplatesList";
import { useTemplateContext } from "@/pages/template/TemplateProvider";

export function TemplateStructure() {
    const { useParams } = templateStructureRoute;
    const { templateId, gameId } = useParams();
    const { isLoading, error } = useAppSelector(gamesSelector);
    const [updateTemplateAction] = useUpdateTemplateAction();
    const [removeTemplateAction] = useRemoveTemplateAction();
    const navigate = useNavigate();
    const templateData = useTemplateContext();
    const references = useReferencingTemplates(templateId!);
    const onTemplateChange = useCallback(
        async (data: EditTemplateFormData) => {
            if (!templateId) throw new Error("templateId missing");
            const updateData: UpdateTemplateMutationVariables = {
                name: data.name,
                description: data.description,
                gameId: data.gameId,
                meta: data.meta,
                type: data.type.value,
                guid: templateId,
                category: data.category!.value,
                consumers: data.consumers.map((option) => option.guid),
            };
            const updatedTemplate = await updateTemplateAction(updateData);
            return updatedTemplate;
        },
        [templateId, updateTemplateAction],
    );
    const onDeleteTemplate = useCallback(async () => {
        if (!templateId) return;

        await removeTemplateAction({
            guid: templateId,
            gameId: gameId!,
        });
        await navigate({
            to: templatesListRoute.fullPath,
            params: { gameId },
        });
    }, [gameId, navigate, removeTemplateAction, templateId]);

    if (!templateData || !templateId || !gameId) return null;

    return (
        <>
            <Helmet title="Structure" />
            <Row className="gap-2">
                {references.length > 0 && (
                    <Col md={12}>
                        <h5>Referenced by</h5>
                        <ul>
                            {references.map((ref, index) => (
                                <li key={index}>
                                    <Link to={`/${gameId}/templates/${ref.templateId}/structure`}>
                                        {ref.template!.name}.{ref.name}
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </Col>
                )}
                <Col md={12}>
                    <EditTemplateForm
                        isLoading={isLoading}
                        error={error}
                        // @ts-ignore
                        onSubmit={onTemplateChange}
                        onDelete={onDeleteTemplate}
                        templateId={templateId}
                        gameId={gameId}
                    />
                </Col>
            </Row>
        </>
    );
}

const TemplateStructureRBAC = withRBAC(TemplateStructure, {
    requiredPermissions: [Permission.StructureRead, Permission.StructureWrite],
    oneOf: true,
});

export default TemplateStructureRBAC;

export const templateStructureRoute = createRoute({
    getParentRoute: () => templatePageRoute,
    path: "structure",
    component: TemplateStructureRBAC,
});
