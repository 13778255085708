import { gql } from "@apollo/client";
import { RemoveEntityMutation, RemoveEntityMutationVariables } from "@/gql";
import { ENTITY_FRAGMENT } from "../fragments/entity.fragment";
import { ENTITY_PARAM_FRAGMENT } from "../fragments/entity-param.fragment";
import { makeGqlMutationHook } from "../util";

const REMOVE_ENTITY = gql`
    ${ENTITY_FRAGMENT}
    ${ENTITY_PARAM_FRAGMENT}

    mutation removeEntity($guid: String!, $gameId: String!, $force: Boolean) {
        result: removeTemplateEntity(guid: $guid, gameId: $gameId, force: $force) {
            ...EntityFragment
            entityParameters {
                ...EntityParamFragment
            }
        }
    }
`;

export const useRemoveEntityMutation = makeGqlMutationHook<
    RemoveEntityMutation,
    RemoveEntityMutationVariables
>(REMOVE_ENTITY);
