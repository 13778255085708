import { useCallback } from "react";
import Button from "react-bootstrap/Button";
import { FaPlus } from "react-icons/fa";
import { useNavigate } from "@tanstack/react-router";
import { useModal } from "@/hooks";
import { Template } from "@/gql";
import { templateStructureRoute } from "@/pages/template/TemplateStructure";
import { CreateTemplateModal } from "../../modals/CreateTemplateModal";

interface CreateTemplateButtonProps {
    gameId: string;
}

export function CreateTemplateButton(props: CreateTemplateButtonProps) {
    const { gameId } = props;
    const navigate = useNavigate();
    const {
        modal: CreateTemplateDialog,
        openModal: openCreateTemplateDialog,
        closeModal: closeCreateTemplateDialog,
    } = useModal(CreateTemplateModal);

    const onTemplateCreated = useCallback(
        (newTemplate?: Template) => {
            if (newTemplate) {
                return navigate({
                    to: templateStructureRoute.fullPath,
                    params: {
                        gameId,
                        templateId: newTemplate.guid,
                    },
                });
            }
            return closeCreateTemplateDialog();
        },
        [closeCreateTemplateDialog, gameId, navigate],
    );

    const onCreateTemplate = useCallback(() => {
        if (gameId)
            openCreateTemplateDialog({
                isOpen: true,
                toggle: (template) => onTemplateCreated(template),
                gameId,
            });
    }, [gameId, onTemplateCreated, openCreateTemplateDialog]);

    return (
        <div className="d-grid">
            <Button variant="success" onClick={onCreateTemplate}>
                <FaPlus size={14} className="align-middle me-1" />
                <span className="align-middle">Create Template</span>
            </Button>
            <CreateTemplateDialog />
        </div>
    );
}
