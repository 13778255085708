import { gql } from "@apollo/client";
import { makeGqlMutationHook } from "@/graphql/util";
import { AddTemplateToCategoryMutation, AddTemplateToCategoryMutationVariables } from "@/gql";

const schema = gql`
    mutation addTemplateToCategory($gameId: String!, $templateId: String!, $categoryId: String!) {
        addTemplateToCategory(gameId: $gameId, templateId: $templateId, categoryId: $categoryId) {
            isOk
            info
        }
    }
`;

export const useAddTemplateToCategory = makeGqlMutationHook<
    AddTemplateToCategoryMutation,
    AddTemplateToCategoryMutationVariables
>(schema);
