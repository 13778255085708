import { useCallback } from "react";
import AsyncSelect from "react-select/async";
import { useDebounceCallback } from "usehooks-ts";
import { UserFragment } from "@/gql";
import { useSuggestUsers } from "@/graphql/permissions";
import type { Options, SingleValue, StylesConfig } from "react-select";

const selectStyles: StylesConfig<UserFragment, false> = {
    menuPortal: (baseStyles) => ({
        ...baseStyles,
        zIndex: 10001,
    }),
};

type Props = {
    gameId: string;
    selectedUser?: UserFragment;
    onSetSelectedUser: (newValue: UserFragment) => void;
    isOptionDisabled?: (option: UserFragment, selectValue: Options<UserFragment>) => boolean;
};

export function UserSelect(props: Props) {
    const { gameId, selectedUser, onSetSelectedUser } = props;
    const [suggestUsers] = useSuggestUsers();
    const loadOptions = useCallback(
        async (inputValue: string) => {
            const { data } = await suggestUsers({ variables: { query: inputValue, gameId } });
            return data?.result ?? [];
        },
        [gameId, suggestUsers],
    );
    const onSelectChange = useDebounceCallback((value: SingleValue<UserFragment>) => {
        if (value !== null) {
            onSetSelectedUser(value);
        }
    }, 200);

    return (
        <AsyncSelect
            isMulti={false}
            cacheOptions
            value={selectedUser}
            menuPortalTarget={document.body}
            styles={selectStyles}
            getOptionLabel={(option) => `${option.firstname} ${option.lastname} <${option.email}>`}
            getOptionValue={(option) => option.email}
            loadOptions={loadOptions}
            isOptionDisabled={props.isOptionDisabled}
            onChange={onSelectChange}
        />
    );
}
