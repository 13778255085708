import { DataTypeField } from "@/fields/types";
import { DataType } from "@/gql";
import { TimestampTableCell } from "./TimestampTableCell";
import { TimestampFormField } from "./TimestampFormField";

export const TimestampDataType: DataTypeField = {
    defaultValue: () => 0,
    dataType: [DataType.Timestamp],
    tableCell: TimestampTableCell,
    formField: TimestampFormField,
};
