import { gql } from "@apollo/client";
import { GAME_INFO_FRAGMENT } from "@/graphql/fragments/game-info.fragment";
import { makeGqlMutationHook } from "@/graphql/util";
import { WizCreateGameMutation, WizCreateGameMutationVariables } from "@/gql";

const schema = gql`
    ${GAME_INFO_FRAGMENT}

    mutation wizCreateGame($name: String!, $userId: String!, $description: String!) {
        wizCreateGame(name: $name, userId: $userId, description: $description) {
            ...GameInfo
        }
    }
`;

export const useCreateGameMutation = makeGqlMutationHook<
    WizCreateGameMutation,
    WizCreateGameMutationVariables
>(schema);
