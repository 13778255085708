import { ErrorBoundary, FallbackProps } from "react-error-boundary";
import { ColumnDefBase } from "@tanstack/react-table";
import { OperableEntityTableData } from "@/types";
import { DataTypeRenderer } from "../types";

function ErrorBoundaryFallback(props: FallbackProps) {
    return (
        <div role="alert">
            <p>Something went wrong while attempting to render a cell:</p>
            <pre style={{ color: "red" }}>{props.error.message}</pre>
        </div>
    );
}

export function tableCellPropsConverter<
    AdditionalProps extends Record<string, any> = Record<string, unknown>,
>(
    Component: DataTypeRenderer<AdditionalProps>,
    additionalProps: AdditionalProps = {} as AdditionalProps,
): ColumnDefBase<OperableEntityTableData>["cell"] {
    return function WrappedCell(props) {
        const row = props.row.original;
        const rowIndex = props.row.index;
        const column = props.column;
        const id = props.column.id;
        return (
            <ErrorBoundary fallbackRender={ErrorBoundaryFallback}>
                <Component
                    tableCellProps={{ row, rowIndex, column, id }}
                    gameId={row.gameId}
                    {...additionalProps}
                />
            </ErrorBoundary>
        );
    };
}
