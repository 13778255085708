import { gql } from "@apollo/client";
import { makeGqlMutationHook } from "@/graphql/util";
import { CreateUploadMutation, CreateUploadMutationVariables } from "@/gql";

const SCHEMA = gql`
    mutation createUpload($name: String!, $description: String!, $gameId: String!) {
        uploadAssetV2(name: $name, description: $description, gameId: $gameId) {
            guid
            name
            description
            gameId
        }
    }
`;

export const useCreateUploadMutation = makeGqlMutationHook<
    CreateUploadMutation,
    CreateUploadMutationVariables
>(SCHEMA);
