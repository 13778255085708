import { useCallback, useMemo } from "react";
import { gql, useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";
import Stack from "react-bootstrap/Stack";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import sortBy from "lodash/sortBy";
import { useSnackbar } from "notistack";
import { useGetGamesQuery } from "@/graphql/wizardry/games";
import { ConvertLocaleStringsMutation, ConvertLocaleStringsMutationVariables } from "@/gql";

const SCHEMA = gql`
    mutation convertLocaleStrings($gameId: String!) {
        convertLocaleStrings(gameId: $gameId) {
            isOk
            info
        }
    }
`;

export function WizTranslationsMigrationForm() {
    const [convertLocaleStrings, { loading }] = useMutation<
        ConvertLocaleStringsMutation,
        ConvertLocaleStringsMutationVariables
    >(SCHEMA);
    const { enqueueSnackbar } = useSnackbar();
    const getGames = useGetGamesQuery();
    const options = useMemo(
        () => sortBy(getGames.data?.wizGetGames ?? [], "name"),
        [getGames.data?.wizGetGames],
    );
    const form = useForm<ConvertLocaleStringsMutationVariables>({});
    const onSubmit = useCallback(
        async (variables: ConvertLocaleStringsMutationVariables) => {
            const { data } = await convertLocaleStrings({ variables });

            enqueueSnackbar(data?.convertLocaleStrings.isOk ? "Done" : "Failed", {
                variant: data?.convertLocaleStrings.isOk ? "success" : "error",
            });
        },
        [convertLocaleStrings, enqueueSnackbar],
    );

    return (
        <Form onSubmit={form.handleSubmit(onSubmit)}>
            <Stack gap={4}>
                <Form.Select
                    {...form.register("gameId", {
                        required: true,
                        disabled: loading || getGames.loading,
                    })}
                >
                    {options.map((game) => (
                        <option key={game.guid} value={game.guid}>
                            {game.name}
                        </option>
                    ))}
                </Form.Select>
                <Button type="submit" disabled={loading}>
                    Migrate
                </Button>
            </Stack>
        </Form>
    );
}
