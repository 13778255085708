import { GroupBase, SelectComponentsConfig, StylesConfig } from "react-select";
import { ComponentOption } from "./types";
import { Option } from "./Option";
import { SingleValue } from "./SingleValue";
import { ValueContainer } from "./ValueContainer";

export const selectStyles: StylesConfig<ComponentOption, false> = {
    menuPortal: (baseStyles) => ({
        ...baseStyles,
        zIndex: 10001,
    }),
    option: (base, props) => ({
        ...base,
        backgroundColor: props.isFocused ? "#2684ff4d" : "transparent",
    }),
    valueContainer: (base) => ({
        ...base,
        overflow: "visible",
    }),
};

export const selectComponents: Partial<
    SelectComponentsConfig<ComponentOption, false, GroupBase<ComponentOption>>
> = {
    Option,
    SingleValue,
    ValueContainer,
};