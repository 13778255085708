import { useCallback } from "react";
import Button from "react-bootstrap/Button";
import { FaEdit } from "react-icons/fa";
import Spinner from "react-bootstrap/Spinner";
import { TableCell } from "@/fields/types";
import { useAddChangeRecord } from "@/components/common/Table/EntitiesTable/contexts/TableCellContext";
import { TranslationRenderer } from "@/components/common/LocalizationRenderer";
import { useLocalizationContext } from "@/components/common/Table/EntitiesTable/contexts/LocalizationProvider";
import { CasedBadge } from "@/components/common/CasedBadge";
import { DisableEntityParameterBtn } from "@/fields/components/DisableEntityParameterBtn";
import { showSelectTranslationModal } from "./SelectTranslationModal";
import { useLocalization } from "./contexts/useLocalization";

export const LocalizedStringEditableTableCell: TableCell =
    function LocalizedStringEditableTableCell(props) {
        const { value, addChangeRecord } = useAddChangeRecord(props);
        const { localesMap } = useLocalizationContext();
        const [isLegacyValue, isLoading, translation, safeValue] = useLocalization(value);
        const onEdit = useCallback(async () => {
            const value = await showSelectTranslationModal({
                gameId: props.templateParam.gameId,
                value: safeValue,
            });
            addChangeRecord(value);
        }, [addChangeRecord, props.templateParam.gameId, safeValue]);

        return (
            <div className="d-flex align-items-start gap-2">
                {!props.readonly && <DisableEntityParameterBtn {...props} />}

                {!props.readonly && (
                    <Button size="sm" onClick={onEdit}>
                        <FaEdit />
                    </Button>
                )}
                {isLoading && (
                    <Button size="sm">
                        <Spinner />
                    </Button>
                )}
                {!isLoading && !translation && (
                    <CasedBadge bg="warning">Translation with id {safeValue} not found</CasedBadge>
                )}
                {isLegacyValue ? (
                    <CasedBadge bg="warning">Legacy value: {value}</CasedBadge>
                ) : translation ? (
                    <TranslationRenderer
                        legacy={isLegacyValue}
                        data={translation}
                        localesMap={localesMap}
                        short
                    />
                ) : (
                    <CasedBadge>No value</CasedBadge>
                )}
            </div>
        );
    };
