import { Header } from "@tanstack/react-table";
import Button from "react-bootstrap/Button";
import { FaThumbtack } from "react-icons/fa";

export function PinColumnButton<Data extends Record<string, any>>(props: Header<Data, unknown>) {
    return (
        <Button
            variant={props.column.getIsPinned() ? "primary" : "link"}
            size="sm"
            onClick={() => {
                props.column.pin(props.column.getIsPinned() ? false : "left");
            }}
        >
            <FaThumbtack />
        </Button>
    );
}
