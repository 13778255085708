import { ChangeEventHandler, memo, useCallback, useMemo } from "react";
import Form from "react-bootstrap/Form";
import { useFieldController } from "@/components/common/Table/EntitiesTable2/hooks/useFieldController";
import { InputProps } from "../../types";
import { useTableContext } from "../../contexts/TableContext";

export const TimestampFieldComponent = memo(function TimestampFieldComponent(props: InputProps) {
    const { readonly } = useTableContext();
    const controller = useFieldController(props.name);
    const value = controller.field.value;
    const valueAsDate = useMemo(() => {
        const dateString = new Date(value as string).toISOString();
        if (dateString.endsWith("Z")) return dateString.slice(0, dateString.length - 1);
        return dateString;
    }, [value]);

    const onDateChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
        (event) => {
            if (readonly) return;

            const dateValue = event.target.valueAsNumber;
            if (dateValue) controller.field.onChange(dateValue.valueOf());
        },
        [controller.field, readonly],
    );

    return (
        <Form.Control
            type="datetime-local"
            ref={controller.field.ref}
            value={valueAsDate}
            onChange={onDateChange}
            disabled={readonly}
        />
    );
});
