import { MouseEventHandler, useCallback, useMemo, useState } from "react";
import noop from "lodash/noop";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { DataType, TemplateParam } from "@/gql";
import { fieldsDefinition } from "@/fields";
import { FieldProps } from "@/fields/types";
import {
    NestingContextProvider,
    useNestingContext,
} from "@/components/common/EntityRefRenderer/context";
import { CasedButton } from "../CasedButton";

type Props<T> = {
    templateParam: TemplateParam;
    value: T;
};

export function FieldRenderer<T>(props: Props<T>) {
    const { depth, maxDepth, shouldExpand } = useNestingContext();
    const isCollapsibleType =
        props.templateParam.type === DataType.EntityRef ||
        (props.templateParam.type === DataType.List &&
            props.templateParam.subType === DataType.EntityRef);
    const maxDepthNotReached = depth <= maxDepth;
    const [expanded, setExpanded] = useState(maxDepthNotReached && shouldExpand);
    const [shouldExpandChildren, setShouldExpandChildren] = useState(expanded);

    const fallbackDisplayComponent = useCallback((props: FieldProps<any>) => {
        return (
            <code className="text-primary bg-warning fs-6 bg-opacity-25 p-1">
                {JSON.stringify(props.value)}
            </code>
        );
    }, []);
    const depthExceededComponent = useCallback(() => {
        return (
            <code className="text-primary bg-warning bg-opacity-10 p-1">
                Nesting depth exceeded
            </code>
        );
    }, []);
    const getDisplayComponent = useCallback(
        (parameterType: DataType) => {
            return fieldsDefinition[parameterType].fieldDisplay
                ? depth <= maxDepth
                    ? fieldsDefinition[parameterType].fieldDisplay!
                    : depthExceededComponent
                : fallbackDisplayComponent;
        },
        [depth, depthExceededComponent, fallbackDisplayComponent, maxDepth],
    );
    const fieldDisplay = useCallback(
        (templateParam: TemplateParam) => {
            const DisplayComponent = getDisplayComponent(templateParam.type);

            return (
                <DisplayComponent
                    gameId={templateParam.gameId}
                    templateParam={templateParam}
                    value={props.value}
                    onChange={noop}
                    readonly
                    depth={depth}
                />
            );
        },
        [getDisplayComponent, props.value, depth],
    );
    const onClick = useCallback<MouseEventHandler<HTMLSpanElement>>(
        (event) => {
            if (isCollapsibleType) {
                if (event.altKey && event.button === 0) {
                    setShouldExpandChildren((prevState) => !prevState);
                }
                setExpanded((prevState) => !prevState);
            }
        },
        [isCollapsibleType],
    );
    const ExpandIcon = useMemo(() => {
        if (!isCollapsibleType) return null;

        if (expanded) {
            return FaChevronUp;
        } else {
            return FaChevronDown;
        }
    }, [expanded, isCollapsibleType]);

    return (
        <div>
            <CasedButton
                className="font-monospace"
                variant={ExpandIcon ? "outline-primary" : "link"}
                onClick={onClick}
                size="sm"
            >
                {ExpandIcon && <ExpandIcon className="me-2" />}
                {props.templateParam.name}
            </CasedButton>{" "}
            {!isCollapsibleType || expanded ? (
                <NestingContextProvider depth={depth} shouldExpand={shouldExpandChildren}>
                    {fieldDisplay(props.templateParam)}
                </NestingContextProvider>
            ) : (
                <span className="font-monospace fst-6 lh-180">{"{...}"}</span>
            )}
        </div>
    );
}
