import { gql } from "@apollo/client";
import { GLOSSARY_ENTRY_FRAGMENT } from "@/graphql/fragments/glossary.fragment";
import { makeGqlMutationHook } from "@/graphql/util";
import { CreateGlossaryEntryMutation, CreateGlossaryEntryMutationVariables } from "@/gql";

const SCHEMA = gql`
    ${GLOSSARY_ENTRY_FRAGMENT}

    mutation createGlossaryEntry($gameId: String!, $glossaryEntries: [RawGlossaryEntryDto!]!) {
        createGlossaryEntry(gameId: $gameId, glossaryEntries: $glossaryEntries) {
            ...GlossaryEntry
        }
    }
`;

export const useCreateGlossaryEntryMutation = makeGqlMutationHook<
    CreateGlossaryEntryMutation,
    CreateGlossaryEntryMutationVariables
>(SCHEMA);
