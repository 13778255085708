import { gql } from "@apollo/client";
import { makeGqlMutationHook } from "@/graphql/util";
import { ARTIFACT_FRAGMENT } from "@/graphql/fragments/artifact.fragment";
import { UpdateArtifactMutation, UpdateArtifactMutationVariables } from "@/gql";

export const useUpdateArtifactMutation = makeGqlMutationHook<
    UpdateArtifactMutation,
    UpdateArtifactMutationVariables
>(gql`
    ${ARTIFACT_FRAGMENT}

    mutation updateArtifact(
        $guid: String!
        $gameId: String!
        $description: String!
        $isProtected: Boolean!
    ) {
        updateArtifact(
            guid: $guid
            gameId: $gameId
            description: $description
            isProtected: $isProtected
        ) {
            ...Artifact
        }
    }
`);
