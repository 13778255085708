import { gql } from "@apollo/client";

export const DEPLOY_DIRECTORY_FRAGMENT = gql`
    fragment DeployDirectory on DeployDirectory {
        guid
        gameId
        name
        path
        variant
        description
    }
`;
