import { memo } from "react";
import { DurationPicker } from "@/components/common/DurationInput";
import { useFieldController } from "@/components/common/Table/EntitiesTable2/hooks/useFieldController";
import { useTableContext } from "../../contexts/TableContext";
import { InputProps } from "../../types";

export const DurationFieldComponent = memo(function DurationFieldComponent(props: InputProps) {
    const { readonly } = useTableContext();

    const controller = useFieldController(props.name);

    return (
        <DurationPicker
            {...controller.field}
            value={controller.field.value as number}
            readonly={readonly}
        />
    );
});
