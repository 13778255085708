import { createRoute } from "@tanstack/react-router";
import { Translation } from "@/components/translation";
import { translationPageLayoutRoute } from "@/pages/translation/TranslationPageLayout";

export default function TranslationsPage() {
    const { gameId } = translationsPageRoute.useParams();
    return <Translation gameId={gameId!} />;
}

export const translationsPageRoute = createRoute({
    getParentRoute: () => translationPageLayoutRoute,
    path: "table",
    component: TranslationsPage,
});
