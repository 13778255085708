import { useMemo } from "react";
import { UniqCheckFailedEvent } from "@/gql";
import { stringify } from "@/utils/stringify";

interface UniqCheckFailedEventProps {
    event: UniqCheckFailedEvent;
}

export function UniqCheckFailedEventRenderer(props: UniqCheckFailedEventProps) {
    const value = useMemo(
        () => stringify(props.event.entityParameterValue),
        [props.event.entityParameterValue],
    );

    const message = (
        <>
            {props.event.error}. Non-unique value is <span className="font-monospace">{value}</span>
        </>
    );

    return <span>{message}</span>;
}
