import { gql } from "@apollo/client";
import { USER_PERMISSIONS_FRAGMENT } from "@/graphql/fragments/user-permissions.fragment";
import { TEMPLATE_DISPLAY_FRAGMENT } from "./template-display.fragment";
import { CONSUMER_FRAGMENT } from "./consumer.fragment";
import { TEMPLATE_PARAM_FRAGMENT } from "./template-param.fragment";
import { TEMPLATE_FRAGMENT } from "./template.fragment";
import { USER_FRAGMENT } from "./user.fragment";

export const GAME_FRAGMENT = gql`
    ${USER_PERMISSIONS_FRAGMENT}

    fragment Game on Game {
        name
        sourceGameId
        guid
        meta {
            deployFlags
        }
        description
        allowSyncFromOrigin
        userPermissions {
            ...UserPermission
        }
    }
`;

export const GAME_DETAILS_FRAGMENT = gql`
    ${USER_PERMISSIONS_FRAGMENT}
    ${TEMPLATE_PARAM_FRAGMENT}
    ${TEMPLATE_DISPLAY_FRAGMENT}
    ${TEMPLATE_FRAGMENT}
    ${CONSUMER_FRAGMENT}

    fragment GameDetails on Game {
        guid
        sourceGameId
        name
        description
        allowSyncFromOrigin
        meta {
            deployFlags
        }
        userPermissions {
            ...UserPermission
        }
        templates {
            ...Template
        }
        templateParams {
            ...TemplateParam
        }
        globalParams {
            ...TemplateParam
        }
        consumers {
            ...Consumer
        }
        templateDisplay {
            ...TemplateDisplay
        }
    }
`;
