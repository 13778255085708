import { memo } from "react";
import { FocusableSwitch } from "@/components/common/FocusableSwitch";
import { useAddChangeRecord } from "@/components/common/Table/EntitiesTable/contexts/TableCellContext";
import { DisableEntityParameterBtn } from "@/fields/components/DisableEntityParameterBtn";
import { TableCell } from "../../types";
import { ListEditor } from "./ListEditor";
import { ListTableCellDisplay } from "./ListTableCellDisplay";

export const ListEditableTableCell: TableCell = memo(function ListEditableTableCell(props) {
    const { value, addChangeRecord } = useAddChangeRecord(props);

    if (props.readonly) {
        return <ListTableCellDisplay {...props} />;
    }

    return (
        <div className="d-flex align-items-top">
            <DisableEntityParameterBtn {...props} />
            <FocusableSwitch
                focused={() => (
                    <ListEditor
                        gameId={props.gameId}
                        value={value}
                        onChange={addChangeRecord}
                        templateParam={props.templateParam}
                        contextEntity={props.tableCellProps.row}
                        readonly={props.readonly}
                    />
                )}
                unfocused={() => <ListTableCellDisplay {...props} />}
            />
        </div>
    );
});
