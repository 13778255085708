import { useCallback, useState } from "react";
import Alert from "react-bootstrap/Alert";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Spinner from "react-bootstrap/Spinner";
import { EditUserForm, UserData } from "@/components/forms/EditUserForm";
import { useUpdateUserMutation } from "@/graphql/auth";
import { useAuth } from "@/hooks/useAuth";

export function EditUserSection() {
    const { user, refetch } = useAuth();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<Error | null>(null);
    const [updateUserMutation] = useUpdateUserMutation();
    const onUserUpdate = useCallback(
        async (variables: UserData) => {
            try {
                setIsLoading(true);
                setError(null);
                await updateUserMutation({ variables });
                await refetch();
            } catch (error: any) {
                setError(error);
            } finally {
                setIsLoading(false);
            }
        },
        [refetch, updateUserMutation],
    );

    if (!user) return <Spinner />;

    return (
        <Row className="gy-4 mb-4">
            <Col md={12}>
                <h3 className="mb-0">User info</h3>
            </Col>
            <Col md={12}>
                <EditUserForm
                    firstname={user.firstname}
                    lastname={user.lastname}
                    onSubmit={onUserUpdate}
                    isLoading={isLoading}
                />
            </Col>
            {error && (
                <Col md={12}>
                    <Alert variant="danger">
                        <Alert.Heading>Error</Alert.Heading>
                        <p>{error.message}</p>
                    </Alert>
                </Col>
            )}
        </Row>
    );
}
