import ReactSelect, { Props as SelectProps } from "react-select";

const classNames = {
    control: () => "form-control",
};

export function Select<Option, IsMulti extends boolean = false>(
    props: SelectProps<Option, IsMulti>,
) {
    return <ReactSelect {...props} menuPortalTarget={document.body} classNames={classNames} />;
}
