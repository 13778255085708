import { useCallback } from "react";
import { useController, useForm } from "react-hook-form";
import { bootstrapDialog, create, show, useModal } from "@ebay/nice-modal-react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import Stack from "react-bootstrap/Stack";
import { useSnackbar } from "notistack";
import { DeeplCreateTranslationMutationVariables } from "@/gql";
import {
    useCheckTranslationKeyForUniqueness,
    useDeeplCreateTranslationMutation,
} from "@/graphql/translation";
import { Locale } from "@/types";

type Props = {
    gameId: string;
    locales: Locale[];
};

type FormData = DeeplCreateTranslationMutationVariables;

function CreateDeeplTranslationModalComponent(props: Props) {
    const { gameId } = props;
    const modal = useModal();
    const dialog = bootstrapDialog(modal);
    const { enqueueSnackbar } = useSnackbar();
    const [createDeeplTranslationMutation, { loading: createDeeplTranslationMutationLoading }] =
        useDeeplCreateTranslationMutation();
    const [checkTranslationKeyForUniqueness, { loading: checkTranslationKeyForUniquenessLoading }] =
        useCheckTranslationKeyForUniqueness();
    const loading =
        createDeeplTranslationMutationLoading || checkTranslationKeyForUniquenessLoading;
    const form = useForm<FormData>({
        mode: "onChange",
        defaultValues: {
            gameId,
            key: "",
            description: "",
            baseLocaleValue: "",
        },
    });
    const keyController = useController({
        control: form.control,
        name: "key",
        rules: {
            min: 1,
            max: 255,
            required: { value: true, message: "Translation key is required" },
            validate: async (key) => {
                const result = await checkTranslationKeyForUniqueness({
                    variables: { gameId, key },
                });

                if (!result.data?.checkTranslationKeyForUniqueness.isOk) {
                    return "This key already exists";
                }
            },
        },
    });
    const onSubmit = useCallback(
        async (data: FormData) => {
            const variables: DeeplCreateTranslationMutationVariables = {
                gameId: data.gameId,
                key: data.key,
                description: data.description,
                baseLocaleValue: data.baseLocaleValue,
            };
            const { errors } = await createDeeplTranslationMutation({ variables });
            if (!errors) {
                enqueueSnackbar("Translation created", {
                    variant: "success",
                });
                modal.resolve();
                return modal.hide();
            } else {
                console.dir(errors);
                enqueueSnackbar("Couldn't create translation", {
                    variant: "error",
                    autoHideDuration: 3000,
                });
            }
        },
        [createDeeplTranslationMutation, modal, enqueueSnackbar],
    );

    return (
        <Modal {...dialog}>
            <Modal.Header>
                <Modal.Title>Create translation from Deepl</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={form.handleSubmit(onSubmit)}>
                    <Stack gap={4}>
                        <Form.Group>
                            <Form.Label>Translation key</Form.Label>
                            <Form.Control
                                {...keyController.field}
                                isInvalid={!!keyController.fieldState.error}
                            />
                            {keyController.fieldState.error && (
                                <Form.Control.Feedback type="invalid">
                                    {keyController.fieldState.error?.message}
                                </Form.Control.Feedback>
                            )}
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Description</Form.Label>
                            <Form.Control
                                {...form.register("description")}
                                isInvalid={!!form.formState.errors.description}
                                as="textarea"
                            />
                            {form.formState.errors.description && (
                                <Form.Control.Feedback type="invalid">
                                    {form.formState.errors.description?.message}
                                </Form.Control.Feedback>
                            )}
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Base locale value</Form.Label>
                            <Form.Control
                                {...form.register("baseLocaleValue")}
                                isInvalid={!!form.formState.errors.description}
                                as="textarea"
                            />
                            {form.formState.errors.description && (
                                <Form.Control.Feedback type="invalid">
                                    {form.formState.errors.description?.message}
                                </Form.Control.Feedback>
                            )}
                        </Form.Group>
                        <Button type="submit" disabled={loading || !form.formState.isValid}>
                            {loading && <Spinner size="sm" />}
                            Create
                        </Button>
                    </Stack>
                </Form>
            </Modal.Body>
        </Modal>
    );
}

const CreateDeeplTranslationModal = create<Props>(CreateDeeplTranslationModalComponent);

export function showCreateDeeplTranslationModal(props: Props): Promise<void> {
    return show(CreateDeeplTranslationModal, props);
}
