import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { BaseModal, BaseModalProps } from "@/components/modals/BaseModal";
import { useCellContext } from "../../contexts/CellContext";
import { ContextActionsProps } from "../../types";
import { ErrorBadge } from "../../components/ErrorBadge";
import { useFieldController } from "../../hooks/useFieldController";
import { EntityRefSelector } from "./EntityRefSelector";

type Props = BaseModalProps & ContextActionsProps;

export function EntityRefSelectorModal(props: Props) {
    const { name: _name, ...rest } = props;
    const { gameId, contextEntity, templateParam } = useCellContext();
    const templateId = templateParam.meta.templateId;
    const controller = useFieldController(props.name);

    if (!templateId) {
        return <ErrorBadge messageCode="MISSING_REFD_TEMPLATE" />;
    }

    return (
        <BaseModal {...rest}>
            <Row className="gap-4">
                <Col md={12}>
                    <EntityRefSelector
                        className="border border-1 rounded"
                        gameId={gameId}
                        contextEntity={contextEntity}
                        clearable={!templateParam.meta.isRequired}
                        name={controller.field.name}
                        value={controller.field.value as string}
                        onChange={controller.field.onChange}
                        templateId={templateId}
                        creatable
                    />
                </Col>
                <Col md={12} className="text-end">
                    <Button onClick={() => props.toggle()}>Save</Button>
                </Col>
            </Row>
        </BaseModal>
    );
}
