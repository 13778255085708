import styled from "styled-components";
import ButtonGroup from "react-bootstrap/ButtonGroup";

export const EntityRendererWrapper = styled.div`
    position: relative;
`;

export const ActionsToolbar = styled(ButtonGroup)`
    position: absolute;
    display: flex;
    left: 0;
    transform: translateX(0);
    z-index: -1;
    will-change: transform;
    transition:
        transform 0.1s ease-in-out,
        z-index 0.1s ease-in-out;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
    background-color: transparent;

    ${EntityRendererWrapper}:hover & {
        transform: translateX(-100%);
        z-index: 0;
    }
`;
