import { useCallback, useMemo } from "react";
import { TableColumn } from "react-data-table-component";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import { FaClone, FaCopy, FaTrash } from "react-icons/fa";
import { useSnackbar } from "notistack";
import { Template, TemplateType } from "@/gql";
import { OperableEntityTableData, OperableTemplateEntity } from "@/types";
import { ConfirmationButton } from "@/components/common/ConfirmationButton";
import { EditEntityBtn2 } from "@/components/common/EditEntityBtn2";
import { useDeleteEntityAction } from "@/store/entities/actions";
import { useCloneEntities } from "@/hooks/useCloneEntities";
import { ExportButton } from "../components/ExportButton";

type Props = {
    readonly: boolean;
    template: Template;
};

export function useActionsColumn(props: Props) {
    const [deleteEntityAction] = useDeleteEntityAction();
    const { enqueueSnackbar } = useSnackbar();
    const onCopyToClipboard = useCallback(
        async (guid: string) => {
            try {
                await window.navigator.clipboard.writeText(guid);

                enqueueSnackbar("Entity id copied to clipboard", {
                    variant: "success",
                });
            } catch (e) {
                enqueueSnackbar("Failed to copy entity id to clipboard", {
                    variant: "error",
                    autoHideDuration: 3000,
                });
            }
        },
        [enqueueSnackbar],
    );
    const onCloneEntity = useCloneEntities();

    return useMemo<TableColumn<OperableEntityTableData>>(
        () => ({
            compact: true,
            omit: props.readonly || props.template.type === TemplateType.Singleton,
            style: {
                width: "125px",
                minWidth: "125px",
                maxWidth: "125px",
            },
            cell: (row) => (
                <ButtonGroup size="sm" vertical className="p-1">
                    <Button
                        as="span"
                        variant="outline-primary"
                        className="text-lowercase"
                        title="Copy entity id"
                        onClick={() => onCopyToClipboard(row.guid)}
                    >
                        <FaCopy className="me-1" />
                        {row.guid.split("-").shift()}
                    </Button>
                    <ButtonGroup size="sm">
                        <ExportButton row={row} />
                        <ConfirmationButton
                            title="Delete"
                            variant="danger"
                            onClick={alert}
                            prompt="Please confirm deletion"
                            onConfirm={() =>
                                deleteEntityAction({ guid: row.guid, gameId: row.gameId })
                            }
                        >
                            <FaTrash />
                        </ConfirmationButton>
                        <Button title="Clone" onClick={() => onCloneEntity(row)}>
                            <FaClone />
                        </Button>
                        <EditEntityBtn2
                            className="px-2"
                            title="Edit"
                            variant="primary"
                            gameId={row.gameId}
                            entityId={row.guid}
                        />
                    </ButtonGroup>
                </ButtonGroup>
            ),
        }),
        [props.readonly, props.template.type, onCopyToClipboard, deleteEntityAction, onCloneEntity],
    );
}
