import { useMemo } from "react";
import { uuidValidator } from "@/utils/validators/uuid";
import { Translation } from "@/types";
import { useLocalizationContext } from "../../../contexts/LocalizationProvider";

const validator = uuidValidator().nullable();

export function useLocalization(
    value: string | null,
): [boolean, boolean, Translation | null, string | null] {
    const result = useMemo(() => validator.safeParse(value), [value]);
    const safeValue = result.success ? (result.data === "" ? null : result.data) : null;
    const { requestTranslation, translations } = useLocalizationContext();

    if (!safeValue)
        return [!result.success && value !== null && value !== "", false, null, safeValue];

    if (!translations[safeValue]) {
        requestTranslation(safeValue);
    }

    return [false, !translations[safeValue], translations[safeValue] ?? null, safeValue];
}
