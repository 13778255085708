import { DataType } from "@/gql";
import { createDatatype } from "../../createDatatype";
import { ScriptFieldComponent } from "./ScriptFieldComponent";
import { ReadonlyScriptField } from "./ReadonlyScriptField";

export const scriptField = createDatatype<any>({
    type: DataType.Script,
    defaultValue: () => ({ blocks: { blocks: [] } }),
    inputComponent: ScriptFieldComponent,
    readonlyComponent: ReadonlyScriptField,
});
