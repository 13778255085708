import { DataType } from "@/gql";
import { DataTypeField } from "../../types";
import { AdditionalBooleanFields } from "./AdditionalBooleanFields";
import { EditableBooleanTableCell } from "./EditableBooleanTableCell";
import { BooleanFormField } from "./BooleanFormField";

export const BooleanDataType: DataTypeField = {
    dataType: DataType.Boolean,
    defaultValue: () => "false",
    tableCell: EditableBooleanTableCell,
    formField: BooleanFormField,
    additionalParameterFields: AdditionalBooleanFields,
    formFieldDisplayOptions: {
        md: 2,
    },
    tableCellDisplayOptions: {
        maxSize: 50,
        enableSorting: false,
    },
};
