import { createContext, PropsWithChildren, useCallback, useContext, useMemo } from "react";
import { useLocalStorage } from "usehooks-ts";

type CategoriesSidebarContext = {
    favoritesSet: Set<string>;
    getIsFavorite: (guid: string) => boolean;
    toggleFavorite: (guid: string) => void;
    pinnedSet: Set<string>;
    getIsPinned: (guid: string) => boolean;
    togglePinned: (guid: string) => void;
};

const favoriteTemplatesKey = (gameId: string) => `FAVORITE_TEMPLATES_KEY-${gameId}`;
const pinnedTemplatesKey = (gameId: string) => `PINNED_TEMPLATES_KEY-${gameId}`;

const CategoriesSidebarContext = createContext<CategoriesSidebarContext>(undefined!);

function useLocalStorageSet(key: string) {
    const [collection, setCollection] = useLocalStorage<string[]>(key, []);
    const collectionSet = useMemo(() => new Set(collection), [collection]);
    const isPresent = useCallback((guid: string) => collectionSet.has(guid), [collectionSet]);
    const onToggleItem = useCallback(
        (guid: string) => {
            const newCollection = new Set(collectionSet);
            if (newCollection.has(guid)) {
                newCollection.delete(guid);
            } else {
                newCollection.add(guid);
            }
            setCollection([...newCollection]);
        },
        [collectionSet, setCollection],
    );

    return useMemo(
        () => ({ collection, collectionSet, isPresent, onToggleItem }),
        [collection, collectionSet, isPresent, onToggleItem],
    );
}

export function CategoriesSidebarContextProvider(props: PropsWithChildren<{ gameId: string }>) {
    const { gameId, children } = props;
    const {
        collection: favoritesCollection,
        collectionSet: favoritesSet,
        isPresent: isFavorite,
        onToggleItem: toggleFavorite,
    } = useLocalStorageSet(favoriteTemplatesKey(gameId));
    const {
        collection: pinnedCollection,
        collectionSet: pinnedSet,
        isPresent: isPinned,
        onToggleItem: togglePinned,
    } = useLocalStorageSet(pinnedTemplatesKey(gameId));

    const value: CategoriesSidebarContext = useMemo(
        () => ({
            favoritesCollection,
            favoritesSet,
            getIsFavorite: isFavorite,
            toggleFavorite,
            pinnedCollection,
            pinnedSet,
            getIsPinned: isPinned,
            togglePinned,
        }),
        [
            favoritesCollection,
            favoritesSet,
            isFavorite,
            isPinned,
            pinnedCollection,
            pinnedSet,
            toggleFavorite,
            togglePinned,
        ],
    );

    return (
        <CategoriesSidebarContext.Provider value={value}>
            {children}
        </CategoriesSidebarContext.Provider>
    );
}

export function useCategoriesSidebarContext() {
    return useContext(CategoriesSidebarContext);
}
