import { components, OptionProps } from "react-select";
import { EntityRefRenderer } from "@/components/common/EntityRefRenderer";
import { ComponentOption } from "./types";

export function Option(props: OptionProps<ComponentOption>) {
    return (
        <components.Option {...props}>
            <EntityRefRenderer entityId={props.data.guid} templateId={props.data.templateId} />
        </components.Option>
    );
}
