import { RootState } from "@/store";

export const isEntitiesLoadingSelector = (state: RootState) => state.entitiesSlice.isLoading;
export const entitiesSelector = (state: RootState) => state.entitiesSlice.entities;
export const entitiesErrorSelector = (state: RootState) => state.entitiesSlice.error;
export const entitiesChangesSelector = (state: RootState) => state.entitiesSlice.entityChanges;
export const entitiesChangesMapSelector = (state: RootState) =>
    state.entitiesSlice.entityChangesMap;
export const entitiesStateChangesMapSelector = (state: RootState) =>
    state.entitiesSlice.entityStateChangesMap;
export const undoneChangeSelector = (state: RootState) => state.entitiesSlice.undoneChanges;
