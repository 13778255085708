import { useCallback, useMemo } from "react";
import {
    components,
    GroupBase,
    OnChangeValue,
    OptionProps,
    SingleValueProps,
    StylesConfig,
    ValueContainerProps,
    ActionMeta,
    SelectComponentsConfig,
} from "react-select";
import Select from "react-select/creatable";
import { useGetEntitiesQuery } from "@/graphql/entities/getEntities.query";
import { useTemplate2 } from "@/store/games/slices";
import { TemplateEntity, TemplateType } from "@/gql";
import { useCreateComponent } from "@/hooks/useCreateComponent";
import { OperableEntityTableData } from "@/types";
import { ErrorBadge } from "../../components/ErrorBadge";
import { EntityRefRenderer } from "../../components/EntityRefRenderer";

interface ComponentOption {
    readonly templateId: string;
    readonly value: string;
}

const Option = (props: OptionProps<ComponentOption>) => (
    <components.Option {...props}>
        <EntityRefRenderer entityId={props.data.value} templateId={props.data.templateId} />
    </components.Option>
);

const ValueContainer = (props: ValueContainerProps<ComponentOption>) => {
    return (
        <components.ValueContainer {...props}>
            {props.getValue().map((value) => (
                <EntityRefRenderer
                    key={value.value}
                    entityId={value.value}
                    templateId={value.templateId}
                />
            ))}
        </components.ValueContainer>
    );
};

const SingleValue = (props: SingleValueProps<ComponentOption>) => (
    <components.SingleValue {...props}>
        <EntityRefRenderer entityId={props.data.value} templateId={props.data.templateId} />
    </components.SingleValue>
);

interface EntityRefSelectorProps {
    contextEntity?: OperableEntityTableData | null;
    gameId: string;
    templateId: string;
    value: string;
    onChange: (value: string) => void;
    name?: string;
    className?: string;
    clearable?: boolean;
    creatable?: boolean;
}

const selectStyles: StylesConfig<ComponentOption, false> = {
    menuPortal: (baseStyles) => ({
        ...baseStyles,
        zIndex: 10001,
    }),
    option: (base, props) => ({
        ...base,
        backgroundColor: props.isFocused ? "#2684ff4d" : "transparent",
    }),
    valueContainer: (base) => ({
        ...base,
        overflow: "visible",
        padding: 2,
    }),
    control: (base) => ({
        ...base,
        border: "none",
        backgroundColor: "transparent",
        flexWrap: "nowrap",
    }),
    indicatorsContainer: (base) => ({
        ...base,
        flexDirection: "column-reverse",
    }),
};

const selectComponents: Partial<
    SelectComponentsConfig<ComponentOption, false, GroupBase<ComponentOption>>
> = {
    Option,
    SingleValue,
    ValueContainer,
};

export function EntityRefSelector(props: EntityRefSelectorProps) {
    const { templateId, gameId, name, value, onChange, clearable = false } = props;
    const [template] = useTemplate2(templateId);
    const onCreateOption = useCreateComponent({
        gameId,
        templateId,
        onChange,
    });
    const templateEntities = useGetEntitiesQuery({ templateId, gameId });
    const options = useMemo<ComponentOption[]>(() => {
        if (!templateEntities.data?.result) return [];

        return templateEntities.data?.result
            .filter(
                (entity) =>
                    entity.templateId === templateId && props.contextEntity?.guid !== entity.guid,
            )
            .map((entity) => ({
                value: entity.guid,
                templateId: entity.templateId,
            }));
    }, [props.contextEntity?.guid, templateEntities.data?.result, templateId]);
    const defaultValue = useMemo(
        () => (value ? { templateId, value } : undefined),
        [templateId, value],
    );
    const isValidNewOption = useCallback(() => {
        return (
            Boolean(props.creatable) &&
            template !== undefined &&
            template !== null &&
            template.type === TemplateType.InjectedComponent
        );
    }, [props.creatable, template]);
    const getNewOptionData = useCallback(() => ({ value: "__new", templateId }), [templateId]);

    const onSelectChange = useCallback(
        (
            newValue: OnChangeValue<ComponentOption, false>,
            actionMeta: ActionMeta<ComponentOption>,
        ) => {
            if (actionMeta.action === "create-option") {
                return onCreateOption();
            }
            if (actionMeta.action === "clear") {
                return onChange("");
            }
            if (newValue === null) return;
            onChange(newValue.value ?? "");
        },
        [onChange, onCreateOption],
    );

    if (!templateId) {
        return <ErrorBadge messageCode="MISSING_TEMPLATEID" />;
    }

    return (
        <Select<ComponentOption, false>
            createOptionPosition="first"
            isValidNewOption={isValidNewOption}
            getNewOptionData={getNewOptionData}
            isMulti={false}
            className={props.className}
            isClearable={clearable}
            defaultValue={defaultValue}
            value={defaultValue}
            name={name}
            menuPortalTarget={document.body}
            styles={selectStyles}
            onCreateOption={onCreateOption}
            options={options}
            components={selectComponents}
            onChange={onSelectChange}
        />
    );
}
