import classNames from "classnames";
import { forwardRef, MouseEventHandler, useCallback } from "react";
import { Variant } from "react-bootstrap/types";
import Button, { ButtonProps } from "react-bootstrap/Button";
import { FaEdit } from "react-icons/fa";
import { useSnackbar } from "notistack";
import { showEditEntityModal } from "@/components/modals2/EditEntityModal";
import { useLazyGetEntity } from "@/graphql/entities";

type Props = ButtonProps & {
    entityId: string;
    gameId: string;
    className?: string;
    variant?: Variant;
    onComplete?: () => void;
};

export const EditEntityBtn2 = forwardRef<HTMLButtonElement, Props>(
    function EditEntityBtn(props, ref) {
        const { variant, entityId, gameId, className, onComplete, ...rest } = props;
        const [getEntity] = useLazyGetEntity();
        const { enqueueSnackbar } = useSnackbar();

        const onEdit = useCallback<MouseEventHandler<HTMLButtonElement>>(
            async (event) => {
                event.stopPropagation();
                const { data } = await getEntity({
                    variables: { guid: entityId, gameId },
                });

                if (!data?.entity?.guid) {
                    enqueueSnackbar("Could not find entity info", { variant: "error" });
                    return;
                }

                await showEditEntityModal({
                    templateId: data.entity.templateId,
                    entityId,
                    gameId,
                });

                typeof onComplete === "function" && onComplete();
            },
            [getEntity, entityId, gameId, onComplete, enqueueSnackbar],
        );

        return (
            <Button
                {...rest}
                ref={ref}
                variant={variant ?? "link"}
                className={classNames("float-start", "p-0", className)}
                onClick={onEdit}
                title="Edit"
            >
                <FaEdit />
            </Button>
        );
    },
);
