import { DataType } from "@/gql";
import { createDatatype } from "../../createDatatype";
import { LocalizedStringFieldComponent } from "./LocalizedStringFieldComponent";
import { LocalizedStringContextActions } from "./LocalizedStringContextActions";

export const localizedStringField = createDatatype({
    defaultValue: () => "",
    type: [DataType.LocalizedString],
    inputComponent: LocalizedStringFieldComponent,
    contextActions: LocalizedStringContextActions,
    // placeholderComponent: LocalizedStringPlaceholder,
    tableCellDisplayOptions: () => ({
        minWidth: "400px",
        maxWidth: "400px",
    }),
});
