import { gql } from "@apollo/client";

export const ASSET_FRAGMENT = gql`
    fragment Asset on AssetModel {
        guid
        gameId
        extension
        name
        description
        filename
        createdAt
        hash
        mime
        size
    }
`;
