import { useCallback, useMemo, useState } from "react";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import ToggleButton from "react-bootstrap/ToggleButton";
import decode from "html-entities-decoder";
import keyBy from "lodash/keyBy";
import uniqWith from "lodash/uniqWith";
import { FaClone, FaCopy, FaExternalLinkAlt, FaEye, FaEyeSlash, FaTrash } from "react-icons/fa";
import { Link, useNavigate } from "@tanstack/react-router";
import Spinner from "react-bootstrap/Spinner";
import { useSnackbar } from "notistack";
import { createColumnHelper } from "@tanstack/react-table";
import { DisplayColumnDef } from "@tanstack/table-core";
import { QueryResult } from "@apollo/client/react/types/types";
import { EntityChange, EntityParameterChangeV2, OperableEntityTableData } from "@/types";
import { tableCellPropsConverter } from "@/fields/utils/tableCellPropsConverter";
import { fieldsDefinition } from "@/fields";
import { useEntityChanges } from "@/store/entities/slices";
import { useAppDispatch } from "@/hooks";
import { useChangeEntityActionV2 } from "@/store/entities/actions";
import { CLEAR_CHANGES_HISTORY } from "@/store/entities/slice";
import { GetEntitiesV2Query, GetEntitiesV2QueryVariables, Template, TemplateParam } from "@/gql";
import { EditEntityBtn2 } from "@/components/common/EditEntityBtn2";
import { useCloneEntities } from "@/hooks/useCloneEntities";
import { entityTableDataToOperable } from "@/utils/entities";
import { useDeleteEntity } from "@/components/entities/hooks/useDeleteEntity";
import { entitiesListRoute } from "@/pages/EntitiesList";
import { entityPageRoute } from "@/pages/EntityPage";

interface EntityCacheProps {
    template: Template;
    getEntities: QueryResult<GetEntitiesV2Query, GetEntitiesV2QueryVariables>;
    readonly: boolean;
    skip: number;
    take: number;
    query: string;
    sortAttribute: string | null;
    descending: boolean;
}

const columnHelper = createColumnHelper<OperableEntityTableData>();

export function useEntityCache(props: EntityCacheProps) {
    const {
        hasChanges: hasEntityChanges,
        addChangeRecord,
        entityChanges,
        entityChangesMap,
        entityParamsStateMap,
    } = useEntityChanges();
    const navigate = useNavigate({ from: entitiesListRoute.fullPath });
    const dispatch = useAppDispatch();
    const refetch = useCallback(() => props.getEntities.refetch(), [props.getEntities]);
    const onCloneEntity = useCloneEntities(props.getEntities.refetch);
    const [deleteEntity, countEntityReferencesLoading] = useDeleteEntity();
    const onDeleteEntity = useCallback(
        async (props: { gameId: string; guid: string }) => {
            await deleteEntity(props);
            await refetch();
        },
        [deleteEntity, refetch],
    );
    const [changeEntityActionV2] = useChangeEntityActionV2();
    const { enqueueSnackbar } = useSnackbar();
    const entitiesMap = useMemo(
        () => keyBy(props.getEntities.data?.getTemplateEntitiesV2.entities ?? [], "guid"),
        [props.getEntities.data?.getTemplateEntitiesV2.entities],
    );

    const [entityExportChanges, setEntityExportChanges] = useState<
        Record<EntityChange["entityId"], EntityChange["export"]>
    >({});
    const hasChanges = useMemo(() => {
        return hasEntityChanges || Reflect.ownKeys(entityExportChanges).length > 0;
    }, [entityExportChanges, hasEntityChanges]);
    const columnsOrder = useMemo<string[]>(() => {
        const predefinedColumnsOrder = props.template.templateDisplay?.fieldsOrder ?? [];
        const templateParamsOrder = (props.template.templateParams ?? []).map(
            (value) => value.guid,
        );

        if (Array.isArray(predefinedColumnsOrder)) {
            return predefinedColumnsOrder;
        } else {
            return templateParamsOrder;
        }
    }, [props.template.templateDisplay?.fieldsOrder, props.template.templateParams]);

    const setEntityExport = useCallback(
        (guid: string, shouldExport: boolean) => {
            setEntityExportChanges((prevState) => {
                if (entitiesMap[guid]?.export === shouldExport) {
                    const { [guid]: _omit, ...newState } = prevState;
                    return { ...newState };
                } else {
                    return { ...prevState, [guid]: shouldExport };
                }
            });
        },
        [entitiesMap],
    );

    const data = useMemo<OperableEntityTableData[]>(() => {
        return (props.getEntities.data?.getTemplateEntitiesV2.entities ?? []).map((entity) => {
            const operableEntity = entityTableDataToOperable(entity);

            if (
                entityChangesMap[operableEntity.guid] === undefined &&
                entityExportChanges[operableEntity.guid] === undefined
            ) {
                return operableEntity;
            }

            return {
                ...operableEntity,
                export: entityExportChanges[operableEntity.guid] ?? operableEntity.export,
                values: {
                    ...operableEntity.values,
                    ...entityChangesMap[operableEntity.guid],
                },
                enabledState: {
                    // ...entity.enabledState,
                    ...entityParamsStateMap[entity.guid],
                },
            };
        });
    }, [
        entityChangesMap,
        entityExportChanges,
        entityParamsStateMap,
        props.getEntities.data?.getTemplateEntitiesV2.entities,
    ]);

    const templateParamToColumn = useCallback(
        (templateParam: TemplateParam): DisplayColumnDef<OperableEntityTableData> | null => {
            const fieldDefinition = fieldsDefinition[templateParam.type];
            const tableCellDisplayOptions =
                fieldDefinition?.tableCellDisplayOptions !== undefined
                    ? typeof fieldDefinition.tableCellDisplayOptions === "function"
                        ? fieldDefinition.tableCellDisplayOptions(templateParam)
                        : fieldDefinition.tableCellDisplayOptions
                    : {};
            if (!fieldDefinition) return null;

            return columnHelper.accessor((row) => row.values[templateParam.guid], {
                id: templateParam.guid,
                header: () => {
                    const name = decode(templateParam.meta.displayName || templateParam.name);
                    return (
                        <div className="d-flex flex-row flex-wrap fs-6">
                            {name}
                            {props.template.templateDisplay?.defaultSortAttribute ===
                                templateParam.guid && (
                                <sup>
                                    <small>primary</small>
                                </sup>
                            )}
                        </div>
                    );
                },
                cell: tableCellPropsConverter(fieldDefinition.tableCell, {
                    templateParam,
                    readonly: props.readonly,
                }),
                ...tableCellDisplayOptions,
            });
        },
        [props.readonly, props.template.templateDisplay?.defaultSortAttribute],
    );

    const fieldColumns = useMemo(() => {
        if (!Array.isArray(props.template.templateParams)) return [];

        if (props.template.templateParams.length === 0) {
            return [
                columnHelper.display({
                    id: "no-parameters-column",
                    cell: () => (
                        <div>
                            <h4>This template has no parameters yet</h4>
                            <p>
                                You can create one on{" "}
                                <Link from={entitiesListRoute.fullPath} to="../params">
                                    Parameters page
                                </Link>
                            </p>
                        </div>
                    ),
                }),
            ];
        }

        return columnsOrder
            .map((guid) => {
                const templateParam = props.template.templateParams!.find((tp) => tp.guid === guid);

                if (!templateParam) return null;

                return templateParamToColumn(templateParam);
            })
            .filter(Boolean);
    }, [columnsOrder, props.template.templateParams, templateParamToColumn]);

    const onCopyToClipboard = useCallback(
        async (guid: string) => {
            try {
                await window.navigator.clipboard.writeText(guid);

                enqueueSnackbar("Entity id copied to clipboard", {
                    variant: "success",
                });
            } catch (e) {
                enqueueSnackbar("Failed to copy entity id to clipboard", {
                    variant: "error",
                    autoHideDuration: 3000,
                });
            }
        },
        [enqueueSnackbar],
    );
    const onOpenEntity = useCallback(
        (guid: string) => {
            return navigate({ to: entityPageRoute.fullPath, params: { entityId: guid } });
        },
        [navigate],
    );

    const actionsColumn = useMemo<DisplayColumnDef<OperableEntityTableData>>(
        () =>
            columnHelper.display({
                id: "actions",
                size: 162,
                cell: (props) => {
                    const row = props.row.original;
                    return (
                        <ButtonGroup size="sm" vertical className="w-100 mw-100">
                            <Button
                                as="span"
                                variant="outline-primary"
                                className="text-lowercase"
                                title="Copy entity id"
                                onClick={() => onCopyToClipboard(row.guid)}
                            >
                                <FaCopy className="me-1" />
                                {row.guid.split("-").shift()}
                            </Button>
                            <ButtonGroup size="sm">
                                <ToggleButton
                                    id={`export-${row.guid}`}
                                    value={`export-${row.guid}`}
                                    type="checkbox"
                                    checked={row.export}
                                    onChange={(event) =>
                                        setEntityExport(row.guid, event.target.checked)
                                    }
                                    title="Export"
                                >
                                    {row.export ? <FaEye /> : <FaEyeSlash />}
                                </ToggleButton>
                                <Button
                                    title="Delete"
                                    variant="danger"
                                    onClick={() => onDeleteEntity(row)}
                                    disabled={countEntityReferencesLoading}
                                >
                                    {countEntityReferencesLoading ? (
                                        <Spinner size="sm" />
                                    ) : (
                                        <FaTrash />
                                    )}
                                </Button>
                                <Button title="Clone" onClick={() => onCloneEntity(row)}>
                                    <FaClone />
                                </Button>
                                <EditEntityBtn2
                                    className="px-2"
                                    title="Edit"
                                    variant="primary"
                                    gameId={row.gameId}
                                    entityId={row.guid}
                                    onComplete={refetch}
                                />
                                <Button onClick={() => onOpenEntity(row.guid)}>
                                    <FaExternalLinkAlt />
                                </Button>
                            </ButtonGroup>
                        </ButtonGroup>
                    );
                },
                enableSorting: false,
            }),
        [
            countEntityReferencesLoading,
            refetch,
            onCopyToClipboard,
            setEntityExport,
            onDeleteEntity,
            onCloneEntity,
            onOpenEntity,
        ],
    );

    const columns = useMemo(() => [actionsColumn, ...fieldColumns], [actionsColumn, fieldColumns]);

    const saveChanges = useCallback(async () => {
        const changesUniq = uniqWith(
            [...entityChanges].reverse(),
            (arrVal, othVal) =>
                arrVal.entityId === othVal.entityId &&
                arrVal.templateParameterId === othVal.templateParameterId,
        ).map(
            (v): EntityParameterChangeV2 => ({
                disabled: v.disabled,
                entityGuid: v.entityId,
                templateParameterGuid: v.templateParameterId,
                value: v.value,
            }),
        );

        await changeEntityActionV2({
            gameId: props.template.gameId,
            entityChanges: entityExportChanges,
            entityParameterChanges: changesUniq,
        });
        dispatch(CLEAR_CHANGES_HISTORY());
        setEntityExportChanges({});
        await refetch();
    }, [
        changeEntityActionV2,
        dispatch,
        entityChanges,
        entityExportChanges,
        props.template.gameId,
        refetch,
    ]);

    return useMemo(
        () => ({
            hasChanges,
            data,
            columns,
            saveChanges,
            addChangeRecord,
            entityChanges,
            entityChangesMap,
            refetch,
        }),
        [
            addChangeRecord,
            columns,
            data,
            entityChanges,
            entityChangesMap,
            hasChanges,
            refetch,
            saveChanges,
        ],
    );
}
