import { memo, PropsWithChildren, useMemo, useRef } from "react";
import styled from "styled-components";
import cn from "classnames";
import { useOnClickOutside } from "usehooks-ts";
import { useMatchRoute } from "@tanstack/react-router";
import { SecondarySidebar } from "@/components/layout/SecondarySidebar";
import { useToggle } from "@/hooks";
import { Sidebar } from "./Sidebar";

const Main = styled.main`
    height: 100vh;
    max-height: 100vh;
    overflow-y: scroll;
`;

const StyledSecondarySidebar = styled(SecondarySidebar)`
    transform: translate(-100%, 0);
    position: absolute;
    left: 100%;
    z-index: 2;
    transition:
        transform 0.2s ease-in-out,
        opacity 0.2s ease-in-out;
    opacity: 0;
`;

const SidebarWrapper = styled.aside`
    height: 100vh;
    max-height: 100vh;

    &.open > ${StyledSecondarySidebar} {
        transform: translate(0, 0);
        opacity: 1;
    }
`;

const fullScreenPages = [
    "/$gameId/templates/$templateId/entities",
    "/$gameId/artifacts/diff",
    "/$gameId/templates/$templateId/export",
    "/$gameId/templates/$templateId/params",
];

function useIsFullscreenPage() {
    const matchRoute = useMatchRoute();
    return useMemo(
        () =>
            fullScreenPages.some((path) =>
                matchRoute({
                    to: path,
                    caseSensitive: false,
                }),
            ),
        [matchRoute],
    );
}

export const AppBody = memo(function AppBody({ children }: PropsWithChildren) {
    const { state, toggle, toggleOff } = useToggle();
    const isFullscreenPage = useIsFullscreenPage();
    const ref = useRef<HTMLDivElement | null>(null);
    useOnClickOutside(ref, toggleOff);

    return (
        <section className="flex-grow-1 d-flex flex-nowrap overflow-x-auto overflow-y-hidden">
            <SidebarWrapper
                className={cn("d-flex flex-nowrap position-relative flex-grow-0 shadow-sm", {
                    open: state,
                })}
                ref={ref}
            >
                <Sidebar className="h-100 shadow z-3" menuState={state} onClick={toggle} />
                {state && <StyledSecondarySidebar onItemSelected={toggleOff} />}
            </SidebarWrapper>

            <Main
                className={cn("flex-grow-1 p-3 d-flex flex-column gap-2", {
                    "overflow-hidden": isFullscreenPage,
                    "h-100": isFullscreenPage,
                })}
            >
                {children}
            </Main>
        </section>
    );
});
