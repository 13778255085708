import { gql } from "@apollo/client";
import { makeGqlQueryHook } from "@/graphql/util";
import { GetHistoryEntriesQuery, GetHistoryEntriesQueryVariables } from "@/gql";

const SCHEMA = gql`
    query getHistoryEntries(
        $gameId: String!
        $actionType: [HistoryAction!]!
        $createdBy: String
        $after: DateTime
        $before: DateTime
        $offset: Float
        $take: Float
    ) {
        historyEntries(
            gameId: $gameId
            actionType: $actionType
            createdBy: $createdBy
            after: $after
            before: $before
            offset: $offset
            take: $take
        ) {
            guid
            gameId
            actionType
            createdAt
            createdBy
            payload
            revertPayload
        }
        historyEntriesCount(
            gameId: $gameId
            actionType: $actionType
            createdBy: $createdBy
            after: $after
            before: $before
        )
    }
`;

export const useGetHistoryEntries = makeGqlQueryHook<
    GetHistoryEntriesQuery,
    GetHistoryEntriesQueryVariables
>(SCHEMA);
