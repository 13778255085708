import {
    FocusEventHandler,
    ForwardedRef,
    forwardRef,
    InputHTMLAttributes,
    memo,
    useCallback,
    useMemo,
    useRef,
} from "react";
import Form from "react-bootstrap/Form";
import { useAddChangeRecord } from "@/components/common/Table/EntitiesTable/contexts/TableCellContext";
import { useFocusState } from "@/hooks/useFocusState";
import { DataType } from "@/gql";
import { DisableEntityParameterBtn } from "@/fields/components/DisableEntityParameterBtn";
import { FieldProps, TableCell } from "../../types";

export const PrimitiveEditableTableCell: TableCell = memo(
    function PrimitiveEditableTableCell(props) {
        const { value, addChangeRecord, disabled } = useAddChangeRecord(props);
        const inputRef = useRef<HTMLInputElement | null>(null);
        const focused = useFocusState(inputRef);
        const onChange = useCallback(
            (value: string | null) => addChangeRecord(value),
            [addChangeRecord],
        );

        return (
            <div className="d-flex gap-1 align-items-center">
                <DisableEntityParameterBtn {...props} />
                <PrimitiveField
                    gameId={props.gameId}
                    templateParam={props.templateParam}
                    focused={focused}
                    ref={inputRef}
                    value={value}
                    onChange={onChange}
                    readonly={props.readonly || disabled}
                    contextEntity={props.tableCellProps.row}
                    dataType={props.templateParam.type}
                />
            </div>
        );
    },
);

type AdditionalInputProps = Pick<InputHTMLAttributes<HTMLInputElement>, "type" | "step">;

export const PrimitiveField = forwardRef(function PrimitiveField(
    props: FieldProps<string> & {
        readonly?: boolean;
        focused?: boolean;
        dataType: DataType;
    },
    ref: ForwardedRef<HTMLInputElement | null>,
) {
    const onChange = useCallback<FocusEventHandler<HTMLInputElement>>(
        (event) => {
            if (!props.readonly) {
                props.onChange(event.target.value);
            }
        },
        [props],
    );

    const additionalProps = useMemo((): AdditionalInputProps => {
        switch (props.dataType) {
            case DataType.Integer:
                return {
                    type: "number",
                };
            case DataType.Float:
                return {
                    type: "number",
                    step: 0.001,
                };
            default:
                return {
                    type: "text",
                };
        }
    }, [props.dataType]);

    return (
        <Form.Control
            className="p-1"
            // className={classNames({
            //     "form-control-plaintext": !props.focused,
            // })}
            {...additionalProps}
            ref={ref}
            disabled={props.readonly}
            value={props.value}
            onChange={onChange}
            onBlur={onChange}
        />
    );
});
