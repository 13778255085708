import { createContext, PropsWithChildren, useCallback, useContext, useMemo, useRef } from "react";
import { useSnackbar } from "notistack";
import { useAppDispatch, useAppSelector } from "@/hooks";
import { ADD_ENTITY } from "@/store/entities/slice";
import { useGetEntitiesByIds } from "@/graphql/entities";
import { entitiesSelector } from "@/store/entities/selectors";

const RequestEntityContext = createContext<{
    requestEntity: (id: string, gameId: string) => void;
}>(null!);

const batchSize = 100;
const batchInterval = 100;

export function RequestEntityProvider(props: PropsWithChildren) {
    const entitiesQueue = useRef<string[]>([]);
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useAppDispatch();
    const [getEntitiesByIds] = useGetEntitiesByIds();
    const entitiesSlice = useAppSelector(entitiesSelector);

    const getIdsBatch = useCallback(() => {
        const batch: string[] = [];
        for (let i = 0; i < batchSize && entitiesQueue.current.length > 0; ++i) {
            const id = entitiesQueue.current.shift();
            if (id !== undefined) {
                batch.push(id);
            }
        }
        return batch;
    }, []);

    const handleEntitiesBatch = useCallback(
        async (entityIds: string[], gameId: string) => {
            const { data } = await getEntitiesByIds({ variables: { entityIds, gameId } });
            if (!data?.result) {
                enqueueSnackbar("Failed to load entities batch. See console for details", {
                    variant: "error",
                });
                console.error("Failed to load entities batch");
                console.dir(entityIds);
                return [];
            }
            data.result.map((entity) => dispatch(ADD_ENTITY(entity)));
            return data!.result;
        },
        [dispatch, enqueueSnackbar, getEntitiesByIds],
    );

    const doWork = useCallback(
        async (gameId: string) => {
            if (entitiesQueue.current.length === 0) return;

            await handleEntitiesBatch(getIdsBatch(), gameId);
            window.requestIdleCallback(() => doWork(gameId));
            // window.setTimeout(doWork, batchInterval, gameId);
        },
        [getIdsBatch, handleEntitiesBatch],
    );

    const requestEntity = useCallback(
        async (entityId: string | null, gameId: string) => {
            if (entityId === null || entitiesSlice[entityId]) return;
            if (!entitiesQueue.current.includes(entityId)) {
                entitiesQueue.current.push(entityId);
            }
            window.requestIdleCallback(() => doWork(gameId));
            // window.setTimeout(doWork, batchInterval, gameId);
        },
        [doWork, entitiesSlice],
    );

    const contextValue = useMemo(() => ({ requestEntity }), [requestEntity]);

    return (
        <RequestEntityContext.Provider value={contextValue}>
            {props.children}
        </RequestEntityContext.Provider>
    );
}

export function useRequestEntityContext() {
    return useContext(RequestEntityContext);
}
