import { ChangeEventHandler, useCallback, useMemo, useState } from "react";
import DataTable, { TableColumn } from "react-data-table-component";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import { TableProps } from "react-data-table-component/dist/DataTable/types";
import { useSnackbar } from "notistack";
import { FaClone, FaLock, FaTrash } from "react-icons/fa";
import Form from "react-bootstrap/Form";
import { GameInfo, User } from "@/gql";
import { UserInfo } from "@/components/common/UserInfo";
import { ConfirmationButton } from "@/components/common/ConfirmationButton";
import { GameInfoExpanded } from "@/components/wizardry/games/GameInfoExpanded";
import { useCloneGameMutation, useDeleteGameMutation } from "@/graphql/wizardry/games";
import { useSelectUserModal } from "@/components/wizardry/games/SelectUserModal";
import { showDeployLockModal } from "@/components/modals2/DeployLockModal";
import { DateDisplay } from "@/components/common/DateDisplay";
import { UserInfoAsync } from "@/components/common/UserInfoAsync";

type Props = {
    data: GameInfo[];
};

type GamesTable = TableProps<GameInfo>;
type HandleRowSelected = Required<GamesTable>["onSelectedRowsChange"];

export function GamesList(props: Props) {
    const [deleteGameMutation] = useDeleteGameMutation();
    const [cloneGameMutation] = useCloneGameMutation();
    const [selectedRows, setSelectedRows] = useState<GameInfo[]>([]);
    const [filterValue, setFilterValue] = useState("");
    const onFilterValueChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
        (event) => setFilterValue(event.target.value),
        [],
    );
    const { enqueueSnackbar } = useSnackbar();
    const selectUserModal = useSelectUserModal();
    const onDeleteSelectedProjects = useCallback(async () => {
        const result = await deleteGameMutation({
            variables: { gameIds: selectedRows.map((row) => row.guid) },
        });

        if (result.data) {
            enqueueSnackbar("Deletion successfully queued", {
                variant: "success",
                autoHideDuration: 5000,
            });
        }
    }, [deleteGameMutation, enqueueSnackbar, selectedRows]);
    const onRemoveProject = useCallback(
        async (guid: string) => {
            await deleteGameMutation({
                variables: { gameIds: [guid] },
            });
        },
        [deleteGameMutation],
    );
    const onCloneProject = useCallback(
        async (gameId: string) => {
            const selectedUser = await selectUserModal();

            if (!selectedUser) {
                return;
            }

            await cloneGameMutation({
                variables: {
                    gameId,
                    userId: (selectedUser as User).guid,
                },
            });
        },
        [cloneGameMutation, selectUserModal],
    );
    const onSetDeployLock = useCallback(async (gameId: string) => {
        return showDeployLockModal({ gameId });
    }, []);
    const handleRowSelected = useCallback<HandleRowSelected>((state) => {
        setSelectedRows(state.selectedRows);
    }, []);
    const onCreateProject = useCallback(() => {}, []);
    const displayData = useMemo(() => {
        if (filterValue.trim().length < 2) return props.data;
        return props.data.filter(
            (row) => row.guid.includes(filterValue) || row.name.includes(filterValue),
        );
    }, [filterValue, props.data]);
    const columns = useMemo<TableColumn<GameInfo>[]>(
        () => [
            {
                name: "Name",
                cell: (row) => (
                    <div className="d-flex flex-column">
                        <div>{row.name}</div>
                        <div className="small">{row.guid}</div>
                    </div>
                ),
            },
            {
                name: "Creator/Owner",
                cell: (row) => <UserInfoAsync userId={row.creatorId} />,
            },
            {
                name: "Created at",
                cell: (row) => <DateDisplay date={row.createdAt} />,
            },
            {
                name: "Deleted at",
                cell: (row) =>
                    row.deletedAt ? <DateDisplay date={row.deletedAt} /> : <span>&mdash;</span>,
            },
            {
                name: "Actions",
                grow: 0,
                compact: true,
                cell: (row) => (
                    <ButtonGroup size="sm">
                        <ConfirmationButton
                            variant="danger"
                            onConfirm={() => onRemoveProject(row.guid)}
                            prompt="Are you sure you want to remove this project? This action is permanent and cannot be undone"
                            confirmationPhrase={row.name}
                            title="Delete"
                        >
                            <FaTrash />
                        </ConfirmationButton>
                        <Button
                            variant="warning"
                            onClick={() => onCloneProject(row.guid)}
                            title="Clone"
                        >
                            <FaClone />
                        </Button>
                        <Button onClick={() => onSetDeployLock(row.guid)} title="Deploy lock">
                            <FaLock />
                        </Button>
                    </ButtonGroup>
                ),
            },
        ],
        [onCloneProject, onRemoveProject, onSetDeployLock],
    );
    const contextActions = useMemo(
        () => (
            <ConfirmationButton
                variant="danger"
                onConfirm={onDeleteSelectedProjects}
                prompt="Please confirm that you want to delete selected projects. Confirmation will actually delete them from the database"
            >
                Delete selected projects
            </ConfirmationButton>
        ),
        [onDeleteSelectedProjects],
    );

    return (
        <div>
            <div className="d-flex align-items-start gap-2 py-2">
                <Form.Group className="w-100">
                    <Form.Control
                        placeholder="Filter"
                        value={filterValue}
                        onChange={onFilterValueChange}
                    />
                    <Form.Text>Filter is case sensitive</Form.Text>
                </Form.Group>
                <Button onClick={onCreateProject} variant="success" size="sm">
                    Create project
                </Button>
            </div>
            <DataTable
                data={displayData}
                columns={columns}
                contextActions={contextActions}
                expandableRowsComponent={GameInfoExpanded}
                expandableRows
                selectableRows
                onSelectedRowsChange={handleRowSelected}
            />
        </div>
    );
}
