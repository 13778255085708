import { useForm } from "react-hook-form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { FormInput } from "@/components/common/forms/form-inputs/FormInput";
import { WizCreateUserMutationVariables } from "@/gql";
import { BaseModal, BaseModalProps } from "@/components/modals/BaseModal";

type Props = BaseModalProps<WizCreateUserMutationVariables>;

export function CreateUserDialog(props: Props) {
    const newUserForm = useForm<WizCreateUserMutationVariables>({
        defaultValues: {
            password: "",
            email: "",
            firstname: "",
            lastname: "",
        },
        mode: "onChange",
        reValidateMode: "onChange",
    });

    return (
        <BaseModal isOpen={props.isOpen} toggle={props.toggle}>
            <Form onSubmit={newUserForm.handleSubmit(props.toggle)}>
                <Row className="gy-2" md={12}>
                    <Col md={6}>
                        <FormInput
                            className="mb-3"
                            control={newUserForm.control}
                            title="First Name"
                            name="firstname"
                            type="text"
                            rules={{ maxLength: 240, required: true }}
                        />
                    </Col>
                    <Col md={6}>
                        <FormInput
                            className="mb-3"
                            control={newUserForm.control}
                            title="Last Name"
                            name="lastname"
                            type="text"
                            rules={{ maxLength: 240, required: true }}
                        />
                    </Col>
                    <Col md={6}>
                        <FormInput
                            className="mb-3"
                            control={newUserForm.control}
                            title="Email"
                            name="email"
                            type="email"
                            rules={{ maxLength: 240, required: true }}
                        />
                    </Col>
                    <Col md={6}>
                        <FormInput
                            className="mb-3"
                            control={newUserForm.control}
                            title="Password"
                            name="password"
                            type="password"
                            rules={{ maxLength: 240, required: true }}
                        />
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col>
                        <Button
                            type="submit"
                            color="danger"
                            disabled={!newUserForm.formState.isValid}
                        >
                            Create user
                        </Button>
                    </Col>
                </Row>
            </Form>
        </BaseModal>
    );
}
