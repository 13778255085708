import { gql } from "@apollo/client";

export const USER_FRAGMENT = gql`
    fragment User on User {
        guid
        firstname
        lastname
        email
        canDoWizardry
        isDisabled
    }
`;

export const AUTH_USER_FRAGMENT = gql`
    fragment AuthUser on AuthResult {
        guid
        firstname
        lastname
        email
        canDoWizardry
        permissions {
            gameId
            permissions
        }
    }
`;
