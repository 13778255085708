import { ComponentProps, lazy, Suspense } from "react";
import { SuspenseFallback } from "@/components/SuspenseFallback";

const ScriptEditorLazy = lazy(async () => {
    const { ScriptEditor } = await import("./ScriptEditor");
    return { default: ScriptEditor };
});

export function ScriptEditor(props: ComponentProps<typeof ScriptEditorLazy>) {
    return (
        <Suspense fallback={<SuspenseFallback />}>
            <ScriptEditorLazy {...props} />
        </Suspense>
    );
}
