import { DataType } from "@/gql";
import { createDatatype } from "../../createDatatype";
import { InjectedRefFieldComponent } from "./InjectedRefFieldComponent";
import { InjectedRefContextActions } from "./InjectedRefContextActions";
import { InjectedRefReadonlyComponent } from "./InjectedRefReadonlyComponent";

export const injectedRefField = createDatatype<string | null>({
    type: DataType.InjectedRef,
    defaultValue: () => null,
    inputComponent: InjectedRefFieldComponent,
    readonlyComponent: InjectedRefReadonlyComponent,
    contextActions: InjectedRefContextActions,
    tableCellDisplayOptions: {
        minWidth: "450px",
        maxWidth: "750px",
    },
});
