import { gql } from "@apollo/client";
import { makeGqlMutationHook } from "@/graphql/util";
import { UpdateDeployDirectoryMutation, UpdateDeployDirectoryMutationVariables } from "@/gql";
import { DEPLOY_DIRECTORY_FRAGMENT } from "../fragments/deploy-directory.fragment";

export const useUpdateDeployDirectoryMutation = makeGqlMutationHook<
    UpdateDeployDirectoryMutation,
    UpdateDeployDirectoryMutationVariables
>(gql`
    ${DEPLOY_DIRECTORY_FRAGMENT}

    mutation updateDeployDirectory(
        $gameId: String!
        $guid: String!
        $name: String!
        $path: String!
        $description: String!
        $variant: Variant
    ) {
        updateDeployDirectory(
            gameId: $gameId
            guid: $guid
            name: $name
            path: $path
            description: $description
            variant: $variant
        ) {
            ...DeployDirectory
        }
    }
`);
