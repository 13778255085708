import { gql } from "@apollo/client";
import { RemoveUserPermissionsMutation, RemoveUserPermissionsMutationVariables } from "@/gql";
import { makeGqlMutationHook } from "../util";

const REMOVE_USER_PERMISSIONS = gql`
    mutation removeUserPermissions($gameId: String!, $userId: String!) {
        result: removeUserPermissions(gameId: $gameId, userId: $userId) {
            isOk
            info
        }
    }
`;

export const useRemoveUserPermissionsMutation = makeGqlMutationHook<
    RemoveUserPermissionsMutation,
    RemoveUserPermissionsMutationVariables
>(REMOVE_USER_PERMISSIONS);
