import { gql } from "@apollo/client";
import { CreateTemplateParamMutation, CreateTemplateParamMutationVariables } from "@/gql";
import { TEMPLATE_PARAM_DETAILED_FRAGMENT } from "../fragments/template-param.fragment";
import { makeGqlMutationHook } from "../util";

const CREATE_TEMPLATE_PARAM = gql`
    ${TEMPLATE_PARAM_DETAILED_FRAGMENT}

    mutation createTemplateParam(
        $consumers: [String!]! = []
        $description: String!
        $gameId: String!
        $isGlobal: Boolean = false
        $meta: JSON!
        $name: String!
        $subType: DataType
        $templateId: String!
        $type: DataType!
    ) {
        result: createTemplateParam(
            consumers: $consumers
            description: $description
            gameId: $gameId
            isGlobal: $isGlobal
            meta: $meta
            name: $name
            subType: $subType
            templateId: $templateId
            type: $type
        ) {
            ...TemplateParamDetailed
        }
    }
`;

export const useCreateTemplateParamMutation = makeGqlMutationHook<
    CreateTemplateParamMutation,
    CreateTemplateParamMutationVariables
>(CREATE_TEMPLATE_PARAM);
