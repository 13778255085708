import { useCallback } from "react";
import NiceModal, { bootstrapDialog, useModal } from "@ebay/nice-modal-react";
import Modal from "react-bootstrap/Modal";
import { useController, useForm } from "react-hook-form";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

type FormData = {
    copies: number;
};

function CloneEntitiesModalComponent() {
    const modal = useModal();
    const dialog = bootstrapDialog(modal);
    const form = useForm<FormData>({
        defaultValues: {
            copies: 1,
        },
        mode: "all",
    });
    const controller = useController({
        control: form.control,
        name: "copies",
        rules: {
            min: 0,
            max: 10,
        },
    });
    const onSubmit = useCallback(
        (data: FormData) => {
            modal.resolve(data);
            modal.hide();
        },
        [modal],
    );
    const onReject = useCallback(() => {
        modal.reject();
        modal.hide();
    }, [modal]);

    return (
        <Modal {...dialog}>
            <Modal.Body>
                <Form onSubmit={form.handleSubmit(onSubmit)}>
                    <Row className="mb-4">
                        <Col md={12}>
                            <Form.Group>
                                <Row>
                                    <Col>
                                        <Form.Label>How many copies to make?</Form.Label>
                                    </Col>
                                </Row>
                                <Row className="align-items-center">
                                    <Col md={9}>
                                        <Form.Range {...controller.field} min={1} max={5} />
                                    </Col>
                                    <Col md={3}>
                                        <Form.Control
                                            type="number"
                                            {...controller.field}
                                            min={1}
                                            max={10}
                                        />
                                    </Col>
                                </Row>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <Button disabled={!form.formState.isValid} type="submit">
                                Done
                            </Button>
                            <Button onClick={onReject} variant="danger">
                                Cancel
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
        </Modal>
    );
}

const CloneEntitiesModal = NiceModal.create(CloneEntitiesModalComponent);

export function showCloneEntitiesModal(): Promise<FormData> {
    return NiceModal.show(CloneEntitiesModal);
}
