import Badge from "react-bootstrap/Badge";
import FormText from "react-bootstrap/FormText";
import { AllowedListSubType } from "@/types";
import { DataType } from "@/gql";
import { FormGroup } from "@/fields/components/FormGroup";
import { useEditEntityFormController } from "@/components/forms/EditEntityForm/hooks/useEditEntityFormController";
import { FormFieldProps } from "../../types";
import { ListEditor } from "./ListEditor";

export function ListFormField(props: FormFieldProps) {
    const subType = props.templateParameter.subType as AllowedListSubType;
    const templateId = props.templateParameter.meta.templateId;
    const { field, fieldState } = useEditEntityFormController(props, "value");

    if (subType === DataType.EntityRef && !templateId) {
        return <Badge bg="danger">Missing reference template</Badge>;
    }

    return (
        <FormGroup {...props} fieldState={fieldState}>
            <ListEditor
                readonly={Boolean(props.readonly)}
                gameId={props.gameId}
                templateParam={props.templateParameter}
                {...field}
            />
            {fieldState.error && (
                <FormText className="text-danger">{fieldState.error.message}</FormText>
            )}
        </FormGroup>
    );
}
