import { useCallback } from "react";
import { useCreateTemplateAction } from "@/store/games/actions";
import { useAppSelector } from "@/hooks";
import { Template } from "@/gql";
import { gamesSliceSelector } from "@/store/games/selectors";
import { CreateTemplateForm, CreateTemplateFormData } from "../forms/CreateTemplateForm";
import { BaseModal, BaseModalProps } from "./BaseModal";

interface CreateTemplateModalProps extends BaseModalProps<Template> {
    gameId: string;
}

export function CreateTemplateModal(props: CreateTemplateModalProps) {
    const { isLoading } = useAppSelector(gamesSliceSelector);
    const [createTemplateAction, { error, loading }] = useCreateTemplateAction();
    const createTemplate = useCallback(
        async (data: CreateTemplateFormData) => {
            const newTemplate = await createTemplateAction({
                ...data,
                gameId: props.gameId,
            });
            props.toggle(newTemplate);
        },
        [createTemplateAction, props],
    );

    return (
        <BaseModal
            isOpen={props.isOpen}
            toggle={props.toggle}
            size="lg"
            backdrop
            keyboard
            title="Create template"
        >
            <CreateTemplateForm
                onSubmit={createTemplate}
                isLoading={isLoading || loading}
                error={error}
            />
        </BaseModal>
    );
}
