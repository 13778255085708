import { ChangeEventHandler, useCallback } from "react";
import Form from "react-bootstrap/Form";
import FormText from "react-bootstrap/FormText";
import { FormFieldProps } from "@/fields/types";
import { FormGroup } from "@/fields/components/FormGroup";
import { useEditEntityFormController } from "@/components/forms/EditEntityForm/hooks/useEditEntityFormController";

export function BooleanFormField(props: FormFieldProps) {
    const { field, fieldState } = useEditEntityFormController(props, "value");
    const onChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
        (event) => {
            return field.onChange(String(event.target.checked));
        },
        [field],
    );

    return (
        <FormGroup {...props} fieldState={fieldState}>
            <Form.Check
                name={field.name}
                onChange={onChange}
                onBlur={field.onBlur}
                ref={field.ref}
                checked={String(field.value) === "true"}
                type="switch"
            />
            {fieldState.error && (
                <FormText className="text-danger">{fieldState.error.message}</FormText>
            )}
        </FormGroup>
    );
}
