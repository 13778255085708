import { useCallback, useMemo } from "react";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import { createColumnHelper } from "@tanstack/react-table";
import { FaClone, FaEdit, FaTrash } from "react-icons/fa";
import Stack from "react-bootstrap/Stack";
import { useHasPermissions } from "@/components/rbac";
import { ConsumerPopulatedFragment, GetConsumersQuery, Permission } from "@/gql";
import { useCloneConsumerMutation, useRemoveConsumerMutation } from "@/graphql/consumers";
import { useShowCloneConsumerModal } from "@/components/modals2/CloneConsumerModal";
import { DataTable2 } from "@/components/common/DataTable2";
import { ConfirmationButton } from "../../ConfirmationButton";
import { useEditConsumerModal } from "../../../modals/EditConsumerModal";

interface ConsumersTableProps {
    gameId: string;
    consumers: GetConsumersQuery["result"];
    onRefetch: () => void;
}

const columnHelper = createColumnHelper<GetConsumersQuery["result"][number]>();

export function ConsumersTable(props: ConsumersTableProps) {
    const { gameId, consumers, onRefetch } = props;
    const canEdit = useHasPermissions([Permission.StructureWrite]);
    const [removeConsumerMutation, { loading: removeLoading }] = useRemoveConsumerMutation();
    const [cloneConsumerMutation, { loading: cloneLoading }] = useCloneConsumerMutation();
    const isLoading = removeLoading || cloneLoading;
    const editConsumerModal = useEditConsumerModal();
    const showCloneConsumerModal = useShowCloneConsumerModal();

    const removeConsumerAction = useCallback(
        async (guid: string) => {
            await removeConsumerMutation({ variables: { guid, gameId } });
            await onRefetch();
        },
        [removeConsumerMutation, gameId, onRefetch],
    );

    const onCloneConsumer = useCallback(
        async (consumer: ConsumerPopulatedFragment) => {
            try {
                const data = await showCloneConsumerModal({
                    guid: consumer.guid,
                    name: consumer.name,
                    description: consumer.description,
                });
                await cloneConsumerMutation({
                    variables: {
                        name: data.name,
                        description: data.description,
                        guid: data.guid,
                        gameId,
                    },
                });
                return onRefetch();
            } catch (error) {
                console.error(error);
            }
        },
        [cloneConsumerMutation, gameId, onRefetch, showCloneConsumerModal],
    );

    const onEditConsumer = useCallback(
        async (consumerId?: string) => {
            await editConsumerModal({ consumerId, gameId });
            return onRefetch();
        },
        [editConsumerModal, gameId, onRefetch],
    );

    const columns = useMemo(
        () => [
            columnHelper.accessor("name", { minSize: 200, header: "Name" }),
            columnHelper.accessor("guid", {
                header: "Guid",
                minSize: 360,
                cell: (props) => <span className="font-monospace">{props.getValue()}</span>,
            }),
            columnHelper.accessor("description", { minSize: 300, header: "Description" }),
            columnHelper.accessor("templatesAssigned", { header: "# of templates assigned" }),
            columnHelper.accessor("templateParamsAssigned", {
                header: "# of template parameters assigned",
            }),
            columnHelper.accessor("guid", {
                header: "Actions",
                maxSize: 100,
                cell: (props) => (
                    <ButtonGroup size="sm">
                        <Button
                            variant="primary"
                            onClick={() => onEditConsumer(props.getValue())}
                            disabled={isLoading || !canEdit}
                            title="Edit"
                        >
                            <FaEdit />
                        </Button>
                        <ConfirmationButton
                            prompt="Are you sure you want to clone this consumer"
                            variant="primary"
                            disabled={isLoading}
                            onConfirm={() => onCloneConsumer(props.row.original)}
                            title="Clone"
                        >
                            <FaClone />
                        </ConfirmationButton>
                        <ConfirmationButton
                            prompt="Please confirm deletion"
                            variant="danger"
                            disabled={consumers.length === 1 || isLoading}
                            onConfirm={() => removeConsumerAction(props.getValue())}
                            confirmationPhrase={props.row.original.name}
                            title="Remove"
                        >
                            <FaTrash />
                        </ConfirmationButton>
                    </ButtonGroup>
                ),
            }),
        ],
        [
            canEdit,
            consumers.length,
            isLoading,
            onCloneConsumer,
            onEditConsumer,
            removeConsumerAction,
        ],
    );

    return (
        <Stack gap={2}>
            {canEdit && (
                <div>
                    <ButtonGroup>
                        <Button onClick={() => onEditConsumer()}>Create</Button>
                    </ButtonGroup>
                </div>
            )}
            <DataTable2 data={consumers} columns={columns} />
        </Stack>
    );
}
