import { type ChangeEvent, useCallback, useMemo, useState } from "react";
import { useDebounceCallback, useLocalStorage } from "usehooks-ts";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { ColumnPinningState, SortingState, VisibilityState } from "@tanstack/react-table";
import stubTrue from "lodash/stubTrue";
import { useHasPermissions } from "@/components/rbac";
import { Permission, Template, TemplateType } from "@/gql";
import { usePagination } from "@/components/common/Table/EntitiesTable/hooks/usePagination";
import { LocalizationProvider } from "@/components/common/Table/EntitiesTable/contexts/LocalizationProvider";
import { showEditEntityModal } from "@/components/modals2/EditEntityModal";
import { DataTable2 } from "@/components/common/DataTable2";
import { OperableEntityTableData } from "@/types";
import { TablePagination } from "@/components/common/DataTable2/TablePagination";
import { useGetEntitiesV2Query } from "@/graphql/entities";
import { ConditionalCellStyles } from "@/components/common/DataTable2/types";
import { EditLockButton } from "@/components/common/Table/EntitiesTable/components/EditLockButton";
import { useAuth } from "@/hooks/useAuth";
import { useEntityCache } from "../../../entities/hooks/useEntityCache";

interface EntitiesTableProps {
    template: Template;
    readonly: boolean;
}

const PINNED_KEYS_LOCAL_STORAGE_KEY = "entities-table-pinned-columns";
const COLUMNS_ORDER_LOCAL_STORAGE_KEY = "entities-table-columns-order";
const VISIBLE_KEYS_LOCAL_STORAGE_KEY = "entities-table-visible-columns";
const SORTING_STATE_LOCAL_STORAGE_KEY = "entities-table-sorting-state";

// const paddingOffset = 16;

export function EntitiesTable(props: EntitiesTableProps) {
    // const tableContainer = useRef<HTMLDivElement | null>(null);
    // const [fixedHeaderScrollHeight, setFixedHeaderScrollHeight] = useState("100vh");
    const { user } = useAuth();
    const [query, setQuery] = useState("");
    const setFilterValue = useDebounceCallback((e: ChangeEvent<HTMLInputElement>) => {
        setQuery(e.target.value);
    }, 500);
    const [sorting, setSorting] = useLocalStorage<SortingState>(
        SORTING_STATE_LOCAL_STORAGE_KEY,
        [],
    );
    const [columnVisibility, setColumnVisibility] = useLocalStorage<VisibilityState>(
        VISIBLE_KEYS_LOCAL_STORAGE_KEY,
        {},
    );
    const [columnPinning, setColumnPinning] = useLocalStorage<ColumnPinningState>(
        PINNED_KEYS_LOCAL_STORAGE_KEY,
        {
            left: [],
            right: [],
        },
    );
    const [columnOrder, setColumnOrder] = useLocalStorage<string[]>(
        COLUMNS_ORDER_LOCAL_STORAGE_KEY,
        [],
    );
    const onFilter = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            setFilterValue(e);
            e.preventDefault();
            e.stopPropagation();
        },
        [setFilterValue],
    );
    const canEdit = useHasPermissions([Permission.DataWrite]);
    const { page, itemsPerPage, onNextPage, onPrevPage, onRowsPerPageChange, setPage } =
        usePagination(props.template);
    const skip = itemsPerPage * (page - 1);
    const take = itemsPerPage;
    const descending = Boolean(sorting.at(0)?.desc);
    const sortAttribute = sorting.at(0)?.id ?? null;
    const getEntities = useGetEntitiesV2Query({
        gameId: props.template.gameId,
        templateId: props.template.guid,
        skip,
        take,
        query,
        descending,
        sortAttribute,
    });
    const { columns, data, hasChanges, saveChanges, entityChangesMap } = useEntityCache({
        template: props.template,
        getEntities,
        readonly:
            !canEdit ||
            (Boolean(props.template.lockedBy) && props.template.lockedBy !== user?.guid),
        skip,
        take,
        sortAttribute,
        query,
        descending,
    });
    const canCreateTemplate =
        props.template.type === TemplateType.Document ||
        (props.template.type === TemplateType.Singleton && data.length === 0);

    const onCreateEntity = useCallback(async () => {
        await showEditEntityModal({
            gameId: props.template.gameId,
            templateId: props.template.guid,
        });
        await getEntities.refetch();
    }, [getEntities, props.template.gameId, props.template.guid]);
    const conditionalCellStyles = useMemo(
        (): ConditionalCellStyles<OperableEntityTableData> => [
            {
                when: (cell) => !cell.row.original.export,
                classNames: ["text-muted", "text-decoration-line-through"],
            },
            {
                when: stubTrue,
                classNames: ["user-select-none"],
            },
            {
                when: (cell) => {
                    return entityChangesMap[cell.row.original.guid]?.[cell.column.id] !== undefined;
                },
                classNames: ["fst-italic", "bg-success", "bg-opacity-25", "text-white"],
            },
        ],
        [entityChangesMap],
    );

    return (
        <LocalizationProvider gameId={props.template.gameId}>
            <div className="d-flex align-items-center gap-2 justify-content-between mb-2">
                <Form.Control defaultValue={query} placeholder="Filter" onChange={onFilter} />
                <EditLockButton />
                {canEdit && (
                    <Button
                        variant="success"
                        onClick={onCreateEntity}
                        disabled={!canCreateTemplate}
                        className="text-nowrap"
                    >
                        Create entity
                    </Button>
                )}
                {canEdit && (
                    <Button variant="primary" onClick={saveChanges} disabled={!hasChanges}>
                        Save
                    </Button>
                )}
            </div>
            <DataTable2<OperableEntityTableData>
                data={data}
                columns={columns}
                pinnedColumns
                loading={getEntities.loading}
                conditionalCellStyles={conditionalCellStyles}
                tableOptions={{
                    state: {
                        columnPinning,
                        columnVisibility,
                        columnOrder,
                        sorting,
                    },
                    enableSorting: true,
                    manualSorting: true,
                    columnResizeMode: "onChange",
                    onSortingChange: setSorting,
                    onColumnVisibilityChange: setColumnVisibility,
                    onColumnOrderChange: setColumnOrder,
                    onColumnPinningChange: setColumnPinning,
                }}
            />
            <TablePagination
                skip={skip}
                take={take}
                total={getEntities.data?.getTemplateEntitiesV2.total ?? 0}
                onPerPageChange={onRowsPerPageChange}
                setPage={setPage}
                onPrevPage={onPrevPage}
                onNextPage={onNextPage}
                perPage={itemsPerPage}
                canPrevPage={false}
                canNextPage={false}
            />
        </LocalizationProvider>
    );
}
