import { useCallback, useMemo } from "react";
import { useDebounceCallback } from "usehooks-ts";
import { ActionMeta, GroupBase, OnChangeValue, OptionsOrGroups } from "react-select";
import Badge from "react-bootstrap/Badge";
import Select from "react-select/async-creatable";
import isEmpty from "lodash/isEmpty";
import { entityTableDataToOperable } from "@/utils/entities";
import { useCreateComponent } from "@/hooks/useCreateComponent";
import { useLazyGetEntitiesQuery } from "@/fields/datatypes/EntityRef/useGetEntitiesQuery";
import { GetEntitiesForSelectQuery, TemplateType } from "@/gql";
import { EntityRefSelectorProps, ComponentOption } from "./types";
import { selectStyles, selectComponents } from "./constants";

export function EntityRefSelectorInner(
    props: EntityRefSelectorProps & {
        data: GetEntitiesForSelectQuery;
    },
) {
    const { data, templateId, gameId, name, value, onChange, clearable = false } = props;
    const { entities, entity, template } = data;
    const valueIsMissing = !isEmpty(value) && !entity;
    const [lazyGetEntitiesQuery] = useLazyGetEntitiesQuery();
    const onCreateOption = useCreateComponent({
        gameId,
        templateId,
        onChange,
    });
    const options = useMemo<ComponentOption[]>(() => {
        if (!entities) return [];

        return entities.entities.map((e) => ({ ...entityTableDataToOperable(e), templateId }));
    }, [entities, templateId]);
    const defaultValue = useMemo<ComponentOption | undefined>(
        () =>
            entity
                ? { ...entityTableDataToOperable(entity), templateId }
                : {
                      templateId,
                      gameId,
                      guid: value,
                      enabledState: {},
                      values: {},
                      export: true,
                  },
        [entity, gameId, templateId, value],
    );
    const isValidNewOption = useCallback(() => {
        return (
            Boolean(props.creatable) &&
            template !== undefined &&
            template.type === TemplateType.InjectedComponent
        );
    }, [props.creatable, template]);
    const getNewOptionData = useCallback(
        (): ComponentOption => ({
            guid: "__new",
            gameId,
            templateId,
            enabledState: {},
            export: true,
            values: [],
        }),
        [gameId, templateId],
    );
    const onFilterDebounced = useDebounceCallback(
        (
            query: string,
            callback: (
                options: OptionsOrGroups<ComponentOption, GroupBase<ComponentOption>>,
            ) => void,
        ) => {
            lazyGetEntitiesQuery({
                variables: {
                    templateId,
                    gameId,
                    guid: valueIsMissing || isEmpty(value) ? null : props.value,
                    query,
                    take: 10,
                },
            }).then((result) =>
                callback(
                    result.data?.entities.entities.map((e) => ({
                        ...entityTableDataToOperable(e),
                        templateId,
                    })) ?? [],
                ),
            );
        },
        300,
    );

    const onSelectChange = useCallback(
        (
            newValue: OnChangeValue<ComponentOption, false>,
            actionMeta: ActionMeta<ComponentOption>,
        ) => {
            if (actionMeta.action === "create-option") {
                return onCreateOption();
            }
            if (actionMeta.action === "clear") {
                return onChange("");
            }
            if (newValue === null) return;
            onChange(newValue.guid ?? "");
        },
        [onChange, onCreateOption],
    );

    if (!templateId) {
        return <Badge bg="danger">Missing templateId</Badge>;
    }

    return (
        <Select<ComponentOption, false>
            createOptionPosition="first"
            isValidNewOption={isValidNewOption}
            getNewOptionData={getNewOptionData}
            className={props.className}
            isClearable={clearable}
            defaultValue={defaultValue}
            value={defaultValue}
            name={name}
            defaultOptions
            loadOptions={onFilterDebounced}
            menuPortalTarget={document.body}
            styles={selectStyles}
            onCreateOption={onCreateOption}
            options={options}
            components={selectComponents}
            onChange={onSelectChange}
        />
    );
}
