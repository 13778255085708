import { gql } from "@apollo/client";
import { makeGqlMutationHook } from "@/graphql/util";
import { FixEntitiesChangeHistoryMutation, FixEntitiesChangeHistoryMutationVariables } from "@/gql";

const SCHEMA = gql`
    mutation fixEntitiesChangeHistory {
        wizFixEntitiesChangeHistory {
            isOk
            info
        }
    }
`;

export const useFixEntitiesChangeHistory = makeGqlMutationHook<
    FixEntitiesChangeHistoryMutation,
    FixEntitiesChangeHistoryMutationVariables
>(SCHEMA);
