import { gql } from "@apollo/client";
import { makeGqlQueryHook, makeGqlQueryLazyHook } from "@/graphql/util";
import { TranslationsQuery, TranslationsQueryVariables } from "@/gql";

const SCHEMA = gql`
    query translations($gameId: String!, $query: String = "", $take: Float, $skip: Float) {
        translations(gameId: $gameId, query: $query, take: $take, skip: $skip) {
            guid
            gameId
            key
            description
            translationStrings {
                guid
                value
                localeId
            }
        }
        locales(gameId: $gameId) {
            guid
            gameId
            code
            name
            isBaseLocale
        }
        translationsTotal(gameId: $gameId, query: $query)
    }
`;

export const useGetTranslations = makeGqlQueryHook<TranslationsQuery, TranslationsQueryVariables>(
    SCHEMA,
);
export const useLazyGetTranslations = makeGqlQueryLazyHook<
    TranslationsQuery,
    TranslationsQueryVariables
>(SCHEMA, {
    fetchPolicy: "network-only",
});
