import Button from "react-bootstrap/Button";
import { FaUndo } from "react-icons/fa";
import { useFieldController } from "../hooks/useFieldController";
import { ContextActionsProps } from "../types";
import { useFieldsDefinitions } from "../hooks/useFieldsDefinitions";
import { useCellContext } from "../contexts/CellContext";
import { useEntitiesTableFormContext } from "../hooks/useEntitiesTableFormContext";
import { ActionsToolbar } from "./components";

export function TableCellContextActions(props: ContextActionsProps) {
    const { type } = useCellContext();
    const { getDefinition } = useFieldsDefinitions();
    const formContext = useEntitiesTableFormContext();
    const additionalActions = getDefinition(type).contextActions;
    const controller = useFieldController(props.name);

    return (
        <ActionsToolbar vertical>
            {controller.fieldState.isDirty && (
                <Button size="sm" onClick={() => formContext.resetField(props.name)}>
                    <FaUndo />
                </Button>
            )}
            {typeof additionalActions === "function" && additionalActions(props)}
        </ActionsToolbar>
    );
}
