import { gql } from "@apollo/client";
import { USER_FRAGMENT } from "@/graphql/fragments/user.fragment";

export const USER_PERMISSIONS_FRAGMENT = gql`
    ${USER_FRAGMENT}

    fragment UserPermission on UserPermission {
        id
        gameId
        permissions
        userId
        isDisabled
        user {
            ...User
        }
    }
`;
