import { ReactNode, useMemo } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import styled from "styled-components";
import Button from "react-bootstrap/Button";
import { FaGripVertical, FaTimes } from "react-icons/fa";

const SquareButton = styled(Button)`
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
`;

type Props<T extends Record<string, any>> = {
    data: T;
    id: string;
    onRemove?: () => void;
    children: (data: T) => ReactNode;
};

export function TemplateViewSortableItem<T extends Record<string, any>>(props: Props<T>) {
    const { data, id, onRemove, children } = props;

    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
        id,
    });

    const style = useMemo(
        () => ({
            transform: CSS.Transform.toString(transform),
            transition,
        }),
        [transform, transition],
    );

    return (
        <div
            key={id}
            className="border rounded px-1 m-1 border-1 d-flex flex-nowrap align-items-center justify-content-between bg-gradient-secondary"
            ref={setNodeRef}
            style={style}
        >
            {typeof onRemove === "function" && (
                <SquareButton variant="link" className="p-0" onClick={onRemove}>
                    <FaTimes />
                </SquareButton>
            )}
            {children(data)}
            <Button
                as="span"
                variant="link"
                className="flex-grow-0 p-1"
                {...attributes}
                {...listeners}
            >
                <FaGripVertical />
            </Button>
        </div>
    );
}
