import { useCallback, useState } from "react";

export function useToggle(initialValue = false) {
    const [state, setState] = useState(() => initialValue);
    const toggleOn = useCallback(() => setState(true), []);
    const toggleOff = useCallback(() => setState(false), []);
    const toggle = useCallback(() => setState((prevState) => !prevState), []);

    return {
        state,
        toggleOn,
        toggleOff,
        toggle,
    };
}
