import { gql } from "@apollo/client";
import { AuthenticateUserMutation, AuthenticateUserMutationVariables } from "@/gql";
import { makeGqlMutationHook } from "../util";
import { AUTH_USER_FRAGMENT } from "../fragments/user.fragment";

export const LOGIN = gql`
    ${AUTH_USER_FRAGMENT}

    mutation authenticateUser($email: String!, $password: String!) {
        result: login(email: $email, password: $password) {
            ...AuthUser
        }
    }
`;

export const useLoginMutation = makeGqlMutationHook<
    AuthenticateUserMutation,
    AuthenticateUserMutationVariables
>(LOGIN);
