import { gql } from "@apollo/client";
import { makeGqlMutationHook } from "@/graphql/util";
import { AssignDefaultCategoryMutation, AssignDefaultCategoryMutationVariables } from "@/gql";

const schema = gql`
    mutation assignDefaultCategory {
        wizAssignDefaultCategory {
            isOk
            info
        }
    }
`;

export const useAssignDefaultCategoryMutation = makeGqlMutationHook<
    AssignDefaultCategoryMutation,
    AssignDefaultCategoryMutationVariables
>(schema);
