import { Helmet } from "react-helmet-async";
import { createRoute } from "@tanstack/react-router";
import { EntitiesTable } from "@/components/common/Table/EntitiesTable";
import { Permission } from "@/gql";
import { useHasPermissions, withRBAC } from "@/components/rbac";
import { DataAwaiter2 } from "@/components/common/DataAwaiter2";
import { templatePageRoute } from "@/pages/template/Template";
import { useTemplateContext } from "@/pages/template/TemplateProvider";

const EntitiesList = withRBAC(
    function EntitiesList() {
        const template = useTemplateContext();
        const canWrite = useHasPermissions([Permission.DataWrite]);

        if (!template) return null;

        return (
            <>
                <Helmet title="Entities" />
                <DataAwaiter2 {...template}>
                    {(data) => <EntitiesTable template={data.template} readonly={!canWrite} />}
                </DataAwaiter2>
            </>
        );
    },
    {
        requiredPermissions: [Permission.DataRead, Permission.DataWrite],
        oneOf: true,
    },
);

export default EntitiesList;

export const entitiesListRoute = createRoute({
    getParentRoute: () => templatePageRoute,
    path: "entities",
    component: EntitiesList,
});
