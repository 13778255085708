import { FC } from "react";
import { useForm } from "react-hook-form";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { ApolloError } from "@apollo/client";
import { CreateGameMutationVariables } from "@/gql";
import { FormInput } from "../common/forms/form-inputs/FormInput";

interface CreateGameFormProps {
    isLoading: boolean;
    error: ApolloError | undefined;
    onSubmit: (data: CreateGameMutationVariables) => void;
}

export const CreateGameForm: FC<CreateGameFormProps> = ({ isLoading, onSubmit, error }) => {
    const { handleSubmit, control } = useForm<CreateGameMutationVariables>({
        defaultValues: {
            gameName: "",
            description: "",
        },
        mode: "onChange",
    });

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Row className="g-4">
                <Col md={12}>
                    <FormInput
                        control={control}
                        title="Game name"
                        name="gameName"
                        rules={{ required: true }}
                    />
                </Col>
                <Col md={12}>
                    <FormInput
                        control={control}
                        title="Game description"
                        name="description"
                        rules={{ required: true, max: 1024 }}
                    />
                </Col>
                <Col md={12}>
                    <Button type="submit" color="danger" disabled={isLoading}>
                        {isLoading ? <Spinner size="sm" animation="border" /> : "Create"}
                    </Button>
                </Col>
                {error ? (
                    <Col>
                        <Alert variant="danger">
                            <Alert.Heading>Failed to create game</Alert.Heading>
                            <p>{error.message}</p>
                        </Alert>
                    </Col>
                ) : null}
            </Row>
        </Form>
    );
};
