import { gql } from "@apollo/client";
import { makeGqlQueryHook, makeGqlQueryLazyHook } from "@/graphql/util";
import { GetTranslationQuery, GetTranslationQueryVariables } from "@/gql";

const SCHEMA = gql`
    query getTranslation($guid: String!, $gameId: String!) {
        translation(guid: $guid, gameId: $gameId) {
            guid
            gameId
            key
            description
            translationStrings {
                guid
                value
                localeId
            }
        }
    }
`;

export const useGetTranslation = makeGqlQueryHook<
    GetTranslationQuery,
    GetTranslationQueryVariables
>(SCHEMA, {
    fetchPolicy: "network-only",
});
export const useLazyGetTranslation = makeGqlQueryLazyHook<
    GetTranslationQuery,
    GetTranslationQueryVariables
>(SCHEMA, {
    fetchPolicy: "network-only",
});
