import {
    ApolloError,
    DocumentNode,
    LazyQueryResultTuple,
    MutationHookOptions,
    QueryHookOptions,
    OperationVariables,
    SubscriptionHookOptions,
    useLazyQuery,
    useMutation,
    useQuery,
    useSubscription,
} from "@apollo/client";
import { LazyQueryHookOptions, MutationTuple, QueryResult } from "@apollo/client/react/types/types";
import { Kind } from "graphql/language";
import { OperationDefinitionNode } from "graphql/language/ast";
import { useSnackbar } from "notistack";

function getErrorNameFromDocumentDef(
    documentNode: DocumentNode,
    fallback?: string,
): string | undefined {
    const definition = documentNode.definitions.findLast(
        (def) => def.kind === Kind.OPERATION_DEFINITION,
    );
    const operationDefinition = definition as OperationDefinitionNode | undefined;
    if (!operationDefinition?.name) return fallback;

    const operationName = operationDefinition.name.value;
    const operation = operationDefinition.operation;

    return `${operationName} ${operation} ended with error`;
}

function useHandleError(documentNode: DocumentNode) {
    const { enqueueSnackbar } = useSnackbar();
    const header = getErrorNameFromDocumentDef(documentNode) ?? "Graphql operation error";

    return function onError(error: ApolloError) {
        if (error.message === "Socket closed") return;
        if (error.message === "Unauthorized") throw error;

        enqueueSnackbar(`${header}: ${error.message}`, {
            variant: "error",
            autoHideDuration: 3000,
        });
    };
}

export function makeGqlSubscriptionHook<Result, Variables extends OperationVariables>(
    documentNode: DocumentNode,
    subscriptionOptions: SubscriptionHookOptions<Result, Variables> = {},
) {
    return function useGqlSubscription(options: SubscriptionHookOptions<Result, Variables> = {}) {
        const handleError = useHandleError(documentNode);
        return useSubscription<Result, Variables>(documentNode, {
            ...subscriptionOptions,
            ...options,
            onError: handleError,
        });
    };
}

export function makeGqlMutationHook<
    Result,
    Variables extends OperationVariables = Record<string, never>,
>(documentNode: DocumentNode) {
    return function useGqlMutation(
        options?: MutationHookOptions<Result, Variables>,
    ): MutationTuple<Result, Variables> {
        const handleError = useHandleError(documentNode);
        return useMutation<Result, Variables>(documentNode, {
            ...options,
            onError: handleError,
        });
    };
}

export function makeGqlQueryLazyHook<
    Result,
    Variables extends OperationVariables = Record<string, never>,
>(
    documentNode: DocumentNode,
    preOptions: Omit<LazyQueryHookOptions<Result, Variables>, "query" | "variables"> = {},
): (
    options?: Omit<LazyQueryHookOptions<Result, Variables>, "query">,
) => LazyQueryResultTuple<Result, Variables> {
    return function useGqlLazyQuery(options): LazyQueryResultTuple<Result, Variables> {
        const handleError = useHandleError(documentNode);
        return useLazyQuery<Result, Variables>(documentNode, {
            ...preOptions,
            ...options,
            onError: handleError,
        });
    };
}

export function makeGqlQueryHook<Result>(
    documentNode: DocumentNode,
    options?: Omit<QueryHookOptions<Result>, "query" | "variables">,
): () => QueryResult<Result>;
export function makeGqlQueryHook<Result, Variables extends OperationVariables>(
    documentNode: DocumentNode,
    options?: Omit<QueryHookOptions<Result, Variables>, "query" | "variables">,
): (variables: Variables) => QueryResult<Result, Variables>;
export function makeGqlQueryHook<Result, Variables extends OperationVariables>(
    documentNode: DocumentNode,
    options: Omit<QueryHookOptions<Result, Variables>, "query" | "variables"> = {},
): (variables: Variables) => QueryResult<Result, Variables> {
    return function useGqlQuery(variables) {
        const handleError = useHandleError(documentNode);
        return useQuery<Result, Variables>(documentNode, {
            ...options,
            variables,
            onError: handleError,
        });
    };
}
