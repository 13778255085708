import { DataType } from "@/gql";
import { DataTypeField } from "../../types";
import { AdditionalIntegerFields } from "./AdditionalParameterFields";
import { PrimitiveEditableTableCell } from "./PrimitiveEditableTableCell";
import { PrimitiveFieldInput } from "./PrimitiveFieldInput";

// строка как аргумент дженерика - это так и задумано
// конвертация в нужный тип происходит во время деплоя
export const IntegerDataType: DataTypeField<string> = {
    defaultValue: (templateParam) => {
        switch (templateParam.type) {
            case DataType.Integer:
                return "0";
            case DataType.Float:
                return "0.0";
            case DataType.String:
                return "";
        }
    },
    dataType: [DataType.Integer, DataType.Float, DataType.String],
    tableCell: PrimitiveEditableTableCell,
    formField: PrimitiveFieldInput,
    additionalParameterFields: AdditionalIntegerFields,
    tableCellDisplayOptions: (templateParam) => {
        if (templateParam.name === "_Title") return { size: 200 };
        if (templateParam.name === "_Description") return { size: 300 };
        return [DataType.String].includes(templateParam.type)
            ? {
                  minSize: 200,
                  maxSize: 400,
              }
            : {
                  minSize: 100,
                  maxSize: 150,
              };
    },
};
