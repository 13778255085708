import { useCallback, useMemo } from "react";
import { FormProvider } from "react-hook-form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import sortBy from "lodash/sortBy";
import { useSnackbar } from "notistack";
import { EntitiesSortOrder, Template, TemplateDisplay, TemplateParam } from "@/gql";
import { isPrimitiveNonBoolean } from "@/utils/isPrimitiveType";
import { FormReactSelect } from "@/components/common/forms/form-inputs/FormReactSelect";
import { useGetTemplateDisplay, useUpdateTemplateDisplay } from "@/graphql/templates";
import { entriesPerPageOptions as entriesPerPage } from "@/constants";
import { GenericSelectOption, useOptions } from "../hooks/useOptions";
import { useEditEntityForm } from "../hooks/useEditEntityForm";
import { UpdateTemplateDisplayMutationVariablesRaw } from "../types";
import { TemplateViewSettings } from "./TemplateViewSettings";
import { EditEntityFormDisplay } from "./EditEntityFormDisplay";

type Props = {
    gameId: string;
    template: Template;
    templateDisplay: TemplateDisplay;
};

export function TemplateDisplayPage(props: Props) {
    const { enqueueSnackbar } = useSnackbar();
    const getTemplateDisplay = useGetTemplateDisplay({
        gameId: props.gameId,
        templateId: props.template.guid,
    });
    const [updateTemplateDisplay, { error, loading }] = useUpdateTemplateDisplay();
    const { selectOptions: entriesPerPageOptions, defaultValue: defaultEntriesPerPage } =
        useOptions(entriesPerPage, props.templateDisplay.entitiesPerPage);
    const { selectOptions: sortOrderOption, defaultValue: defaultSortOrderOption } = useOptions(
        ["Asc", "Desc"],
        props.templateDisplay.defaultSortOrder,
    );
    const sortableAttributes = useMemo(() => {
        return props.template.templateParams?.filter(isPrimitiveNonBoolean) ?? [];
    }, [props.template]);
    const selectedSortableAttribute = useMemo(() => {
        return (
            props.template.templateParams?.find(
                (tp) => tp.guid === props.templateDisplay.defaultSortAttribute,
            ) ?? null
        );
    }, [props.template.templateParams, props.templateDisplay.defaultSortAttribute]);
    const editEntityFieldsSettings = useMemo(() => {
        return sortBy(props.template.templateParams, (tp) => tp.meta.display?.editEntityOrder).map(
            (tp) => ({
                width: tp.meta.display?.editEntityWidth ?? 3,
                field: tp.guid,
                name: tp.name,
            }),
        );
    }, [props.template.templateParams]);
    const form = useEditEntityForm({
        values: {
            gameId: props.gameId,
            templateId: props.templateDisplay.templateId,
            entitiesPerPage: defaultEntriesPerPage!,
            defaultSortAttribute: selectedSortableAttribute,
            defaultSortOrder: defaultSortOrderOption!,
            fieldsOrder: props.templateDisplay.fieldsOrder,
            refFieldsOrder: props.templateDisplay.refFieldsOrder,
            editEntityFieldsSettings: {
                showAsList: props.template.meta.listFieldsVerticallyInEditEntityModal,
                fields: editEntityFieldsSettings,
            },
        },
    });
    const canSubmit =
        loading || !form.formState.isDirty || (form.formState.isDirty && !form.formState.isValid);
    const [fieldsOrder, refFieldsOrder] = form.watch(["fieldsOrder", "refFieldsOrder"]);

    const onSubmit = useCallback(
        async (data: UpdateTemplateDisplayMutationVariablesRaw) => {
            try {
                const result = await updateTemplateDisplay({
                    variables: {
                        gameId: data.gameId,
                        templateId: data.templateId,
                        entitiesPerPage: +data.entitiesPerPage.value,
                        fieldsOrder: data.fieldsOrder,
                        refFieldsOrder: data.refFieldsOrder,
                        defaultSortOrder: data.defaultSortOrder.value as EntitiesSortOrder,
                        defaultSortAttribute: data.defaultSortAttribute?.guid ?? null,
                        editEntityModalSettings: {
                            showAsList: data.editEntityFieldsSettings.showAsList,
                            fields: data.editEntityFieldsSettings.fields.map(
                                ({ name, ...field }) => field,
                            ),
                        },
                    },
                });
                getTemplateDisplay.refetch();
            } catch (e: any) {
                enqueueSnackbar(`Error during save: ${e.message}`, {
                    variant: "error",
                    autoHideDuration: 3000,
                });
            }
        },
        [getTemplateDisplay, enqueueSnackbar, updateTemplateDisplay],
    );

    return (
        <Form onSubmit={form.handleSubmit(onSubmit)}>
            <FormProvider {...form}>
                <Row>
                    <Col md={1} className="offset-11">
                        <Button type="submit" disabled={canSubmit} className="w-100">
                            Save
                        </Button>
                    </Col>
                </Row>
                <Row className="my-2 px-2">
                    <Col md={12}>
                        <h4>Settings</h4>
                        <Row>
                            <Col md={2}>
                                <FormReactSelect<
                                    UpdateTemplateDisplayMutationVariablesRaw,
                                    GenericSelectOption,
                                    false
                                >
                                    name="entitiesPerPage"
                                    title="Entries per page"
                                    control={form.control}
                                    options={entriesPerPageOptions}
                                    getOptionLabel={(v) => v.label}
                                    getOptionValue={(v) => v.value}
                                />
                            </Col>
                            <Col md={3}>
                                <FormReactSelect<
                                    UpdateTemplateDisplayMutationVariablesRaw,
                                    TemplateParam,
                                    false
                                >
                                    name="defaultSortAttribute"
                                    title="Default sort attribute"
                                    control={form.control}
                                    options={sortableAttributes}
                                    required={sortableAttributes.length > 0}
                                    getOptionLabel={(v) => v.name}
                                    getOptionValue={(v) => v.guid}
                                />
                            </Col>
                            <Col md={3}>
                                <FormReactSelect<
                                    UpdateTemplateDisplayMutationVariablesRaw,
                                    GenericSelectOption,
                                    false
                                >
                                    title="Default sort order"
                                    name="defaultSortOrder"
                                    control={form.control}
                                    options={sortOrderOption}
                                    getOptionLabel={(v) => v.label}
                                    getOptionValue={(v) => v.value}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <hr className="border border-dark border-opacity-25" />
                <Tabs defaultActiveKey="view" className="mb-3">
                    <Tab eventKey="view" title="View">
                        <p>
                            This section allows to select or reorder columns{" "}
                            <strong>in entities table</strong>
                        </p>
                        <TemplateViewSettings
                            template={props.template}
                            templateDisplay={props.templateDisplay}
                            ids={fieldsOrder}
                            fieldName="fieldsOrder"
                        />
                    </Tab>
                    <Tab eventKey="ref-view" title="Reference view">
                        <p>
                            This section allows to select or reorder columns{" "}
                            <strong>in reference display</strong>
                        </p>
                        <TemplateViewSettings
                            template={props.template}
                            templateDisplay={props.templateDisplay}
                            ids={refFieldsOrder}
                            fieldName="refFieldsOrder"
                        />
                    </Tab>
                    <Tab eventKey="edit-entity-view" title="Edit entity view">
                        <p>
                            This section allows to select, reorder and resize fields{" "}
                            <strong>in edit entity modal display</strong>
                        </p>
                        <EditEntityFormDisplay />
                    </Tab>
                </Tabs>
                {error && (
                    <Row>
                        <Alert variant="danger">
                            <Alert.Heading>Error during save</Alert.Heading>
                            <p>{error.message}</p>
                        </Alert>
                    </Row>
                )}
            </FormProvider>
        </Form>
    );
}
