import { DataType } from "@/gql";
import { createDatatype } from "../../createDatatype";
import { PrimitiveFieldComponent } from "./PrimitiveFieldComponent";
import { PrimitivePlaceholder } from "./PrimitivePlaceholder";

export const primitiveField = createDatatype<string>({
    type: [DataType.Integer, DataType.Float, DataType.String],
    defaultValue: (templateParam) => {
        if (!templateParam) return "";
        switch (templateParam.type) {
            case DataType.Integer:
                return "0";
            case DataType.Float:
                return "0.0";
            case DataType.String:
            case DataType.LocalizedString:
            default:
                return "";
        }
    },
    inputComponent: PrimitiveFieldComponent,
    placeholderComponent: PrimitivePlaceholder,
    sortFunction: (templateParam) => (rowA, rowB) => {
        const sortAttribute = templateParam.guid;

        if (!sortAttribute) return 0;

        return rowB.values[sortAttribute] > rowA.values[sortAttribute] ? 1 : -1;
    },
    tableCellDisplayOptions: (templateParam) =>
        [DataType.String, DataType.LocalizedString].includes(templateParam.type)
            ? {
                  minWidth: "200px",
                  maxWidth: "400px",
              }
            : {
                  minWidth: "100px",
                  maxWidth: "150px",
              },
});
