import { gql } from "@apollo/client";
import { makeGqlQueryHook } from "@/graphql/util";
import { GlobalParametersQuery, GlobalParametersQueryVariables } from "@/gql";
import { TEMPLATE_PARAM_FRAGMENT } from "@/graphql/fragments/template-param.fragment";

const SCHEMA = gql`
    ${TEMPLATE_PARAM_FRAGMENT}

    query globalParameters($gameId: String!, $type: DataType) {
        globalParameters(gameId: $gameId, type: $type) {
            ...TemplateParam
        }
    }
`;

export const useGlobalParameters = makeGqlQueryHook<
    GlobalParametersQuery,
    GlobalParametersQueryVariables
>(SCHEMA, {
    fetchPolicy: "network-only",
});
