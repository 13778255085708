import { createContext, PropsWithChildren, useContext, useMemo } from "react";

type NestingContextProps = {
    depth: number;
    maxDepth: number;
    shouldExpand: boolean;
};

const MAX_NESTING_DEPTH = 3;

export const NestingContext = createContext<NestingContextProps>({
    depth: 0,
    maxDepth: MAX_NESTING_DEPTH,
    shouldExpand: false,
});

export function useNestingContext() {
    return useContext(NestingContext);
}

export function NestingContextProvider(
    props: PropsWithChildren<{ shouldExpand?: boolean; depth?: number }>,
) {
    const { depth: parentDepth, shouldExpand } = useNestingContext();
    const value = useMemo<NestingContextProps>(
        () => ({
            maxDepth: MAX_NESTING_DEPTH,
            depth: parentDepth + 1,
            shouldExpand: shouldExpand || Boolean(props.shouldExpand),
        }),
        [parentDepth, props.shouldExpand, shouldExpand],
    );

    return <NestingContext.Provider value={value}>{props.children}</NestingContext.Provider>;
}
