import { useCallback, useMemo } from "react";
import { TableColumn } from "react-data-table-component";
import Badge from "react-bootstrap/Badge";
import { FaTimes } from "react-icons/fa";
import { type MultiValue } from "react-select";
import Form from "react-bootstrap/Form";
import { AppDataTable } from "@/components/common/DataTable";
import { GameDetailsFragment, Permission } from "@/gql";
import {
    useRemoveUserPermissionsAction,
    useSetPermissionsDisabledStateAction,
    useSetUserPermissionsAction,
} from "@/store/games/actions";
import { useToggle } from "@/hooks";
import { ConfirmationButton } from "@/components/common/ConfirmationButton";
import { permissionsList } from "@/components/roles-page/constants";
import { PermissionOption } from "@/components/roles-page/types";
import { RolesSelect } from "@/components/common/RolesSelect";
import { booleanCell } from "@/components/common/DataTable/datatype-cells/boolean";
import { useAuth } from "@/hooks/useAuth";

type UserPermission = GameDetailsFragment["userPermissions"][0];

const rolesOrder = [
    Permission.DataRead,
    Permission.StructureRead,
    Permission.DataWrite,
    Permission.StructureWrite,
    Permission.CanDeploy,
    Permission.RolesChange,
].reduce((acc, role, index) => ({ ...acc, [role]: index }), {} as Record<Permission, number>);

interface UsersTableProps {
    creatorId: string;
    userPermissions: UserPermission[];
    gameId: string;
    currentUserId: string;
}

export function UsersTable(props: UsersTableProps) {
    const { user } = useAuth();
    const { gameId } = props;
    const [setUserPermissionsAction] = useSetUserPermissionsAction();
    const [removeUserPermissionsAction] = useRemoveUserPermissionsAction();
    const [setPermissionsDisabledStateAction] = useSetPermissionsDisabledStateAction();
    const { state: changeInProgress, toggleOn: startLoading, toggleOff: stopLoading } = useToggle();
    const userAvailablePermissions = useMemo(() => {
        return permissionsList.filter((p) => p.value === Permission.RolesChange);
    }, []);
    const onRemovePermission = useCallback(
        async (userId: string) => {
            await removeUserPermissionsAction({ userId, gameId });
        },
        [gameId, removeUserPermissionsAction],
    );
    const onPermissionsChange = useCallback(
        async (value: MultiValue<PermissionOption>, userId: string) => {
            await setUserPermissionsAction({
                userId,
                gameId,
                permissions: value.map((v) => v.value),
            });
        },
        [gameId, setUserPermissionsAction],
    );
    const onDisabledChange = useCallback(
        async (userId: string, newDisabledState: boolean) => {
            try {
                startLoading();
                await setPermissionsDisabledStateAction({
                    userId,
                    gameId,
                    newDisabledState,
                });
            } catch (e: any) {
                console.error(e.message);
            } finally {
                stopLoading();
            }
        },
        [gameId, setPermissionsDisabledStateAction, startLoading, stopLoading],
    );
    const columns = useMemo<TableColumn<UserPermission>[]>(
        () => [
            {
                name: "Is disabled",
                cell: (row) =>
                    props.currentUserId !== row.userId && (
                        <Form.Switch
                            disabled={changeInProgress}
                            defaultChecked={row.isDisabled}
                            onChange={(event) => onDisabledChange(row.userId, event.target.checked)}
                        />
                    ),
                grow: 0,
                minWidth: "120px",
            },
            {
                name: "Wizard?",
                cell: booleanCell((row) => row.user!.canDoWizardry),
            },
            {
                name: "Email",
                cell: (row) =>
                    row.user ? (
                        <span>
                            {row.user.firstname} {row.user.lastname} &lt;{row.user.email}&gt;
                            {row.userId === props.creatorId && (
                                <Badge className="ms-1" bg="success">
                                    Creator
                                </Badge>
                            )}
                            {props.currentUserId === row.userId && (
                                <Badge className="ms-1" bg="info">
                                    You
                                </Badge>
                            )}
                        </span>
                    ) : null,
                grow: 1,
            },
            {
                name: "Permissions",
                grow: 3,
                cell: (row) => {
                    const options =
                        row.userId === props.currentUserId && !user?.canDoWizardry
                            ? userAvailablePermissions
                            : permissionsList;
                    const optionsSorted = options.sort(
                        (a, b) => rolesOrder[a.value] ?? 0 - rolesOrder[b.value] ?? 0,
                    );
                    return (
                        <RolesSelect
                            userId={row.userId}
                            value={row.permissions.map<PermissionOption>((permission) => ({
                                value: permission,
                                label: permission,
                                isDisabled:
                                    permission === Permission.RolesChange &&
                                    row.userId === props.currentUserId,
                            }))}
                            options={optionsSorted}
                            onChange={(newValue) => onPermissionsChange(newValue, row.userId)}
                        />
                    );
                },
            },
            {
                name: "Actions",
                cell: (row) =>
                    props.currentUserId !== row.userId &&
                    row.user && (
                        <ConfirmationButton
                            prompt="Are you sure you want to remove permissions for this user?"
                            onConfirm={() => onRemovePermission(row.user!.guid)}
                            size="sm"
                            variant="danger"
                            title="Remove user permissions"
                        >
                            <FaTimes size={14} className="align-middle" />
                        </ConfirmationButton>
                    ),
                grow: 0,
            },
        ],
        [
            changeInProgress,
            onDisabledChange,
            onPermissionsChange,
            onRemovePermission,
            props.creatorId,
            props.currentUserId,
            user?.canDoWizardry,
            userAvailablePermissions,
        ],
    );

    return <AppDataTable columns={columns} data={props.userPermissions} />;
}
