import { PropsWithChildren, useMemo } from "react";
import classNames from "classnames";
import { FaGripVertical, FaTimes } from "react-icons/fa";
import styled from "styled-components";
import Button from "react-bootstrap/Button";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

export interface Props<T = any> {
    id: T;
    className?: string;
    childClassName?: string;
    onRemove?: () => void;
}

const Card = styled.div`
    cursor: grab;
    transition: width 0.2s ease-in-out;
`;

export function MovableCard<T extends number | string>(props: PropsWithChildren<Props<T>>) {
    const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
        id: props.id,
    });
    const style = useMemo(
        () => ({
            transform: CSS.Transform.toString(transform),
            transition,
        }),
        [transform, transition],
    );

    return (
        <Card
            ref={setNodeRef}
            className={classNames(props.className, "align-items-stretch", {
                "opacity-25": isDragging,
            })}
            style={style}
        >
            <div
                className={classNames(
                    props.childClassName,
                    "h-100 d-flex flex-wrap w-100 justify-content-start",
                )}
            >
                <Button
                    as="span"
                    variant="link"
                    className="flex-grow-0 p-1"
                    {...attributes}
                    {...listeners}
                >
                    <FaGripVertical />
                </Button>
                {typeof props.onRemove === "function" && (
                    <Button
                        tabIndex={-1}
                        onClick={() => props.onRemove!()}
                        size="sm"
                        className="flex-grow-0"
                        variant="danger"
                    >
                        <FaTimes size={14} className="align-middle" />
                    </Button>
                )}
                {props.children}
            </div>
        </Card>
    );
}
