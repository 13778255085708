import styled from "styled-components";
import { DataTypeField } from "@/fields/types";
import { EntityRefRenderer } from "@/components/common/EntityRefRenderer";
import { DataType } from "@/gql";

const Wrapper = styled.div`
    > :before {
        content: "{";
        display: inline-block;
    }

    > :after {
        content: "}";
        display: inline-block;
    }
`;

export const ListDisplayField: DataTypeField<string[]>["fieldDisplay"] = function (props) {
    const { depth = 0 } = props;

    return (
        <div className="ps-2">
            <div>[</div>
            {(props.value ?? []).map((v) => (
                <Wrapper key={v}>
                    {props.templateParam.subType === DataType.EntityRef ||
                    props.templateParam.subType === DataType.InjectedRef ? (
                        <EntityRefRenderer
                            key={v}
                            templateId={props.templateParam.meta.templateId!}
                            entityId={v}
                            depth={depth + 1}
                        />
                    ) : (
                        <code key={v} className="text-primary bg-warning bg-opacity-10 p-1">
                            {JSON.stringify(v)}
                        </code>
                    )}
                </Wrapper>
            ))}
            <div>]</div>
        </div>
    );
};
