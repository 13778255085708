import { createContext, ReactNode, useContext } from "react";
import { QueryResult } from "@apollo/client";
import { useGetTemplate } from "@/graphql/templates";
import { GetTemplateQuery } from "@/gql";
import { DataAwaiter2 } from "@/components/common/DataAwaiter2";
import { templatePageRoute } from "./Template";

const TemplateContext = createContext<
    QueryResult<GetTemplateQuery, { guid: string; gameId: string }>
>(undefined!);

export function TemplateProvider(props: {
    children: (data: GetTemplateQuery) => ReactNode[] | ReactNode;
}) {
    const { useParams } = templatePageRoute;
    const { templateId, gameId } = useParams();
    const template = useGetTemplate({ gameId: gameId!, guid: templateId! });

    return (
        <TemplateContext.Provider value={template}>
            <DataAwaiter2 {...template}>{(data) => props.children(data)}</DataAwaiter2>
        </TemplateContext.Provider>
    );
}

export function useTemplateContext() {
    return useContext(TemplateContext);
}
