import { DatatypeDefinition } from "../types";
import { assetField } from "./asset";
import { entityRefField } from "./entityRef";
import { scriptField } from "./script";
import { primitiveField } from "./primitive";
import { booleanField } from "./boolean";
import { durationField } from "./duration";
import { timestampField } from "./timestamp";
import { enumField } from "./enum";
import { injectedRefField } from "./injectedRef";
import { listField } from "./list";
import { localizedStringField } from "./localizedString";

export const typeDefs: DatatypeDefinition<any>[] = [
    primitiveField,
    timestampField,
    durationField,
    booleanField,
    scriptField,
    enumField,
    entityRefField,
    injectedRefField,
    listField,
    assetField,
    localizedStringField,
];
