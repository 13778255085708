import styled from "styled-components";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import { TableCellWrapper } from "./TableCell.components";

export const ActionsToolbar = styled(ButtonGroup)`
    position: absolute;
    display: flex;
    visibility: hidden;
    left: 0;
    top: 0;
    transform: translateX(0);
    z-index: -1;
    will-change: transform;
    transition:
        transform 0.1s ease-in-out,
        z-index 0.1s ease-in-out;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
    background-color: transparent;

    ${TableCellWrapper}:hover & {
        transform: translateX(-100%);
        z-index: 1;
        visibility: visible;
    }
`;
