import { createRoute } from "@tanstack/react-router";
import { useGetLocales } from "@/graphql/translation";
import { DataAwaiter2 } from "@/components/common/DataAwaiter2";
import { LocalesTable } from "@/components/translation/LocalesTable";
import { translationPageLayoutRoute } from "@/pages/translation/TranslationPageLayout";

export default function LocalesPage() {
    const { gameId } = localesPageRoute.useParams();
    const getLocales = useGetLocales({ gameId: gameId! });

    return (
        <DataAwaiter2 {...getLocales}>
            {(data) => <LocalesTable gameId={gameId!} data={data} />}
        </DataAwaiter2>
    );
}

export const localesPageRoute = createRoute({
    getParentRoute: () => translationPageLayoutRoute,
    path: "locales",
    component: LocalesPage,
});
