import { gql } from "@apollo/client";
import { GetUserByIdQuery, GetUserByIdQueryVariables } from "@/gql";
import { makeGqlQueryHook } from "../util";
import { USER_FRAGMENT } from "../fragments/user.fragment";

const SCHEMA = gql`
    ${USER_FRAGMENT}

    query getUserById($userId: String!) {
        user: userById(userId: $userId) {
            ...User
        }
    }
`;

export const useGetUserById = makeGqlQueryHook<GetUserByIdQuery, GetUserByIdQueryVariables>(SCHEMA);
