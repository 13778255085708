import { gql } from "@apollo/client";
import { VERSIONS_FILE_FRAGMENT } from "@/graphql/fragments/versions-file.fragment";

export const ARTIFACT_FRAGMENT = gql`
    fragment Artifact on Artifact {
        guid
        description
        deployedAt
        gameId
        createdBy
        createdAt
        status
        buildLog
        isProtected
    }
`;

export const ARTIFACT_DETAILED_FRAGMENT = gql`
    ${ARTIFACT_FRAGMENT}
    ${VERSIONS_FILE_FRAGMENT}

    fragment ArtifactDetailed on Artifact {
        ...Artifact
        consumerArtifacts {
            guid
            gameId
            consumerId
            consumerName
            generationStatus
            artifactId
            deployFlags {
                name
                value
                description
            }
            dataFiles {
                id
                name
                version
                data
            }
            metadataFile
            versionsFile {
                ...VersionsFile
            }
        }
    }
`;
