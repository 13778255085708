import { useMemo } from "react";
import { FieldValues, UseFormReturn } from "react-hook-form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { NestedFormSelect } from "@/components/common/forms/form-inputs/FormSelect";
import { DataType, TemplateType } from "@/gql";
import { entityToSelectOption } from "@/utils/entityToSelectOption";
import { useAvailableTemplates } from "@/store/games/slices";
import { ConnectForm } from "@/components/common/forms/ConnectForm";
import { AdditionalParameterFields } from "../../types";

const availableDatatypes = new Set([
    DataType.Integer,
    DataType.Float,
    DataType.String,
    DataType.Boolean,
    DataType.EntityRef,
    DataType.InjectedRef,
]);

function AdditionalParameters(props: { methods: UseFormReturn<FieldValues, any> }) {
    const selectedSubtype = props.methods.watch("subType");
    const availableTemplates = useAvailableTemplates();
    const entityRefOptions = useMemo(
        () =>
            availableTemplates
                .filter((v) => {
                    if (selectedSubtype === DataType.InjectedRef)
                        return v.type === TemplateType.InjectedComponent;
                    if (selectedSubtype === DataType.EntityRef)
                        return v.type === TemplateType.Document;
                    return true;
                })
                .map(entityToSelectOption),
        [availableTemplates, selectedSubtype],
    );
    const availableParamTypes = useMemo(() => {
        return Object.entries(DataType)
            .filter(([, value]) => availableDatatypes.has(value))
            .map(([name, value]) => ({
                value,
                label: name,
            }));
    }, []);

    return (
        <Row className="gy-4">
            <Col xs={12} md={6}>
                <NestedFormSelect
                    name="subType"
                    title="Subtype"
                    options={availableParamTypes}
                    rules={{
                        onChange: (event) => {
                            props.methods.setValue("subType", event.target.value);
                        },
                    }}
                />
            </Col>
            <Col xs={12} md={6}>
                <NestedFormSelect
                    name="meta.templateId"
                    title="Referenced template id"
                    options={entityRefOptions}
                    disabled={
                        entityRefOptions.length < 1 ||
                        (selectedSubtype !== DataType.EntityRef &&
                            selectedSubtype !== DataType.InjectedRef)
                    }
                />
            </Col>
        </Row>
    );
}

export const ListAdditionalParameters: AdditionalParameterFields = () => {
    return <ConnectForm>{(methods) => <AdditionalParameters methods={methods} />}</ConnectForm>;
};
