import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import { History } from "@/gql";
import { CasedBadge } from "@/components/common/CasedBadge";
import { UserInfo } from "@/components/common/UserInfo";
import { DateDisplay } from "@/components/common/DateDisplay";
import { Diff } from "@/components/common/Diff";
import { useToggle } from "@/hooks";
import { CasedButton } from "@/components/common/CasedButton";
import { UserInfoAsync } from "@/components/common/UserInfoAsync";

type Props = {
    entry: History;
};

export function HistoryEntry(props: Props) {
    const { state: showDetails, toggle } = useToggle();

    return (
        <Container fluid className="border rounded-1 p-2">
            <div className="d-flex justify-content-between gap-2">
                <div className="w-25">
                    <CasedBadge>{props.entry.actionType}</CasedBadge>
                </div>
                <div className="flex-grow-1">
                    {props.entry.createdBy ? (
                        <UserInfoAsync userId={props.entry.createdBy} />
                    ) : (
                        "no user"
                    )}
                </div>
                <div>
                    <DateDisplay date={props.entry.createdAt} format="DD/MM/YYYY HH:mm:ss" />
                </div>
                <div className="d-flex justify-content-end">
                    <CasedButton size="sm" onClick={toggle}>
                        Toggle details
                    </CasedButton>
                </div>
            </div>
            {showDetails && (
                <Row className="mt-4">
                    <Col style={{ minHeight: "50vh" }}>
                        <Diff from={props.entry.revertPayload} to={props.entry.payload} />
                    </Col>
                </Row>
            )}
        </Container>
    );
}
