import { ChangeEventHandler, useCallback, useMemo, useState } from "react";
import classNames from "classnames";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import ListGroup from "react-bootstrap/ListGroup";
import Badge from "react-bootstrap/Badge";
import styled from "styled-components";
import { Variant } from "react-bootstrap/types";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Stack from "react-bootstrap/Stack";
import ToggleButton from "react-bootstrap/ToggleButton";
import { GoStar, GoStarFill } from "react-icons/go";
import { useDebounceCallback } from "usehooks-ts";
import {
    Artifact as ArtifactModel,
    ArtifactStatus,
    DeployDirectory,
    UpdateArtifactMutationVariables,
} from "@/gql";
import { ConfirmationButton } from "@/components/common/ConfirmationButton";
import { DateDisplay } from "@/components/common/DateDisplay";
import { DeployedVersions } from "@/components/deploy/DeployedVersions";
import { Collapse } from "@/components/common/Collapse";
import { DeployResults } from "@/components/common/Deploy/components/DeployResults";
import { UserInfoAsync } from "@/components/common/UserInfoAsync";

type Props = {
    deployLocked: boolean;
    artifact: ArtifactModel;
    deployDirectories: DeployDirectory[];
    onCreateDeployDirectory?: () => void;
    onUpdate?: (data: { isProtected: boolean; description: string }) => void;
    onDeploy?: (deployDirectoryId: string) => void;
    onDelete?: () => void;
};

const ArtifactCube = styled.div.attrs((props) => ({
    style: {
        backgroundColor: `#${props.color}`,
    },
}))`
    display: inline-block;
`;

const ButtonsSection = styled.div`
    width: 225px;
`;

export function Artifact(props: Props) {
    const [artifact, setArtifact] = useState(() => props.artifact);
    const isArtifactReady = artifact.status === ArtifactStatus.Done;
    const isFailedBuild = artifact.status === ArtifactStatus.Failed;
    const isDeployed = artifact.currentlyDeployed.length > 0;
    const [title, variant] = useMemo((): readonly [string, Variant] => {
        if (isFailedBuild) return ["Failed artifact", "danger"];
        if (isDeployed) return ["Deployed artifact", "success"];

        return ["Artifact", "info"];
    }, [isDeployed, isFailedBuild]);
    const deployButtons = useMemo(() => {
        if (props.deployDirectories.length === 0) {
            return (
                <p>
                    No deploy directories.
                    <Button
                        variant="link"
                        onClick={props.onCreateDeployDirectory}
                        className="d-inline p-0 text-lowercase"
                    >
                        Create one?
                    </Button>
                </p>
            );
        }
        return props.deployDirectories.map((deployDirectory) => {
            const isDeployed = artifact.currentlyDeployed.find((dd) => {
                return dd.guid === deployDirectory.guid;
            });
            const isButtonDisabled = isFailedBuild || props.deployLocked || !isArtifactReady;

            return (
                <ConfirmationButton
                    key={deployDirectory.guid}
                    disabled={isButtonDisabled}
                    variant={deployDirectory.variant.toLowerCase()}
                    prompt="Are you sure you want to deploy this version?"
                    onConfirm={() =>
                        typeof props.onDeploy === "function" && props.onDeploy(deployDirectory.guid)
                    }
                >
                    <span>{deployDirectory.name}: </span>
                    <span>
                        {isFailedBuild ? "Cannot deploy" : isDeployed ? "Redeploy" : "Deploy"}
                    </span>
                </ConfirmationButton>
            );
        });
    }, [artifact.currentlyDeployed, isArtifactReady, isFailedBuild, props]);
    const onChange = useDebounceCallback((data: UpdateArtifactMutationVariables) => {
        if (typeof props.onUpdate === "function") {
            props.onUpdate(data);
        }
    }, 500);
    const onDescriptionChange = useCallback<ChangeEventHandler<HTMLTextAreaElement>>(
        (event) => {
            setArtifact((prevState) => {
                const newState = { ...prevState, description: event.target.value };
                onChange(newState);
                return newState;
            });
        },
        [onChange],
    );
    const onProtectedChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
        (event) => {
            setArtifact((prevState) => {
                const newState = { ...prevState, isProtected: event.target.checked };
                onChange(newState);
                return newState;
            });
        },
        [onChange],
    );

    const artifactId = useMemo(() => {
        return artifact.guid.split("-").at(0);
    }, [artifact.guid]);

    return (
        <div
            className={classNames(
                "d-flex",
                "flex-wrap",
                "border",
                "border-2",
                "gap-2",
                "p-2",
                "rounded",
                {
                    "border-success": isDeployed,
                    "border-warning": isFailedBuild,
                },
            )}
        >
            <div className="flex-grow-1">
                <ListGroup variant="flush">
                    <ListGroup.Item className="p-1 d-flex align-middle">
                        <ToggleButton
                            id={`${artifact.guid}-${artifact.gameId}-isProtected`}
                            value={`${artifact.guid}-${artifact.gameId}-isProtected`}
                            checked={artifact.isProtected}
                            title={
                                artifact.isProtected
                                    ? "Protected artifact"
                                    : "Click to protect this artifact from cleanup"
                            }
                            variant="link"
                            type="checkbox"
                            size="lg"
                            className="p-0 px-1"
                            onChange={onProtectedChange}
                        >
                            {artifact.isProtected ? <GoStarFill /> : <GoStar />}
                        </ToggleButton>
                        <Badge bg={variant} className="fst-italic lh-1 fs-6">
                            {title}
                        </Badge>
                        &nbsp;
                        {artifactId && (
                            <ArtifactCube className="rounded-1" color={artifactId}>
                                #{artifactId}
                            </ArtifactCube>
                        )}
                    </ListGroup.Item>
                    <ListGroup.Item className="p-1">
                        <span className="fst-italic lh-1">built at</span>&nbsp;
                        <DateDisplay date={artifact.createdAt} />
                    </ListGroup.Item>
                    {artifact.createdBy && (
                        <ListGroup.Item className="p-1">
                            <span className="fst-italic lh-1">built by</span>&nbsp;
                            <UserInfoAsync userId={artifact.createdBy} />
                        </ListGroup.Item>
                    )}
                    <ListGroup.Item className="p-1">
                        <span className="fst-italic lh-1">Last deployed</span>&nbsp;
                        {artifact.deployedAt ? (
                            <DateDisplay date={artifact.deployedAt} format="DD MMM YYYY HH:mm:ss" />
                        ) : (
                            "Never"
                        )}
                    </ListGroup.Item>
                    <ListGroup.Item className="p-1">
                        <span className="fst-italic lh-1">Status</span>&nbsp;
                        {artifact.status}
                    </ListGroup.Item>
                </ListGroup>
            </div>
            <div className="w-25">
                <Stack gap={2}>
                    <Form.Group>
                        <Form.Label>Description</Form.Label>
                        <Form.Control
                            as="textarea"
                            cols={30}
                            defaultValue={artifact.description}
                            onChange={onDescriptionChange}
                        />
                    </Form.Group>
                </Stack>
            </div>
            <ButtonsSection>
                <ButtonGroup vertical className="w-100">
                    {deployButtons}
                    {typeof props.onDelete === "function" && (
                        <ConfirmationButton
                            disabled={
                                props.deployLocked || artifact.status === ArtifactStatus.Created
                            }
                            variant="danger"
                            prompt="Are you sure you want to delete this artifact?"
                            onConfirm={props.onDelete}
                        >
                            Delete
                        </ConfirmationButton>
                    )}
                </ButtonGroup>
            </ButtonsSection>
            {artifact.buildLog !== null && (
                <div className="w-100">
                    <Collapse label="Build log" className="p-1">
                        <DeployResults results={artifact.buildLog} />
                    </Collapse>
                </div>
            )}
            <div className="w-100">
                <Collapse label="Artifact details" className="p-1">
                    <DeployedVersions artifact={artifact} />
                </Collapse>
            </div>
        </div>
    );
}
