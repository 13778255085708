import { ComponentProps } from "react";
import { TableCell } from "@/fields/types";
import { useAddChangeRecord } from "@/components/common/Table/EntitiesTable/contexts/TableCellContext";
import { DisableValueBtn } from "@/fields/components/DisableValueBtn";

export function DisableEntityParameterBtn(
    props: ComponentProps<TableCell> & { className?: string },
) {
    const { disabled, setDisabled } = useAddChangeRecord(props);

    if (props.templateParam.meta.isRequired || props.templateParam.meta.isProtected) return null;

    return <DisableValueBtn onClick={setDisabled} value={disabled} className="me-1" />;
}
