import { type MultiValue, StylesConfig } from "react-select";
import { Select } from "@/components/common/Select";
import { PermissionOption } from "@/components/roles-page/types";

type Props = {
    userId: string;
    value: PermissionOption[];
    options: PermissionOption[];
    onChange: (value: MultiValue<PermissionOption>) => void;
};

const styles: StylesConfig<PermissionOption, true> = {
    control: (base) => {
        return {
            ...base,
            borderStyle: "none",
            padding: 0,
        };
    },
    valueContainer: (base) => {
        return { ...base, padding: 0 };
    },
    multiValue: (base, state) => {
        return state.data.isDisabled ? { ...base, backgroundColor: "gray" } : base;
    },
    multiValueLabel: (base, state) => {
        return state.data.isDisabled
            ? { ...base, fontWeight: "bold", color: "white", paddingRight: 6 }
            : base;
    },
    multiValueRemove: (base, state) => {
        return state.data.isDisabled ? { ...base, display: "none" } : base;
    },
};

export function RolesSelect(props: Props) {
    return (
        <Select
            isMulti={true}
            defaultValue={props.value}
            styles={styles}
            isClearable={false}
            options={props.options}
            onChange={(newValue) => props.onChange(newValue)}
        />
    );
}
