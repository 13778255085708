import { gql } from "@apollo/client";
import { makeGqlQueryHook, makeGqlQueryLazyHook } from "@/graphql/util";
import { GetEntitiesForSelectQuery, GetEntitiesForSelectQueryVariables } from "@/gql";
import { TEMPLATE_FRAGMENT } from "@/graphql/fragments/template.fragment";
import { TEMPLATE_PARAM_FRAGMENT } from "@/graphql/fragments/template-param.fragment";

const SCHEMA = gql`
    ${TEMPLATE_FRAGMENT}
    ${TEMPLATE_PARAM_FRAGMENT}

    query getEntitiesForSelect(
        $templateId: String!
        $gameId: String!
        $guid: String
        $take: Float!
        $query: String!
    ) {
        entity: entityV2(gameId: $gameId, guid: $guid) {
            guid
            gameId
            export
            values {
                parameterId
                shouldOmit
                value
            }
        }
        entities: getTemplateEntitiesV2(
            templateId: $templateId
            gameId: $gameId
            take: $take
            query: $query
        ) {
            total
            entities {
                guid
                gameId
                export
                values {
                    parameterId
                    shouldOmit
                    value
                }
            }
        }
        template(guid: $templateId, gameId: $gameId) {
            ...Template
            templateParams {
                ...TemplateParam
            }
        }
    }
`;

export const useGetEntitiesQuery = makeGqlQueryHook<
    GetEntitiesForSelectQuery,
    GetEntitiesForSelectQueryVariables
>(SCHEMA);
export const useLazyGetEntitiesQuery = makeGqlQueryLazyHook<
    GetEntitiesForSelectQuery,
    GetEntitiesForSelectQueryVariables
>(SCHEMA, { fetchPolicy: "network-only" });
