import { gql } from "@apollo/client";
import { JOB_FRAGMENT } from "@/graphql/fragments/job.fragment";
import { CloneGameMutation, CloneGameMutationVariables } from "@/gql";
import { makeGqlMutationHook } from "../util";

const CLONE_GAME = gql`
    ${JOB_FRAGMENT}

    mutation cloneGame($gameId: String!) {
        result: cloneGame(gameId: $gameId) {
            ...Job
        }
    }
`;

export const useCloneGameMutation = makeGqlMutationHook<
    CloneGameMutation,
    CloneGameMutationVariables
>(CLONE_GAME);
