import { startPage } from "@/pages/StartPage";
import { loginPageRoute } from "@/pages/LoginPage";
import { gamesRoute } from "@/pages/GamesPage";
import { profilePageRoute } from "@/pages/Profile";
import { wizardryRoute } from "@/pages/wizardry/WizardryLayout";
import { page403Route } from "@/pages/403";
import { wizardryGamesRoute } from "@/pages/wizardry/GamesWizardry";
import { wizardryUsersRoute } from "@/pages/wizardry/UsersWizardry";
import { pandorasBoxWizardry } from "@/pages/wizardry/PandorasBoxWizardry";
import { layoutWithBreadcrumbs } from "@/layouts/LayoutWithBreadcrumbs";
import { templatesListRoute } from "@/pages/TemplatesList";
import { entityPageRoute } from "@/pages/EntityPage";
import { importPageRoute } from "@/pages/ImportPage";
import { categoriesPageRoute } from "@/pages/CategoriesPage";
import { artifactRoute } from "@/pages/Artifacts";
import { artifactsListPage } from "@/pages/ArtifactsList";
import { artifactsHistoryRoute } from "@/pages/ArtifactsHistory";
import { artifactsDiffPage } from "@/pages/ArtifactsDiff";
import { projectHistory } from "@/pages/ProjectHistory";
import { projectSettings } from "@/pages/project-settings/ProjectSettings";
import { projectSettingsRoute } from "@/pages/project-settings/ProjectSettingsPage";
import { permissionsPage } from "@/pages/project-settings/PermissionsPage";
import { deployDirectoriesRoute } from "@/pages/project-settings/DeployDirectories";
import { consumersRoute } from "@/pages/Consumers";
import { assetsRoute } from "@/pages/Assets";
import { globalParamsRoute } from "@/pages/GlobalParams";
import { blocklyRoute } from "@/pages/BlocklySandbox";
import { templatePageRoute } from "@/pages/template/Template";
import { entitiesListRoute } from "@/pages/EntitiesList";
import { entitiesList2Route } from "@/pages/EntitiesList2";
import { templateStructureRoute } from "@/pages/template/TemplateStructure";
import { templateParametersRoute } from "@/pages/template/TemplateParameters";
import { templateDisplayRoute } from "@/pages/template/TemplateDisplay";
import { exportTemplateToSpreadsheetRoute } from "@/pages/template/TemplateExportToSpreadsheet";
import { importTemplateFromSpreadsheetRoute } from "@/pages/template/TemplateImportFromSpreadsheet";
import { translationPageLayoutRoute } from "@/pages/translation/TranslationPageLayout";
import { translationsPageRoute } from "@/pages/translation/TranslationsPage";
import { localesPageRoute } from "@/pages/translation/LocalesPage";
import { glossariesPageRoute } from "@/pages/translation/GlossariesPage";
import { rootRoute } from "@/pages/__root";
import { passwordResetRoute } from "@/pages/PasswordReset";
import { setNewPasswordRoute } from "@/pages/SetNewPasswordPage";
import { deployFlagsRoute } from "@/pages/project-settings/DeployFlagsPage";

export const routeTree = rootRoute.addChildren([
    startPage.addChildren([
        loginPageRoute,
        page403Route,
        gamesRoute,
        profilePageRoute,
        wizardryRoute.addChildren([wizardryGamesRoute, wizardryUsersRoute, pandorasBoxWizardry]),
        layoutWithBreadcrumbs.addChildren([
            templatesListRoute,
            entityPageRoute,
            importPageRoute,
            categoriesPageRoute,
            artifactRoute.addChildren([
                artifactsListPage,
                artifactsHistoryRoute,
                artifactsDiffPage,
            ]),
            projectHistory,
            projectSettings.addChildren([
                projectSettingsRoute,
                deployFlagsRoute,
                permissionsPage,
                deployDirectoriesRoute,
            ]),
            consumersRoute,
            assetsRoute,
            globalParamsRoute,
            blocklyRoute,
            templatePageRoute.addChildren([
                entitiesListRoute,
                entitiesList2Route,
                templateStructureRoute,
                templateParametersRoute,
                templateDisplayRoute,
                exportTemplateToSpreadsheetRoute,
                importTemplateFromSpreadsheetRoute,
            ]),
            translationPageLayoutRoute.addChildren([
                translationsPageRoute,
                localesPageRoute,
                glossariesPageRoute,
            ]),
        ]),
    ]),
    passwordResetRoute,
    setNewPasswordRoute,
]);
