import Button, { ButtonProps } from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import { useMemo } from "react";
import cn from "classnames";

export function ProgressButton(props: ButtonProps & { loading?: boolean }) {
    const { loading = false, ...buttonProps } = props;
    const className = useMemo(
        () => cn("d-flex gap-1 align-items-center", props.className),
        [props.className],
    );

    return (
        <Button {...buttonProps} disabled={loading || buttonProps.disabled} className={className}>
            {loading && <Spinner size="sm" />} {props.children}
        </Button>
    );
}
