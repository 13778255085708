import { createRoute } from "@tanstack/react-router";
import { useCallback, useEffect, useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import { LoginPageLayout } from "@/layouts/LoginPageLayout";
import { useCheckPasswordResetToken, useSetNewPassword } from "@/graphql/auth";
import { NewPasswordForm } from "@/components/forms/NewPasswordForm";
import { rootRoute } from "@/pages/__root";

export default function SetNewPasswordPage() {
    const { token } = setNewPasswordRoute.useParams();
    const [tokenValid, setTokenValid] = useState<boolean | null>(null);
    const [checkPasswordResetToken] = useCheckPasswordResetToken();
    const [setNewPassword] = useSetNewPassword();
    const onPasswordSet = useCallback(
        async (password: string) => {
            if (!token) return;
            await setNewPassword({
                variables: {
                    password,
                    token,
                },
            });
        },
        [setNewPassword, token],
    );
    const checkToken = useCallback(async () => {
        if (!token) return;

        try {
            const { data } = await checkPasswordResetToken({ variables: { token } });

            setTokenValid(data!.result.isOk);
        } catch (e) {
            setTokenValid(false);
        }
    }, [checkPasswordResetToken, token]);

    useEffect(() => {
        checkToken();
    }, [checkToken]);

    if (tokenValid === null) {
        return <Spinner animation="grow" />;
    }

    return (
        <LoginPageLayout>
            {tokenValid ? <NewPasswordForm onSubmit={onPasswordSet} /> : <h3>Token is invalid</h3>}
        </LoginPageLayout>
    );
}

export const setNewPasswordRoute = createRoute({
    getParentRoute: () => rootRoute,
    path: "password-reset/$token",
    component: SetNewPasswordPage,
});
