import { gql } from "@apollo/client";
import { GetEntitiesQuery, GetEntitiesQueryVariables } from "@/gql";
import { ENTITY_FRAGMENT } from "../fragments/entity.fragment";
import { ENTITY_PARAM_FRAGMENT } from "../fragments/entity-param.fragment";
import { makeGqlQueryHook, makeGqlQueryLazyHook } from "../util";

const GET_ENTITIES = gql`
    ${ENTITY_FRAGMENT}
    ${ENTITY_PARAM_FRAGMENT}

    query getEntities($templateId: String!, $gameId: String!) {
        result: getTemplateEntities(templateId: $templateId, gameId: $gameId) {
            ...EntityFragment
            entityParameters {
                ...EntityParamFragment
            }
        }
    }
`;

export const useGetEntitiesQuery = makeGqlQueryHook<GetEntitiesQuery, GetEntitiesQueryVariables>(
    GET_ENTITIES,
    { fetchPolicy: "network-only" },
);
export const useLazyGetEntitiesQuery = makeGqlQueryLazyHook<
    GetEntitiesQuery,
    GetEntitiesQueryVariables
>(GET_ENTITIES, { fetchPolicy: "network-only" });
