import { gql } from "@apollo/client";
import { USER_FRAGMENT } from "@/graphql/fragments/user.fragment";
import { makeGqlQueryHook } from "@/graphql/util";
import { WizGetUsersQuery } from "@/gql";

const schema = gql`
    ${USER_FRAGMENT}

    query wizGetUsers {
        wizGetUsers {
            ...User
            userPermissions {
                id
                gameId
                permissions
                userId
                isDisabled
                game {
                    name
                    sourceGameId
                    guid
                    meta {
                        deployFlags
                    }
                    description
                }
            }
        }
    }
`;

export const useGetUsersQuery = makeGqlQueryHook<WizGetUsersQuery>(schema);
