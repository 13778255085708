import { gql } from "@apollo/client";
import { UpdateTemplateEntitiesV2Mutation, UpdateTemplateEntitiesV2MutationVariables } from "@/gql";
import { makeGqlMutationHook } from "../util";
import { ENTITY_TABLE_DATA_FRAGMENT } from "../fragments/entity-table-data.fragment";

const UPDATE_TEMPLATE_ENTITIES = gql`
    ${ENTITY_TABLE_DATA_FRAGMENT}

    mutation updateTemplateEntitiesV2(
        $entityChanges: [EntityChangeDto!]! = []
        $entityParametersChange: [EntityParameterChangeV2Dto!]! = []
        $gameId: String!
    ) {
        result: updateTemplateEntitiesV2(
            entityChanges: $entityChanges
            entityParametersChange: $entityParametersChange
            gameId: $gameId
        ) {
            ...EntityTableData
        }
    }
`;

export const useUpdateTemplateEntitiesV2Mutation = makeGqlMutationHook<
    UpdateTemplateEntitiesV2Mutation,
    UpdateTemplateEntitiesV2MutationVariables
>(UPDATE_TEMPLATE_ENTITIES);
