import { gql } from "@apollo/client";
import { USER_PERMISSIONS_FRAGMENT } from "@/graphql/fragments/user-permissions.fragment";
import {
    SetPermissionsDisabledStateMutation,
    SetPermissionsDisabledStateMutationVariables,
} from "@/gql";
import { makeGqlMutationHook } from "../util";

const SET_PERMISSIONS_DISABLED_STATE = gql`
    ${USER_PERMISSIONS_FRAGMENT}

    mutation setPermissionsDisabledState(
        $userId: String!
        $gameId: String!
        $newDisabledState: Boolean!
    ) {
        result: setPermissionsDisabledState(
            userId: $userId
            gameId: $gameId
            newDisabledState: $newDisabledState
        ) {
            ...UserPermission
        }
    }
`;

export const useSetPermissionsDisabledState = makeGqlMutationHook<
    SetPermissionsDisabledStateMutation,
    SetPermissionsDisabledStateMutationVariables
>(SET_PERMISSIONS_DISABLED_STATE);
