import Button from "react-bootstrap/Button";
import { gql, useLazyQuery, useMutation } from "@apollo/client";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";
import { ChangeEvent, useCallback, useState } from "react";
import { useSnackbar } from "notistack";
import Stack from "react-bootstrap/Stack";
import {
    GetOldArtifactsQuery,
    GetOldArtifactsQueryVariables,
    CleanupArtifactsMutation,
    CleanupArtifactsMutationVariables,
    Artifact as ArtifactModel,
} from "@/gql";
import { useGetGamesQuery } from "@/graphql/wizardry/games";
import { ARTIFACT_FRAGMENT } from "@/graphql/fragments/artifact.fragment";
import { Collapse } from "@/components/common/Collapse";
import { Artifact } from "@/components/deploy";
import { DEPLOY_DIRECTORY_FRAGMENT } from "@/graphql/fragments/deploy-directory.fragment";
import { ConfirmationButton } from "@/components/common/ConfirmationButton";

const GET_OLD_ARTIFACTS_SCHEMA = gql`
    ${ARTIFACT_FRAGMENT}
    ${DEPLOY_DIRECTORY_FRAGMENT}

    query getOldArtifacts($gameIds: [String!]) {
        result: getArtifactsForCleanup(gameIds: $gameIds) {
            ...Artifact
            currentlyDeployed {
                ...DeployDirectory
            }
        }
    }
`;

const REMOVE_OLD_ARTIFACTS_SCHEMA = gql`
    mutation cleanupArtifacts($gameIds: [String!]) {
        cleanupArtifacts(gameIds: $gameIds) {
            isOk
            info
        }
    }
`;

export function CleanupArtifacts() {
    const getGamesQuery = useGetGamesQuery();
    const [oldArtifacts, setOldArtifacts] = useState<ArtifactModel[]>(() => []);
    const [selectedProjects, setSelectedProjects] = useState<string[]>(() => []);
    const { enqueueSnackbar } = useSnackbar();
    const [getOldArtifacts, { loading: getOldArtifactsLoading }] = useLazyQuery<
        GetOldArtifactsQuery,
        GetOldArtifactsQueryVariables
    >(GET_OLD_ARTIFACTS_SCHEMA, {
        fetchPolicy: "network-only",
    });
    const [cleanupArtifacts, { loading: cleanupArtifactsLoading }] = useMutation<
        CleanupArtifactsMutation,
        CleanupArtifactsMutationVariables
    >(REMOVE_OLD_ARTIFACTS_SCHEMA);
    const oldArtifactsCount = oldArtifacts.length ?? 0;
    const onSelectedProjectsChange = useCallback((e: ChangeEvent<HTMLSelectElement>) => {
        const selectedOptions = Array.from(e.target.selectedOptions).map((option) => option.value);
        setSelectedProjects(selectedOptions);
    }, []);
    const onCollectArtifacts = useCallback(async () => {
        const result = await getOldArtifacts();

        if (result.data) {
            setOldArtifacts(result.data?.result);
            if (result.data.result.length === 0) {
                enqueueSnackbar("Cleanup done", { variant: "success" });
            }
        }
    }, [enqueueSnackbar, getOldArtifacts]);
    const onCleanupArtifacts = useCallback(async () => {
        try {
            const { data, errors } = await cleanupArtifacts({
                variables: { gameIds: selectedProjects },
            });

            if (data?.cleanupArtifacts.isOk) {
                enqueueSnackbar("Cleanup done", { variant: "success" });
                await onCollectArtifacts();
            }
            if (errors) {
                errors.forEach((error) => {
                    enqueueSnackbar(error.message, { variant: "error" });
                });
            }
        } catch (error: any) {
            enqueueSnackbar(error.message, { variant: "error" });
        }
    }, [cleanupArtifacts, enqueueSnackbar, onCollectArtifacts, selectedProjects]);

    return (
        <Stack gap={4}>
            <h4>Cleanup artifacts</h4>
            <p>
                This allows wizard to delete artifacts that are older than two weeks and not marked
                as important. Select desired projects below to run the operation only on them.
                Otherwise operation will be run on all projects
            </p>
            <Form.Select
                disabled={getGamesQuery.loading}
                multiple
                value={selectedProjects}
                onChange={onSelectedProjectsChange}
            >
                {getGamesQuery.data?.wizGetGames.map((project) => (
                    <option key={project.guid} value={project.guid}>
                        {project.name}
                    </option>
                ))}
            </Form.Select>
            <Button disabled={getOldArtifactsLoading} onClick={onCollectArtifacts}>
                {getOldArtifactsLoading && <Spinner size="sm" />} Collect old artifacts
            </Button>
            {oldArtifactsCount > 0 && (
                <div>
                    <Collapse label={`Artifacts (${oldArtifactsCount})`}>
                        <Stack gap={2}>
                            {oldArtifacts.map((artifact) => (
                                <Artifact
                                    key={artifact.guid}
                                    deployLocked={false}
                                    artifact={artifact}
                                    deployDirectories={[]}
                                />
                            ))}
                        </Stack>
                    </Collapse>
                </div>
            )}
            <ConfirmationButton
                disabled={
                    oldArtifactsCount === 0 || cleanupArtifactsLoading || getOldArtifactsLoading
                }
                onConfirm={onCleanupArtifacts}
            >
                {cleanupArtifactsLoading && <Spinner size="sm" />} Remove old artifacts
            </ConfirmationButton>
        </Stack>
    );
}
