import { Helmet } from "react-helmet-async";
import { createRoute } from "@tanstack/react-router";
import { useCallback } from "react";
import { ConsumersTable } from "@/components/common/Table/ConsumersTable";
import { Permission } from "@/gql";
import { withRBAC } from "@/components/rbac";
import { layoutWithBreadcrumbs } from "@/layouts/LayoutWithBreadcrumbs";
import { useGetConsumers } from "@/graphql/consumers";
import { DataAwaiter2 } from "@/components/common/DataAwaiter2";

export const consumersRoute = createRoute({
    getParentRoute: () => layoutWithBreadcrumbs,
    path: "consumers",
    component: withRBAC(
        function ConsumersPage() {
            const { useParams } = consumersRoute;
            const { gameId } = useParams();
            const getConsumers = useGetConsumers({ gameId });
            const refetch = useCallback(() => getConsumers.refetch(), [getConsumers]);

            return (
                <DataAwaiter2 {...getConsumers}>
                    {(data) => (
                        <>
                            <Helmet title="Consumers" />
                            <ConsumersTable
                                gameId={gameId}
                                consumers={data.result}
                                onRefetch={refetch}
                            />
                        </>
                    )}
                </DataAwaiter2>
            );
        },
        {
            requiredPermissions: [Permission.StructureRead, Permission.StructureWrite],
            oneOf: true,
        },
    ),
});
