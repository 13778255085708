import { gql } from "@apollo/client";
import { AUTH_USER_FRAGMENT } from "@/graphql/fragments/user.fragment";
import { makeGqlMutationHook } from "@/graphql/util";
import { WizCreateUserMutation, WizCreateUserMutationVariables } from "@/gql";

const schema = gql`
    ${AUTH_USER_FRAGMENT}

    mutation wizCreateUser(
        $firstname: String!
        $lastname: String!
        $email: String!
        $password: String!
    ) {
        wizCreateUser(
            firstname: $firstname
            lastname: $lastname
            email: $email
            password: $password
        ) {
            ...AuthUser
        }
    }
`;

export const useCreateUserMutation = makeGqlMutationHook<
    WizCreateUserMutation,
    WizCreateUserMutationVariables
>(schema);
