import { BaseSyntheticEvent, useCallback, useMemo, useState } from "react";
import {
    useController,
    useFieldArray,
    useForm,
    type SubmitHandler,
    FieldArrayWithId,
} from "react-hook-form";
import { bootstrapDialog, create, show, useModal } from "@ebay/nice-modal-react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import Stack from "react-bootstrap/Stack";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useSnackbar } from "notistack";
import Badge from "react-bootstrap/Badge";
import { MdTranslate } from "react-icons/md";
import { Translation, TranslationString, UpdateTranslationMutationVariables } from "@/gql";
import { useGetTranslationFromDeepl, useUpdateTranslationMutation } from "@/graphql/translation";
import { Locale } from "@/types";

type Props = {
    gameId: string;
    translation: Omit<Translation, "referencingParams" | "translationStrings"> & {
        translationStrings: Omit<TranslationString, "locale" | "translationId" | "gameId">[];
    };
    locales: Locale[];
};

type FormData = {
    gameId: string;
    guid: string;
    key: string;
    description: string;
    locStrings: Array<Locale & { value: string }>;
};

function UpdateTranslationModalComponent(props: Props) {
    const { locales, translation } = props;
    const modal = useModal();
    const { enqueueSnackbar } = useSnackbar();
    const dialog = bootstrapDialog(modal);
    const [updateTranslationMutation, { loading: updateTranslationLoading }] =
        useUpdateTranslationMutation();
    const [getTranslationFromDeepl, { loading: getTranslationFromDeeplLoading }] =
        useGetTranslationFromDeepl();
    // const [baseLocaleValue, setBaseLocaleValue] = useState("");
    const loading = updateTranslationLoading || getTranslationFromDeeplLoading;
    const defaultValues = useMemo<FormData>(
        () => ({
            gameId: translation.gameId,
            guid: translation.guid,
            key: translation.key,
            description: translation.description,
            locStrings: locales.map((locale) => {
                const translationLocaleValue = translation.translationStrings.find((st) => {
                    return st.localeId === locale.guid;
                })?.value;

                return {
                    ...locale,
                    value: translationLocaleValue ?? "",
                };
            }),
        }),
        [
            locales,
            translation.description,
            translation.gameId,
            translation.guid,
            translation.key,
            translation.translationStrings,
        ],
    );
    const form = useForm<FormData>({
        mode: "onChange",
        defaultValues,
    });
    const baseLocaleIndex = locales.findIndex((locale) => locale.isBaseLocale);
    const baseLocaleValue = form.watch(`locStrings.${baseLocaleIndex}.value`);
    const keyController = useController({
        control: form.control,
        name: "key",
        rules: {
            min: 1,
            max: 255,
        },
    });
    const descriptionController = useController({
        control: form.control,
        name: "description",
        rules: {
            max: 1024,
        },
    });
    const localesArray = useFieldArray({
        control: form.control,
        name: "locStrings",
    });
    const onFillTranslationFromDeepl = useCallback(
        async (locale: FieldArrayWithId<FormData, "locStrings", "id">, index: number) => {
            const { gameId, guid } = locale;

            if (!baseLocaleValue) {
                enqueueSnackbar("Base locale value is empty or does not exist", {
                    variant: "error",
                    autoHideDuration: 3000,
                });
                return;
            }

            const result = await getTranslationFromDeepl({
                variables: { gameId, sourceLocaleId: guid, value: baseLocaleValue },
            });

            if (!result.data?.getTranslationFromDeepl) {
                enqueueSnackbar("Could not get translation", {
                    variant: "error",
                    autoHideDuration: 3000,
                });
                return;
            }

            form.setValue(`locStrings.${index}.value`, result.data.getTranslationFromDeepl);
        },
        [baseLocaleValue, enqueueSnackbar, form, getTranslationFromDeepl],
    );
    const onSubmit = useCallback<SubmitHandler<FormData>>(
        async (data: FormData) => {
            const baseLocaleIndex = props.locales.findIndex((v) => v.isBaseLocale);
            const baseTranslationChanged = Boolean(
                form.formState.dirtyFields.locStrings?.at(baseLocaleIndex)?.value,
            );
            const variables: UpdateTranslationMutationVariables = {
                gameId: data.gameId,
                guid: data.guid,
                key: data.key,
                description: data.description,
                locStrings: data.locStrings.map((locStr) => ({
                    value: locStr.value,
                    localeId: locStr.guid,
                })),
            };
            const { errors } = await updateTranslationMutation({ variables });
            if (!errors) {
                enqueueSnackbar("Translation saved", {
                    variant: "success",
                });
                modal.resolve({ baseTranslationChanged });
                return modal.hide();
            } else {
                console.dir(errors);
                enqueueSnackbar("Couldn't save translation", {
                    variant: "error",
                    autoHideDuration: 3000,
                });
            }
        },
        [props.locales, form, updateTranslationMutation, enqueueSnackbar, modal],
    );

    return (
        <Modal {...dialog} size="xl">
            <Modal.Header>
                <Modal.Title>Update translation</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={form.handleSubmit(onSubmit)}>
                    <Stack gap={4}>
                        <Form.Group>
                            <Form.Label>Translation key</Form.Label>
                            <Form.Control
                                {...keyController.field}
                                isInvalid={!!descriptionController.formState.errors.key}
                            />
                            {keyController.formState.errors.key && (
                                <Form.Control.Feedback type="invalid">
                                    {keyController.formState.errors.key?.message}
                                </Form.Control.Feedback>
                            )}
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Description</Form.Label>
                            <Form.Control
                                {...descriptionController.field}
                                isInvalid={!!descriptionController.formState.errors.description}
                                as="textarea"
                            />
                            {descriptionController.formState.errors.description && (
                                <Form.Control.Feedback type="invalid">
                                    {descriptionController.formState.errors.description?.message}
                                </Form.Control.Feedback>
                            )}
                        </Form.Group>
                        <Row className="g-4">
                            {localesArray.fields.map((locale, index) => (
                                <Col md={6} key={locale.id}>
                                    <Form.Group>
                                        <Form.Label className="d-flex align-items-center gap-1 mb-1">
                                            <span>
                                                {locale.name} ({locale.code})
                                            </span>
                                            {locale.isBaseLocale && <Badge>Base locale</Badge>}
                                            {!locale.isBaseLocale && (
                                                <Button
                                                    size="sm"
                                                    className="py-0"
                                                    disabled={baseLocaleValue.length === 0}
                                                    onClick={() =>
                                                        onFillTranslationFromDeepl(locale, index)
                                                    }
                                                >
                                                    <MdTranslate />
                                                </Button>
                                            )}
                                        </Form.Label>
                                        <Form.Control
                                            {...form.register(`locStrings.${index}.value`, {
                                                // onChange: (event: BaseSyntheticEvent) => {
                                                //     if (locale.isBaseLocale) {
                                                //         setBaseLocaleValue(event.target.value);
                                                //     }
                                                // },
                                            })}
                                            as="textarea"
                                            rows={10}
                                        />
                                    </Form.Group>
                                </Col>
                            ))}
                        </Row>
                        <Button type="submit" disabled={!form.formState.isDirty || loading}>
                            {loading && <Spinner size="sm" />}
                            Save
                        </Button>
                    </Stack>
                </Form>
            </Modal.Body>
        </Modal>
    );
}

const UpdateTranslationModal = create<Props>(UpdateTranslationModalComponent);

export function showUpdateTranslationModal(
    props: Props,
): Promise<{ baseTranslationChanged: boolean }> {
    return show(UpdateTranslationModal, props);
}
