import { memo, useMemo } from "react";
import { stringify } from "@/utils/stringify";
import { InputProps } from "../../types";
import { useEntitiesTableFormContext } from "../../hooks/useEntitiesTableFormContext";

export const ReadonlyScriptField = memo(function ReadonlyScriptField(props: InputProps) {
    const methods = useEntitiesTableFormContext();
    const valueAsString = useMemo(
        () => stringify(methods.watch(props.name)),
        [methods, props.name],
    );

    return <code>{valueAsString}</code>;
});
