import { gql } from "@apollo/client";
import { makeGqlMutationHook } from "@/graphql/util";
import { RemoveTranslationMutation, RemoveTranslationMutationVariables } from "@/gql";
import { TRANSLATION_FRAGMENT } from "@/graphql/fragments/translation.fragment";

const SCHEMA = gql`
    ${TRANSLATION_FRAGMENT}

    mutation removeTranslation($gameId: String!, $guid: String!) {
        removeTranslation(gameId: $gameId, guid: $guid) {
            ...Translation
        }
    }
`;

export const useRemoveTranslationMutation = makeGqlMutationHook<
    RemoveTranslationMutation,
    RemoveTranslationMutationVariables
>(SCHEMA);
