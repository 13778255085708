import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Helmet } from "react-helmet-async";
import { createRoute } from "@tanstack/react-router";
import { UpdateGameForm } from "@/components/forms/UpdateGameForm";
import { useAppSelector } from "@/hooks";
import { gameDetailsSelector } from "@/store/games/selectors";
import { projectSettings } from "@/pages/project-settings/ProjectSettings";

export default function ProjectSettingsPage() {
    const { name, description, guid, allowSyncFromOrigin, sourceGameId } =
        useAppSelector(gameDetailsSelector);

    return (
        <>
            <Helmet title="Project" />
            <Container fluid>
                <Row>
                    <Col>
                        <UpdateGameForm
                            gameDetails={{
                                name,
                                description,
                                guid,
                                sourceGameId,
                                allowSyncFromOrigin,
                            }}
                        />
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export const projectSettingsRoute = createRoute({
    getParentRoute: () => projectSettings,
    path: "/",
    component: ProjectSettingsPage,
});
