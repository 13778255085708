import { gql } from "@apollo/client";
import { USER_FRAGMENT } from "@/graphql/fragments/user.fragment";
import { makeGqlMutationHook } from "@/graphql/util";
import { WizUnblockUserMutation, WizUnblockUserMutationVariables } from "@/gql";

const schema = gql`
    ${USER_FRAGMENT}

    mutation wizUnblockUser($userId: String!) {
        wizUnblockUser(userId: $userId) {
            ...User
        }
    }
`;

export const useUnblockUserMutation = makeGqlMutationHook<
    WizUnblockUserMutation,
    WizUnblockUserMutationVariables
>(schema);
