import { memo } from "react";
import { AssetRenderer } from "@/components/common/AssetRenderer";
import { ErrorBadge } from "../../components/ErrorBadge";
import { useCellContext } from "../../contexts/CellContext";
import { InputProps } from "../../types";
import { useFieldController } from "../../hooks/useFieldController";

export const ReadonlyAssetField = memo(function ReadonlyAssetField(props: InputProps) {
    const { gameId } = useCellContext();
    const controller = useFieldController(props.name);

    if (!controller.field.value) {
        return <ErrorBadge messageCode="NO_VALUE" />;
    }

    return <AssetRenderer guid={controller.field.value as string} gameId={gameId} />;
});
