import { gql } from "@apollo/client";
import { UpdatePasswordMutation, UpdatePasswordMutationVariables } from "@/gql";
import { AUTH_USER_FRAGMENT } from "@/graphql/fragments/user.fragment";
import { makeGqlMutationHook } from "../util";

const SCHEMA = gql`
    ${AUTH_USER_FRAGMENT}

    mutation updatePassword(
        $oldPassword: String!
        $newPassword: String!
        $confirmNewPassword: String!
    ) {
        result: updatePassword(
            oldPassword: $oldPassword
            newPassword: $newPassword
            confirmNewPassword: $confirmNewPassword
        ) {
            ...AuthUser
        }
    }
`;

export const useUpdatePasswordMutation = makeGqlMutationHook<
    UpdatePasswordMutation,
    UpdatePasswordMutationVariables
>(SCHEMA);
