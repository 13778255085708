import { useForm } from "react-hook-form";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import { FormInput } from "@/components/common/forms/form-inputs/FormInput";

export type UserEmailData = {
    email: string;
};

type Props = UserEmailData & {
    isLoading: boolean;
    onSubmit: (data: UserEmailData) => void;
};

export function EditEmailForm(props: Props) {
    const { formState, handleSubmit, control } = useForm<UserEmailData>({
        defaultValues: {
            email: props.email,
        },
        mode: "onChange",
    });

    return (
        <Form onSubmit={handleSubmit(props.onSubmit)}>
            <Row className="gy-2">
                <Col md={4}>
                    <FormInput
                        disabled={props.isLoading}
                        name="email"
                        control={control}
                        rules={{ required: true }}
                    />
                </Col>
                <Col>
                    <Button
                        type="submit"
                        color="danger"
                        disabled={!formState.isValid || !formState.isDirty}
                    >
                        {props.isLoading ? <Spinner size="sm" animation="border" /> : "Save"}
                    </Button>
                </Col>
            </Row>
        </Form>
    );
}
