import { gql } from "@apollo/client";
import { JOB_FRAGMENT } from "@/graphql/fragments/job.fragment";
import { makeGqlMutationHook } from "@/graphql/util";
import { WizDeleteGameMutation, WizDeleteGameMutationVariables } from "@/gql";

const schema = gql`
    ${JOB_FRAGMENT}

    mutation wizDeleteGame($gameIds: [String!]!) {
        wizDeleteGame(gameIds: $gameIds) {
            ...Job
        }
    }
`;

export const useDeleteGameMutation = makeGqlMutationHook<
    WizDeleteGameMutation,
    WizDeleteGameMutationVariables
>(schema);
