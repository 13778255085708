import { gql } from "@apollo/client";

export const TRANSLATION_FRAGMENT = gql`
    fragment Translation on Translation {
        guid
        gameId
        key
        description
    }
`;
