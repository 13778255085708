import { useConsumerById } from "@/store/games/slices";
import {
    ConsumerProcessingEvent,
    ConsumerProcessingFailedEvent,
    ConsumerProcessingFinishedEvent,
    DeployStep,
} from "@/gql";

interface ConsumerProcessingEventRendererProps {
    event:
        | ConsumerProcessingEvent
        | ConsumerProcessingFailedEvent
        | ConsumerProcessingFinishedEvent;
}

export function ConsumerProcessingEventRenderer(props: ConsumerProcessingEventRendererProps) {
    const { event } = props;
    const consumer = useConsumerById(event.consumerId);

    if (!consumer) return <span>Missing consumer {event.consumerId}</span>;

    switch (event.deployCode) {
        case DeployStep.ConsumerProcessing:
            return <span>Processing consumer {consumer.name}</span>;
        case DeployStep.ConsumerProcessingFinished:
            return <span>Finished processing consumer {consumer.name}</span>;
        case DeployStep.ConsumerProcessingFailed:
            return (
                <span>
                    Consumer {consumer.name} processing failed: {event.error}
                </span>
            );
        default:
            return <span>Missing consumer {event.consumerId}</span>;
    }
}
