import { TableColumn } from "react-data-table-component";
import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import { useSnackbar } from "notistack";
import { useHasPermissions } from "@/components/rbac";
import { Permission, AssetFragment, Upload } from "@/gql";
import { useCollectionReducer } from "@/hooks/useCollectionReducer";
import {
    useLazyGetAssets,
    useCreateUploadMutation,
    useDeleteAssetMutation,
    useLazyGetAsset,
} from "@/graphql/assets";
import { useModal } from "@/hooks";
import { EditAssetModal } from "@/components/modals/EditAssetModal";
import { AssetRawData } from "@/components/forms/EditAssetForm";
import { AppDataTable } from "@/components/common/DataTable";
import { FileSizeDisplay } from "@/components/common/FileSizeDisplay";
import { ConfirmationButton } from "@/components/common/ConfirmationButton";

type Props = {
    gameId: string;
};

export function AssetsTable(props: Props) {
    const { gameId } = props;
    const [error, setError] = useState<Error | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [createUploadMutation] = useCreateUploadMutation();
    const [deleteAssetMutation] = useDeleteAssetMutation();
    const [getAssetsQuery] = useLazyGetAssets();
    const [getAssetQuery] = useLazyGetAsset();
    const canEdit = useHasPermissions([Permission.DataWrite]);
    const { collection, ...actions } = useCollectionReducer<AssetFragment>("guid");
    const {
        modal: ModalComponent,
        openModal: openAssetModal,
        closeModal: closeAssetModal,
    } = useModal(EditAssetModal);
    const loadAssets = useCallback(async () => {
        const { data } = await getAssetsQuery({ variables: { gameId } });

        if (!data) {
            enqueueSnackbar("Failed to load assets", {
                variant: "error",
                autoHideDuration: 3000,
            });
            return;
        }

        actions.set(data.result);
    }, [actions, gameId, getAssetsQuery, enqueueSnackbar]);
    const removeAsset = useCallback(
        async (guid: string) => {
            try {
                setIsLoading(true);
                await deleteAssetMutation({ variables: { guid, gameId } });
                actions.remove(guid);
                closeAssetModal();
            } catch (e) {
                setError(e as Error);
            } finally {
                setIsLoading(false);
            }
        },
        [actions, closeAssetModal, deleteAssetMutation, gameId],
    );

    const uploadAsset = useCallback(
        async (data: AssetRawData) => {
            let snackbarKey = enqueueSnackbar("Upload started", {
                variant: "info",
            });

            try {
                const result = await createUploadMutation({
                    variables: {
                        gameId: data.gameId,
                        name: data.name,
                        description: data.description,
                    },
                });
                const upload = result.data?.uploadAssetV2;

                if (!upload) {
                    closeSnackbar(snackbarKey);
                    enqueueSnackbar("Upload failed", {
                        variant: "error",
                        autoHideDuration: 3000,
                    });
                    throw new Error(result.errors?.[0]?.message ?? "Unknown upload error");
                }

                closeSnackbar(snackbarKey);
                snackbarKey = enqueueSnackbar("Upload created");

                const formData = new FormData();
                formData.append("file", data.file![0]!);

                const response = await fetch(
                    `${window.env.httpApiUrl}/assets/upload/${upload.guid}`,
                    {
                        method: "POST",
                        body: formData,
                    },
                );

                closeSnackbar(snackbarKey);
                snackbarKey = enqueueSnackbar("Upload finished");

                if (!response.ok) {
                    throw new Error(response.statusText);
                }

                const newAssetData = (await response.json()) as Upload;
                const newAsset = await getAssetQuery({
                    variables: {
                        gameId: newAssetData.gameId,
                        guid: newAssetData.guid,
                    },
                });
                if (!newAsset.data) {
                    closeSnackbar(snackbarKey);
                    enqueueSnackbar("Failed to fetch new asset", {
                        variant: "error",
                        autoHideDuration: 3000,
                    });
                    return;
                }
                actions.add(newAsset.data.result);
                closeAssetModal();
            } catch (e) {
                setError(e as Error);
                enqueueSnackbar("Upload failed", {
                    variant: "error",
                    autoHideDuration: 3000,
                });
            }
        },
        [
            enqueueSnackbar,
            createUploadMutation,
            closeSnackbar,
            getAssetQuery,
            actions,
            closeAssetModal,
        ],
    );

    const addAsset = useCallback(() => {
        openAssetModal({
            isOpen: true,
            gameId,
            isLoading: isLoading,
            error: error,
            onSubmit: uploadAsset,
            toggle: closeAssetModal,
        });
    }, [closeAssetModal, error, gameId, isLoading, openAssetModal, uploadAsset]);
    const tableActions = useMemo<ReactNode>(
        () => (
            <ButtonGroup>{canEdit && <Button onClick={addAsset}>Upload new</Button>}</ButtonGroup>
        ),
        [addAsset, canEdit],
    );
    const columns = useMemo<TableColumn<AssetFragment>[]>(
        () => [
            {
                name: "Filename",
                selector: (row) => row.filename,
            },
            { name: "Name", selector: (row) => row.name },
            {
                name: "Description",
                selector: (row) => row.description,
                format: (row) =>
                    row.description === "" ? (
                        <span className="fst-italic">No description</span>
                    ) : (
                        row.description
                    ),
            },
            {
                name: "Extension",
                selector: (row) => row.extension,
            },
            {
                name: "Mime",
                selector: (row) => row.mime,
            },
            {
                name: "Size",
                selector: (row) => row.size,
                format: (row) => <FileSizeDisplay bytes={row.size} />,
            },
            { name: "Hash", selector: (row) => row.hash },
            {
                name: "Actions",
                maxWidth: "150px",
                cell: (row) => (
                    <ButtonGroup size="sm">
                        <ConfirmationButton
                            prompt="Are you sure you want to delete this asset?"
                            variant="danger"
                            onConfirm={() => removeAsset(row.guid)}
                        >
                            Remove
                        </ConfirmationButton>
                    </ButtonGroup>
                ),
            },
        ],
        [removeAsset],
    );
    useEffect(() => {
        loadAssets();
    }, []);

    return (
        <>
            <AppDataTable data={collection} columns={columns} actions={tableActions} />
            <ModalComponent />
        </>
    );
}
