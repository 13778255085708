import { ChangeEventHandler, ComponentProps, type ReactNode, useCallback, useMemo } from "react";
import { useController, UseControllerProps } from "react-hook-form";
import Form from "react-bootstrap/Form";
import { ConnectForm } from "../ConnectForm";

export interface SelectOption {
    key?: string;
    value: string | number;
    label: string | ReactNode | ReactNode[];
    disabled?: boolean;
}

interface FormSelectProps {
    options: SelectOption[];
}

export function FormSelect<FormValues extends Record<string, any>>(
    props: UseControllerProps<FormValues> & ComponentProps<typeof Form.Select> & FormSelectProps,
) {
    // disabled: true sets value to undefined which is complete bullshit
    const { field, fieldState } = useController({ ...props, disabled: undefined });
    const error = useMemo(() => {
        if (!fieldState.error) return null;
        switch (fieldState.error.type) {
            case "required":
                return "This field is required";
            default:
                return fieldState.error.message || "Invalid value";
        }
    }, [fieldState.error]);
    const selectOptions = useMemo(() => {
        return props.options.map((option) => {
            const optionKey = option.key ?? `_key-${option.value}-${option.label}`;

            return (
                <option key={optionKey} value={option.value}>
                    {option.label}
                </option>
            );
        });
    }, [props]);
    const onChangeProxy = useCallback<ChangeEventHandler<HTMLSelectElement>>(
        (event) => {
            const values = Array.from(event.currentTarget.selectedOptions).map(
                (option) => option.value,
            );
            const value = props.multiple ? values : values.at(0);
            field.onChange(value);
        },
        [field, props.multiple],
    );

    return (
        <Form.Select
            {...field}
            className="form-control"
            disabled={props.disabled}
            multiple={props.multiple}
            onChange={onChangeProxy}
        >
            {selectOptions}
        </Form.Select>
    );
}

export function NestedFormSelect(inputProps: ComponentProps<typeof FormSelect>) {
    return (
        <ConnectForm>
            {({ register }) => <FormSelect {...inputProps} {...register(inputProps.name)} />}
        </ConnectForm>
    );
}
