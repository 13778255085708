interface CheckIfValidArgs<R extends string = string> {
    required: R[];
    existing: Record<R, R>;
    oneOf?: boolean;
}

export function checkIfRBACValid<R extends string = string>({
    required,
    existing,
    oneOf,
}: CheckIfValidArgs<R>) {
    if (required.length === 0) {
        return true;
    }

    if (oneOf) {
        return required.some((r) => !!existing[r]);
    }

    return required.every((r) => !!existing[r]);
}
