import isEmpty from "lodash/isEmpty";
import { DataAwaiter2 } from "@/components/common/DataAwaiter2";
import { useGetEntitiesQuery } from "./useGetEntitiesQuery";
import { EntityRefSelectorInner } from "./EntityRefSelectorInner";
import { EntityRefSelectorProps } from "./types";

export function EntityRefSelector(props: EntityRefSelectorProps) {
    const { templateId, gameId } = props;
    const entitiesQuery = useGetEntitiesQuery({
        templateId,
        gameId,
        guid: isEmpty(props.value) ? null : props.value,
        query: "",
        take: 10,
    });

    // с error=undefined мы всё ещё позволяем нарисовать
    // выпадающий список в случае удалённой сущности
    return (
        <DataAwaiter2 {...entitiesQuery} error={undefined}>
            {(data) => <EntityRefSelectorInner {...props} data={data} />}
        </DataAwaiter2>
    );
}
