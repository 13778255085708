import { useCallback, useLayoutEffect, useMemo } from "react";
import { TableColumn } from "react-data-table-component";
import { Link } from "@tanstack/react-router";
import { GetEntitiesTableDataQuery, TemplateParam } from "@/gql";
import { OperableEntityTableData } from "@/types";
import { useCollectionReducer } from "@/hooks/useCollectionReducer";
import { entitiesList2Route } from "@/pages/EntitiesList2";
import { HeaderCell } from "../components/HeaderCell";
import { useFieldsDefinitions } from "../hooks/useFieldsDefinitions";
import { withTemplateParam } from "../withTemplateParam";
import { useActionsColumn } from "./useActionsColumn";

type Props = {
    readonly: boolean;
} & GetEntitiesTableDataQuery;

export function useTableColumns(props: Props) {
    const { getDefinition } = useFieldsDefinitions();
    const { collection: pinnedColumnsCollection, ...pinActions } = useCollectionReducer("id");
    const pinnedColumns = useMemo(
        () => new Set(pinnedColumnsCollection.map((v) => v.id)),
        [pinnedColumnsCollection],
    );

    const onPin = useCallback(
        (id: string) => {
            if (pinnedColumns.has(id)) {
                pinActions.remove(id);
            } else {
                pinActions.add({ id });
            }
        },
        [pinActions, pinnedColumns],
    );

    const templateParamToColumn = useCallback(
        (templateParam: TemplateParam): TableColumn<OperableEntityTableData> => {
            const fieldDefinition = getDefinition(templateParam.type);
            const isStickyColumn = pinnedColumns.has(templateParam.guid);
            const tableCellDisplayOptions = fieldDefinition.tableCellDisplayOptions(templateParam);

            return {
                ...tableCellDisplayOptions,
                id: templateParam.guid,
                name: (
                    <HeaderCell
                        name={templateParam.meta.displayName || templateParam.name}
                        pinned={pinnedColumns.has(templateParam.guid)}
                        onPin={() => onPin(templateParam.guid)}
                    />
                ),
                grow: 1,
                compact: true,
                cell: withTemplateParam(
                    props.readonly
                        ? fieldDefinition.readonlyComponent
                        : fieldDefinition.inputComponent,
                    {
                        templateParam,
                    },
                ),
                reorder: !isStickyColumn,
                sortFunction:
                    typeof fieldDefinition.sortFunction === "function"
                        ? fieldDefinition.sortFunction(templateParam)
                        : undefined,
            };
        },
        [getDefinition, onPin, pinnedColumns, props.readonly],
    );

    const columnsOrder = useMemo<string[]>(() => {
        const predefinedColumnsOrder = props.templateDisplay.fieldsOrder ?? [];
        const templateParamsOrder = (props.template.templateParams ?? []).map(
            (value) => value.guid,
        );

        if (Array.isArray(predefinedColumnsOrder)) {
            return predefinedColumnsOrder;
        } else {
            return templateParamsOrder;
        }
    }, [props.templateDisplay.fieldsOrder, props.template.templateParams]);

    const fieldColumns = useMemo(() => {
        if (!Array.isArray(props.template.templateParams)) return [];

        if (props.template.templateParams.length === 0) {
            return [
                {
                    cell: () => (
                        <div>
                            <h4>This template has no parameters yet</h4>
                            <p>
                                You can create one on{" "}
                                <Link from={entitiesList2Route.fullPath} to="../params">
                                    Parameters page
                                </Link>
                            </p>
                        </div>
                    ),
                },
            ];
        }

        return columnsOrder
            .map((guid) => {
                const templateParam = props.template.templateParams!.find((tp) => tp.guid === guid);

                if (!templateParam) return null;

                return templateParamToColumn(templateParam);
            })
            .filter(Boolean);
    }, [columnsOrder, props.template.templateParams, templateParamToColumn]);

    const doStickyColumnsStuff = useCallback(() => {
        const cells = document.querySelectorAll(".rdt_TableCol, .rdt_TableCell");
        cells.forEach((cell) => {
            if (pinnedColumns.has((cell as HTMLElement).dataset["columnId"])) {
                cell.classList.add("sticky-column");
                const siblings = Array.from((cell as HTMLElement).parentElement!.children);
                const cellIndex = siblings.indexOf(cell);
                const leftOffset = siblings.slice(0, cellIndex).reduce((acc, node) => {
                    if (node !== cell && node.classList.contains("sticky-column")) {
                        return acc + node.clientWidth;
                    }
                    return acc;
                }, 0);
                (cell as HTMLElement).style.left = `${leftOffset}px`;
            } else {
                cell.classList.remove("sticky-column");
                (cell as HTMLElement).style.left = "";
            }
        });
    }, [pinnedColumns]);

    useLayoutEffect(() => {
        doStickyColumnsStuff();
    }, [doStickyColumnsStuff]);

    const actionsColumn = useActionsColumn(props);

    const columns = useMemo(() => [actionsColumn, ...fieldColumns], [actionsColumn, fieldColumns]);

    return { columns };
}
