import { useMemo } from "react";
import DataTable, { IDataTableProps, TableStyles } from "react-data-table-component";
import merge from "lodash/merge";

export type AppDataTableProps<T> = IDataTableProps<T>;

const customStyles: TableStyles = {
    table: {
        style: {
            display: "table",
            "--bs-table-color": "var(--bs-body-color)",
            "--bs-table-bg": "transparent",
            "--bs-table-border-color": "var(--bs-border-color)",
            "--bs-table-accent-bg": "transparent",
            "--bs-table-striped-color": "var(--bs-body-color)",
            "--bs-table-striped-bg": "rgba(0,0,0,0.05)",
            "--bs-table-active-color": "var(--bs-body-color)",
            "--bs-table-active-bg": "rgba(0,0,0,0.1)",
            "--bs-table-hover-color": "var(--bs-body-color)",
            "--bs-table-hover-bg": "rgba(0,0,0,0.075)",
            width: "100%",
            color: "var(--bs-table-color)",
            "vertical-align": "top",
            "border-color": "var(--bs-table-border-color)",
            paddingBottom: "150px",
        },
    },
    rows: {
        style: {
            display: "table-row",
        },
        stripedStyle: {
            color: "rgba(0, 0, 0, 0.87)",
            backgroundColor: "#f5f5f5",
        },
    },
    headCells: {
        style: {
            display: "table-cell",
        },
    },
    head: {
        style: {
            display: "table-header-group",
            position: "initial",
        },
    },
    headRow: {
        style: {
            display: "table-row",
            borderWidth: "1px 0",
            borderColor: "#dee2e6",
            fontSize: "0.875rem",
            fontWeight: 700,
        },
    },
    cells: {
        style: {
            display: "table-cell",
            alignSelf: "start",
            wordBreak: "normal",
            whiteSpace: "break-spaces",
            fontSize: "0.875rem",
            verticalAlign: "top",
            padding: "8px 16px",
        },
    },
};

export function AppDataTable<T>(props: IDataTableProps<T>) {
    const styles = useMemo(() => merge(customStyles, props.customStyles), [props.customStyles]);

    return <DataTable {...props} customStyles={styles} />;
}
