import { type ToolboxItemInfo } from "blockly/core/utils/toolbox";

export const logicBlocks: ToolboxItemInfo = {
    kind: "category",
    name: "Logic",
    // toolboxitemId: "catLogic",
    colour: "120",
    contents: [
        { kind: "block", type: "controls_if" },
        { kind: "block", type: "logic_compare" },
        { kind: "block", type: "logic_operation" },
        { kind: "block", type: "logic_negate" },
        { kind: "block", type: "logic_boolean" },
        { kind: "block", type: "logic_null" },
        { kind: "block", type: "logic_ternary" },
    ],
};
