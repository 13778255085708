import { FallbackProps } from "react-error-boundary";
import { TemplateParam } from "@/gql";

export function EditEntityFormFallback(
    props: FallbackProps & { templateParameter: TemplateParam },
) {
    return (
        <div>
            <p className="small">Error while rendering parameter:</p>
            <ul className="small">
                <li>name: {props.templateParameter.name}</li>
                <li>type: {props.templateParameter.type}</li>
            </ul>
            <pre style={{ color: "red" }}>{props.error.message}</pre>
        </div>
    );
}
