import { useCallback, useMemo } from "react";
import { Link, createRoute } from "@tanstack/react-router";
import { FaTrash } from "react-icons/fa";
import Col from "react-bootstrap/Col";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Nav from "react-bootstrap/Nav";
import { Helmet } from "react-helmet-async";
import { useHasPermissions, withRBAC } from "@/components/rbac";
import { useGameDetails } from "@/store/games/slice";
import { TablePage } from "@/components/common/TablePage";
import { useRemoveTemplateAction } from "@/store/games/actions";
import { CreateTemplateButton } from "@/components/common/CreateTemplateButton";
import { Permission } from "@/gql";
import { Template } from "@/types";
import { ConfirmationButton } from "@/components/common/ConfirmationButton";
import { layoutWithBreadcrumbs } from "@/layouts/LayoutWithBreadcrumbs";
import type { TableColumn } from "react-data-table-component";

export function TemplatesList() {
    const { useParams } = templatesListRoute;
    const { gameId } = useParams();
    const gameInfo = useGameDetails(gameId);
    const [removeTemplateAction] = useRemoveTemplateAction();
    const canEditTemplates = useHasPermissions([Permission.StructureWrite]);

    const actionsRow = useCallback(() => {
        return (
            <Col>
                <Nav variant="pills">
                    {gameId && (
                        <Nav.Item>
                            <CreateTemplateButton gameId={gameId} />
                        </Nav.Item>
                    )}
                </Nav>
            </Col>
        );
    }, [gameId]);

    const removeTemplate = useCallback(
        (guid: string) => {
            return removeTemplateAction({ guid, gameId: gameId! });
        },
        [gameId, removeTemplateAction],
    );

    const templateNameRenderer = useCallback(
        (row: Template) => <Link to={`${row.guid}/entities`}>{row.name}</Link>,
        [],
    );

    const actionsColumnRenderer = useCallback(
        (row: Template) => {
            return (
                <ButtonGroup>
                    <ConfirmationButton
                        prompt="Are you sure you want to delete this template?"
                        color="danger"
                        size="sm"
                        confirmationPhrase={row.guid}
                        onConfirm={() => removeTemplate(row.guid)}
                    >
                        <FaTrash className="align-middle" />
                    </ConfirmationButton>
                </ButtonGroup>
            );
        },
        [removeTemplate],
    );

    const columns = useMemo<TableColumn<Template>[]>(
        () => [
            {
                name: "Name",
                selector: (row) => row.name,
                cell: templateNameRenderer,
                ignoreRowClick: true,
            },
            {
                name: "Description",
                cell: (row) => (
                    <div className="text-wrap">{row.description ?? "No description"}</div>
                ),
            },
            {
                name: "Type",
                selector: (row) => row.type,
            },
            {
                omit: !canEditTemplates,
                name: "Actions",
                key: "actions",
                cell: actionsColumnRenderer,
            },
        ],
        [actionsColumnRenderer, canEditTemplates, templateNameRenderer],
    );

    return (
        <>
            <Helmet title="Templates" />
            <TablePage
                columns={columns}
                data={gameInfo?.templates ?? null}
                actionsRow={actionsRow}
            />
        </>
    );
}

const TemplatesListRBAC = withRBAC(TemplatesList, {
    requiredPermissions: [Permission.StructureRead, Permission.StructureWrite],
    oneOf: true,
});
export default TemplatesListRBAC;
export const templatesListRoute = createRoute({
    getParentRoute: () => layoutWithBreadcrumbs,
    path: "templates",
    component: TemplatesListRBAC,
});
