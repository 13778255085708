import { DataType } from "./gql";

export const allowedSubtypes = new Set<DataType>([
    DataType.Integer,
    DataType.Float,
    DataType.String,
    DataType.LocalizedString,
    DataType.Boolean,
    DataType.EntityRef,
    DataType.InjectedRef,
]);

export const entriesPerPageOptions = [10, 15, 25, 50, 75];
