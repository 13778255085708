import { createContext, memo, PropsWithChildren, useContext, useMemo } from "react";
import { OperableEntityTableData } from "@/types";
import { DataType, Maybe, TemplateParam } from "@/gql";
import { layoutWithBreadcrumbs } from "@/layouts/LayoutWithBreadcrumbs";

type CellContext = {
    row: OperableEntityTableData;
    gameId: string;
    templateParam: TemplateParam;
    type: DataType;
    subType?: Maybe<DataType>;
    refTemplateId?: Maybe<string>;
    contextEntity: OperableEntityTableData;
    isRequired: boolean;
};

export const CellContext = createContext<CellContext>(undefined!);

export const CellContextProvider = memo(function CellContextProvider(
    props: PropsWithChildren<{
        row: OperableEntityTableData;
        templateParam: TemplateParam;
        contextEntity?: OperableEntityTableData;
    }>,
) {
    const { gameId } = layoutWithBreadcrumbs.useParams();
    const providerValue = useMemo<CellContext>(
        () => ({
            row: props.row,
            gameId: gameId!,
            templateParam: props.templateParam,
            type: props.templateParam.type,
            subType: props.templateParam.subType,
            refTemplateId: props.templateParam.meta.templateId,
            contextEntity: props.contextEntity ?? props.row,
            isRequired: props.templateParam.meta.isRequired,
        }),
        [gameId, props.contextEntity, props.row, props.templateParam],
    );

    return <CellContext.Provider value={providerValue}>{props.children}</CellContext.Provider>;
});

export function useCellContext() {
    return useContext(CellContext);
}
