import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import { Link } from "@tanstack/react-router";
import { gql, useLazyQuery } from "@apollo/client";
import { useCallback, useEffect } from "react";
import Spinner from "react-bootstrap/Spinner";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import { useSnackbar } from "notistack";
import { GameFragment, GetSourceGameInfoQuery, GetSourceGameInfoQueryVariables } from "@/gql";
import { ConfirmationButton } from "@/components/common/ConfirmationButton";
import { Avatar } from "@/components/common/Avatar";
import { useCloneGameMutation, useRemoveGameMutation, useSyncGameFromOrigin } from "@/graphql/game";

interface Props {
    game: GameFragment;
    onRefetch: () => void;
}

const GetSourceGameInfo = gql`
    query getSourceGameInfo($gameId: String!) {
        getGame: getSourceGame(gameId: $gameId) {
            name
            guid
        }
    }
`;

export function GameCard(props: Props) {
    const [getSourceGameInfo, { data: sourceGameInfo, loading }] = useLazyQuery<
        GetSourceGameInfoQuery,
        GetSourceGameInfoQueryVariables
    >(GetSourceGameInfo);
    const { enqueueSnackbar } = useSnackbar();
    const [removeGame, { loading: removeGameLoading }] = useRemoveGameMutation();
    const [cloneGame, { loading: cloneGameLoading }] = useCloneGameMutation();
    const [syncGameFromOrigin, { loading: syncGameFromOriginLoading }] = useSyncGameFromOrigin();
    const isLoading = loading || removeGameLoading || cloneGameLoading || syncGameFromOriginLoading;
    const onSyncFromOrigin = useCallback(async () => {
        await syncGameFromOrigin({
            variables: { gameId: props.game.guid },
        });

        enqueueSnackbar("Sync from origin job has been put into queue");

        props.onRefetch();
    }, [enqueueSnackbar, props, syncGameFromOrigin]);
    const onRemove = useCallback(async () => {
        await removeGame({
            variables: { gameIds: [props.game.guid] },
        });

        enqueueSnackbar("Remove game job has been put into queue");

        props.onRefetch();
    }, [enqueueSnackbar, props, removeGame]);
    const onClone = useCallback(async () => {
        await cloneGame({
            variables: { gameId: props.game.guid },
        });

        enqueueSnackbar("Clone game job has been put into queue");

        props.onRefetch();
    }, [cloneGame, enqueueSnackbar, props]);

    const loadSourceGameInfo = useCallback(async () => {
        await getSourceGameInfo({
            variables: { gameId: props.game.sourceGameId! },
        });
    }, [getSourceGameInfo, props.game.sourceGameId]);

    useEffect(() => {
        props.game.sourceGameId && loadSourceGameInfo();
    }, [loadSourceGameInfo, props.game.sourceGameId]);

    return (
        <Card>
            <Card.Img as={Avatar} variant="top" name={props.game.name} height={180} />
            <Card.Body>
                <Card.Title>
                    <Link to={`../${props.game.guid}/templates`}>{props.game.name}</Link>
                </Card.Title>
                <Card.Text className="small muted">{props.game.guid}</Card.Text>
                {props.game.description !== "" ? (
                    <Card.Text>{props.game.description}</Card.Text>
                ) : (
                    <Card.Text>
                        <span className="muted">No description provided</span>
                    </Card.Text>
                )}
                {props.game.sourceGameId && (
                    <Card.Text className="small muted">
                        <strong>Cloned from:</strong>
                        {sourceGameInfo?.getGame ? (
                            <span>
                                {sourceGameInfo.getGame.name} {sourceGameInfo.getGame.guid}
                            </span>
                        ) : (
                            <span>
                                {props.game.sourceGameId} {loading && <Spinner />}
                            </span>
                        )}
                    </Card.Text>
                )}
            </Card.Body>
            <Card.Body className="d-flex justify-content-between">
                <ButtonGroup size="sm">
                    <ConfirmationButton
                        prompt="Are you sure you want to delete this game"
                        onConfirm={onRemove}
                        variant="danger"
                        disabled={isLoading}
                        confirmationPhrase={props.game.guid}
                    >
                        {removeGameLoading && <Spinner size="sm" />}
                        Delete
                    </ConfirmationButton>
                    {props.game.sourceGameId && props.game.allowSyncFromOrigin && (
                        <ConfirmationButton
                            onConfirm={onSyncFromOrigin}
                            variant="warning"
                            disabled={isLoading}
                        >
                            {syncGameFromOriginLoading && <Spinner size="sm" />}
                            Sync from origin
                        </ConfirmationButton>
                    )}
                    <Button onClick={onClone} disabled={isLoading}>
                        {cloneGameLoading && <Spinner size="sm" />}
                        Clone
                    </Button>
                </ButtonGroup>
            </Card.Body>
        </Card>
    );
}
