import FormText from "react-bootstrap/FormText";
import { DurationPicker } from "@/components/common/DurationInput";
import { FormFieldProps } from "@/fields/types";
import { FormGroup } from "@/fields/components/FormGroup";
import { useEditEntityFormController } from "@/components/forms/EditEntityForm/hooks/useEditEntityFormController";

export function DurationFormInput(props: FormFieldProps) {
    const { field, fieldState } = useEditEntityFormController(props, "value");

    return (
        <FormGroup {...props} fieldState={fieldState}>
            <DurationPicker {...field} />
            {fieldState.error && (
                <FormText className="text-danger">{fieldState.error.message}</FormText>
            )}
        </FormGroup>
    );
}
