import { gql } from "@apollo/client";
import { JOB_FRAGMENT } from "@/graphql/fragments/job.fragment";
import { makeGqlMutationHook } from "@/graphql/util";
import { RemoveJobMutation, RemoveJobMutationVariables } from "@/gql";

const SCHEMA = gql`
    ${JOB_FRAGMENT}

    mutation removeJob($jobId: String!, $queueType: QueueType!) {
        removeJob(jobId: $jobId, queueType: $queueType) {
            ...Job
        }
    }
`;

export const useRemoveJob = makeGqlMutationHook<RemoveJobMutation, RemoveJobMutationVariables>(
    SCHEMA,
);
