import { gql } from "@apollo/client";
import { USER_PERMISSIONS_FRAGMENT } from "@/graphql/fragments/user-permissions.fragment";

export const GAME_INFO_FRAGMENT = gql`
    ${USER_PERMISSIONS_FRAGMENT}

    fragment GameInfo on GameInfo {
        name
        sourceGameId
        guid
        meta {
            deployFlags
        }
        description
        allowSyncFromOrigin
        creatorId
        createdAt
        updatedAt
        deletedAt
        userPermissions {
            ...UserPermission
        }
    }
`;
