import { ChangeEventHandler, memo, useCallback, useMemo } from "react";
import Form from "react-bootstrap/Form";
import { useGlobalEnum } from "@/store/games/slices";
import { useCellContext } from "../../contexts/CellContext";
import { ErrorBadge } from "../../components/ErrorBadge";
import { InputProps } from "../../types";
import { useTableContext } from "../../contexts/TableContext";
import { useFieldController } from "../../hooks/useFieldController";
import { EnumTableCell } from "./EnumTableCell";

export const EnumFieldComponent = memo(function EnumFieldComponent(props: InputProps) {
    const { readonly } = useTableContext();
    const { templateParam } = useCellContext();

    const controller = useFieldController(props.name);

    if (!templateParam.meta.enumId) {
        throw new Error(`Enum parameter ${templateParam.name} doesn't reference a enum`);
    }

    const enumDef = useGlobalEnum(templateParam.meta.enumId);
    const onChange = useCallback<ChangeEventHandler<HTMLSelectElement>>(
        (event) => controller.field.onChange(event.currentTarget.value),
        [controller.field],
    );
    const selectOptions = useMemo(() => {
        if (!enumDef?.meta.enum?.enumValues) return [];

        const enumValues = enumDef.meta.enum?.enumValues;

        return enumValues.map((option) => {
            const optionKey = option.name ?? `_key-${option.value}-${option.name}`;

            return (
                <option key={optionKey} value={option.value}>
                    {enumDef.name}.{option.name}
                </option>
            );
        });
    }, [enumDef]);

    if (!enumDef) return <ErrorBadge messageCode="MISSING_ENUM_DEF" />;

    return readonly ? (
        <EnumTableCell {...props} />
    ) : (
        <Form.Select
            ref={controller.field.ref}
            size="sm"
            defaultValue={controller.field.value as number}
            onChange={onChange}
        >
            {selectOptions}
        </Form.Select>
    );
});
