import { useMemo } from "react";
import isNil from "lodash/isNil";
import { useGlobalEnum } from "@/store/games/slices";
import { useCellContext } from "../../contexts/CellContext";
import { ErrorBadge } from "../../components/ErrorBadge";
import { InputProps } from "../../types";
import { useFieldController } from "../../hooks/useFieldController";

export function EnumTableCell(props: InputProps) {
    const { templateParam } = useCellContext();

    if (!templateParam.meta.enumId) {
        throw new Error(`Enum parameter ${templateParam.name} doesn't reference a enum`);
    }

    const controller = useFieldController(props.name);
    const value = controller.field.value;
    const globalEnum = useGlobalEnum(templateParam.meta.enumId);
    const selectedEnumValue = useMemo(() => {
        return globalEnum?.meta.enum?.enumValues.find((enumPair) => enumPair.value == value);
    }, [globalEnum, value]);

    if (isNil(value) || !globalEnum || isNil(selectedEnumValue))
        return <ErrorBadge messageCode="INCORRECT_ENUM_VALUE" />;

    const displayedValue = `${globalEnum.name}.${selectedEnumValue.name}`;

    return <span className="form-control-plaintext font-monospace">{displayedValue}</span>;
}
