import { useCallback, useMemo } from "react";
import { bootstrapDialog, create, NiceModalHocProps, show, useModal } from "@ebay/nice-modal-react";
import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import Form from "react-bootstrap/Form";
import Stack from "react-bootstrap/Stack";
import Button from "react-bootstrap/Button";
import { useLazyGetTemplate } from "@/graphql/templates";
import { AddDescriptionParamsMutationVariables, DataType, TemplateParam } from "@/gql";
import { useAddDescriptionParams } from "@/graphql/template-params";

type Props = {
    gameId: string;
    templateId: string;
    templateParams: TemplateParam[];
};

type SelectOption = {
    name: string;
    value: string;
};

const allowedParameterTypes = new Set([DataType.String, DataType.Integer, DataType.Float]);

const AddDescriptiveParameters = create(function AddDescriptiveParameters(props: Props) {
    const modal = useModal();
    const dialog = useMemo(() => bootstrapDialog(modal), [modal]);
    const form = useForm<AddDescriptionParamsMutationVariables>({
        defaultValues: {
            gameId: props.gameId,
            templateId: props.templateId,
            descriptionFrom: null,
            titleFrom: null,
        },
        mode: "onChange",
    });
    const selectOptions = useMemo(
        () =>
            props.templateParams
                .filter((tp) => allowedParameterTypes.has(tp.type))
                .map<SelectOption>((tp) => ({
                    name: tp.name,
                    value: tp.guid,
                })),
        [props.templateParams],
    );
    const onCompleted = useCallback(() => {
        modal.resolve();
        modal.hide();
    }, [modal]);
    const [addDescriptionParams, { loading: loading }] = useAddDescriptionParams({
        onCompleted,
    });
    const onSubmit = useCallback(
        async (data: AddDescriptionParamsMutationVariables) => {
            await addDescriptionParams({
                variables: data,
            });
        },
        [addDescriptionParams],
    );

    return (
        <Modal {...dialog} keyboard backdrop>
            <Modal.Header>
                <Modal.Title>Add descriptive parameters</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Stack as={Form} onSubmit={form.handleSubmit(onSubmit)} gap={2}>
                    <Form.Group>
                        <Form.Label>Copy Title field from</Form.Label>
                        <Form.Select {...form.register("titleFrom")}>
                            {selectOptions.map((option) => (
                                <option key={option.value} value={option.value}>
                                    {option.name}
                                </option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Copy Description field from</Form.Label>
                        <Form.Select {...form.register("descriptionFrom")}>
                            {selectOptions.map((option) => (
                                <option key={option.value} value={option.value}>
                                    {option.name}
                                </option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                    <Button type="submit" disabled={!form.formState.isValid || loading}>
                        Add descriptive parameters
                    </Button>
                </Stack>
            </Modal.Body>
        </Modal>
    );
});

export function useAddDescriptiveParameters() {
    const [getTemplate] = useLazyGetTemplate();
    const { enqueueSnackbar } = useSnackbar();

    return useCallback(
        async (props: Omit<Props, "templateParams">) => {
            const template = await getTemplate({
                variables: { gameId: props.gameId, guid: props.templateId },
            });

            if (!template.data?.template.templateParams) {
                enqueueSnackbar("Could not get template parameters", { variant: "warning" });
            }

            return show<void, Props & NiceModalHocProps, Props>(AddDescriptiveParameters, {
                gameId: props.gameId,
                templateId: props.templateId,
                templateParams: template.data?.template.templateParams ?? [],
            });
        },
        [enqueueSnackbar, getTemplate],
    );
}
