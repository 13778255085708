import { useCallback, useState } from "react";
import { Link } from "@tanstack/react-router";
import Dropdown from "react-bootstrap/Dropdown";
import { useAuth } from "@/hooks/useAuth";
import { Avatar } from "@/components/common/Avatar";
import { profilePageRoute } from "@/pages/Profile";
import { gamesRoute } from "@/pages/GamesPage";
import { wizardryRoute } from "@/pages/wizardry/WizardryLayout";

export function UserDropdown() {
    const { user, logout } = useAuth();
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const onToggle = useCallback(() => {
        setDropdownOpen((prevState) => !prevState);
    }, []);

    if (!user) return null;

    return (
        <Dropdown onToggle={onToggle} show={isDropdownOpen}>
            <Dropdown.Toggle
                as="a"
                className="d-flex align-items-center link-light text-decoration-none"
            >
                <Avatar
                    width={48}
                    height={48}
                    name={`${user.firstname} ${user.lastname}`}
                    className="rounded-circle m-2 me-0"
                />
            </Dropdown.Toggle>
            <Dropdown.Menu className="text-small shadow">
                <Dropdown.Item as={Link} to={profilePageRoute.to}>
                    Profile
                </Dropdown.Item>
                <Dropdown.Item as={Link} to={gamesRoute.to}>
                    Projects
                </Dropdown.Item>
                {user.canDoWizardry && (
                    <Dropdown.Item as={Link} to={wizardryRoute.to}>
                        Wizardry
                    </Dropdown.Item>
                )}
                <Dropdown.Item>
                    <hr className="dropdown-divider" />
                </Dropdown.Item>
                <Dropdown.Item href="#" onClick={logout}>
                    Sign out
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    );
}
