import { DataType } from "@/gql";
import { DataTypeField } from "../../types";
import { ListAdditionalParameters } from "./ListAdditionalParameters";
import { ListEditableTableCell } from "./ListEditableTableCell";
import { ListFormField } from "./ListFormField";
import { ListDisplayField } from "./ListDisplayField";

export const ListDataType: DataTypeField = {
    dataType: DataType.List,
    defaultValue: () => [],
    tableCell: ListEditableTableCell,
    fieldDisplay: ListDisplayField,
    formField: ListFormField,
    formFieldDisplayOptions: {
        md: 6,
    },
    additionalParameterFields: ListAdditionalParameters,
    tableCellDisplayOptions: (templateParam) =>
        templateParam.subType === DataType.EntityRef ||
        templateParam.subType === DataType.InjectedRef
            ? {
                  minSize: 400,
                  enableSorting: false,
              }
            : {
                  minSize: 200,
                  maxSize: 350,
                  enableSorting: false,
              },
};
