import { useCallback, useMemo } from "react";
import { TableColumn } from "react-data-table-component";
import sortBy from "lodash/sortBy";
import keyBy from "lodash/keyBy";
import mapValues from "lodash/mapValues";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import { DeployDirectory } from "@/gql";
import { AppDataTable } from "@/components/common/DataTable";
import { stringify } from "@/utils/stringify";
import { useFilePreviewModal } from "@/components/modals2/FilePreviewModal";
import {
    useLazyGetArtifactEntitiesFile,
    useLazyGetArtifactMetadataFile,
    useLazyGetArtifactVersionsFile,
} from "@/graphql/deploy";
import { ConsumerArtifact } from "./types";

type ConsumerArtifactData = {
    name: string;
    data: () => Promise<string>;
    bucketPath?: string;
};

type Props = {
    deployedOn: DeployDirectory[];
    consumerArtifact: ConsumerArtifact;
};

function resolveBucketPath({
    gameId,
    consumerId,
    deployDirectoryPath,
    entityName,
    entityVersion,
}: {
    gameId: string;
    consumerId: string;
    deployDirectoryPath: string;
    entityName: string;
    entityVersion: string;
}) {
    return `${window.env.bucketUrl}/${window.env.bucketPathPrefix}entities/${gameId}/${consumerId}/${deployDirectoryPath}/${entityName}_v${entityVersion}.json`;
}

export function ArtifactDetails(props: Props) {
    const [getArtifactEntitiesFile] = useLazyGetArtifactEntitiesFile();
    const [getArtifactMetadataFile] = useLazyGetArtifactMetadataFile();
    const [getArtifactVersionsFile] = useLazyGetArtifactVersionsFile();
    const { gameId, guid } = props.consumerArtifact;

    const bucketFilesMap = useMemo(
        () =>
            mapValues(
                keyBy(props.consumerArtifact?.dataFiles, (v) => v.id),
                (v) => `data/${v.name}_v${v.version}.json`,
            ),
        [props.consumerArtifact?.dataFiles],
    );
    const bucketPathsMap = useMemo(
        () =>
            mapValues(
                keyBy(props.consumerArtifact?.dataFiles, (v) => v.id),
                // (v) => `data/${v.name}_v${v.version}.json`,
                (v) =>
                    resolveBucketPath({
                        gameId: props.consumerArtifact.gameId,
                        consumerId: props.consumerArtifact.consumerId,
                        deployDirectoryPath: "%DD%",
                        entityName: v.name,
                        entityVersion: v.version,
                    }),
            ),
        [
            props.consumerArtifact.consumerId,
            props.consumerArtifact?.dataFiles,
            props.consumerArtifact.gameId,
        ],
    );
    const data = useMemo<ConsumerArtifactData[]>(
        () => [
            {
                name: "versions.json",
                data: async () => {
                    const data = await getArtifactVersionsFile({ variables: { gameId, guid } });
                    return stringify(data.data?.artifactVersionsFile);
                },
            },
            {
                name: "config_meta.json",
                data: async () => {
                    const data = await getArtifactMetadataFile({ variables: { gameId, guid } });
                    return stringify(data.data?.artifactMetadataFile);
                },
            },
            ...sortBy(
                (props.consumerArtifact.dataFiles ?? [])
                    .filter((v) => Boolean(bucketPathsMap[v.id]))
                    .map(
                        (dataFile): ConsumerArtifactData => ({
                            name: bucketFilesMap[dataFile.id]!,
                            data: async () => {
                                const data = await getArtifactEntitiesFile({
                                    variables: { gameId, guid, name: dataFile.name },
                                });
                                return stringify(
                                    data.data?.artifactEntitiesFile,
                                    "__typename",
                                    "bucketPath",
                                );
                            },
                            bucketPath: bucketPathsMap[dataFile.id],
                        }),
                    ),
                ["name"],
            ),
        ],
        [
            bucketFilesMap,
            bucketPathsMap,
            gameId,
            getArtifactEntitiesFile,
            getArtifactMetadataFile,
            getArtifactVersionsFile,
            guid,
            props.consumerArtifact.dataFiles,
        ],
    );
    const showFilePreviewModal = useFilePreviewModal();
    // const { modal: TextModalComponent, openModal2 } = useModal(FilePreviewModal);
    const onDetailsClicked = useCallback(
        async (row: ConsumerArtifactData) => {
            await showFilePreviewModal({
                data: row.data,
                fileName: row.name,
            });
        },
        [showFilePreviewModal],
    );
    const onDetailsDownload = useCallback(async (row: ConsumerArtifactData) => {
        try {
            // @ts-ignore
            const fsHandle = await window.showSaveFilePicker({
                suggestedName: row.name,
            });
            const writable = await fsHandle.createWritable();
            await writable.write(stringify(row.data, "__typename", "bucketPath"));
            await writable.close();
        } catch (e) {
            alert(
                "Failed to save file. This either means that you have an older browser or the API was changed",
            );
        }
    }, []);
    const columns = useMemo<TableColumn<ConsumerArtifactData>[]>(() => {
        return [
            {
                name: "Actions",
                wrap: true,
                cell: (row) => (
                    <ButtonGroup size="sm">
                        <Button onClick={() => onDetailsClicked(row)}>Preview</Button>
                        <Button onClick={() => onDetailsDownload(row)}>Download</Button>
                        {row.bucketPath &&
                            props.deployedOn.map((dd) => {
                                const path = row.bucketPath?.replace("%DD%", dd.path);
                                return (
                                    <Button
                                        key={dd.guid}
                                        href={path}
                                        title={path}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Open in bucket: {dd.name}
                                    </Button>
                                );
                            })}
                    </ButtonGroup>
                ),
            },
            {
                name: "Entry",
                selector: (row) => row.name,
            },
        ];
    }, [onDetailsClicked, onDetailsDownload, props.deployedOn]);

    return <AppDataTable data={data} columns={columns} />;
}
