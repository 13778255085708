import { FieldDisplay } from "@/fields/types";
import { EntityRefRenderer } from "@/components/common/EntityRefRenderer";

export function InjectedRefDisplayField(props: FieldDisplay<string>) {
    const { depth = 0 } = props;

    return (
        <div className="ps-2">
            <EntityRefRenderer
                templateId={props.templateParam.meta.templateId!}
                entityId={props.value}
                depth={depth + 1}
            />
        </div>
    );
}
