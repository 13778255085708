import Button from "react-bootstrap/Button";
import { FaTrash } from "react-icons/fa";
import { EditEntityBtn2 } from "@/components/common/EditEntityBtn2";
import { useFieldController } from "@/components/common/Table/EntitiesTable2/hooks/useFieldController";
import { useCellContext } from "../../contexts/CellContext";
import { ContextActionsProps } from "../../types";

export function InjectedRefContextActions(props: ContextActionsProps) {
    const { templateParam, gameId } = useCellContext();
    const { templateId, isRequired } = templateParam.meta;
    const controller = useFieldController(props.name);

    return (
        <>
            {controller.field.value !== null && templateId && (
                <EditEntityBtn2
                    className="p-1"
                    variant="primary"
                    gameId={gameId}
                    entityId={controller.field.value as string}
                />
            )}
            {!isRequired && (
                <Button className="float-start p-1" onClick={() => controller.field.onChange(null)}>
                    <FaTrash />
                </Button>
            )}
        </>
    );
}
