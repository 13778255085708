import Container from "react-bootstrap/Container";
import { ExpanderComponentProps } from "react-data-table-component";
import { TemplateParamMatchModel } from "@/gql";

export function TemplateParamExpanded(props: ExpanderComponentProps<TemplateParamMatchModel>) {
    return (
        <Container fluid className="ps-5 pe-2 py-5 bg-body-secondary">
            {props.data.matchErrors.length > 0 && (
                <>
                    <h5>Parameter matching errors</h5>
                    <ul>
                        {props.data.matchErrors.map((error, index) => (
                            <li key={index}>
                                <p>{error}</p>
                            </li>
                        ))}
                        <li></li>
                    </ul>
                </>
            )}
        </Container>
    );
}
