import { DataType } from "@/gql";
import { DataTypeField } from "../../types";
import { EnumFormField } from "./formField";
import { AdditionalEnumParameterFields } from "./AdditionalEnumParameterFields";
import { EnumEditableTableCell } from "./EnumEditableTableCell";
import { EnumDisplayField } from "./EnumDisplayField";

export const EnumDataType: DataTypeField = {
    dataType: DataType.Enum,
    defaultValue: (templateParam) => {
        return templateParam.meta.enum?.enumValues[0]?.value;
    },
    tableCell: EnumEditableTableCell,
    fieldDisplay: EnumDisplayField,
    formField: EnumFormField,
    additionalParameterFields: AdditionalEnumParameterFields,
    tableCellDisplayOptions: {
        minSize: 250,
        enableSorting: false,
    },
};
