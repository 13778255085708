import { ExpanderComponentProps } from "react-data-table-component";
import { GameInfo } from "@/gql";
import { AddUserForm, UsersTable } from "@/components/roles-page/components";
import { useAuth } from "@/hooks/useAuth";
import { UpdateGameForm } from "@/components/forms/UpdateGameForm";
import { useGetGamesQuery } from "@/graphql/wizardry/games";

export function GameInfoExpanded(props: ExpanderComponentProps<GameInfo>) {
    const { user } = useAuth();
    const getGamesQuery = useGetGamesQuery();

    return (
        <div className="p-2 bg-body-secondary">
            <div>
                <h4>Project settings</h4>
                <UpdateGameForm
                    gameDetails={{
                        description: props.data.description,
                        name: props.data.name,
                        guid: props.data.guid,
                        allowSyncFromOrigin: props.data.allowSyncFromOrigin,
                        sourceGameId: props.data.sourceGameId,
                    }}
                />
            </div>
            <hr />
            <div>
                <h4>Add user to project</h4>
                <AddUserForm gameId={props.data.guid} onSubmit={getGamesQuery.refetch} />
            </div>
            <hr />
            {user?.guid && (
                <div>
                    <h4>Users and permissions</h4>
                    <UsersTable
                        gameId={props.data.guid}
                        creatorId={props.data.creatorId}
                        userPermissions={props.data.userPermissions}
                        currentUserId={user.guid}
                    />
                </div>
            )}
        </div>
    );
}
