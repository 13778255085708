import { useMemo, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { Helmet } from "react-helmet-async";
import { createRoute } from "@tanstack/react-router";
import { useGetGamesQuery } from "@/graphql/game";
import { DataAwaiter2 } from "@/components/common/DataAwaiter2";
import { Select } from "@/components/common/Select";
import { ImportSummary } from "@/components/import-page/ImportSummary";
import { layoutWithBreadcrumbs } from "@/layouts/LayoutWithBreadcrumbs";
import { withRBAC } from "@/components/rbac";
import { Permission } from "@/gql";

const ImportPage = withRBAC(
    function ImportPage() {
        const { gameId } = importPageRoute.useParams();
        const getGamesQuery = useGetGamesQuery();
        const [selectedSourceGameId, setSelectedSourceGameId] = useState<string | undefined>();
        const gamesList = useMemo(() => {
            return getGamesQuery.data?.result.filter((v) => v.guid !== gameId);
        }, [gameId, getGamesQuery.data?.result]);
        const selectedGame = useMemo(() => {
            return getGamesQuery.data?.result.find((v) => v.guid === selectedSourceGameId);
        }, [getGamesQuery.data?.result, selectedSourceGameId]);

        return (
            <DataAwaiter2 {...getGamesQuery}>
                {() => (
                    <Row>
                        <Helmet title="Import" />
                        <Col md={8}>
                            <Form.Group className="mb-2">
                                <Form.Label>Source game</Form.Label>
                                <Select
                                    options={gamesList}
                                    value={selectedGame}
                                    getOptionLabel={(v) => `${v.name} (${v.guid})`}
                                    getOptionValue={(v) => v.guid}
                                    onChange={(v) => v && setSelectedSourceGameId(v.guid)}
                                />
                            </Form.Group>
                        </Col>
                        {selectedSourceGameId && gameId && (
                            <Col md={12}>
                                <ImportSummary
                                    importGameId={gameId}
                                    exportGameId={selectedSourceGameId}
                                />
                            </Col>
                        )}
                    </Row>
                )}
            </DataAwaiter2>
        );
    },
    {
        requiredPermissions: [Permission.StructureRead, Permission.StructureWrite],
    },
);

export default ImportPage;

export const importPageRoute = createRoute({
    getParentRoute: () => layoutWithBreadcrumbs,
    path: "import",
    component: ImportPage,
});
