import { gql } from "@apollo/client";
import { DeleteAssetMutation, DeleteAssetMutationVariables } from "@/gql";
import { makeGqlMutationHook } from "../util";

const SCHEMA = gql`
    mutation deleteAsset($guid: String!, $gameId: String!) {
        result: removeAsset(guid: $guid, gameId: $gameId) {
            guid
        }
    }
`;

export const useDeleteAssetMutation = makeGqlMutationHook<
    DeleteAssetMutation,
    DeleteAssetMutationVariables
>(SCHEMA);
