import { useMemo } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { NestedFormInput } from "@/components/common/forms/form-inputs/FormInput";
import { DataType } from "@/gql";
import { ConnectForm } from "@/components/common/forms/ConnectForm";
import { AdditionalParameterFields } from "../../types";

const rules = { required: false };

export const AdditionalIntegerFields: AdditionalParameterFields = (props) => {
    const valueAsNumber = useMemo(
        () => [DataType.Integer, DataType.Float].includes(props.type),
        [props.type],
    );

    return (
        <ConnectForm>
            {() => (
                <Row>
                    <Col>
                        <NestedFormInput
                            name="meta.defaultValue"
                            title="Default value"
                            rules={rules}
                            valueAsNumber={valueAsNumber}
                        />
                    </Col>
                </Row>
            )}
        </ConnectForm>
    );
};
