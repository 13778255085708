import { useMemo } from "react";
import Badge from "react-bootstrap/Badge";
import { AllowedListSubType } from "@/types";
import { useAddChangeRecord } from "@/components/common/Table/EntitiesTable/contexts/TableCellContext";
import { DataType, Maybe } from "@/gql";
import { EntityRefRenderer } from "@/components/common/EntityRefRenderer";
import { CasedBadge } from "@/components/common/CasedBadge";
import { TableCell } from "../../types";

export const ListTableCellDisplay: TableCell = (props) => {
    const referencedTemplate = props.templateParam.meta.templateId;
    const subType = props.templateParam.subType as Maybe<AllowedListSubType>;
    const { value } = useAddChangeRecord<string[]>(props);
    const transformedValue = useMemo(() => {
        if (subType === null) throw new Error(`${subType} is not an allowed subtype`);
        switch (subType) {
            case DataType.Float:
            case DataType.Integer:
            case DataType.Boolean:
                return value.map(String);
            case DataType.String:
            case DataType.LocalizedString:
            case DataType.EntityRef:
            case DataType.InjectedRef:
                return value;
            default:
                throw new Error(`${subType} is not an allowed subtype`);
        }
    }, [subType, value]);

    if (subType === DataType.EntityRef || subType === DataType.InjectedRef) {
        if (!referencedTemplate) {
            return <Badge bg="danger">Missing reference template</Badge>;
        }

        if (transformedValue === null) {
            return <Badge bg="danger">Missing value. Please report</Badge>;
        }

        return (
            <div className="d-flex flex-column gap-2 p-1 m-1 border border-1 rounded-1">
                {transformedValue.length === 0 ? (
                    <CasedBadge>Empty list</CasedBadge>
                ) : (
                    transformedValue.map((value, index) => {
                        return (
                            <span key={index} className="border border-1 rounded-1 shadow-sm">
                                <EntityRefRenderer
                                    entityId={value}
                                    templateId={referencedTemplate}
                                />
                            </span>
                        );
                    })
                )}
            </div>
        );
    }

    return <span>[{transformedValue.join(", ")}]</span>;
};
