import { gql } from "@apollo/client";

export const JOB_FRAGMENT = gql`
    fragment Job on JobModel {
        id
        jobId
        name
        data
        failedReason
        attemptsMade
        timestamp
        progress
        status
        stacktrace
        returnValue
        processedOn
        finishedOn
    }
`;
