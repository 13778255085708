import { useCallback, useMemo } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { FaPlus } from "react-icons/fa";
import {
    closestCenter,
    DndContext,
    DragEndEvent,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
} from "@dnd-kit/core";
import { arrayMove, SortableContext, sortableKeyboardCoordinates } from "@dnd-kit/sortable";
import { restrictToParentElement } from "@dnd-kit/modifiers";
import { Template, TemplateDisplay } from "@/gql";
import { useEditEntityFormContext } from "../hooks/useEditEntityFormContext";
import { TemplateViewSortableItem } from "./TemplateViewSortableItem";

type Props = {
    ids: string[];
    fieldName: "fieldsOrder" | "refFieldsOrder";
    template: Template;
    templateDisplay: TemplateDisplay;
};

export function TemplateViewSettings(props: Props) {
    const form = useEditEntityFormContext();
    const items = form.watch(props.fieldName);
    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        }),
    );
    const onChange = useCallback(
        (newIds: string[]) => {
            form.setValue(props.fieldName, newIds, {
                shouldDirty: true,
                shouldTouch: true,
                shouldValidate: true,
            });
        },
        [form, props.fieldName],
    );
    const handleDragEnd = useCallback(
        (event: DragEndEvent) => {
            const { active, over } = event;

            if (active.id !== over?.id) {
                const oldIndex = items.indexOf(active.id as string);
                const newIndex = items.indexOf(over?.id as string);

                const newIds = arrayMove(items, oldIndex, newIndex);

                onChange(newIds);
            }
        },
        [items, onChange],
    );
    const onAdd = useCallback(
        (guid: string) => {
            onChange([...props.ids, guid]);
        },
        [onChange, props.ids],
    );
    const onRemove = useCallback(
        (removedId: string) => {
            const ids = props.ids.filter((id) => id !== removedId);
            onChange(ids);
        },
        [onChange, props.ids],
    );
    const templateParams = useMemo(() => {
        return props.ids
            .map((id) => (props.template?.templateParams ?? []).find((tp) => tp.guid === id))
            .filter(Boolean);
    }, [props.ids, props.template]);
    const optionsToAdd = useMemo(() => {
        return (props.template!.templateParams ?? []).filter((v) => !props.ids.includes(v.guid));
    }, [props.ids, props.template]);

    return (
        <div className="d-flex flex-wrap border rounded border-1 p-3 align-items-center gap-2 m-0">
            <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragEnd={handleDragEnd}
                modifiers={[restrictToParentElement]}
            >
                <SortableContext items={items}>
                    {templateParams.map((tp) => (
                        <TemplateViewSortableItem
                            key={tp.guid}
                            id={tp.guid}
                            data={tp}
                            onRemove={() => onRemove(tp.guid)}
                        >
                            {(tp) => <span className="pe-5">{tp.name}</span>}
                        </TemplateViewSortableItem>
                    ))}
                    <div className="ms-auto p-2 d-flex flex-nowrap align-items-center justify-content-end">
                        <Dropdown>
                            <Dropdown.Toggle disabled={optionsToAdd.length === 0} size="sm">
                                <FaPlus />
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                {optionsToAdd.map((option) => (
                                    <Dropdown.Item
                                        key={option.guid}
                                        onClick={() => onAdd(option.guid)}
                                    >
                                        {option.name}
                                    </Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </SortableContext>
            </DndContext>
        </div>
    );
}
