import { gql } from "@apollo/client";
import { UpdateConsumerMutation, UpdateConsumerMutationVariables } from "@/gql";
import { CONSUMER_POPULATED_FRAGMENT } from "../fragments/consumer-populated.fragment";
import { makeGqlMutationHook } from "../util";

const UPDATE_CONSUMER = gql`
    ${CONSUMER_POPULATED_FRAGMENT}

    mutation updateConsumer(
        $description: String!
        $guid: String!
        $gameId: String!
        $name: String!
        $templates: [String!]
        $templateParameters: [String!]
    ) {
        result: updateConsumer(
            guid: $guid
            gameId: $gameId
            description: $description
            name: $name
            templates: $templates
            templateParameters: $templateParameters
        ) {
            ...ConsumerPopulated
        }
    }
`;

export const useUpdateConsumerMutation = makeGqlMutationHook<
    UpdateConsumerMutation,
    UpdateConsumerMutationVariables
>(UPDATE_CONSUMER);
