import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { NestedFormSelect, SelectOption } from "@/components/common/forms/form-inputs/FormSelect";
import { useGlobalParameters } from "@/store/games/slices";
import { DataType } from "@/gql";
import { entityToSelectOption } from "@/utils/entityToSelectOption";
import { ConnectForm } from "@/components/common/forms/ConnectForm";
import { AdditionalParameterFields } from "../../types";

export const ScriptAdditionalParameters: AdditionalParameterFields = () => {
    const availableScriptScopes = useGlobalParameters<SelectOption>(
        DataType.Script,
        entityToSelectOption,
    );

    return (
        <ConnectForm>
            {() => {
                return (
                    <Row className="gy-4">
                        <Col>
                            <NestedFormSelect
                                name="meta.scriptScopes"
                                title="Script scopes"
                                options={availableScriptScopes}
                                multiple
                            />
                        </Col>
                    </Row>
                );
            }}
        </ConnectForm>
    );
};
