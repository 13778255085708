import { forwardRef, useCallback } from "react";
import {
    OptionProps,
    SingleValueProps,
    components,
    OnChangeValue,
    ValueContainerProps,
    StylesConfig,
    GroupBase,
} from "react-select";
import AsyncSelect from "react-select/async";
import Select from "react-select/base";
import { useSnackbar } from "notistack";
import { useLazyGetAssets } from "@/graphql/assets";
import { AssetRenderer } from "@/components/common/AssetRenderer";
import { useCellContext } from "../../contexts/CellContext";

interface ComponentOption {
    readonly name: string;
    readonly value: string;
    readonly gameId: string;
}

const Option = (props: OptionProps<ComponentOption>) => (
    <components.Option {...props}>
        <AssetRenderer guid={props.data.value} gameId={props.data.gameId} />
    </components.Option>
);

const ValueContainer = (props: ValueContainerProps<ComponentOption>) => {
    return (
        <components.ValueContainer {...props}>
            {props.getValue().map((value) => (
                <AssetRenderer key={value.value} guid={value.value} gameId={value.gameId} />
            ))}
        </components.ValueContainer>
    );
};

const SingleValue = (props: SingleValueProps<ComponentOption>) => (
    <components.SingleValue {...props}>
        <AssetRenderer guid={props.data.value} gameId={props.data.gameId} />
    </components.SingleValue>
);

interface Props {
    value: string;
    onChange: (value: string) => void;
    name?: string;
    className?: string;
    clearable?: boolean;
}

const selectStyles: StylesConfig<ComponentOption, false> = {
    menuPortal: (baseStyles) => ({
        ...baseStyles,
        zIndex: 10001,
    }),
};

const selectComponents = {
    Option,
    SingleValue,
    ValueContainer,
};

export const AssetSelector = forwardRef<
    Select<{ name: string; gameId: string; value: string }, false, GroupBase<ComponentOption>>,
    Props
>(function AssetSelector(props: Props, ref) {
    const { gameId } = useCellContext();
    const { name, value, onChange, clearable = false } = props;
    const [getAssetsQuery] = useLazyGetAssets();
    const { enqueueSnackbar } = useSnackbar();
    const loadOptions = useCallback(async (): Promise<ComponentOption[]> => {
        const { data } = await getAssetsQuery({ variables: { gameId } });

        if (!data) {
            enqueueSnackbar("Failed to load assets", {
                variant: "error",
                autoHideDuration: 3000,
            });
            return [];
        }

        return data.result.map((asset) => {
            return {
                value: asset.guid,
                name: asset.name,
                gameId: asset.gameId,
            };
        });
    }, [gameId, getAssetsQuery, enqueueSnackbar]);

    const onSelectChange = useCallback(
        (newValue: OnChangeValue<ComponentOption, false>) => {
            onChange(newValue?.value ?? "");
        },
        [onChange],
    );

    return (
        <AsyncSelect
            ref={ref}
            isMulti={false}
            className={props.className}
            isClearable={clearable}
            cacheOptions
            defaultValue={value ? { name: "%name%", gameId: "", value } : null}
            name={name}
            menuPortalTarget={document.body}
            defaultOptions
            styles={selectStyles}
            loadOptions={loadOptions}
            components={selectComponents}
            onChange={onSelectChange}
        />
    );
});
