import { gql } from "@apollo/client";
import { GetConsumersQuery, GetConsumersQueryVariables } from "@/gql";
import { CONSUMER_FRAGMENT } from "@/graphql/fragments/consumer.fragment";
import { makeGqlQueryHook } from "../util";

const GET_CONSUMERS = gql`
    ${CONSUMER_FRAGMENT}

    query getConsumers($gameId: String!) {
        result: getConsumers(gameId: $gameId) {
            ...Consumer
            templatesAssigned
            templateParamsAssigned
        }
    }
`;

export const useGetConsumers = makeGqlQueryHook<GetConsumersQuery, GetConsumersQueryVariables>(
    GET_CONSUMERS,
);
