import { gql } from "@apollo/client";
import { GetConsumerForEditQuery, GetConsumerForEditQueryVariables } from "@/gql";
import { CONSUMER_FRAGMENT } from "../fragments/consumer.fragment";
import { TEMPLATE_SHORT_FRAGMENT } from "../fragments/template.fragment";
import { TEMPLATE_PARAM_SHORT_FRAGMENT } from "../fragments/template-param.fragment";
import { makeGqlQueryHook } from "../util";

const SCHEMA = gql`
    ${CONSUMER_FRAGMENT}
    ${TEMPLATE_SHORT_FRAGMENT}
    ${TEMPLATE_PARAM_SHORT_FRAGMENT}

    query getConsumerForEdit($gameId: String!, $consumerId: String) {
        templates(gameId: $gameId) {
            ...TemplateShort
            templateParams {
                ...TemplateParamShort
            }
        }

        consumer(gameId: $gameId, consumerId: $consumerId) {
            ...Consumer
            templates {
                ...TemplateShort
            }
            templateParams {
                ...TemplateParamShort
            }
        }
    }
`;

export const useGetConsumerForEdit = makeGqlQueryHook<
    GetConsumerForEditQuery,
    GetConsumerForEditQueryVariables
>(SCHEMA);
