import { gql, useQuery } from "@apollo/client";
import { useCallback } from "react";
import { createRoute } from "@tanstack/react-router";
import { DataAwaiter2 } from "@/components/common/DataAwaiter2";
import { GlossariesTable } from "@/components/translation/GlossariesTable";
import { GetGlossariesPageQuery, GetGlossariesPageQueryVariables } from "@/gql";
import {
    GLOSSARY_ENTRY_FRAGMENT,
    GLOSSARY_ENTRY_VALUE_FRAGMENT,
} from "@/graphql/fragments/glossary.fragment";
import { LOCALE_FRAGMENT } from "@/graphql/fragments/locale.fragment";
import { translationPageLayoutRoute } from "@/pages/translation/TranslationPageLayout";

const SCHEMA = gql`
    ${GLOSSARY_ENTRY_FRAGMENT}
    ${GLOSSARY_ENTRY_VALUE_FRAGMENT}
    ${LOCALE_FRAGMENT}

    query getGlossariesPage($gameId: String!) {
        getGlossaries(gameId: $gameId) {
            ...GlossaryEntry
            values {
                ...GlossaryEntryValue
            }
        }
        locales(gameId: $gameId) {
            ...Locale
        }
    }
`;

export default function GlossariesPage() {
    const { gameId } = glossariesPageRoute.useParams();
    const getGlossaries = useQuery<GetGlossariesPageQuery, GetGlossariesPageQueryVariables>(
        SCHEMA,
        { variables: { gameId: gameId! } },
    );
    const onRefetch = useCallback(() => getGlossaries.refetch(), [getGlossaries]);

    return (
        <DataAwaiter2 {...getGlossaries}>
            {(data) => <GlossariesTable gameId={gameId!} data={data} onRefetch={onRefetch} />}
        </DataAwaiter2>
    );
}

export const glossariesPageRoute = createRoute({
    getParentRoute: () => translationPageLayoutRoute,
    path: "glossaries",
    component: GlossariesPage,
});
