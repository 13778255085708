function hashCode(str: string) {
    return Array.from(str).reduce((hash, char) => {
        return char.charCodeAt(0) + ((hash << 5) - hash);
    }, 0);
}

function intToRGB(i: number) {
    const c = (i & 0x00ffffff).toString(16).toUpperCase();

    return "00000".substring(0, 6 - c.length) + c;
}

export function stringToRBG(string: string) {
    return intToRGB(hashCode(string));
}
