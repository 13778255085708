import { MouseEventHandler, useCallback, useMemo, useState } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import cn from "classnames";
import { DataType, TemplateParam } from "@/gql";
import { CasedButton } from "@/components/common/CasedButton";
import { useExpansionContext } from "@/components/common/Table/EntitiesTable2/contexts/ExpansionContext";
import { useCellContext } from "../../contexts/CellContext";
import { TableCell } from "../TableCell";
import { NestingContextProvider, useNestingContext } from "./context";

type Props<T> = {
    templateParam: TemplateParam;
    value: T;
    className?: string;
};

export function FieldRenderer<T>(props: Props<T>) {
    const { depth, maxDepth, shouldExpand } = useNestingContext();
    const { templateParam, contextEntity, type, subType } = useCellContext();
    const key = `${contextEntity.guid}-${templateParam.guid}`;
    const isCollapsibleType =
        props.templateParam.type === DataType.EntityRef ||
        props.templateParam.type === DataType.InjectedRef ||
        (props.templateParam.type === DataType.List &&
            (props.templateParam.subType === DataType.EntityRef ||
                props.templateParam.subType === DataType.InjectedRef));
    const { state, setState } = useExpansionContext();
    const [expanded, setExpanded] = useState(
        () => state.has(key) || (depth <= maxDepth && shouldExpand),
    );
    const [shouldExpandChildren, setShouldExpandChildren] = useState(expanded);
    const isRef =
        type === DataType.EntityRef ||
        type === DataType.InjectedRef ||
        subType === DataType.EntityRef ||
        subType === DataType.InjectedRef;

    const onClick = useCallback<MouseEventHandler<HTMLSpanElement>>(
        (event) => {
            if (isCollapsibleType) {
                if (event.altKey && event.button === 0) {
                    setShouldExpandChildren((prevState) => !prevState);
                }
                setState(key, !expanded);
                setExpanded((prevState) => !prevState);
            }
        },
        [expanded, isCollapsibleType, key, setState],
    );
    const ExpandIcon = useMemo(() => {
        if (!isCollapsibleType) return null;

        if (expanded) {
            return FaChevronUp;
        } else {
            return FaChevronDown;
        }
    }, [expanded, isCollapsibleType]);

    return (
        <div
            className={cn("d-flex flex-nowrap gap-1", props.className, {
                "align-items-center": !isRef,
                "flex-column": isRef,
                "align-items-start": isRef,
                "bg-white": depth % 2 === 0,
                "bg-body-tertiary": depth % 2 === 1,
            })}
        >
            <CasedButton className="font-monospace" variant="link" onClick={onClick} size="sm">
                {ExpandIcon && <ExpandIcon className="me-1" />}
                {props.templateParam.name}
            </CasedButton>{" "}
            <div className="ps-2 flex-grow-1 w-100">
                {!isCollapsibleType || expanded ? (
                    <NestingContextProvider depth={depth} shouldExpand={shouldExpandChildren}>
                        <TableCell />
                    </NestingContextProvider>
                ) : (
                    <span className="font-monospace fst-6 lh-180">{"{...}"}</span>
                )}
            </div>
        </div>
    );
}
