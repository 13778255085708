import { useCallback, useMemo } from "react";
import { useFieldArray } from "react-hook-form";
import times from "lodash/times";
import {
    closestCenter,
    DndContext,
    DragEndEvent,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
} from "@dnd-kit/core";
import { restrictToParentElement } from "@dnd-kit/modifiers";
import { arrayMove, SortableContext, sortableKeyboardCoordinates } from "@dnd-kit/sortable";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Stack from "react-bootstrap/Stack";
import { useEditEntityFormContext } from "../hooks/useEditEntityFormContext";
import { EditEntityFieldSetting } from "../types";
import { EditEntityFormDisplaySortableItem } from "./EditEntityFormDisplaySortableItem";

export function EditEntityFormDisplay() {
    const form = useEditEntityFormContext();
    const fields = form.watch("editEntityFieldsSettings.fields");
    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        }),
    );
    const onChange = useCallback(
        (newFields: EditEntityFieldSetting[]) => {
            form.setValue("editEntityFieldsSettings.fields", newFields, {
                shouldDirty: true,
                shouldTouch: true,
                shouldValidate: true,
            });
        },
        [form],
    );
    const arrayField = useFieldArray({
        name: "editEntityFieldsSettings.fields",
        control: form.control,
    });
    const items = useMemo(() => arrayField.fields.map((field) => field.field), [arrayField.fields]);
    const handleDragEnd = useCallback(
        (event: DragEndEvent) => {
            const { active, over } = event;

            if (active.id !== over?.id) {
                const oldIndex = items.indexOf(active.id as string);
                const newIndex = items.indexOf(over?.id as string);

                const newIds = arrayMove(fields, oldIndex, newIndex);

                onChange(newIds);
            }
        },
        [fields, items, onChange],
    );

    return (
        <Stack gap={4}>
            <Row>
                <Form.Group>
                    <Form.Check
                        {...form.register("editEntityFieldsSettings.showAsList")}
                        label="Display fields vertically"
                    />
                    <Form.Text>
                        Checking this options makes form disregard configured field sizes and just
                        stack them vertically
                    </Form.Text>
                </Form.Group>
            </Row>
            <Row className="g-1">
                <DndContext
                    sensors={sensors}
                    collisionDetection={closestCenter}
                    onDragEnd={handleDragEnd}
                    modifiers={[restrictToParentElement]}
                >
                    <SortableContext items={items}>
                        {arrayField.fields.map((field, index) => (
                            <EditEntityFormDisplaySortableItem
                                key={field.id}
                                id={field.field}
                                width={form.watch(`editEntityFieldsSettings.fields.${index}.width`)}
                                data={field}
                            >
                                {(field) => (
                                    <>
                                        <p className="flex-grow-1">{field.name}</p>
                                        <Form.Group>
                                            <Form.Label>Width</Form.Label>
                                            <Form.Select
                                                {...form.register(
                                                    `editEntityFieldsSettings.fields.${index}.width`,
                                                    {
                                                        valueAsNumber: true,
                                                    },
                                                )}
                                                size="sm"
                                            >
                                                {times(11, (i) => (
                                                    <option value={i + 2}>{i + 2}</option>
                                                ))}
                                            </Form.Select>
                                        </Form.Group>
                                    </>
                                )}
                            </EditEntityFormDisplaySortableItem>
                        ))}
                    </SortableContext>
                </DndContext>
            </Row>
        </Stack>
    );
}
