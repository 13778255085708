import { FC, useCallback } from "react";
import { useNavigate, createRoute } from "@tanstack/react-router";
import { Helmet } from "react-helmet-async";
import Button from "react-bootstrap/Button";
import Stack from "react-bootstrap/Stack";
import { LoginForm, LoginFormData } from "@/components/forms/LoginForm";
import { useAuth } from "@/hooks/useAuth";
import { LoginPageLayout } from "@/layouts/LoginPageLayout";
import { startPage } from "@/pages/StartPage";

export const LoginPage: FC = () => {
    const navigate = useNavigate();
    const { auth, error } = useAuth();
    const onSubmit = useCallback(
        async (data: LoginFormData & { login: boolean }) => {
            await auth(data);
            await navigate({ to: "/games" });
        },
        [auth, navigate],
    );

    return (
        <LoginPageLayout>
            <Helmet title="Login" />
            {window.env.loginFormDisabled ? (
                <Stack gap={3}>
                    <Button as="a" href={`${window.env.httpApiUrl}/keycloak/auth`} rel="noreferrer">
                        Log in
                    </Button>
                    <small>Pressing this button will redirect to Keycloak auth</small>
                </Stack>
            ) : (
                <LoginForm onSubmit={onSubmit} error={error} />
            )}
        </LoginPageLayout>
    );
};

export default LoginPage;
export const loginPageRoute = createRoute({
    getParentRoute: () => startPage,
    path: "/login",
    component: LoginPage,
});
