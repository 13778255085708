import { gql } from "@apollo/client";
import { GetEntitiesByIdsQuery, GetEntitiesByIdsQueryVariables } from "@/gql";
import { makeGqlQueryLazyHook } from "../util";
import { ENTITY_TABLE_DATA_FRAGMENT } from "../fragments/entity-table-data.fragment";

const SCHEMA = gql`
    ${ENTITY_TABLE_DATA_FRAGMENT}

    query getEntitiesByIds($entityIds: [String!]!, $gameId: String!) {
        result: getEntitiesByIds(entityIds: $entityIds, gameId: $gameId) {
            ...EntityTableData
        }
    }
`;

export const useGetEntitiesByIds = makeGqlQueryLazyHook<
    GetEntitiesByIdsQuery,
    GetEntitiesByIdsQueryVariables
>(SCHEMA);
