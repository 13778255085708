import { useCallback, useMemo } from "react";
import { bootstrapDialog, create, NiceModalHocProps, show, useModal } from "@ebay/nice-modal-react";
import Modal from "react-bootstrap/Modal";
import { Operation } from "@/gql";

type Props = Operation;

const BulkUpdateFromDeeplFail = create(function BulkUpdateFromDeeplFail(props: Props) {
    const modal = useModal();
    const dialog = useMemo(() => bootstrapDialog(modal), [modal]);

    return (
        <Modal {...dialog} size="lg">
            <Modal.Body>
                <p>Update failed. Here are all errors and warnings that occurred in process</p>
                {props.errors && (
                    <>
                        <h4>Errors</h4>
                        <ul>
                            {props.errors.map((item, index) => (
                                <li key={index}>{item}</li>
                            ))}
                        </ul>
                    </>
                )}
                {props.warnings && (
                    <>
                        <h4>Warnings</h4>
                        <ul>
                            {props.warnings.map((item, index) => (
                                <li key={index}>{item}</li>
                            ))}
                        </ul>
                    </>
                )}
            </Modal.Body>
        </Modal>
    );
});

export function useBulkUpdateFromDeeplFail() {
    return useCallback((props: Props) => {
        return show<void, NiceModalHocProps & Props, Props>(BulkUpdateFromDeeplFail, props);
    }, []);
}
