import { DeployFailedEvent, DeployFinishedEvent, DeployStartedEvent, DeployStep } from "@/gql";

interface DeployEventRendererProps {
    event: DeployFailedEvent | DeployFinishedEvent | DeployStartedEvent;
}

export function DeployEventRenderer(props: DeployEventRendererProps) {
    switch (props.event.deployCode) {
        case DeployStep.DeployStarted:
            return <span>Deploy started</span>;
        case DeployStep.DeployFinished:
            return <span>Deploy finished</span>;
        case DeployStep.DeployFailed:
            return <span>Deploy failed</span>;
        default:
            return <span>Incorrect event code</span>;
    }
}
