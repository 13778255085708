import {
    DeployStep,
    EntityUploadEvent,
    EntityUploadFailedEvent,
    EntityUploadFinishedEvent,
} from "@/gql";

interface EntityEventRendererProps {
    event: EntityUploadEvent | EntityUploadFailedEvent | EntityUploadFinishedEvent;
}

export function EntityEventRenderer(props: EntityEventRendererProps) {
    switch (props.event.deployCode) {
        case DeployStep.EntityUpload:
            return <span>Uploading entity {props.event.entityName}</span>;
        case DeployStep.EntityUploadFinished:
            return (
                <span>
                    Finished uploading entity
                    {props.event.entityName}
                </span>
            );
        case DeployStep.EntityUploadFailed:
            return (
                <span>
                    Failed to upload {props.event.entityName}. Error: {props.event.error}
                </span>
            );
        default:
            return <span>Incorrect entity event renderer code</span>;
    }
}
