import { gql } from "@apollo/client";
import { DeployLockQuery, DeployLockQueryVariables } from "@/gql";
import { makeGqlQueryHook } from "../util";
import { DEPLOY_LOCK_FRAGMENT } from "../fragments/deploy-lock.fragment";
import { USER_FRAGMENT } from "../fragments/user.fragment";

const SCHEMA = gql`
    ${DEPLOY_LOCK_FRAGMENT}
    ${USER_FRAGMENT}

    query deployLock($gameId: String!) {
        deployLock(gameId: $gameId) {
            ...DeployLock
            creator {
                ...User
            }
        }
    }
`;

export const useDeployLockQuery = makeGqlQueryHook<DeployLockQuery, DeployLockQueryVariables>(
    SCHEMA,
    {
        fetchPolicy: "network-only",
    },
);
