import { gql } from "@apollo/client";
import { CreateConsumerMutation, CreateConsumerMutationVariables } from "@/gql";
import { CONSUMER_POPULATED_FRAGMENT } from "../fragments/consumer-populated.fragment";
import { makeGqlMutationHook } from "../util";

const CREATE_CONSUMER = gql`
    ${CONSUMER_POPULATED_FRAGMENT}

    mutation createConsumer(
        $gameId: String!
        $name: String!
        $description: String!
        $templates: [String!]
        $templateParameters: [String!]
    ) {
        result: createConsumer(
            gameId: $gameId
            name: $name
            description: $description
            templates: $templates
            templateParameters: $templateParameters
        ) {
            ...ConsumerPopulated
        }
    }
`;

export const useCreateConsumerMutation = makeGqlMutationHook<
    CreateConsumerMutation,
    CreateConsumerMutationVariables
>(CREATE_CONSUMER);
