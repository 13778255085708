import { gql } from "@apollo/client";
import { makeGqlMutationHook } from "@/graphql/util";
import { BulkUpdateFromDeeplMutation, BulkUpdateFromDeeplMutationVariables } from "@/gql";

const SCHEMA = gql`
    mutation bulkUpdateFromDeepl($gameId: String!, $locales: [String!]!, $keys: [String!]!) {
        bulkUpdateFromDeepl(gameId: $gameId, locales: $locales, keys: $keys) {
            ok
            errors
            warnings
        }
    }
`;

export const useBulkUpdateFromDeeplMutation = makeGqlMutationHook<
    BulkUpdateFromDeeplMutation,
    BulkUpdateFromDeeplMutationVariables
>(SCHEMA);
