import { useMemo } from "react";
import { useForm } from "react-hook-form";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import Alert from "react-bootstrap/Alert";
import { TemplateMeta } from "@/types";
import { TemplateType } from "@/gql";
import { FormSelect } from "../common/forms/form-inputs/FormSelect";
import { FormInput } from "../common/forms/form-inputs/FormInput";

export interface CreateTemplateFormData {
    name: string;
    description: string;
    meta: TemplateMeta;
    type: TemplateType;
}

interface CreateTemplateFormProps {
    initialData?: CreateTemplateFormData;
    submitButtonText?: string;
    isLoading: boolean;
    error?: Error;
    onSubmit: (data: CreateTemplateFormData) => void;
}

export function CreateTemplateForm(props: CreateTemplateFormProps) {
    const { handleSubmit, control, formState } = useForm<CreateTemplateFormData>({
        defaultValues: {
            name: props.initialData?.name ?? "",
            description: props.initialData?.description ?? "",
            meta: props.initialData?.meta ?? {},
            type: props.initialData?.type ?? TemplateType.Document,
        },
        mode: "onChange",
    });
    const templateTypes = useMemo(() => {
        return Object.entries(TemplateType).map(([type, value]) => ({
            value,
            label: type,
        }));
    }, []);

    return (
        <Form onSubmit={handleSubmit(props.onSubmit)}>
            <Row>
                <Col>
                    <FormInput
                        className="mb-2"
                        name="name"
                        control={control}
                        title="Template name"
                        rules={{
                            required: { value: true, message: "This field is required" },
                            min: 3,
                            max: 250,
                            pattern: {
                                value: /^[A-Z][\w\d]+$/,
                                message: "Must be camelcase and not end with spaces",
                            },
                        }}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormInput
                        className="mb-2"
                        name="description"
                        as="textarea"
                        control={control}
                        title="Description"
                        rules={{ required: false, max: 1024 }}
                    />
                </Col>
            </Row>
            <Row>
                <Col xs={12} md={6}>
                    <FormSelect
                        className="mb-2"
                        name="type"
                        control={control}
                        title="Template type"
                        options={templateTypes}
                        rules={{ required: true }}
                    />
                </Col>
            </Row>

            <Row className="mt-3">
                <Col>
                    <Button
                        type="submit"
                        color="danger"
                        disabled={props.isLoading || !formState.isValid}
                    >
                        {props.isLoading ? (
                            <Spinner size="sm" animation="border" />
                        ) : (
                            props.submitButtonText || "Create template"
                        )}
                    </Button>
                </Col>
            </Row>

            {props.error ? (
                <Row className="mt-3">
                    <Col>
                        <Alert variant="danger">
                            <Alert.Heading>Failed to create template</Alert.Heading>
                            <p>{props.error.message}</p>
                        </Alert>
                    </Col>
                </Row>
            ) : null}
        </Form>
    );
}
