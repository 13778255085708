import { useCallback, useMemo, useState } from "react";

export function usePagination(total: number) {
    const [paginationState, setPaginationState] = useState(() => ({ take: 100, offset: 0 }));
    const onOpenFirst = useCallback(() => {
        setPaginationState((prevState) => ({
            take: prevState.take,
            offset: 0,
        }));
    }, []);
    const onOpenLast = useCallback(() => {
        setPaginationState((prevState) => ({
            offset: Math.floor(total / paginationState.take) * paginationState.take,
            take: prevState.take,
        }));
    }, [paginationState.take, total]);
    const onOpenNext = useCallback(() => {
        setPaginationState((prevState) => ({
            take: prevState.take,
            offset: prevState.offset + prevState.take,
        }));
    }, []);
    const onOpenPrev = useCallback(() => {
        setPaginationState((prevState) => ({
            take: prevState.take,
            offset: prevState.offset - prevState.take,
        }));
    }, []);
    const canOpenNext = paginationState.offset + paginationState.take < total;
    const canOpenPrev = paginationState.offset - paginationState.take > 0;
    const currentPage = Math.floor(paginationState.offset / paginationState.take) + 1;
    const totalPages = Math.floor(total / paginationState.take) + 1;

    return useMemo(
        () => ({
            ...paginationState,
            onOpenFirst,
            onOpenLast,
            onOpenNext,
            onOpenPrev,
            canOpenNext,
            canOpenPrev,
            currentPage,
            totalPages,
        }),
        [
            canOpenNext,
            canOpenPrev,
            onOpenFirst,
            onOpenLast,
            onOpenNext,
            onOpenPrev,
            currentPage,
            totalPages,
            paginationState,
        ],
    );
}
