import { gql } from "@apollo/client";
import { UpdateUserMutation, UpdateUserMutationVariables } from "@/gql";
import { AUTH_USER_FRAGMENT } from "@/graphql/fragments/user.fragment";
import { makeGqlMutationHook } from "../util";

const SCHEMA = gql`
    ${AUTH_USER_FRAGMENT}

    mutation updateUser($firstname: String!, $lastname: String!) {
        result: updateUser(firstname: $firstname, lastname: $lastname) {
            ...AuthUser
        }
    }
`;

export const useUpdateUserMutation = makeGqlMutationHook<
    UpdateUserMutation,
    UpdateUserMutationVariables
>(SCHEMA);
