import { useController, useFormContext } from "react-hook-form";
import Row from "react-bootstrap/Row";
import cn from "classnames";
import Col from "react-bootstrap/Col";
import ToggleButton from "react-bootstrap/ToggleButton";
import { TemplateParam } from "@/gql";
import { ExportFormProps } from "./types";

type Props = {
    templateParam: TemplateParam;
    className?: string;
    disabled?: boolean;
};

export function SelectablePlainParam(props: Props) {
    const { control, setValue } = useFormContext<ExportFormProps>();
    const tp = props.templateParam;
    const fieldName = `templates.${tp.templateId!}.${tp.guid}` as const;
    const controller = useController<ExportFormProps, `templates.${string}.${string}`>({
        name: fieldName,
        control,
        defaultValue: false,
        shouldUnregister: true,
        disabled: props.disabled,
        rules: {
            onChange: () => {
                if (tp.meta.templateId) {
                    setValue(`templates.${tp.meta.templateId}`, {});
                }
            },
        },
    });

    return (
        <Row key={tp.guid} className={cn(props.className, "d-flex")}>
            <Col md={2}>
                <ToggleButton
                    type="checkbox"
                    size="sm"
                    id={fieldName}
                    value={fieldName}
                    disabled={controller.field.disabled}
                    name={controller.field.name}
                    checked={controller.field.value}
                    onChange={controller.field.onChange}
                    onBlur={controller.field.onBlur}
                    ref={controller.field.ref}
                >
                    {controller.field.value ? "Selected" : "Select"}
                </ToggleButton>
            </Col>
            <Col md={3}>{tp.name}</Col>
            <Col md={3}>{tp.type}</Col>
            <Col className="small" md={4}>
                {tp.guid}
            </Col>
        </Row>
    );
}
