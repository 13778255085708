import { createContext, PropsWithChildren, useCallback, useContext, useState } from "react";

type ExpansionContext = {
    readonly state: Set<string>;
    setState(key: string, newState: boolean): void;
};

const ExpansionContext = createContext<ExpansionContext>(undefined!);

export function ExpansionContextProvider(props: PropsWithChildren) {
    const [state, setState] = useState(() => new Set<string>());
    const setExpansionState = useCallback((key: string, newState: boolean) => {
        setState((prevState) => {
            if (newState) {
                prevState.add(key);
            } else {
                prevState.delete(key);
            }
            return prevState;
        });
    }, []);
    const value: ExpansionContext = {
        state,
        setState: setExpansionState,
    };

    return <ExpansionContext.Provider value={value}>{props.children}</ExpansionContext.Provider>;
}

export function useExpansionContext() {
    return useContext(ExpansionContext);
}
