import { gql } from "@apollo/client";
import { makeGqlMutationHook } from "@/graphql/util";
import { UpdateTemplateCategoryMutation, UpdateTemplateCategoryMutationVariables } from "@/gql";

const schema = gql`
    mutation updateTemplateCategory($name: String!, $gameId: String!, $guid: String!) {
        updateCategory(name: $name, gameId: $gameId, guid: $guid) {
            isOk
            info
        }
    }
`;

export const useUpdateTemplateCategory = makeGqlMutationHook<
    UpdateTemplateCategoryMutation,
    UpdateTemplateCategoryMutationVariables
>(schema);
