import {
    VersionsFileUploadEvent,
    VersionsFileUploadFailedEvent,
    VersionsFileUploadFinishedEvent,
} from "@/gql";

type Props = {
    event:
        | VersionsFileUploadEvent
        | VersionsFileUploadFailedEvent
        | VersionsFileUploadFinishedEvent;
};

export function VersionsFileUploadEventRenderer(props: Props) {
    switch (props.event.deployCode) {
        case "VersionsFileUpload":
            return <span>Versions file upload</span>;
        case "VersionsFileUploadFinished":
            return <span>Versions file successfully uploaded</span>;
        case "VersionsFileUploadFailed":
            return <span>Versions file upload failed</span>;
        default:
            return null;
    }
}
