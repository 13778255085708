import { useMemo } from "react";
import { useAppSelector } from "@/hooks";
import { DataType, TemplateType, TemplateParam, GetTemplateQuery } from "@/gql";
import { Consumer } from "@/types";
import {
    gameDetailsSelector,
    projectFlagsSelector,
    templatesMapSelector,
} from "@/store/games/selectors";
import {
    useGetAvailableTemplates,
    useGetReferencingParameters,
    useGetTemplate,
} from "@/graphql/templates";
import { useGetGlobalParameter, useGetGlobalParameters } from "@/graphql/template-params";

export function useReferencingTemplates(templateId: string) {
    const { guid: gameId } = useAppSelector(gameDetailsSelector);
    const getReferencingParameters = useGetReferencingParameters({ gameId, templateId });

    return useMemo(
        () => getReferencingParameters.data?.referencingParameters ?? [],
        [getReferencingParameters.data?.referencingParameters],
    );
}

// export function useTemplateReferences(templateId: string) {
//     const templatesMap = useAppSelector(templatesMapSelector);
//     const { templateParams } = useAppSelector(gameDetailsSelector);
//
//     return useMemo(() => {
//         return templateParams
//             .filter((tp) => tp.meta.templateId === templateId)
//             .map((tp) => ({
//                 templateParameter: tp,
//                 template: templatesMap[tp.templateId!],
//             }));
//     }, [templateId, templateParams, templatesMap]);
// }

export function useTemplate2(templateId: string) {
    const { guid: gameId } = useAppSelector(gameDetailsSelector);
    const getTemplate = useGetTemplate({ gameId, guid: templateId });
    return useMemo<[GetTemplateQuery["template"] | null, boolean]>(
        () => [getTemplate.data?.template ?? null, getTemplate.loading],
        [getTemplate.data?.template, getTemplate.loading],
    );
}

// export function useTemplate(templateId: string) {
//     const { templates, templateParams } = useAppSelector(gameDetailsSelector);
//     return useMemo(() => {
//         const template = templates.find((t) => t.guid === templateId);
//         if (!template) return undefined;
//         const templateParameters = templateParams.filter((tp) => tp.templateId === templateId);
//         return {
//             ...template,
//             templateParams: templateParameters,
//         };
//     }, [templateId, templateParams, templates]);
// }

// export function useTemplateInfo(templateId?: string | null) {
//     const { templates, templateParams } = useAppSelector(gameDetailsSelector);
//
//     return useMemo(() => {
//         let template = templates.find((t) => t.guid === templateId);
//         const templateParamsForTemplate = templateParams.filter(
//             (tp) => tp.templateId === templateId,
//         );
//         if (template) {
//             template = {
//                 ...template,
//                 templateParams: templateParamsForTemplate,
//             };
//         }
//
//         return {
//             template,
//             templateDisplay: templateDisplay.find((t) => t.templateId === templateId),
//         };
//     }, [templateDisplay, templateId, templateParams, templates]);
// }

export function useAvailableTemplates() {
    const { guid: gameId } = useAppSelector(gameDetailsSelector);
    const { data } = useGetAvailableTemplates({ gameId });

    return useMemo(
        () => data?.templates.filter((template) => template.type !== TemplateType.Singleton) ?? [],
        [data?.templates],
    );
}

export function useAvailableConsumers(): Consumer[] {
    const { consumers } = useAppSelector(gameDetailsSelector);
    return consumers;
}

export function useConsumerById(consumerId: string) {
    const { consumers } = useAppSelector(gameDetailsSelector);
    return useMemo(
        () => consumers.find((consumer) => consumer.guid === consumerId),
        [consumerId, consumers],
    );
}

export function useGlobalParameters(): TemplateParam[];
export function useGlobalParameters(dataType?: DataType): TemplateParam[];
export function useGlobalParameters<T = any>(
    dataType: DataType,
    transformFn: (param: any) => T,
): T[];
export function useGlobalParameters<T = TemplateParam>(
    dataType?: DataType,
    transformFn: (param: any) => T = (v) => v as unknown as T,
) {
    const { guid: gameId } = useAppSelector(gameDetailsSelector);
    const { data } = useGetGlobalParameters({ gameId, type: dataType });

    return useMemo(() => {
        if (!data) return [];

        return data.parameters
            .filter((globalParameter) =>
                dataType !== undefined ? globalParameter.type === dataType : true,
            )
            .map(transformFn);
    }, [data, dataType, transformFn]);
}

export function useGlobalEnum(guid: string) {
    const { guid: gameId } = useAppSelector(gameDetailsSelector);
    const { data } = useGetGlobalParameter({ gameId, guid, type: DataType.Enum });

    return useMemo(() => data?.parameter, [data?.parameter]);
}

export function useProjectHasFlag(flag: string) {
    const flags = useAppSelector(projectFlagsSelector);
    return useMemo(() => flags.includes(flag), [flag, flags]);
}

export const REMOVE_MIN_VERSIONS = "remove-min-versions";
export const OMIT_UNUSED_METADATA = "omit-unused-metadata";
export const REPLACE_UNNYID = "replace-unnyid";
export const ALLOW_DISABLING_PARAMETERS = "allow-disabling-parameters";
