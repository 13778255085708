import { DataType } from "@/gql";
import { DurationDataType } from "./datatypes/Duration";
import { TimestampDataType } from "./datatypes/Timestamp";
import { IntegerDataType } from "./datatypes/Primitive";
import { DataTypeField } from "./types";
import { BooleanDataType } from "./datatypes/Boolean";
import { EnumDataType } from "./datatypes/Enum";
import { EntityRefDataType } from "./datatypes/EntityRef";
import { InjectedRefDataType } from "./datatypes/InjectedRef";
import { ListDataType } from "./datatypes/List";
import { ScriptDataType } from "./datatypes/Script";
import { AssetDataType } from "./datatypes/Asset";
import { LocalizedString } from "./datatypes/LocalizedString";

const defs = [
    IntegerDataType,
    BooleanDataType,
    EnumDataType,
    ListDataType,
    EntityRefDataType,
    InjectedRefDataType,
    ScriptDataType,
    AssetDataType,
    DurationDataType,
    TimestampDataType,
    LocalizedString,
];

export const fieldsDefinition = defs.reduce(
    (acc, def) => {
        (Array.isArray(def.dataType) ? def.dataType : [def.dataType]).forEach((dataType) => {
            if (acc[dataType] !== undefined) {
                console.warn(
                    `Definition for datatype ${dataType} already exists and will be overwritten`,
                );
            }
            acc[dataType] = def;
        });
        return acc;
    },
    {} as Record<DataType, DataTypeField>,
);
