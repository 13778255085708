import { useMemo } from "react";
import { dayjs } from "@/utils/dayjs";
import type { FC } from "react";

interface DateDisplayProps {
    date: Date | number;
    format?: string;
}

export const DateDisplay: FC<DateDisplayProps> = ({ date, format = "DD MMM YYYY HH:mm" }) => {
    return <>{useMemo(() => dayjs(date).format(format), [date, format])}</>;
};
