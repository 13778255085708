import { gql } from "@apollo/client";
import { GetArtifactsHistoryQuery, GetArtifactsHistoryQueryVariables } from "@/gql";
import { ARTIFACT_HISTORY_ENTRY_DETAILED_FRAGMENT } from "../fragments/artifact-history-entry.fragment";
import { makeGqlQueryHook } from "../util";

const SCHEMA = gql`
    ${ARTIFACT_HISTORY_ENTRY_DETAILED_FRAGMENT}

    query getArtifactsHistory($gameId: String!) {
        artifactHistoryEntries(gameId: $gameId) {
            ...ArtifactHistoryEntryDetailed
        }
    }
`;

export const useGetArtifactsHistory = makeGqlQueryHook<
    GetArtifactsHistoryQuery,
    GetArtifactsHistoryQueryVariables
>(SCHEMA);
