import Nav from "react-bootstrap/Nav";
import { Outlet, createRoute, useNavigate } from "@tanstack/react-router";
import { Helmet } from "react-helmet-async";
import { NavItem } from "@/components/common/NavItem";
import { withRBAC } from "@/components/rbac";
import { Permission } from "@/gql";
import { layoutWithBreadcrumbs } from "@/layouts/LayoutWithBreadcrumbs";
import { deployDirectoriesRoute } from "@/pages/project-settings/DeployDirectories";
import { deployFlagsRoute } from "@/pages/project-settings/DeployFlagsPage";
import { permissionsPage } from "@/pages/project-settings/PermissionsPage";

export function ProjectSettings() {
    useNavigate();
    return (
        <>
            <Helmet title="Settings" />
            <Nav variant="pills">
                <NavItem from={projectSettings.to} to={projectSettings.to}>
                    Settings
                </NavItem>
                <NavItem from={projectSettings.to} to={deployFlagsRoute.to}>
                    Deploy flags
                </NavItem>
                <NavItem from={projectSettings.to} to={deployDirectoriesRoute.to}>
                    Deploy directories
                </NavItem>
                <NavItem from={projectSettings.to} to={permissionsPage.to}>
                    Project permissions
                </NavItem>
            </Nav>
            <Outlet />
        </>
    );
}

const ProjectSettingsRBAC = withRBAC(ProjectSettings, {
    requiredPermissions: [Permission.StructureRead, Permission.StructureWrite],
});
export default ProjectSettingsRBAC;
export const projectSettings = createRoute({
    getParentRoute: () => layoutWithBreadcrumbs,
    path: "project",
    component: ProjectSettingsRBAC,
});
