import Button from "react-bootstrap/Button";
import { BaseModal } from "@/components/modals/BaseModal";

type Props = {
    isOpen: boolean;
    spreadsheetId: string;
    toggle: () => void;
};

export function ExportResultModal(props: Props) {
    const spreadsheetUrl = `https://docs.google.com/spreadsheets/d/${props.spreadsheetId}/edit#gid=0`;

    return (
        <BaseModal isOpen={props.isOpen} toggle={props.toggle}>
            <h3>Template data has been exported successfully</h3>
            <p>You can see the file by clicking the button below</p>
            <Button as="a" href={spreadsheetUrl} target="_blank">
                Open spreadsheet
            </Button>
        </BaseModal>
    );
}
