import {
    CSSProperties,
    ElementType,
    memo,
    ReactElement,
    useCallback,
    useRef,
    useState,
} from "react";
import { AsProp } from "react-bootstrap/esm/helpers";
import { useClickOutside } from "@/hooks/useClickOutside";

interface FocusableSwitchProps {
    focused: () => ReactElement;
    unfocused: () => ReactElement;
    className?: string;
    style?: CSSProperties;
}

export const FocusableSwitch = memo(function FocusableSwitch<As extends ElementType = ElementType>(
    props: FocusableSwitchProps & AsProp<As>,
) {
    const wrapperRef = useRef<HTMLDivElement | null>(null);
    const [isFocused, setIsFocused] = useState(false);
    const setFocused = useCallback(() => setIsFocused(true), [setIsFocused]);
    const setUnfocused = useCallback(() => setIsFocused(false), [setIsFocused]);
    useClickOutside(wrapperRef, setUnfocused, isFocused);

    return (
        <div ref={wrapperRef} onClick={setFocused} className={props.className} style={props.style}>
            {!isFocused ? props.unfocused() : props.focused()}
        </div>
    );
});
