import Select, {
    ClassNamesConfig,
    components,
    GroupBase,
    OnChangeValue,
    SelectComponentsConfig,
} from "react-select";
import { useCallback, useMemo } from "react";
import Badge from "react-bootstrap/Badge";
import Stack from "react-bootstrap/Stack";
import { DateDisplay } from "@/components/common/DateDisplay";
import { Artifact, ArtifactStatus } from "@/gql";
import { UserInfoAsync } from "@/components/common/UserInfoAsync";

type Props = {
    gameId: string;
    value?: string | null;
    onChange: (guid: string) => void;
    options: Artifact[];
};

function ArtifactDisplay(props: { data: Artifact }) {
    const badgeVariant = useMemo(() => {
        switch (props.data.status) {
            case ArtifactStatus.Created:
                return "warning";
            case ArtifactStatus.Done:
                return "success";
            case ArtifactStatus.Failed:
                return "danger";
        }
    }, [props.data.status]);

    return (
        <Stack>
            <div className="d-flex justify-content-between">
                <div className="small">id: {props.data.guid}</div>
                <Badge className="mb-auto" bg={badgeVariant}>
                    {props.data.status}
                </Badge>
            </div>
            <div className="mb-auto small fw-bolder">
                built at <DateDisplay date={props.data.createdAt} />
            </div>
            <div className="mb-auto small fw-bolder">
                built by <UserInfoAsync userId={props.data.createdBy} />
            </div>
        </Stack>
    );
}

const Components: SelectComponentsConfig<Artifact, false, GroupBase<Artifact>> = {
    ValueContainer: (props) => (
        <components.ValueContainer {...props}>
            {props.getValue().map((v) => (
                <ArtifactDisplay key={v.guid} data={v} />
            ))}
        </components.ValueContainer>
    ),
    Option: (props) => (
        <components.Option {...props}>
            <ArtifactDisplay data={props.data} />
        </components.Option>
    ),
};

const classNames: ClassNamesConfig<Artifact, false, GroupBase<Artifact>> = {
    control: () => "form-control",
};

export function ArtifactSelector(props: Props) {
    const defaultValue = useMemo(() => {
        return props.options.find((option) => option.guid === props.value);
    }, [props.options, props.value]);
    const onChange = useCallback(
        (newValue: OnChangeValue<Artifact, false>) => {
            newValue && props.onChange(newValue.guid);
        },
        [props],
    );

    return (
        <Select<Artifact, false>
            getOptionValue={(v) => v.guid}
            getOptionLabel={(v) => v.guid}
            options={props.options}
            defaultValue={defaultValue}
            components={Components}
            onChange={onChange}
            menuPortalTarget={document.body}
            defaultInputValue={props.value ?? undefined}
            classNames={classNames}
        />
    );
}
