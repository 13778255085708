import { useFormContext } from "react-hook-form";
import { DataAwaiter2 } from "@/components/common/DataAwaiter2";
import { useGetTemplate } from "@/graphql/templates";
import { ExportFormProps } from "./types";
import { TemplateSelect } from "./TemplateSelect";

type Props = {
    disabled?: boolean;
};

export function ExportTemplates(props: Props) {
    const { getValues } = useFormContext<ExportFormProps>();
    const [gameId, templateId] = getValues(["gameId", "templateId"]);
    const template = useGetTemplate({ gameId, guid: templateId });

    return (
        <DataAwaiter2 {...template}>
            {({ template }) => (
                <section>
                    <small>Select templates and fields to export</small>
                    <TemplateSelect disabled={props.disabled} templateId={template.guid} />
                </section>
            )}
        </DataAwaiter2>
    );
}
