import { useMemo } from "react";

/**
 * Format bytes as human-readable text.
 *
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use
 *           binary (IEC), aka powers of 1024.
 * @param dp Number of decimal places to display.
 *
 * @return Formatted string.
 */
function humanFileSize(bytes: number, si = false, dp = 1) {
    const threshold = si ? 1000 : 1024;

    if (Math.abs(bytes) < threshold) {
        return bytes + " B";
    }

    const units = si
        ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
        : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
    let u = -1;
    const r = 10 ** dp;

    do {
        bytes /= threshold;
        ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= threshold && u < units.length - 1);

    return bytes.toFixed(dp) + " " + units[u];
}

type Props = {
    bytes: number;
    si?: boolean;
    dp?: number;
};

export function FileSizeDisplay(props: Props) {
    const valueFormatted = useMemo(
        () => humanFileSize(props.bytes, props.si ?? false, props.dp ?? 1),
        [props.bytes, props.dp, props.si],
    );

    return <span>{valueFormatted}</span>;
}
