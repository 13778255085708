import { DataType } from "@/gql";
import { createDatatype } from "../../createDatatype";
import { TimestampFieldComponent } from "./TimestampFieldComponent";

export const timestampField = createDatatype<number>({
    type: DataType.Timestamp,
    defaultValue: () => 0,
    inputComponent: TimestampFieldComponent,
    sortFunction: (templateParam) => (rowA, rowB) => {
        const sortAttribute = templateParam.guid;

        if (!sortAttribute) return 0;

        return rowB.values[sortAttribute] > rowA.values[sortAttribute] ? 1 : -1;
    },
});
