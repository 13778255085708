import { gql } from "@apollo/client";
import { makeGqlMutationHook } from "@/graphql/util";
import { ImportGlossariesMutation, ImportGlossariesMutationVariables } from "@/gql";

const SCHEMA = gql`
    mutation importGlossaries($gameId: String!, $spreadsheetId: String!) {
        importGlossariesFromSpreadsheet(gameId: $gameId, spreadsheetId: $spreadsheetId) {
            isOk
            info
        }
    }
`;

export const useImportGlossariesMutation = makeGqlMutationHook<
    ImportGlossariesMutation,
    ImportGlossariesMutationVariables
>(SCHEMA);
