import { useCallback, useEffect, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "@/hooks";
import { EntityParameterChangeArgs, OperableEntityTableData } from "@/types";
import { useRequestEntityContext } from "@/contexts/useRequestEntity";
import {
    entitiesChangesMapSelector,
    entitiesChangesSelector,
    entitiesErrorSelector,
    entitiesSelector,
    entitiesStateChangesMapSelector,
    isEntitiesLoadingSelector,
    undoneChangeSelector,
} from "@/store/entities/selectors";
import { ADD_CHANGE_RECORD, REDO_CHANGE, UNDO_CHANGE } from "./slice";
import { useLoadEntities } from "./actions";

export function useEntityById(
    entityId: string,
    gameId: string,
): OperableEntityTableData | undefined {
    const entities = useAppSelector(entitiesSelector);
    const requestEntityContext = useRequestEntityContext();
    if (!entities[entityId]) {
        requestEntityContext.requestEntity(entityId, gameId);
    }
    return entities[entityId];
}

export function useEntities(templateId: string, gameId: string) {
    const entitiesList = useAppSelector(entitiesSelector);
    const error = useAppSelector(entitiesErrorSelector);
    const isLoading = useAppSelector(isEntitiesLoadingSelector);
    const [loadEntities] = useLoadEntities();

    useEffect(() => {
        loadEntities({ templateId, gameId });
    }, [templateId, gameId, loadEntities]);

    return useMemo(
        () => ({
            error,
            isLoading,
            entities: Object.values(entitiesList).filter(
                // This is a part of new entities table which is not supported
                // @ts-ignore
                (entity) => entity.templateId === templateId && entity.gameId === gameId,
            ),
        }),
        [entitiesList, error, gameId, isLoading, templateId],
    );
}

export function useEntityChanges() {
    const entityChanges = useAppSelector(entitiesChangesSelector);
    const undoneChanges = useAppSelector(undoneChangeSelector);
    // Type of this map is Record<EntityId, Record<TemplateParamId, Value>>
    const entityChangesMap = useAppSelector(entitiesChangesMapSelector);
    const entityParamsStateMap = useAppSelector(entitiesStateChangesMapSelector);
    const dispatch = useAppDispatch();
    const hasChanges = entityChanges.length > 0;

    const addChangeRecord = useCallback(
        (record: EntityParameterChangeArgs) => {
            dispatch(ADD_CHANGE_RECORD({ record }));
        },
        [dispatch],
    );

    const undoChange = useCallback(
        (event: KeyboardEvent) => {
            if (entityChanges.length === 0 || !(event.ctrlKey && event.key === "z")) return;

            dispatch(UNDO_CHANGE());
        },
        [dispatch, entityChanges.length],
    );

    const redoChange = useCallback(
        (event: KeyboardEvent) => {
            if (
                undoneChanges.length === 0 ||
                !(event.ctrlKey && event.shiftKey && event.key === "Z")
            )
                return;

            dispatch(REDO_CHANGE());
        },
        [dispatch, undoneChanges.length],
    );

    useEffect(() => {
        document.addEventListener("keydown", undoChange);
        document.addEventListener("keydown", redoChange);

        return () => {
            document.removeEventListener("keydown", undoChange);
            document.removeEventListener("keydown", redoChange);
        };
    }, [redoChange, undoChange]);

    return useMemo(
        () => ({
            entityChanges,
            hasChanges,
            entityChangesMap,
            entityParamsStateMap,
            addChangeRecord,
        }),
        [addChangeRecord, entityChanges, entityChangesMap, entityParamsStateMap, hasChanges],
    );
}
