import { gql } from "@apollo/client";
import { LOCALE_FRAGMENT } from "@/graphql/fragments/locale.fragment";
import { makeGqlMutationHook } from "@/graphql/util";
import { UpdateLocaleMutation, UpdateLocaleMutationVariables } from "@/gql";

const SCHEMA = gql`
    ${LOCALE_FRAGMENT}

    mutation updateLocale(
        $gameId: String!
        $guid: String!
        $name: String!
        $code: String!
        $isBaseLocale: Boolean!
    ) {
        updateLocale(
            gameId: $gameId
            guid: $guid
            name: $name
            code: $code
            isBaseLocale: $isBaseLocale
        ) {
            ...Locale
        }
    }
`;

export const useUpdateLocaleMutation = makeGqlMutationHook<
    UpdateLocaleMutation,
    UpdateLocaleMutationVariables
>(SCHEMA);
