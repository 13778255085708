import { gql } from "@apollo/client";
import { makeGqlMutationHook } from "@/graphql/util";
import { ExportTranslationsMutation, ExportTranslationsMutationVariables } from "@/gql";

const SCHEMA = gql`
    mutation exportTranslations($gameId: String!, $locales: [String!]!, $emails: [String!]!) {
        exportTranslationsToSpreadsheet(gameId: $gameId, locales: $locales, emails: $emails) {
            spreadsheetId
        }
    }
`;
export const useExportTranslations = makeGqlMutationHook<
    ExportTranslationsMutation,
    ExportTranslationsMutationVariables
>(SCHEMA);
