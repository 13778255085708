import { gql } from "@apollo/client";
import { GetGlobalParameterQuery, GetGlobalParameterQueryVariables } from "@/gql";
import { TEMPLATE_PARAM_FRAGMENT } from "../fragments/template-param.fragment";
import { makeGqlQueryHook } from "../util";

const SCHEMA = gql`
    ${TEMPLATE_PARAM_FRAGMENT}

    query getGlobalParameter($gameId: String!, $guid: String!, $type: DataType) {
        parameter: getGlobalParameter(gameId: $gameId, guid: $guid, type: $type) {
            ...TemplateParam
        }
    }
`;

export const useGetGlobalParameter = makeGqlQueryHook<
    GetGlobalParameterQuery,
    GetGlobalParameterQueryVariables
>(SCHEMA);
