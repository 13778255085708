import ListGroup from "react-bootstrap/ListGroup";
import { ImportedTemplateSummary } from "@/gql";
import { BaseModal } from "./BaseModal";

interface Props {
    isOpen: boolean;
    toggle: () => void;
    data: ImportedTemplateSummary[];
}

export function ImportResultsModal(props: Props) {
    return (
        <BaseModal isOpen={props.isOpen} toggle={props.toggle} title="Import results">
            <ListGroup>
                {props.data.map((value) => (
                    <ListGroup.Item key={value.templateName} as="li">
                        <div className="fw-bold">{value.templateName}</div>
                        <div className="small ms-2"># entities before: {value.entitiesBefore}</div>
                        <div className="small ms-2"># entities after: {value.entitiesAfter}</div>
                    </ListGroup.Item>
                ))}
            </ListGroup>
        </BaseModal>
    );
}
