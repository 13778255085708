import { gql } from "@apollo/client";
import { makeGqlMutationHook } from "@/graphql/util";
import { DeeplCreateTranslationMutation, DeeplCreateTranslationMutationVariables } from "@/gql";
import { TRANSLATION_FRAGMENT } from "@/graphql/fragments/translation.fragment";

const SCHEMA = gql`
    ${TRANSLATION_FRAGMENT}

    mutation deeplCreateTranslation(
        $gameId: String!
        $key: String!
        $description: String!
        $baseLocaleValue: String!
    ) {
        deepl_createTranslation(
            gameId: $gameId
            key: $key
            description: $description
            baseLocaleValue: $baseLocaleValue
        ) {
            ...Translation
        }
    }
`;

export const useDeeplCreateTranslationMutation = makeGqlMutationHook<
    DeeplCreateTranslationMutation,
    DeeplCreateTranslationMutationVariables
>(SCHEMA);
