import { gql } from "@apollo/client";
import { makeGqlMutationHook } from "@/graphql/util";
import { ExportGlossariesMutation, ExportGlossariesMutationVariables } from "@/gql";

const SCHEMA = gql`
    mutation exportGlossaries($gameId: String!, $locales: [String!]!, $emails: [String!]!) {
        exportGlossariesToSpreadsheet(gameId: $gameId, locales: $locales, emails: $emails) {
            spreadsheetId
        }
    }
`;

export const useExportGlossariesMutation = makeGqlMutationHook<
    ExportGlossariesMutation,
    ExportGlossariesMutationVariables
>(SCHEMA);
