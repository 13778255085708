import { useCallback, useState } from "react";
import NiceModal, { bootstrapDialog, NiceModalHocProps, useModal } from "@ebay/nice-modal-react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Stack from "react-bootstrap/Stack";
import { TranslationSelect } from "./TranslationSelect";

type Props = {
    gameId: string;
    value: string | null;
};

function SelectTranslationModalComponent(props: Props) {
    const modal = useModal();
    const dialog = bootstrapDialog(modal);
    const [value, setValue] = useState<string | null>(null);
    const onChange = useCallback((newValue: string | null) => {
        setValue(newValue);
    }, []);
    const onSave = useCallback(() => {
        modal.resolve(value);
        return modal.hide();
    }, [modal, value]);

    return (
        <Modal {...dialog}>
            <Modal.Body>
                <Stack gap={4}>
                    <TranslationSelect gameId={props.gameId} value={value} onChange={onChange} />
                    <Button onClick={onSave}>Save</Button>
                </Stack>
            </Modal.Body>
        </Modal>
    );
}

const SelectTranslationModal = NiceModal.create<Props>(SelectTranslationModalComponent);

export function showSelectTranslationModal(props: Props) {
    return NiceModal.show<string, Props & NiceModalHocProps, Props>(SelectTranslationModal, props);
}
