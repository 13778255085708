import { gql } from "@apollo/client";
import { JOB_FRAGMENT } from "@/graphql/fragments/job.fragment";
import { makeGqlMutationHook } from "@/graphql/util";
import { WizCloneGameMutation, WizCloneGameMutationVariables } from "@/gql";

const schema = gql`
    ${JOB_FRAGMENT}

    mutation wizCloneGame($userId: String!, $gameId: String!) {
        wizCloneGame(userId: $userId, gameId: $gameId) {
            ...Job
        }
    }
`;

export const useCloneGameMutation = makeGqlMutationHook<
    WizCloneGameMutation,
    WizCloneGameMutationVariables
>(schema);
