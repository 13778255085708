import { gql } from "@apollo/client";
import { makeGqlQueryLazyHook } from "@/graphql/util";
import { GetArtifactMetadataFileQuery, GetArtifactMetadataFileQueryVariables } from "@/gql";

const SCHEMA = gql`
    query getArtifactMetadataFile($gameId: String!, $guid: String!) {
        artifactMetadataFile(gameId: $gameId, guid: $guid)
    }
`;

export const useLazyGetArtifactMetadataFile = makeGqlQueryLazyHook<
    GetArtifactMetadataFileQuery,
    GetArtifactMetadataFileQueryVariables
>(SCHEMA);
