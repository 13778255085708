import { createAction, createReducer } from "@reduxjs/toolkit";
import set from "lodash/set";
import unset from "lodash/unset";
import { EntityParameterChangeArgs, OperableEntityTableData, TemplateEntity } from "@/types";
import { entityTableDataToOperable, entityToOperableEntity } from "@/utils/entities";
import { EntityTableData } from "@/gql";

interface EntitiesSlice {
    entityChanges: EntityParameterChangeArgs[];
    entityChangesMap: Record<string, Record<string, string>>;
    entityStateChangesMap: Record<string, Record<string, boolean>>;
    undoneChanges: EntityParameterChangeArgs[];
    entities: Record<string, OperableEntityTableData>;
    isLoading: boolean;
    error: Error | null;
}

const initialState: () => EntitiesSlice = () => ({
    entityChanges: [],
    entityChangesMap: {},
    entityStateChangesMap: {},
    undoneChanges: [],
    entities: {},
    error: null,
    isLoading: false,
});

export const SET_ENTITIES = createAction<TemplateEntity[]>("SET_ENTITIES");
export const REMOVE_ENTITY = createAction<{ guid: string }>("REMOVE_ENTITY");
export const ADD_ENTITY = createAction<EntityTableData>("ADD_ENTITY");
export const REPLACE_ENTITY = createAction<EntityTableData>("REPLACE_ENTITY");
export const START_ENTITIES_LOADING = createAction("START_ENTITIES_LOADING");
export const STOP_ENTITIES_LOADING = createAction("STOP_ENTITIES_LOADING");
export const SET_ENTITIES_ERROR = createAction<Error>("SET_ENTITIES_ERROR");
export const CLEAR_ENTITIES_ERROR = createAction("CLEAR_ENTITIES_ERROR");
export const ADD_CHANGE_RECORD = createAction<{ record: EntityParameterChangeArgs }>(
    "ADD_CHANGE_RECORD",
);
export const CLEAR_CHANGES_HISTORY = createAction("CLEAR_CHANGE_HISTORY");
export const UNDO_CHANGE = createAction("UNDO_CHANGE");
export const REDO_CHANGE = createAction("REDO_CHANGE");

export const entitiesSlice = createReducer<EntitiesSlice>(initialState, (builder) => {
    builder
        .addCase(START_ENTITIES_LOADING, (state) => {
            state.isLoading = true;
        })
        .addCase(STOP_ENTITIES_LOADING, (state) => {
            state.isLoading = false;
        })
        .addCase(SET_ENTITIES_ERROR, (state, action) => {
            state.error = action.payload;
        })
        .addCase(CLEAR_ENTITIES_ERROR, (state) => {
            state.error = null;
        })
        .addCase(SET_ENTITIES, (state, action) => {
            action.payload.forEach((entity) => {
                state.entities[entity.guid] = entityToOperableEntity(entity);
            });
        })
        .addCase(REMOVE_ENTITY, (state, action) => {
            delete state.entities[action.payload.guid];
        })
        .addCase(ADD_ENTITY, (state, action) => {
            state.entities[action.payload.guid] = entityTableDataToOperable(action.payload);
        })
        .addCase(REPLACE_ENTITY, (state, action) => {
            state.entities[action.payload.guid] = entityTableDataToOperable(action.payload);
        })
        .addCase(ADD_CHANGE_RECORD, (state, action) => {
            const { entityId, templateParameterId, value, disabled } = action.payload.record;
            state.entityChanges.push(action.payload.record);
            set(state.entityChangesMap, `${entityId}.${templateParameterId}`, value);
            if (!disabled) {
                unset(state.entityStateChangesMap, `${entityId}.${templateParameterId}`);
            } else {
                set(state.entityStateChangesMap, `${entityId}.${templateParameterId}`, true);
            }
            state.undoneChanges = [];
        })
        .addCase(UNDO_CHANGE, (state) => {
            const lastChange = state.entityChanges.at(-1);
            if (!lastChange) return;
            const { entityId, templateParameterId, value, disabled } = lastChange;

            state.undoneChanges = [...state.undoneChanges, lastChange];
            state.entityChanges = [...state.entityChanges.slice(0, -1)];
            const prevChange = state.entityChanges.findLast(
                (change) =>
                    change.entityId === entityId &&
                    change.templateParameterId === templateParameterId,
            );
            if (!prevChange) {
                unset(state.entityChangesMap, `${entityId}.${templateParameterId}`);
                unset(state.entityStateChangesMap, `${entityId}.${templateParameterId}`);
            } else {
                set(state.entityChangesMap, `${entityId}.${templateParameterId}`, prevChange.value);
                set(
                    state.entityStateChangesMap,
                    `${entityId}.${templateParameterId}`,
                    prevChange.disabled,
                );
            }
        })
        .addCase(REDO_CHANGE, (state) => {
            const changeToRedo = state.undoneChanges.slice(-1);
            state.undoneChanges = [...state.undoneChanges.slice(0, -1)];
            state.entityChanges = [...state.entityChanges, ...changeToRedo];
        })
        .addCase(CLEAR_CHANGES_HISTORY, (state) => {
            state.entityChanges = [];
            state.undoneChanges = [];
            state.entityChangesMap = {};
            state.entityStateChangesMap = {};
        });
});
