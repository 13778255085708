import { gql } from "@apollo/client";
import { AddDescriptionParamsMutation, AddDescriptionParamsMutationVariables } from "@/gql";
import { makeGqlMutationHook } from "../util";
import { TEMPLATE_PARAM_DETAILED_FRAGMENT } from "../fragments/template-param.fragment";

const SCHEMA = gql`
    ${TEMPLATE_PARAM_DETAILED_FRAGMENT}

    mutation addDescriptionParams(
        $gameId: String!
        $templateId: String!
        $titleFrom: String
        $descriptionFrom: String
    ) {
        addDescriptionParams(
            gameId: $gameId
            templateId: $templateId
            titleFrom: $titleFrom
            descriptionFrom: $descriptionFrom
        ) {
            ...TemplateParamDetailed
        }
    }
`;

export const useAddDescriptionParams = makeGqlMutationHook<
    AddDescriptionParamsMutation,
    AddDescriptionParamsMutationVariables
>(SCHEMA);
