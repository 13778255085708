import { memo } from "react";
import Placeholder from "react-bootstrap/Placeholder";
import Form from "react-bootstrap/Form";

export const PrimitivePlaceholder = memo(function PrimitivePlaceholder() {
    return (
        <Placeholder animation="glow" size="sm">
            <Placeholder as={Form.Control} className="form-control-sm" />
        </Placeholder>
    );
});
