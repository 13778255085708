import { gql, useQuery } from "@apollo/client";
import { useCallback, useMemo } from "react";
import { useSnackbar } from "notistack";
import { ARTIFACT_FRAGMENT } from "@/graphql/fragments/artifact.fragment";
import { DEPLOY_DIRECTORY_FRAGMENT } from "@/graphql/fragments/deploy-directory.fragment";
import { GetArtifactsPageQuery, GetArtifactsPageQueryVariables } from "@/gql";
import { showDeployDirectoryModal } from "@/components/modals2/DeployDirectoryModal";
import { useCreateDeployDirectoryMutation } from "@/graphql/deploy-directories";
import {
    useDeleteArtifactMutation,
    useDeployArtifactMutation,
    useUpdateArtifactMutation,
} from "@/graphql/deploy";

const SCHEMA = gql`
    ${ARTIFACT_FRAGMENT}
    ${DEPLOY_DIRECTORY_FRAGMENT}

    query getArtifactsPage($gameId: String!) {
        artifacts(gameId: $gameId) {
            ...Artifact
            currentlyDeployed {
                ...DeployDirectory
            }
        }
        deployDirectories(gameId: $gameId) {
            ...DeployDirectory
        }
    }
`;

export function useArtifacts(gameId: string) {
    const { enqueueSnackbar } = useSnackbar();
    const getArtifactsPage = useQuery<GetArtifactsPageQuery, GetArtifactsPageQueryVariables>(
        SCHEMA,
        { variables: { gameId } },
    );
    const [createDeployDirectoryMutation] = useCreateDeployDirectoryMutation();
    const [deleteArtifactMutation] = useDeleteArtifactMutation();
    const [deployArtifactMutation] = useDeployArtifactMutation();
    const [updateArtifact] = useUpdateArtifactMutation();
    const onDeployArtifact = useCallback(
        (guid: string, deployDirectoryId: string) => {
            return deployArtifactMutation({
                variables: {
                    gameId: gameId!,
                    artifactId: guid,
                    deployDirectoryId,
                },
            });
        },
        [deployArtifactMutation, gameId],
    );
    const onDeleteArtifact = useCallback(
        async (guid: string) => {
            await deleteArtifactMutation({ variables: { guid, gameId: gameId! } });
            return getArtifactsPage.refetch();
        },
        [deleteArtifactMutation, gameId, getArtifactsPage],
    );
    const onUpdateArtifact = useCallback(
        async (updateData: { guid: string; description: string; isProtected: boolean }) => {
            const { guid, description, isProtected } = updateData;
            const { data } = await updateArtifact({
                variables: {
                    gameId: gameId!,
                    guid,
                    description,
                    isProtected,
                },
            });
            if (data) {
                enqueueSnackbar("Saved", {
                    variant: "success",
                });
                return getArtifactsPage.refetch();
            }
        },
        [updateArtifact, gameId, enqueueSnackbar, getArtifactsPage],
    );
    const onCreateDeployDirectory = useCallback(async () => {
        const result = await showDeployDirectoryModal({});
        await createDeployDirectoryMutation({
            variables: {
                gameId,
                name: result.name,
                path: result.path,
                variant: result.variant,
            },
        });
        return getArtifactsPage.refetch();
    }, [createDeployDirectoryMutation, getArtifactsPage, gameId]);

    return useMemo(
        () => ({
            onDeployArtifact,
            onDeleteArtifact,
            onUpdateArtifact,
            onCreateDeployDirectory,
            artifacts: getArtifactsPage,
        }),
        [
            getArtifactsPage,
            onCreateDeployDirectory,
            onDeleteArtifact,
            onDeployArtifact,
            onUpdateArtifact,
        ],
    );
}
