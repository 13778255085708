import { gql } from "@apollo/client";

export const CONSUMER_FRAGMENT = gql`
    fragment Consumer on Consumer {
        guid
        gameId
        name
        description
        gameId
        templatesAssigned
        templateParamsAssigned
    }
`;
