import { useCallback, useMemo } from "react";
import { TableColumn } from "react-data-table-component";
import keyBy from "lodash/keyBy";
import { mapValue } from "graphql/jsutils/mapValue";
import orderBy from "lodash/orderBy";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import { FaEdit, FaFile, FaFileExport, FaFileImport, FaTrash } from "react-icons/fa";
import Badge from "react-bootstrap/Badge";
import { AppDataTable } from "@/components/common/DataTable";
import { GetGlossariesPageQuery } from "@/gql";
import { ConfirmationButton } from "@/components/common/ConfirmationButton";
import { useRemoveGlossaryEntryMutation } from "@/graphql/translation/glossaries";
import { showEditGlossaryEntryModal } from "@/components/translation/EditGlossaryEntryModal";
import { showExportGlossariesModal } from "@/components/modals2/ExportGlossariesModal";
import { showImportGlossariesModal } from "@/components/modals2/ImportGlossariesModal";
import { useCreateGlossaryEntryFromDeepl } from "@/components/translation/CreateGlossaryEntryFromDeeplModal";
import { GlossaryEntryTransformed } from "./types";

type Props = {
    gameId: string;
    data: GetGlossariesPageQuery;
    onRefetch: () => void;
};

export function GlossariesTable(props: Props) {
    const data = useMemo(() => {
        return props.data.getGlossaries.map<GlossaryEntryTransformed>((glossary) => ({
            ...glossary,
            values: mapValue(keyBy(glossary.values, "localeId"), (v) => v.value),
        }));
    }, [props.data.getGlossaries]);
    const [removeGlossaryEntryMutation, { loading: removeGlossaryEntryLoading }] =
        useRemoveGlossaryEntryMutation();
    const showCreateGlossaryEntryFromDeeplModal = useCreateGlossaryEntryFromDeepl();
    const isLoading = removeGlossaryEntryLoading;
    const onCreateGlossaryEntry = useCallback(async () => {
        await showEditGlossaryEntryModal({ gameId: props.gameId });
        await props.onRefetch();
    }, [props]);
    const onCreateGlossaryEntryFromDeepl = useCallback(async () => {
        await showCreateGlossaryEntryFromDeeplModal({
            gameId: props.gameId,
        });
        await props.onRefetch();
    }, [props, showCreateGlossaryEntryFromDeeplModal]);
    const onUpdateGlossaryEntry = useCallback(
        async (row: GlossaryEntryTransformed) => {
            await showEditGlossaryEntryModal({ entry: row, gameId: props.gameId });
            await props.onRefetch();
        },
        [props],
    );
    const onRemoveGlossaryEntry = useCallback(
        async (row: GlossaryEntryTransformed) => {
            await removeGlossaryEntryMutation({
                variables: { guid: row.guid, gameId: row.gameId },
            });
            await props.onRefetch();
        },
        [props, removeGlossaryEntryMutation],
    );
    const onExport = useCallback(() => {
        return showExportGlossariesModal({
            gameId: props.gameId,
            locales: props.data.locales,
        });
    }, [props.data.locales, props.gameId]);
    const onImport = useCallback(async () => {
        await showImportGlossariesModal({ gameId: props.gameId });
        await props.onRefetch();
    }, [props]);

    const actions = useMemo(
        () => (
            <ButtonGroup size="sm">
                <Button disabled={isLoading} onClick={onExport}>
                    <FaFileImport /> Export to spreadsheet
                </Button>
                <Button disabled={isLoading} onClick={onImport}>
                    <FaFileExport /> Import from spreadsheet
                </Button>
                <Button disabled={isLoading} onClick={onCreateGlossaryEntry}>
                    <FaFile /> Create
                </Button>
                <Button disabled={isLoading} onClick={onCreateGlossaryEntryFromDeepl}>
                    <FaFile /> Create from Deepl
                </Button>
            </ButtonGroup>
        ),
        [isLoading, onCreateGlossaryEntry, onCreateGlossaryEntryFromDeepl, onExport, onImport],
    );
    const columns = useMemo<TableColumn<GlossaryEntryTransformed>[]>(
        () => [
            {
                name: "Actions",
                cell: (row) => (
                    <ButtonGroup size="sm">
                        <Button disabled={isLoading} onClick={() => onUpdateGlossaryEntry(row)}>
                            <FaEdit /> Edit
                        </Button>
                        <ConfirmationButton
                            disabled={isLoading}
                            onConfirm={() => onRemoveGlossaryEntry(row)}
                            variant="danger"
                        >
                            <FaTrash /> Remove
                        </ConfirmationButton>
                    </ButtonGroup>
                ),
            },
            ...orderBy(
                props.data.locales.map<TableColumn<GlossaryEntryTransformed>>((locale) => ({
                    name: (
                        <span>
                            {locale.name} ({locale.code}){" "}
                            {locale.isBaseLocale && <Badge>Base locale</Badge>}
                        </span>
                    ),
                    cell: (row) => <span>{row.values[locale.guid]}</span>,
                })),
                "isBaseLocale",
            ),
        ],
        [isLoading, onRemoveGlossaryEntry, onUpdateGlossaryEntry, props.data.locales],
    );

    return <AppDataTable actions={actions} data={data} columns={columns} />;
}
