/** Этот компонент должен использоваться там, где нужно вывести
 * перечисление полей сущности, на которую ссылаемся
 * Например, в ячейке таблицы сущностей */
import { forwardRef, memo, useMemo } from "react";
import Badge from "react-bootstrap/Badge";
import classNames from "classnames";
import Spinner from "react-bootstrap/Spinner";
// import { useTemplateInfo } from "@/store/games/slices";
import { useEntityById } from "@/store/entities/slices";
import { TemplateParam } from "@/gql";
import { useAppSelector } from "@/hooks";
import { isEntitiesLoadingSelector } from "@/store/entities/selectors";
import { ErrorBadge } from "../ErrorBadge";
import { CellContextProvider, useCellContext } from "../../contexts/CellContext";
import { FieldRenderer } from "./FieldRenderer";

interface EntityRefRendererProps {
    templateId: string;
    entityId: string;
    depth?: number;
}

// Куча тс-игноров здесь глушат реальные ошибки
// Так сделано потому что новая таблица больше не поддерживается
export const EntityRefRenderer = memo(
    forwardRef<HTMLDivElement, EntityRefRendererProps>(function EntityRefRenderer(props, ref) {
        const cellContext = useCellContext();
        const gameId = cellContext.gameId;
        const { depth = 0 } = props;

        // @ts-ignore
        const { template, templateDisplay } = useTemplateInfo(props.templateId);
        const isLoading = useAppSelector(isEntitiesLoadingSelector);
        const entity = useEntityById(props.entityId, gameId!);
        const paramsMap = entity?.values ?? {};
        const templateParametersToDisplay = useMemo(() => {
            const refDisplayNotSet = !templateDisplay?.refFieldsOrder.length;
            const refFieldsOrder = templateDisplay?.refFieldsOrder ?? [];
            // @ts-ignore
            const templateParams: TemplateParam[] = template?.templateParams ?? [];

            if (refDisplayNotSet) return templateParams;

            return templateParams.filter((tp) => {
                return refFieldsOrder.includes(tp.guid);
            });
            // @ts-ignore
        }, [templateDisplay?.refFieldsOrder, template?.templateParams]);

        if (props.entityId === "__new") {
            return <Badge bg="info">Create new</Badge>;
        }

        if (entity === undefined && props.entityId !== null) {
            if (isLoading) {
                return (
                    <div>
                        <Spinner size="sm" /> Loading
                    </div>
                );
            } else {
                return <ErrorBadge messageCode="MISSING_REFD_ENTITY" />;
            }
        }

        if (entity === undefined && props.entityId !== null) {
            return;
        }

        if (entity === undefined && props.entityId === null) {
            if (cellContext.templateParam.meta.isRequired) {
                return <ErrorBadge messageCode="MISSING_REFD_ENTITY" />;
            } else {
                return <ErrorBadge messageCode="NO_VALUE" />;
            }
        }

        if (!template) {
            return <ErrorBadge messageCode="NO_TEMPLATE" />;
        }

        // @ts-ignore
        if (!template?.templateParams || template?.templateParams.length === 0) {
            return <ErrorBadge messageCode="NO_FIELDS_TO_DISPLAY" />;
        }

        return (
            <div
                className={classNames("align-items-start d-flex flex-nowrap", `depth-${depth}`)}
                ref={ref}
            >
                <div className="d-flex gap-1 flex-column p-1 border border-1 rounded w-100">
                    {templateParametersToDisplay.map((templateParam) => (
                        <CellContextProvider
                            key={templateParam.guid}
                            {...cellContext}
                            templateParam={templateParam}
                            contextEntity={entity}
                        >
                            <FieldRenderer
                                templateParam={templateParam}
                                value={paramsMap[templateParam.guid]?.value}
                            />
                        </CellContextProvider>
                    ))}
                </div>
            </div>
        );
    }),
);
