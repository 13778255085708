import { ComponentProps, useMemo } from "react";
import { FieldValues, useController, UseControllerProps } from "react-hook-form";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import { Select } from "../../Select";
import { ConnectForm } from "../ConnectForm";
import type { GroupBase, Props, StylesConfig } from "react-select";

function selectStyles<
    Option,
    IsMulti extends boolean,
    Group extends GroupBase<Option>,
>(): StylesConfig<Option, IsMulti, Group> {
    return {
        menuPortal: (base) => ({
            ...base,
            zIndex: 10001,
        }),
    };
}

export function FormReactSelect<
    FormValues extends FieldValues,
    Option,
    IsMulti extends boolean = false,
    Group extends GroupBase<Option> = GroupBase<Option>,
>(props: Props<Option, IsMulti, Group> & UseControllerProps<FormValues> & { title?: string }) {
    const { field, fieldState } = useController(props);
    const styles = useMemo(selectStyles, []);
    const error = useMemo(() => {
        if (!fieldState.error) return null;
        switch (fieldState.error.type) {
            case "required":
                return "This field is required";
            default:
                return fieldState.error.message || "Invalid value";
        }
    }, [fieldState.error]);

    return (
        <Form.Group className={props.className}>
            <Form.Label>{props.title ?? props.name}</Form.Label>
            {/* this is some stupid type fuckery that's better be suppressed for overall well-being */}
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore */}
            <Select styles={styles} menuPortalTarget={document.body} {...props} {...field} />
            {props.required && (
                <Col md={12}>
                    <Form.Text muted>Required</Form.Text>
                </Col>
            )}
            {error && <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>}
        </Form.Group>
    );
}

export function NestedFormReactSelect<
    FormValues extends FieldValues,
    Option,
    IsMulti extends boolean = false,
    Group extends GroupBase<Option> = GroupBase<Option>,
>({
    // @ts-ignore
    ref: _ref,
    ...inputProps
}: ComponentProps<typeof FormReactSelect<FormValues, Option, IsMulti, Group>>) {
    return (
        <ConnectForm>
            {/* Suppressed type fuckery pt.2 */}
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore */}
            {({ register }) => <FormReactSelect {...inputProps} {...register(inputProps.name)} />}
        </ConnectForm>
    );
}
