import { ChangeEvent, useCallback, useEffect, useMemo, useRef } from "react";
import { type TableColumn, type PaginationComponentProps } from "react-data-table-component";
import keyBy from "lodash/keyBy";
import orderBy from "lodash/orderBy";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import styled from "styled-components";
import Form from "react-bootstrap/Form";
import Badge from "react-bootstrap/Badge";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import { FaEdit, FaFile, FaFileExport, FaFileImport, FaTrash } from "react-icons/fa";
import { MdTranslate } from "react-icons/md";
import { useSnackbar } from "notistack";
import { useDebounceCallback } from "usehooks-ts";
import {
    CreateGlossaryEntryMutationVariables,
    Locale,
    Translation as GqlTranslation,
    TranslationsQuery,
    TranslationString,
} from "@/gql";
import { AppDataTable } from "@/components/common/DataTable";
import { ConfirmationButton } from "@/components/common/ConfirmationButton";
import { showCreateTranslationModal } from "@/components/translation/CreateTranslationModal";
import {
    useBulkUpdateFromDeeplMutation,
    useLazyGetTranslation,
    useRemoveTranslationMutation,
    useUpdateTranslationFromDeepl,
} from "@/graphql/translation";
import { showUpdateTranslationModal } from "@/components/translation/UpdateTranslationModal";
import { showExportTranslationModal } from "@/components/modals2/ExportTranslationsModal";
import { showImportTranslationsModal } from "@/components/modals2/ImportTranslationsModal";
import { TranslationsTablePaginationOptions } from "@/components/translation/types";
import { showCreateDeeplTranslationModal } from "@/components/translation/CreateDeeplTranslationModal";
import { useCreateGlossaryEntryMutation } from "@/graphql/translation/glossaries";
import { useBulkUpdateFromDeepl } from "@/components/translation/modals/BulkUpdateFromDeepl";

type Props = {
    loading: boolean;
    gameId: string;
    data: TranslationsQuery;
    onRefetch: () => void;
    total: number;
    pagination: TranslationsTablePaginationOptions;
    onPaginationChange: (data: Partial<TranslationsTablePaginationOptions>) => void;
};

type Translation = Pick<GqlTranslation, "description" | "gameId" | "guid" | "key">;
type TranslationData = Translation & { values: Record<Locale["code"], TranslationString["value"]> };

const TranslationCell = styled.div`
    min-width: 15vw;
    max-width: 20vw;
    white-space: break-spaces;
`;

export function TranslationsTable(props: Props) {
    const localesMap = useMemo(() => keyBy(props.data.locales, "guid"), [props.data.locales]);
    const filterInputRef = useRef<HTMLInputElement | null>(null);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [getTranslation] = useLazyGetTranslation();
    const [removeTranslationMutation] = useRemoveTranslationMutation();
    const [updateTranslationFromDeepl] = useUpdateTranslationFromDeepl();
    const [createGlossaryEntryMutation] = useCreateGlossaryEntryMutation();
    const bulkUpdateFromDeepl = useBulkUpdateFromDeepl();
    const onChangeRowsPerPage = useCallback<PaginationComponentProps["onChangeRowsPerPage"]>(
        (currentRowsPerPage, currentPage) => {
            props.onPaginationChange({
                take: currentRowsPerPage,
                skip: (currentPage - 1) * currentRowsPerPage,
            });
        },
        [props],
    );
    const onChangePage = useCallback<PaginationComponentProps["onChangePage"]>(
        (page) => {
            props.onPaginationChange({
                skip: props.pagination.take * (page - 1),
            });
        },
        [props],
    );
    const onQueryChange = useDebounceCallback((e: ChangeEvent<HTMLInputElement>) => {
        const newQuery = e.target.value;

        if (props.pagination.query !== newQuery) {
            props.onPaginationChange({
                query: newQuery,
            });
        }
    }, 1000);
    const onCreateDeeplTranslation = useCallback(async () => {
        await showCreateDeeplTranslationModal({
            gameId: props.gameId,
            locales: props.data.locales,
        });
        props.onRefetch();
    }, [props]);
    const onBulkUpdateFromDeepl = useCallback(async () => {
        await bulkUpdateFromDeepl({
            gameId: props.gameId,
        });
        props.onRefetch();
    }, [bulkUpdateFromDeepl, props]);
    const onCreateTranslation = useCallback(async () => {
        await showCreateTranslationModal({
            gameId: props.gameId,
            locales: props.data.locales,
        });
        props.onRefetch();
    }, [props]);
    const onUpdateTranslationFromDeepl = useCallback(
        async (row: Translation) => {
            await updateTranslationFromDeepl({
                variables: {
                    guid: row.guid,
                    gameId: row.gameId,
                },
            });
            await props.onRefetch();
        },
        [props, updateTranslationFromDeepl],
    );
    const promptForUpdateTranslation = useCallback(
        (row: Translation) => {
            enqueueSnackbar(
                "Base translation was changed. Do you wish to update rest of them with translation from Deepl?",
                {
                    variant: "success",
                    action: (snackbarId) => (
                        <>
                            <Button
                                size="sm"
                                onClick={async () => {
                                    await onUpdateTranslationFromDeepl(row);
                                    closeSnackbar(snackbarId);
                                }}
                            >
                                Update
                            </Button>
                            <Button size="sm" onClick={() => closeSnackbar(snackbarId)}>
                                Dismiss
                            </Button>
                        </>
                    ),
                },
            );
        },
        [closeSnackbar, enqueueSnackbar, onUpdateTranslationFromDeepl],
    );
    const onEditTranslation = useCallback(
        async (row: TranslationData) => {
            const { data } = await getTranslation({
                variables: {
                    guid: row.guid,
                    gameId: row.gameId,
                },
            });
            if (!data?.translation) {
                enqueueSnackbar("Couldn't find translation entry", {
                    variant: "error",
                    autoHideDuration: 3000,
                });
                return;
            }
            const { baseTranslationChanged } = await showUpdateTranslationModal({
                gameId: props.gameId,
                locales: props.data.locales,
                translation: data.translation,
            });

            if (baseTranslationChanged) {
                promptForUpdateTranslation(data.translation);
            }
            props.onRefetch();
        },
        [enqueueSnackbar, getTranslation, promptForUpdateTranslation, props],
    );
    const onRemoveTranslation = useCallback(
        async (row: Translation) => {
            await removeTranslationMutation({ variables: { gameId: row.gameId, guid: row.guid } });
            props.onRefetch();
        },
        [props, removeTranslationMutation],
    );
    const onExport = useCallback(() => {
        return showExportTranslationModal({
            gameId: props.gameId,
            locales: props.data.locales,
        });
    }, [props.data.locales, props.gameId]);
    const onImport = useCallback(async () => {
        await showImportTranslationsModal({ gameId: props.gameId });
        await props.onRefetch();
    }, [props]);
    const actions = useMemo(
        () => (
            <ButtonGroup>
                <Button onClick={onExport}>
                    <FaFileImport /> Export to spreadsheet
                </Button>
                <Button onClick={onImport}>
                    <FaFileExport /> Import from spreadsheet
                </Button>
                <Button onClick={onCreateTranslation}>
                    <FaFile /> Create
                </Button>
                <Button onClick={onBulkUpdateFromDeepl}>
                    <MdTranslate /> Bulk update from Deepl
                </Button>
                <Button onClick={onCreateDeeplTranslation}>
                    <MdTranslate /> Create from Deepl
                </Button>
            </ButtonGroup>
        ),
        [onCreateDeeplTranslation, onCreateTranslation, onExport, onImport],
    );
    const onAddToGlossary = useCallback(
        async (row: TranslationData) => {
            const variables: CreateGlossaryEntryMutationVariables = {
                gameId: row.gameId,
                glossaryEntries: Object.entries(row.values)
                    .map(([code, value]) => {
                        const locale = props.data.locales.find((locale) => locale.code === code);
                        if (!locale) return null;
                        return { localeId: locale.guid, value };
                    })
                    .filter(Boolean),
            };
            await createGlossaryEntryMutation({ variables });
        },
        [createGlossaryEntryMutation, props.data.locales],
    );
    const data = useMemo<TranslationData[]>(() => {
        return props.data.translations.map((translation) => {
            return {
                guid: translation.guid,
                gameId: translation.gameId,
                key: translation.key,
                description: translation.description,
                values: translation.translationStrings.reduce(
                    (acc, value) => {
                        const locKey = localesMap[value.localeId]?.code;

                        if (locKey !== undefined) {
                            acc[locKey] = value.value;
                        }

                        return acc;
                    },
                    {} as Record<Locale["code"], TranslationString["value"]>,
                ),
            };
        });
    }, [localesMap, props.data.translations]);
    const columns = useMemo<TableColumn<TranslationData>[]>(
        () => [
            {
                name: "Actions",
                cell: (row) => (
                    <div className="d-flex flex-column align-items-start">
                        <ButtonGroup size="sm">
                            <Button onClick={() => onEditTranslation(row)} title="Edit">
                                <FaEdit />
                            </Button>
                            <ConfirmationButton
                                onConfirm={() => onRemoveTranslation(row)}
                                variant="danger"
                                title="Remove"
                            >
                                <FaTrash />
                            </ConfirmationButton>
                            <DropdownButton title={<MdTranslate />} size="sm">
                                <Dropdown.Item
                                    as={ConfirmationButton}
                                    variant="link"
                                    size="sm"
                                    onConfirm={() => onUpdateTranslationFromDeepl(row)}
                                    prompt="Are you sure you want to update this row with translations from Deepl"
                                    title="Update with Deepl"
                                >
                                    Update with Deepl
                                </Dropdown.Item>
                                <Dropdown.Item
                                    as={Button}
                                    variant="link"
                                    onClick={() => onAddToGlossary(row)}
                                >
                                    Add to glossary
                                </Dropdown.Item>
                            </DropdownButton>
                        </ButtonGroup>
                    </div>
                ),
                style: {
                    maxWidth: "200px",
                    minWidth: "200px",
                },
            },
            {
                name: "Key",
                cell: (row) => <span title={row.key}>{row.key}</span>,
                style: {
                    maxWidth: "300px",
                    minWidth: "300px",
                },
                grow: 0,
            },
            {
                name: "Description",
                cell: (row) => <span title={row.description}>{row.description}</span>,
                style: {
                    maxWidth: "350px",
                    minWidth: "350px",
                },
                grow: 0,
            },
            ...orderBy(props.data.locales, (v) => !v.isBaseLocale).map((locale) => ({
                name: (
                    <div className="d-flex gap-1">
                        {locale.name} ({locale.code})
                        {locale.isBaseLocale && <Badge>Base locale</Badge>}
                    </div>
                ),
                cell: (row: TranslationData) => (
                    <TranslationCell>
                        {row.values[locale.code] ?? <Badge bg="info">No translation</Badge>}
                    </TranslationCell>
                ),
            })),
        ],
        [
            onAddToGlossary,
            onEditTranslation,
            onRemoveTranslation,
            onUpdateTranslationFromDeepl,
            props.data.locales,
        ],
    );

    useEffect(() => {
        filterInputRef.current?.focus();
    }, []);

    return (
        <AppDataTable
            subHeader
            subHeaderComponent={
                <Form.Control
                    ref={filterInputRef}
                    defaultValue={props.pagination.query}
                    placeholder="Filter"
                    onChange={onQueryChange}
                />
            }
            progressPending={props.loading}
            subHeaderWrap
            fixedHeader
            customStyles={{
                table: {
                    style: {
                        flexGrow: 1,
                    },
                },
                header: {
                    style: {
                        flexGrow: 0,
                    },
                },
                subHeader: {
                    style: {
                        flexGrow: 0,
                    },
                },
            }}
            actions={actions}
            data={data}
            columns={columns}
            persistTableHead
            striped
            pagination
            paginationServer
            paginationDefaultPage={Math.floor(props.pagination.skip / props.pagination.take) + 1}
            paginationServerOptions={{
                persistSelectedOnPageChange: true,
                persistSelectedOnSort: true,
            }}
            paginationTotalRows={props.total}
            paginationRowsPerPageOptions={[25, 50, 100]}
            paginationPerPage={props.pagination.take}
            onChangeRowsPerPage={onChangeRowsPerPage}
            onChangePage={onChangePage}
        />
    );
}
