import { useMemo } from "react";
import { Link as NavLink } from "@tanstack/react-router";
import {
    DeployStep,
    TemplateValidationEvent,
    TemplateValidationErrorEvent,
    TemplateValidationFailedEvent,
    TemplateValidationMissingReferenceEvent,
} from "@/gql";
import { useGetTemplate } from "@/graphql/templates";

export interface TemplateValidationEventRendererProps {
    gameId: string;
    event:
        | TemplateValidationEvent
        | TemplateValidationErrorEvent
        | TemplateValidationFailedEvent
        | TemplateValidationMissingReferenceEvent;
}

export function TemplateValidationEventRenderer(props: TemplateValidationEventRendererProps) {
    const getTemplate = useGetTemplate({ guid: props.event.templateId, gameId: props.gameId });
    const template = getTemplate.data?.template;
    const entityInfo = useMemo(() => {
        if (!template) return { templateName: null, parameterName: null };
        const templateParameter = template.templateParams?.find(
            (templateParameter) => templateParameter.guid === props.event.templateParameterId,
        );

        return {
            templateName: template.name,
            parameterName: templateParameter?.name,
        };
    }, [props.event.templateParameterId, template]);

    switch (props.event.deployCode) {
        case DeployStep.TemplateValidation:
            return (
                <span>
                    Validating template parameter {entityInfo.parameterName} of{" "}
                    <NavLink to={`../templates/${props.event.templateId}`}>
                        {entityInfo.templateName}
                    </NavLink>
                </span>
            );
        case DeployStep.TemplateValidationError:
            return (
                <span>
                    Error happened during parameter validation {entityInfo.parameterName} of{" "}
                    {entityInfo.templateName}. Error: {props.event.error}
                </span>
            );
        case DeployStep.TemplateValidationFailed:
            return (
                <span>
                    Validation failed for parameter {entityInfo.parameterName} of{" "}
                    {entityInfo.templateName}. Error: {props.event.error}
                </span>
            );
        case DeployStep.TemplateValidationMissingReference:
            return (
                <span>
                    Parameter {entityInfo.parameterName} of {entityInfo.templateName} references
                    template not included in to the scope of current consumer. Error:{" "}
                    {props.event.error}
                </span>
            );
        default:
            return <span>Incorrect template validation event code</span>;
    }
}
