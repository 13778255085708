import { DataType } from "@/gql";
import { createDatatype } from "../../createDatatype";
import { ListPlaceholderField } from "./ListPlaceholderField";
import { ListFieldComponent } from "./ListFieldComponent";

export const listField = createDatatype<string[]>({
    type: DataType.List,
    defaultValue: () => [],
    inputComponent: ListFieldComponent,
    placeholderComponent: ListPlaceholderField,
    tableCellDisplayOptions: (templateParam) =>
        templateParam.subType === DataType.EntityRef ||
        templateParam.subType === DataType.InjectedRef
            ? {
                  minWidth: "450px",
                  maxWidth: "750px",
              }
            : {
                  minWidth: "200px",
                  maxWidth: "350px",
              },
});
