import { useCallback, useMemo } from "react";
import { TableColumn } from "react-data-table-component";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import { FaEdit, FaTrash } from "react-icons/fa";
import Badge from "react-bootstrap/Badge";
import { useSnackbar } from "notistack";
import { AppDataTable } from "@/components/common/DataTable";
import { GetLocalesQuery } from "@/gql";
import { ConfirmationButton } from "@/components/common/ConfirmationButton";
import { useGetLocales, useRemoveLocaleMutation } from "@/graphql/translation";
import { Locale } from "@/types";
import { showCreateLocaleModal } from "./CreateLocaleModal";
import { showUpdateLocaleModal } from "./UpdateLocaleModal";

type Props = {
    gameId: string;
    data: GetLocalesQuery;
};

export function LocalesTable(props: Props) {
    const { gameId } = props;
    const { enqueueSnackbar } = useSnackbar();
    const [removeLocaleMutation] = useRemoveLocaleMutation();
    const getLocales = useGetLocales({ gameId: gameId! });
    const onAddLocale = useCallback(async () => {
        await showCreateLocaleModal({ gameId });
        return getLocales.refetch();
    }, [gameId, getLocales]);
    const onEditLocale = useCallback(
        async (row: Locale) => {
            await showUpdateLocaleModal({ locale: row });
            return getLocales.refetch();
        },
        [getLocales],
    );
    const onRemoveLocale = useCallback(
        async (row: Locale) => {
            await removeLocaleMutation({ variables: row });
            enqueueSnackbar("Locale removed", {
                variant: "success",
            });
            return getLocales.refetch();
        },
        [getLocales, removeLocaleMutation, enqueueSnackbar],
    );
    const actions = useMemo(
        () => (
            <ButtonGroup>
                <Button onClick={onAddLocale}>Add</Button>
            </ButtonGroup>
        ),
        [onAddLocale],
    );
    const columns = useMemo<TableColumn<Locale>[]>(
        () => [
            {
                name: "Actions",
                cell: (row) => (
                    <ButtonGroup size="sm">
                        <Button onClick={() => onEditLocale(row)}>
                            <FaEdit /> Edit
                        </Button>
                        <ConfirmationButton onConfirm={() => onRemoveLocale(row)} variant="danger">
                            <FaTrash /> Remove
                        </ConfirmationButton>
                    </ButtonGroup>
                ),
            },
            {
                name: "Locale name",
                cell: (row) => (
                    <span>
                        {row.name} {row.isBaseLocale && <Badge>Base locale</Badge>}
                    </span>
                ),
            },
            {
                name: "Code",
                selector: (row) => row.code,
            },
        ],
        [onEditLocale, onRemoveLocale],
    );

    return (
        <AppDataTable
            actions={actions}
            data={props.data.locales}
            columns={columns}
            striped
            keyField="guid"
        />
    );
}
