import { useCallback, useMemo } from "react";
import { TableCell } from "@/fields/types";
import { useAddChangeRecord } from "@/components/common/Table/EntitiesTable/contexts/TableCellContext";
import { DisableEntityParameterBtn } from "@/fields/components/DisableEntityParameterBtn";
import { TimestampInput } from "@/fields/datatypes/Timestamp/TimestampInput";
import { CopyToClipboard } from "@/components/common/CopyToClipboard";
import { dayjs } from "@/utils/dayjs";

export const TimestampTableCell: TableCell = function (props) {
    const { value, addChangeRecord, disabled } = useAddChangeRecord<number>(props);
    const onChange = useCallback(
        (value: number) => {
            if (props.readonly) return;

            if (value) addChangeRecord(value);
        },
        [addChangeRecord, props.readonly],
    );
    const datetimeLocal = useMemo(() => {
        const date = dayjs(value);
        return date.format("DD MMM YYYY / HH:mm z");
    }, [value]);

    return (
        <div className="d-flex align-items-top">
            <DisableEntityParameterBtn {...props} />

            <div className="d-flex flex-column gap-1">
                <TimestampInput
                    value={value}
                    onChange={onChange}
                    disabled={props.readonly || disabled}
                />
                <div className="d-flex flex-wrap align-items-center gap-1">
                    <CopyToClipboard value={value} className="flex-grow-0" />
                    <CopyToClipboard value={datetimeLocal} className="flex-grow-0" />
                </div>
            </div>
        </div>
    );
};
