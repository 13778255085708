import { useForm } from "react-hook-form";
import Form from "react-bootstrap/Form";
import { BaseModal, BaseModalProps } from "@/components/modals/BaseModal";
import { FormInput } from "@/components/common/forms/form-inputs/FormInput";

type Props = BaseModalProps<{ categoryName: string }> & { categoryName: string };

export function RenameCategory(props: Props) {
    const form = useForm<{ categoryName: string }>({
        defaultValues: {
            categoryName: props.categoryName,
        },
    });

    return (
        <BaseModal isOpen={props.isOpen} toggle={props.toggle}>
            <Form onSubmit={form.handleSubmit(props.toggle)}>
                <FormInput
                    control={form.control}
                    className="mb-2"
                    name="categoryName"
                    title="Category name"
                    rules={{
                        required: { value: true, message: "This field is required" },
                        min: 3,
                        max: 250,
                    }}
                />
            </Form>
        </BaseModal>
    );
}
