import { RefObject, useEffect } from "react";

export function useClickOutside<Element extends HTMLElement>(
    ref: RefObject<Element>,
    callback: () => void,
    enabled: boolean,
) {
    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            if (enabled && ref.current && !ref.current.contains(event.target)) {
                callback();
            }
        }
        const refCopy = ref.current;

        // TODO здесь нужно слушать mousedown на document, но это вызывает проблемы
        // если переключаемый компонент рисует модалку, с которой нужно взаимодействовать
        refCopy?.addEventListener("mousedown", handleClickOutside);
        // document.addEventListener("mousedown", handleClickOutside);
        return () => {
            refCopy?.removeEventListener("mousedown", handleClickOutside);
            // document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [callback, enabled, ref]);
}
