import { memo } from "react";
import Form from "react-bootstrap/Form";
import { InputProps } from "../../types";
import { useTableContext } from "../../contexts/TableContext";
import { useFieldController } from "../../hooks/useFieldController";

export const BooleanFieldComponent = memo(function BooleanFieldComponent(props: InputProps) {
    const { readonly } = useTableContext();

    const controller = useFieldController(props.name);
    const checked =
        typeof controller.field.value === "boolean"
            ? controller.field.value
            : typeof controller.field.value === "string"
            ? controller.field.value === "true"
            : false;

    return (
        <Form.Check
            tabIndex={1}
            type="switch"
            {...controller.field}
            value={controller.field.value as string}
            checked={checked}
            disabled={controller.field.disabled || readonly}
            required={false}
        />
    );
});
