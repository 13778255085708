import Badge from "react-bootstrap/Badge";

const errors = {
    ENTITY_NOT_LOADED: { variant: "danger", message: "Entity not loaded" },
    INCORRECT_ENUM_VALUE: { variant: "danger", message: "Incorrect enum value" },
    INCORRECT_TEMPLATE_ID: {
        variant: "danger",
        message: "Incorrect templateId",
        help: `Template id of an entity doesn't match template id of a template currently displayed
        Possible reason: template referenced by template parameter was changed but default value was not`,
    },
    MISSING_ENUM_DEF: { variant: "danger", message: "Missing enum definition" },
    MISSING_REFD_ENTITY: { variant: "danger", message: "Missing referenced entity" },
    MISSING_REFD_TEMPLATE: { variant: "danger", message: "Missing reference template" },
    MISSING_TEMPLATEID: { variant: "danger", message: "Missing templateId" },
    NO_TEMPLATE: { variant: "danger", message: "No template" },
    NO_FIELDS_TO_DISPLAY: { variant: "warning", message: "No fields to display" },
    NOT_IMPLEMENTED: { variant: "danger", message: "Not implemented" },
    NO_VALUE: { variant: "success", message: "No value" },
} as const;

type Props = {
    messageCode: keyof typeof errors;
};

export function ErrorBadge(props: Props) {
    const error = errors[props.messageCode];
    return (
        <Badge bg={error.variant} title={"help" in error ? error.help : undefined}>
            {error.message}
        </Badge>
    );
}
