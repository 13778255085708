import { gql } from "@apollo/client";
import { ASSET_FRAGMENT } from "@/graphql/fragments/asset.fragment";
import { GetAssetQuery, GetAssetQueryVariables } from "@/gql";
import { makeGqlQueryHook, makeGqlQueryLazyHook } from "../util";

const SCHEMA = gql`
    ${ASSET_FRAGMENT}

    query getAsset($guid: String!, $gameId: String!) {
        result: getAsset(guid: $guid, gameId: $gameId) {
            ...Asset
        }
    }
`;

export const useGetAsset = makeGqlQueryHook<GetAssetQuery, GetAssetQueryVariables>(SCHEMA);
export const useLazyGetAsset = makeGqlQueryLazyHook<GetAssetQuery, GetAssetQueryVariables>(SCHEMA);
