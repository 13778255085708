import { useMemo } from "react";
import Stack from "react-bootstrap/Stack";
import ListGroup from "react-bootstrap/ListGroup";
import Badge from "react-bootstrap/Badge";
import sortBy from "lodash/sortBy";
import styled from "styled-components";
import { Locale } from "@/gql";
import { CasedBadge } from "@/components/common/CasedBadge";
import { Translation } from "@/types";

type Props = {
    short?: boolean;
    legacy?: boolean;
    data: Translation;
    localesMap?: Record<string, Locale>;
};

const ListGroupItem = styled(ListGroup.Item)<{ $short?: boolean }>`
    ${(props) => {
        if (props.$short)
            return `
display: -webkit-box;
-webkit-line-clamp: 3;
-webkit-box-orient: vertical;
overflow: hidden;
text-overflow: ellipsis;
        `;
        return "";
    }}
`;

export function TranslationRenderer(props: Props) {
    const { data, localesMap, legacy = false, short = false } = props;
    const translations = useMemo(() => {
        if (!localesMap) return [];
        if (!data) return [];
        return sortBy(data.translationStrings, (v) => localesMap[v.localeId]?.code);
    }, [data, localesMap]);

    return (
        <Stack>
            <span className="p-2 font-monospace">
                {data?.key} {legacy && <Badge bg="warning">Legacy value</Badge>}
            </span>
            {localesMap && (
                <ListGroup className="list-group-flush">
                    {translations.map((str) => (
                        <ListGroupItem
                            $short={short}
                            key={str.guid}
                            title={str.value}
                            className="p-2"
                        >
                            <CasedBadge>{localesMap[str.localeId]?.code}</CasedBadge> {str.value}
                        </ListGroupItem>
                    ))}
                </ListGroup>
            )}
        </Stack>
    );
}
