import { ReactNode, useMemo } from "react";
import DataTable, { ExpanderComponentProps, TableColumn } from "react-data-table-component";
import Stack from "react-bootstrap/Stack";
import isNil from "lodash/isNil";
import { DateDisplay } from "@/components/common/DateDisplay";
import { JobModel } from "@/gql";
import { stringify } from "@/utils/stringify";

type Props = {
    jobs: JobModel[];
    actionsColumn?: (row: JobModel) => ReactNode[] | ReactNode;
};

function JobsTableExpandedRowComponent(props: ExpanderComponentProps<JobModel>) {
    const isFailed = props.data.failedReason || props.data.stacktrace.length > 0;
    const failedReason =
        props.data.failedReason !== "" && !isNil(props.data.failedReason)
            ? props.data.failedReason
            : "unknown";

    return (
        <Stack gap={2} className="p-4">
            <div>
                <h5>Job data</h5>
                <pre>{stringify(props.data.data)}</pre>
            </div>
            <hr className="my-1" />
            {isFailed && (
                <div>
                    <h5>Error</h5>
                    <p>Reason: {failedReason}</p>
                    {props.data.stacktrace.length > 0 && (
                        <pre>{props.data.stacktrace.join("\n")}</pre>
                    )}
                </div>
            )}
        </Stack>
    );
}

export function JobsTable(props: Props) {
    const columns = useMemo<TableColumn<JobModel>[]>(
        () => [
            {
                name: "Job name",
                selector: (row) => row.name,
            },
            {
                name: "Status",
                selector: (row) => row.status,
            },
            {
                name: "Return value",
                selector: (row) =>
                    row.returnValue !== null ? stringify(row.returnValue) : "No return value",
            },
            {
                name: "Progress",
                selector: (row) => row.progress.toFixed(4),
            },
            {
                name: "Timestamp",
                cell: (row) => <DateDisplay date={row.timestamp} format="DD MMM YYYY HH:mm:ss" />,
            },
            {
                name: "Processed on",
                cell: (row) =>
                    row.processedOn ? (
                        <DateDisplay date={row.processedOn} format="DD MMM YYYY HH:mm:ss" />
                    ) : (
                        <span>Never</span>
                    ),
            },
            {
                name: "Finished on",
                cell: (row) =>
                    row.finishedOn ? (
                        <DateDisplay date={row.finishedOn} format="DD MMM YYYY HH:mm:ss" />
                    ) : (
                        <span>Never</span>
                    ),
            },
            {
                name: "Actions",
                omit: typeof props.actionsColumn !== "function",
                cell: (row) =>
                    typeof props.actionsColumn === "function" && props.actionsColumn(row),
            },
        ],
        [props],
    );

    return (
        <DataTable
            data={props.jobs}
            keyField="jobId"
            columns={columns}
            expandableRows
            expandableRowsComponent={JobsTableExpandedRowComponent}
        />
    );
}
