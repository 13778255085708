import { useReducer } from "react";

enum CollectionAction {
    SET_ITEMS = "SET_ITEMS",
    ADD_ITEM = "ADD_ITEM",
    REMOVE_ITEM = "REMOVE_ITEM",
    REPLACE_ITEM = "REPLACE_ITEM",
}

type ReducerState<Item> = { state: Item[] };

type ReducerAction<Item> =
    | { type: CollectionAction.SET_ITEMS; items: Item[] }
    | { type: CollectionAction.ADD_ITEM; item: Item }
    | { type: CollectionAction.REMOVE_ITEM; key: Item[keyof Item] }
    | { type: CollectionAction.REPLACE_ITEM; key: Item[keyof Item]; item: Item };

export function useCollectionReducer<Item>(id: keyof Item, initialValue: Item[] = []) {
    const [collection, dispatch] = useReducer<
        (state: ReducerState<Item>, action: ReducerAction<Item>) => ReducerState<Item>
    >(
        (prevState, action) => {
            switch (action.type) {
                case CollectionAction.SET_ITEMS:
                    return { state: action.items };
                case CollectionAction.REMOVE_ITEM:
                    return { state: prevState.state.filter((entity) => entity[id] !== action.key) };
                case CollectionAction.ADD_ITEM:
                    return { state: [...prevState.state, action.item] };
                case CollectionAction.REPLACE_ITEM:
                    return {
                        state: prevState.state.map((entity) =>
                            entity[id] === action.key ? action.item : entity,
                        ),
                    };
            }
        },
        { state: initialValue },
    );

    const set = (items: Item[]) => dispatch({ type: CollectionAction.SET_ITEMS, items });
    const add = (item: Item) => dispatch({ type: CollectionAction.ADD_ITEM, item });
    const remove = (key: Item[keyof Item]) => dispatch({ type: CollectionAction.REMOVE_ITEM, key });
    const replace = (key: Item[keyof Item], item: Item) =>
        dispatch({ type: CollectionAction.REPLACE_ITEM, item, key });

    return {
        collection: collection.state,
        set,
        add,
        remove,
        replace,
    };
}
