import { gql } from "@apollo/client";
import { makeGqlMutationHook } from "@/graphql/util";
import { ExportToSpreadsheetMutation, ExportToSpreadsheetMutationVariables } from "@/gql";

const SCHEMA = gql`
    mutation exportToSpreadsheet(
        $gameId: String!
        $emails: [String!]!
        $templateId: String!
        $templates: [ExportToSpreadsheetTemplate!]!
    ) {
        exportToSpreadsheet(
            emails: $emails
            gameId: $gameId
            templateId: $templateId
            templates: $templates
        ) {
            spreadsheetId
            errors
        }
    }
`;

export const useExportToSpreadsheet = makeGqlMutationHook<
    ExportToSpreadsheetMutation,
    ExportToSpreadsheetMutationVariables
>(SCHEMA);
