import { gql } from "@apollo/client";
import { makeGqlMutationHook } from "@/graphql/util";
import { ImportEntitiesFromMutation, ImportEntitiesFromMutationVariables } from "@/gql";

const SCHEMA = gql`
    mutation importEntitiesFrom(
        $targetGameId: String!
        $sourceGameId: String!
        $templateIds: [String!]!
    ) {
        importEntitiesFrom(
            targetGameId: $targetGameId
            sourceGameId: $sourceGameId
            templateIds: $templateIds
        ) {
            templateName
            entitiesBefore
            entitiesAfter
        }
    }
`;

export const useImportEntitiesFrom = makeGqlMutationHook<
    ImportEntitiesFromMutation,
    ImportEntitiesFromMutationVariables
>(SCHEMA);
