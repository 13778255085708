import { MouseEventHandler, useCallback, useMemo } from "react";
import Stack from "react-bootstrap/Stack";
import cn from "classnames";
import { useSnackbar } from "notistack";
import { FaExternalLinkAlt } from "react-icons/fa";
import {
    ConfirmationModalProps,
    useShowConfirmationModal,
} from "@/components/modals/ConfirmationModal";
import { useCountEntityReferences, useRemoveEntityMutation } from "@/graphql/entities";
import {
    useShowTemplateParamModal,
    type UseTemplateParamModalProps,
} from "@/components/modals2/TemplateParamModal";

export function useDeleteEntity() {
    const { enqueueSnackbar } = useSnackbar();
    const showConfirmationModal = useShowConfirmationModal();
    const [removeEntityMutation, { loading: removeEntityMutationLoading }] =
        useRemoveEntityMutation();
    const [countEntityReferences, { loading: countEntityReferencesLoading }] =
        useCountEntityReferences();
    const loading = removeEntityMutationLoading || countEntityReferencesLoading;
    const showTemplateParamModal = useShowTemplateParamModal();
    const onEditTemplateParameter = useCallback(
        (props: UseTemplateParamModalProps): MouseEventHandler<HTMLAnchorElement> => {
            return (e) => {
                e.preventDefault();
                e.stopPropagation();
                showTemplateParamModal(props);
            };
        },
        [showTemplateParamModal],
    );

    const onDeleteEntity = useCallback(
        async (row: { guid: string; gameId: string }) => {
            const { data } = await countEntityReferences({ variables: row });

            if (!data) {
                enqueueSnackbar("Could not count references. Cannot delete", { variant: "error" });
                return;
            }

            const entityParamsCount = data.countEntityReferences.entityParameters.length;
            const templateParamsCount = data.countEntityReferences.templateParameters.length;

            let confirmationModalProps: ConfirmationModalProps;

            if (entityParamsCount === 0 && templateParamsCount === 0) {
                confirmationModalProps = {
                    prompt: "Please confirm deletion",
                };
            } else {
                confirmationModalProps = {
                    prompt: (
                        <Stack>
                            <div>This entity is referenced by several parameters:</div>
                            <ul>
                                <li>
                                    <div>{entityParamsCount} entity parameter(s)</div>
                                    {entityParamsCount > 0 && (
                                        <ul>
                                            {data.countEntityReferences.entityParameters.map(
                                                (entityParameter) => (
                                                    <li key={entityParameter.guid}>
                                                        <a
                                                            target="_blank"
                                                            href={`/${row.gameId}/entity/${entityParameter.entityId}`}
                                                            rel="noreferrer"
                                                        >
                                                            {entityParameter.entityId}{" "}
                                                            <FaExternalLinkAlt />
                                                        </a>
                                                    </li>
                                                ),
                                            )}
                                        </ul>
                                    )}
                                </li>
                                <li
                                    className={cn({ "bg-warning-subtle": templateParamsCount > 0 })}
                                >
                                    <div>
                                        {templateParamsCount} template parameter(s) as default value
                                    </div>
                                    {templateParamsCount > 0 && (
                                        <ul>
                                            {data.countEntityReferences.templateParameters.map(
                                                (templateParameter) => (
                                                    <li key={templateParameter.guid}>
                                                        <a
                                                            href={`/${row.gameId}/templates/${templateParameter.templateId}`}
                                                            onClick={onEditTemplateParameter({
                                                                gameId: row.gameId,
                                                                templateId:
                                                                    templateParameter.templateId!,
                                                                templateParamId:
                                                                    templateParameter.guid,
                                                            })}
                                                        >
                                                            {templateParameter.name}
                                                        </a>{" "}
                                                        (click to edit)
                                                    </li>
                                                ),
                                            )}
                                        </ul>
                                    )}
                                </li>
                            </ul>
                            {templateParamsCount > 0
                                ? "Please update template parameters shown above and try again"
                                : "Please confirm"}
                        </Stack>
                    ),
                    disableConfirm: templateParamsCount > 0,
                    confirmationPhrase: "CONFIRM",
                };
            }

            const result = await showConfirmationModal(confirmationModalProps);

            if (!result) return;

            await removeEntityMutation({
                variables: { guid: row.guid, gameId: row.gameId, force: true },
            });
        },
        [
            countEntityReferences,
            enqueueSnackbar,
            onEditTemplateParameter,
            removeEntityMutation,
            showConfirmationModal,
        ],
    );

    return useMemo(() => [onDeleteEntity, loading] as const, [loading, onDeleteEntity]);
}
