import { createRootRoute, Outlet } from "@tanstack/react-router";
import { Provider as ReduxProvider } from "react-redux";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { SnackbarProvider } from "notistack";
import NiceModal from "@ebay/nice-modal-react";
import Page404 from "@/pages/404";
import { JobsPanel } from "@/components/common/JobsPanel";
import { store } from "@/store";
import { SetupGraphql } from "@/graphql";
import { RequestEntityProvider } from "@/contexts/useRequestEntity";

export function RootPage() {
    return (
        <HelmetProvider>
            <SnackbarProvider
                autoHideDuration={1500}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
            >
                <SetupGraphql>
                    <ReduxProvider store={store}>
                        <RequestEntityProvider>
                            <NiceModal.Provider>
                                <Helmet title="Eris" />
                                <Outlet />
                                {location.pathname !== "/" && <JobsPanel />}
                            </NiceModal.Provider>
                        </RequestEntityProvider>
                    </ReduxProvider>
                </SetupGraphql>
            </SnackbarProvider>
        </HelmetProvider>
    );
}

export const rootRoute = createRootRoute({
    component: RootPage,
    notFoundComponent: Page404,
});
