import { useController } from "react-hook-form";
import { DataType } from "@/gql";
import { useTableContext } from "../contexts/TableContext";
import { useCellContext } from "../contexts/CellContext";
import { useFieldsDefinitions } from "./useFieldsDefinitions";
import { useEntitiesTableFormContext } from "./useEntitiesTableFormContext";

export function useFieldController(name: `entities.${string}.values.${string}`) {
    const formContext = useEntitiesTableFormContext();
    const { readonly } = useTableContext();
    const { row, templateParam, type } = useCellContext();
    const { getDefinition } = useFieldsDefinitions();
    const fieldDefinition = getDefinition(type);
    return useController({
        name,
        disabled: readonly,
        defaultValue: row.values[templateParam.guid] ?? fieldDefinition.defaultValue(templateParam),
        control: formContext.control,
        rules: {
            required:
                templateParam.type !== DataType.Boolean && templateParam.type !== DataType.List
                    ? {
                          value: templateParam.meta.isRequired,
                          message: "this field is required",
                      }
                    : undefined,
            // @ts-ignore
            validate:
                typeof fieldDefinition.validator === "function"
                    ? fieldDefinition.validator(templateParam)
                    : undefined,
        },
    });
}
