import { useCallback, useEffect, useMemo, useRef } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useController, useFormContext } from "react-hook-form";
import orderBy from "lodash/orderBy";
import { FormSelect } from "@/components/common/forms/form-inputs/FormSelect";
import { useAvailableTemplates } from "@/store/games/slices";
import { entityToSelectOption } from "@/utils/entityToSelectOption";
import { TemplateType } from "@/gql";
import { TemplateParamCreationAttributes } from "@/components/forms/EditTemplateParamForm";
import { AdditionalParameterFields } from "../../types";
import { InjectedRefICTableCell } from "./InjectedRefICTableCell";

export const InjectedRefParameterFields: AdditionalParameterFields = (props) => {
    const form = useFormContext<TemplateParamCreationAttributes>();
    const availableTemplates = useAvailableTemplates();
    const injectedRefOptions = useMemo(
        () =>
            orderBy(
                availableTemplates
                    .filter((v) => v.type === TemplateType.InjectedComponent)
                    .map(entityToSelectOption),
                (v) => v.label,
            ),
        [availableTemplates],
    );
    const firstICOption = injectedRefOptions.at(0)?.value;
    const templateId = form.watch("meta.templateId");
    const prevTemplateId = useRef(templateId);
    const templateIdController = useController({
        name: "meta.templateId",
        control: form.control,
        defaultValue: firstICOption !== undefined ? String(firstICOption) : undefined,
    });
    const defaultValueController = useController({
        name: "meta.defaultValue",
        control: form.control,
    });

    // Костыль для обновления defaultValue
    useEffect(() => {
        if (prevTemplateId.current !== null && templateId !== prevTemplateId.current) {
            form.setValue("meta.defaultValue", null);
        }
        if (templateId === null && injectedRefOptions.length > 0) {
            form.setValue("meta.templateId", String(injectedRefOptions.at(0)!.value));
        }
    }, [form, injectedRefOptions, templateId]);

    return (
        <Row className="gy-4">
            <Col md={12}>
                <FormSelect
                    {...templateIdController.field}
                    value={templateIdController.field.value ?? undefined}
                    title="Referenced template id"
                    defaultValue={injectedRefOptions.at(0)?.value}
                    options={injectedRefOptions}
                />
            </Col>
            <Col md={12}>
                <InjectedRefICTableCell
                    gameId={props.gameId}
                    value={defaultValueController.field.value}
                    onChange={defaultValueController.field.onChange}
                    templateId={templateIdController.field.value!}
                    clearable
                />
            </Col>
        </Row>
    );
};
