import { gql } from "@apollo/client";
import { TEMPLATE_PARAM_FRAGMENT } from "@/graphql/fragments/template-param.fragment";
import { makeGqlQueryHook, makeGqlQueryLazyHook } from "@/graphql/util";
import { GetTemplateParamQuery, GetTemplateParamQueryVariables } from "@/gql";
import { CONSUMER_FRAGMENT } from "@/graphql/fragments/consumer.fragment";

const SCHEMA = gql`
    ${TEMPLATE_PARAM_FRAGMENT}
    ${CONSUMER_FRAGMENT}

    query getTemplateParam($guid: String!, $gameId: String!) {
        templateParam(guid: $guid, gameId: $gameId) {
            ...TemplateParam
            consumers {
                ...Consumer
            }
        }
    }
`;

export const useGetTemplateParamQuery = makeGqlQueryHook<
    GetTemplateParamQuery,
    GetTemplateParamQueryVariables
>(SCHEMA);
export const useLazyGetTemplateParamQuery = makeGqlQueryLazyHook<
    GetTemplateParamQuery,
    GetTemplateParamQueryVariables
>(SCHEMA);
