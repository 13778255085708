import { DataType, TemplateParam } from "@/gql";

const primitiveTypes: Set<DataType> = new Set([
    DataType.Boolean,
    DataType.String,
    DataType.Integer,
    DataType.Float,
    DataType.Timestamp,
    DataType.Duration,
]);

export function isPrimitiveType(templateParam: TemplateParam) {
    return primitiveTypes.has(templateParam.type);
}

export function isPrimitiveNonBoolean(templateParam: TemplateParam) {
    return templateParam.type !== DataType.Boolean && primitiveTypes.has(templateParam.type);
}
