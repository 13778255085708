import Badge from "react-bootstrap/Badge";
import { memo } from "react";
import Spinner from "react-bootstrap/Spinner";
import { useAddChangeRecord } from "@/components/common/Table/EntitiesTable/contexts/TableCellContext";
import { useEntityById } from "@/store/entities/slices";
import { EntityRefRenderer } from "@/components/common/EntityRefRenderer";
import { useTemplate2 } from "@/store/games/slices";
import { DisableEntityParameterBtn } from "@/fields/components/DisableEntityParameterBtn";
import { FieldProps, TableCell } from "../../types";
import { InjectedRefICTableCell } from "./InjectedRefICTableCell";

export const InjectedRefTableCell: TableCell = memo(function InjectedRefTableCell(props) {
    const templateId = props.templateParam.meta.templateId;
    const { value, addChangeRecord } = useAddChangeRecord(props);
    const selectedEntity = useEntityById(value, props.templateParam.gameId);

    if (!templateId) {
        return <Badge bg="danger">Missing reference template</Badge>;
    }

    if (
        !selectedEntity &&
        value !== "" &&
        value !== null &&
        props.templateParam.meta.defaultValue !== null
    ) {
        return <Badge bg="danger">Entity not loaded</Badge>;
    }

    if (props.readonly) {
        return <EntityRefRenderer entityId={value} templateId={templateId} />;
    }

    return (
        <div className="d-flex align-items-center">
            <DisableEntityParameterBtn {...props} />
            <InjectedRefField
                gameId={props.gameId}
                templateParam={props.templateParam}
                contextEntity={props.tableCellProps.row}
                clearable={!props.templateParam.meta.isRequired}
                value={value}
                onChange={addChangeRecord}
                templateId={templateId}
                readonly={props.readonly}
            />
        </div>
    );
});

export function InjectedRefField(
    props: FieldProps<string> & { clearable?: boolean; templateId: string },
) {
    const [template, loading] = useTemplate2(props.templateId);

    if (loading) return <Spinner size="sm" />;

    if (!template) {
        return <Badge bg="danger">Missing referenced template</Badge>;
    }

    return <InjectedRefICTableCell {...props} />;
}
