import { gql } from "@apollo/client";
import { ARTIFACT_FRAGMENT } from "../fragments/artifact.fragment";
import { DEPLOY_DIRECTORY_FRAGMENT } from "../fragments/deploy-directory.fragment";

export const ARTIFACT_HISTORY_ENTRY_FRAGMENT = gql`
    fragment ArtifactHistoryEntry on ArtifactHistoryEntry {
        guid
        gameId
        artifactId
        createdBy
        createdAt
        status
    }
`;

export const ARTIFACT_HISTORY_ENTRY_DETAILED_FRAGMENT = gql`
    ${ARTIFACT_HISTORY_ENTRY_FRAGMENT}
    ${ARTIFACT_FRAGMENT}
    ${DEPLOY_DIRECTORY_FRAGMENT}

    fragment ArtifactHistoryEntryDetailed on ArtifactHistoryEntry {
        ...ArtifactHistoryEntry
        artifact {
            ...Artifact
            currentlyDeployed {
                ...DeployDirectory
            }
        }
    }
`;
