import { FallbackPlaceholder } from "./components/FallbackPlaceholder";
import { defaultFieldDisplayOptions } from "./constants";
import { AdditionalFieldsFallback } from "./components/AdditionalFieldsFallback";
import {
    type DatatypeDefinition,
    type DatatypeOptions,
    type TableCellDisplayOptionsFunction,
} from "./types";

export function createDatatype<T, Nullable extends boolean = false>(
    options: DatatypeOptions<T, Nullable>,
): DatatypeDefinition<T, Nullable> {
    const tableCellDisplayOptions: TableCellDisplayOptionsFunction =
        options.tableCellDisplayOptions !== undefined
            ? typeof options.tableCellDisplayOptions === "function"
                ? options.tableCellDisplayOptions
                : () => options.tableCellDisplayOptions ?? {}
            : () => ({});

    return {
        type: Array.isArray(options.type) ? options.type : [options.type],
        inputComponent: options.inputComponent,
        readonlyComponent: options.readonlyComponent ?? options.inputComponent,
        placeholderComponent: options.placeholderComponent ?? FallbackPlaceholder,
        contextActions: options.contextActions,
        additionalParameterFields: options.additionalParameterFields ?? AdditionalFieldsFallback,
        defaultValue: options.defaultValue,
        validator: (templateParam) => {
            const {
                guid: templateParamId,
                meta: { isUnique },
            } = templateParam;

            return function (value, formValues) {
                // DISABLED TO THE FORESEEABLE FUTURE AS
                // THIS GETS MESSY ON LARGE COLLECTIONS
                // if (isUnique) {
                //     const targetTemplates = Object.values(formValues.entities).filter(
                //         (entry) => entry.templateId === templateParam.templateId,
                //     );
                //     const uniqSet = new Set(
                //         targetTemplates.map((entry) => entry.values[templateParamId]),
                //     );
                //
                //     const uniquenessCheckFailed = targetTemplates.length !== uniqSet.size;
                //
                //     if (uniquenessCheckFailed) return "Uniqueness check failed";
                // }

                if (typeof options.validator === "function")
                    return options.validator(value, formValues);

                return true;
            };
        },
        sortFunction: options.sortFunction,
        formFieldDisplayOptions: options.formFieldDisplayOptions ?? defaultFieldDisplayOptions,
        tableCellDisplayOptions,
    };
}
