export const locales = [
    {
        language: "bg",
        name: "Bulgarian",
    },
    {
        language: "cs",
        name: "Czech",
    },
    {
        language: "da",
        name: "Danish",
    },
    {
        language: "de",
        name: "German",
    },
    {
        language: "el",
        name: "Greek",
    },
    {
        language: "en-us",
        name: "English (American)",
    },
    {
        language: "en-uk",
        name: "English (British)",
    },
    {
        language: "es",
        name: "Spanish",
    },
    {
        language: "et",
        name: "Estonian",
    },
    {
        language: "fi",
        name: "Finnish",
    },
    {
        language: "fr",
        name: "French",
    },
    {
        language: "hu",
        name: "Hungarian",
    },
    {
        language: "id",
        name: "Indonesian",
    },
    {
        language: "it",
        name: "Italian",
    },
    {
        language: "ja",
        name: "Japanese",
    },
    {
        language: "ko",
        name: "Korean",
    },
    {
        language: "lt",
        name: "Lithuanian",
    },
    {
        language: "lv",
        name: "Latvian",
    },
    {
        language: "nb",
        name: "Norwegian",
    },
    {
        language: "nl",
        name: "Dutch",
    },
    {
        language: "pl",
        name: "Polish",
    },
    {
        language: "pt-pt",
        name: "Portuguese (all Portuguese varieties excluding Brazilian Portuguese)",
    },
    {
        language: "pt-br",
        name: "Portuguese (Brazilian)",
    },
    {
        language: "ro",
        name: "Romanian",
    },
    {
        language: "ru",
        name: "Russian",
    },
    {
        language: "sk",
        name: "Slovak",
    },
    {
        language: "sl",
        name: "Slovenian",
    },
    {
        language: "sv",
        name: "Swedish",
    },
    {
        language: "tr",
        name: "Turkish",
    },
    {
        language: "uk",
        name: "Ukrainian",
    },
    {
        language: "zh",
        name: "Chinese",
    },
];
