import { useController } from "react-hook-form";
import ToggleButton from "react-bootstrap/ToggleButton";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { OperableEntityTableData, OperableTemplateEntity } from "@/types";
import { useEntitiesTableFormContext } from "../hooks/useEntitiesTableFormContext";

type Props = {
    row: OperableEntityTableData;
};

export function ExportButton(props: Props) {
    const { control, watch } = useEntitiesTableFormContext();
    const name = `entities.${props.row.guid}.export` as const;
    const controller = useController({ control, name });
    const exported = watch(name);

    return (
        <ToggleButton
            id={`export-${props.row.guid}`}
            value={`export-${props.row.guid}`}
            type="checkbox"
            ref={controller.field.ref}
            checked={controller.field.value}
            name={controller.field.name}
            onChange={controller.field.onChange}
            onBlur={controller.field.onBlur}
            disabled={controller.field.disabled}
            title="Export"
        >
            {exported ? <FaEye /> : <FaEyeSlash />}
        </ToggleButton>
    );
}
