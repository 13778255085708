import {
    createContext,
    PropsWithChildren,
    useCallback,
    useContext,
    useMemo,
    useState,
} from "react";
import { useDebounceCallback } from "usehooks-ts";
import keyBy from "lodash/keyBy";
import { Locale } from "@/gql";
import { useGetLocales, useGetTranslationsByIds } from "@/graphql/translation";
import { Translation } from "@/types";

type LocalizationProvider = {
    translations: Record<string, Translation>;
    localesMap: Record<string, Locale>;
    requestTranslation(guid: string): void;
};

export const Localization = createContext<LocalizationProvider>(undefined!);

export function LocalizationProvider(props: PropsWithChildren<{ gameId: string }>) {
    const getLocales = useGetLocales({ gameId: props.gameId });
    const [getTranslationsByIds] = useGetTranslationsByIds();
    const [queue, setQueue] = useState(() => new Set<string>());
    const [translations, setTranslations] = useState<Record<string, Translation>>(() => ({}));
    const handleQueue = useDebounceCallback(async () => {
        const { data } = await getTranslationsByIds({
            variables: { gameId: props.gameId, guid: [...queue] },
        });
        if (data) {
            setTranslations((prevState) => ({
                ...prevState,
                ...keyBy(data.translationByIds, "guid"),
            }));
            setQueue(() => new Set());
        }
    }, 100);
    const requestTranslation = useCallback(
        (guid: string) => {
            setQueue((prevState) => prevState.add(guid));
            handleQueue();
        },
        [handleQueue],
    );
    const value = useMemo<LocalizationProvider>(
        () => ({
            translations,
            localesMap: keyBy(getLocales.data?.locales ?? {}, "guid"),
            requestTranslation,
        }),
        [getLocales.data, requestTranslation, translations],
    );

    return <Localization.Provider value={value}>{props.children}</Localization.Provider>;
}

export function useLocalizationContext() {
    return useContext(Localization);
}
