import { gql } from "@apollo/client";
import { makeGqlQueryHook } from "@/graphql/util";
import {
    GLOSSARY_ENTRY_FRAGMENT,
    GLOSSARY_ENTRY_VALUE_FRAGMENT,
} from "@/graphql/fragments/glossary.fragment";
import { GetGlossariesQuery, GetGlossariesQueryVariables } from "@/gql";

const SCHEMA = gql`
    ${GLOSSARY_ENTRY_FRAGMENT}
    ${GLOSSARY_ENTRY_VALUE_FRAGMENT}

    query getGlossaries($gameId: String!) {
        getGlossaries(gameId: $gameId) {
            ...GlossaryEntry
            values {
                ...GlossaryEntryValue
            }
        }
    }
`;

export const useGetGlossaryEntriesQuery = makeGqlQueryHook<
    GetGlossariesQuery,
    GetGlossariesQueryVariables
>(SCHEMA);
