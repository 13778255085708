import { FC, type ReactNode, useEffect } from "react";
import Alert from "react-bootstrap/Alert";
import { OperationVariables } from "@apollo/client";
import { QueryResult } from "@apollo/client/react/types/types";
import { SuspenseFallback } from "@/components/SuspenseFallback";

type DataAwaiterRenderChildrenProps<Data> = {
    children: (data: Data) => ReactNode;
};

type DataAwaiterComponentProps<Data> = {
    component: FC<{ data: Data }>;
};

export function DataAwaiter2<Data, Variables extends OperationVariables = Record<string, never>>(
    props: QueryResult<Data, Variables> &
        (DataAwaiterRenderChildrenProps<Data> | DataAwaiterComponentProps<Data>),
) {
    // Не помню, зачем это изначально было добавлено, но вроде бы для какого-то бага
    // useEffect(() => {
    //     props.refetch();
    // }, []);

    if (props.loading) return <SuspenseFallback />;

    if (props.error) {
        return (
            <Alert className="mb-3" variant="danger">
                <Alert.Heading>Apollo error</Alert.Heading>
                <span>{props.error.message}</span>
            </Alert>
        );
    }

    if (!props.data) {
        return <span>Error: data is null</span>;
    }

    if (!("component" in props) && !("children" in props)) {
        console.error("Either component or children must be provided");
        return null;
    }

    if ("component" in props) {
        return <props.component data={props.data} />;
    }

    return props.children(props.data);
}
