import { memo } from "react";
import Placeholder from "react-bootstrap/Placeholder";
import Form from "react-bootstrap/Form";

export const EnumFallbackComponent = memo(function EnumFallbackComponent() {
    return (
        <Placeholder animation="glow" size="sm">
            <Placeholder as={Form.Select} className="form-control-sm" />
        </Placeholder>
    );
});
