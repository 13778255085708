import { gql } from "@apollo/client";
import { GenerateArtifactMutation, GenerateArtifactMutationVariables } from "@/gql";
import { ARTIFACT_FRAGMENT } from "@/graphql/fragments/artifact.fragment";
import { makeGqlMutationHook } from "../util";

const DEPLOY = gql`
    ${ARTIFACT_FRAGMENT}

    mutation generateArtifact($gameId: String!) {
        generateArtifact(gameId: $gameId) {
            ...Artifact
        }
    }
`;

export const useGenerateArtifactMutation = makeGqlMutationHook<
    GenerateArtifactMutation,
    GenerateArtifactMutationVariables
>(DEPLOY);
