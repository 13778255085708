import { Helmet } from "react-helmet-async";
import { createRoute } from "@tanstack/react-router";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { AddUserForm, UsersTable } from "@/components/roles-page/components";
import { useAppSelector } from "@/hooks";
import { Permission } from "@/gql";
import { withRBAC } from "@/components/rbac";
import { useAuth } from "@/hooks/useAuth";
import { gameDetailsSelector } from "@/store/games/selectors";
import { projectSettings } from "@/pages/project-settings/ProjectSettings";
import { useGetProjectPermissions } from "@/graphql/game";
import { DataAwaiter2 } from "@/components/common/DataAwaiter2";

export function PermissionsPage() {
    const { useParams } = permissionsPage;
    const { gameId } = useParams();
    const getProjectPermissions = useGetProjectPermissions({ gameId });
    const { creatorId } = useAppSelector(gameDetailsSelector);
    const { user } = useAuth();

    if (!gameId || !user?.guid)
        throw new Error("parameter gameId is not available in current context");

    return (
        <section>
            <Helmet title="Permissions" />
            <Row>
                <Col md={12}>
                    <AddUserForm gameId={gameId} />
                </Col>
                <Col md={12}>
                    <DataAwaiter2 {...getProjectPermissions}>
                        {(data) => (
                            <UsersTable
                                userPermissions={data.projectPermissions}
                                creatorId={creatorId}
                                gameId={gameId}
                                currentUserId={user.guid}
                            />
                        )}
                    </DataAwaiter2>
                </Col>
            </Row>
        </section>
    );
}

const PermissionsPageRBAC = withRBAC(PermissionsPage, {
    requiredPermissions: [Permission.RolesChange],
});
export default PermissionsPageRBAC;
export const permissionsPage = createRoute({
    getParentRoute: () => projectSettings,
    path: "permissions",
    component: PermissionsPageRBAC,
});
