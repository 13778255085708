import { useCallback, useMemo, useState } from "react";
import isNil from "lodash/isNil";
import { DataType, Maybe } from "@/gql";
import { DatatypeDefinition } from "../types";
import { createDatatype } from "../createDatatype";
import { FallbackInputComponent } from "../components/FallbackInputComponent";
import { typeDefs } from "../datatypes";

export function useFieldsDefinitions() {
    const [fieldsDefinition] = useState(() =>
        typeDefs.reduce(
            (acc, def) => {
                def.type.forEach((dataType) => {
                    if (Reflect.has(acc, dataType)) {
                        console.warn(
                            `Definition for datatype ${dataType} already exists and will be overwritten`,
                        );
                    }
                    Reflect.set(acc, dataType, def);
                });
                return acc;
            },
            {} as Record<DataType, DatatypeDefinition<any>>,
        ),
    );
    const fallbackDefinition = useMemo<DatatypeDefinition<string, false>>(() => {
        return createDatatype({
            type: [
                DataType.Asset,
                DataType.Boolean,
                DataType.Duration,
                DataType.EntityRef,
                DataType.InjectedRef,
                DataType.Enum,
                DataType.Float,
                DataType.Integer,
                DataType.List,
                DataType.LocalizedString,
                DataType.Script,
                DataType.String,
                DataType.Timestamp,
            ],
            inputComponent: FallbackInputComponent,
            defaultValue: () => "",
        });
    }, []);

    const getDefinition = useCallback(
        (type?: Maybe<DataType> | null) => {
            if (isNil(type)) return fallbackDefinition;
            return fieldsDefinition[type!] ?? fallbackDefinition;
        },
        [fallbackDefinition, fieldsDefinition],
    );

    return useMemo(
        () => ({
            getDefinition,
        }),
        [getDefinition],
    );
}
