import { ComponentProps, ComponentPropsWithRef, ForwardedRef, forwardRef, useMemo } from "react";
import { useController, UseControllerProps } from "react-hook-form";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import { ConnectForm } from "../ConnectForm";

export const FormInput = forwardRef(function FormInput<FormValues extends Record<string, any>>(
    props: UseControllerProps<FormValues> & ComponentProps<typeof Form.Control>,
    ref: ForwardedRef<HTMLInputElement | null>,
) {
    // disabled: true sets value to undefined which is complete bullshit
    const { field, fieldState } = useController({ ...props, disabled: undefined });
    const error = useMemo(() => {
        if (!fieldState.error) return null;
        switch (fieldState.error.type) {
            case "required":
                return "This field is required";
            default:
                return fieldState.error.message || "Invalid value";
        }
    }, [fieldState.error]);

    return (
        <Form.Group className={props.className}>
            {props.title && <Form.Label>{props.title}</Form.Label>}
            <Form.Control
                {...field}
                ref={ref}
                step={props.step}
                min={props.min}
                max={props.max}
                type={props.type}
                as={props.as}
                disabled={props.disabled}
                isInvalid={fieldState.error !== undefined}
                placeholder={!props.title ? props.name : props.title ?? ""}
            />
            {props.required && (
                <Col md={12}>
                    <Form.Text muted>Required</Form.Text>
                </Col>
            )}
            {fieldState.error && (
                <Form.Control.Feedback type="invalid">
                    {fieldState.error.message}
                </Form.Control.Feedback>
            )}
        </Form.Group>
    );
});

export function NestedFormInput(inputProps: ComponentPropsWithRef<typeof FormInput>) {
    return (
        <ConnectForm>
            {({ register }) => (
                <FormInput
                    {...inputProps}
                    {...register(inputProps.name, { required: inputProps.required })}
                />
            )}
        </ConnectForm>
    );
}
