import {
    createContext,
    PropsWithChildren,
    useCallback,
    useContext,
    useLayoutEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import keyBy from "lodash/keyBy";
import { useCounter } from "usehooks-ts";
import { Locale } from "@/gql";
import { useGetLocales, useGetTranslationsByIds } from "@/graphql/translation";
import { Translation } from "@/types";

type LocalizationProvider = {
    translations: Record<string, Translation>;
    localesMap: Record<string, Locale>;
    requestTranslation(guid: string): Translation | null;
};

type TranslationsCollection = Record<string, null | Translation>;

export const Localization = createContext<LocalizationProvider>(undefined!);

export function LocalizationProvider(props: PropsWithChildren<{ gameId: string }>) {
    const getLocales = useGetLocales({ gameId: props.gameId });
    const [getTranslationsByIds, { loading }] = useGetTranslationsByIds();
    const queueRef = useRef<string[]>([]);
    const { count, increment } = useCounter(0);
    const [translations, setTranslations] = useState<TranslationsCollection>(() => ({}));
    const addGuidToQueue = useCallback(
        (guid: string) => {
            if (guid in translations) return;
            if (queueRef.current.includes(guid)) return;
            queueRef.current.push(guid);
            requestIdleCallback(
                () => {
                    increment();
                },
                { timeout: 16 * 5 },
            );
        },
        [increment, translations],
    );
    const getTranslation = useCallback(
        (guid: string) => {
            if (!(guid in translations)) addGuidToQueue(guid);
            return translations[guid];
        },
        [addGuidToQueue, translations],
    );
    const handleQueue = useCallback(async () => {
        const ids = [...queueRef.current];

        const { data } = await getTranslationsByIds({
            variables: { gameId: props.gameId, guid: ids },
        });
        if (!data) return;

        setTranslations((prevState) => {
            const translationsMap = keyBy(data.translationByIds, "guid");

            return Object.fromEntries([
                ...Object.entries(prevState),
                ...ids.map((id) => {
                    if (id in translationsMap) return [id, translationsMap[id]];
                    return [id, null];
                }),
            ]);
        });
        queueRef.current = [];
    }, [getTranslationsByIds, props.gameId]);
    const requestTranslation = useCallback(
        (guid: string) => {
            return getTranslation(guid);
        },
        [getTranslation],
    );
    const value = useMemo<LocalizationProvider>(
        () => ({
            translations,
            localesMap: keyBy(getLocales.data?.locales ?? {}, "guid"),
            requestTranslation,
        }),
        [getLocales.data, requestTranslation, translations],
    );

    useLayoutEffect(() => {
        if (queueRef.current.length > 0 && !loading) {
            handleQueue();
        }
    }, [handleQueue, count, loading]);

    return <Localization.Provider value={value}>{props.children}</Localization.Provider>;
}

export function useLocalizationContext() {
    return useContext(Localization);
}
