import { DataType } from "@/gql";
import { createDatatype } from "../../createDatatype";
import { DurationFieldComponent } from "../../datatypes/duration/DurationFieldComponent";

export const durationField = createDatatype<number>({
    defaultValue: () => 0,
    type: [DataType.Duration],
    inputComponent: DurationFieldComponent,
    sortFunction: (templateParam) => (rowA, rowB) => {
        const sortAttribute = templateParam.guid;

        if (!sortAttribute) return 0;

        return rowB.values[sortAttribute] > rowA.values[sortAttribute] ? 1 : -1;
    },
});
