import { Blocks } from "blockly";
import { luaGenerator } from "blockly/lua";
import { ScriptEditorEnumDefinition, ScriptScope } from "@/types";
import NamespaceAccessor from "./namespace-accessor";
import Table from "./table";
import Enum from "./enum";

export function registerBlock(blockname: string, { def, codeGen }: { def: any; codeGen: any }) {
    Blocks[blockname] = def;
}

class BlocklyDeclarationBus {
    enumsMap: Map<string, ScriptEditorEnumDefinition>;
    namespacesMap: Map<string, ScriptScope>;

    constructor() {
        this.enumsMap = new Map();
        this.namespacesMap = new Map();
    }

    get enums() {
        return [...this.enumsMap.values()];
    }

    registerEnum(data: ScriptEditorEnumDefinition) {
        this.enumsMap.set(data.name, data);
    }

    get namespaces() {
        return [...this.namespacesMap.values()];
    }

    registerNameSpace(data: ScriptScope) {
        this.namespacesMap.set(data.name, data);
    }
}

export const blocklyDeclarationsBus = new BlocklyDeclarationBus();

export default function registerDefinitions() {
    [NamespaceAccessor, Table, Enum].forEach((definition) => {
        definition(registerBlock, { blocklyDeclarationsBus, luaGenerator });
    });
}
