import { useCallback, useMemo } from "react";
import { TableColumn } from "react-data-table-component";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import { FaEdit, FaTrash } from "react-icons/fa";
import {
    useCreateDeployDirectoryMutation,
    useDeleteDeployDirectoryMutation,
    useDeployDirectoriesQuery,
    useUpdateDeployDirectoryMutation,
} from "@/graphql/deploy-directories";
import { AppDataTable } from "@/components/common/DataTable";
import { DeployDirectory, GetDeployDirectoriesQuery } from "@/gql";
import { ConfirmationButton } from "@/components/common/ConfirmationButton";
import { showDeployDirectoryModal } from "@/components/modals2/DeployDirectoryModal";

export function DeployDirectoriesTable(props: GetDeployDirectoriesQuery & { gameId: string }) {
    const deployDirectories = useDeployDirectoriesQuery({ gameId: props.gameId });
    const [createDeployDirectoryMutation] = useCreateDeployDirectoryMutation();
    const [updateDeployDirectoryMutation] = useUpdateDeployDirectoryMutation();
    const [deleteDeployDirectoryMutation] = useDeleteDeployDirectoryMutation();

    const onEdit = useCallback(
        async (row: DeployDirectory) => {
            const result = await showDeployDirectoryModal({
                deployDirectory: row,
            });
            await updateDeployDirectoryMutation({
                variables: {
                    gameId: props.gameId,
                    guid: result.guid,
                    name: result.name,
                    variant: result.variant,
                    description: result.description,
                    path: result.path,
                },
            });
            await deployDirectories.refetch();
        },
        [deployDirectories, props.gameId, updateDeployDirectoryMutation],
    );
    const onCreate = useCallback(async () => {
        const result = await showDeployDirectoryModal({});
        await createDeployDirectoryMutation({
            variables: {
                gameId: props.gameId,
                name: result.name,
                path: result.path,
                variant: result.variant,
            },
        });
        await deployDirectories.refetch();
    }, [createDeployDirectoryMutation, deployDirectories, props.gameId]);

    const columns = useMemo<TableColumn<DeployDirectory>[]>(
        () => [
            {
                name: "Name",
                selector: (row) => row.name,
            },
            {
                name: "Description",
                selector: (row) => (row.description ? row.description : "No description"),
            },
            {
                name: "Path",
                selector: (row) => row.path,
            },
            {
                name: "Variant",
                selector: (row) => row.variant,
            },
            {
                name: "Actions",
                cell: (row) =>
                    row.path === "dev" ? (
                        <span>No actions allowed</span>
                    ) : (
                        <ButtonGroup>
                            <ConfirmationButton
                                onConfirm={() =>
                                    deleteDeployDirectoryMutation({
                                        variables: { guid: row.guid, gameId: row.gameId },
                                        onCompleted: () => deployDirectories.refetch(),
                                    })
                                }
                                size="sm"
                                prompt="Are you sure you want to delete this deploy directory?"
                                title="Delete deploy directory"
                                variant="danger"
                            >
                                <FaTrash />
                            </ConfirmationButton>
                            <Button
                                title="Edit deploy directory"
                                size="sm"
                                onClick={() => onEdit(row)}
                            >
                                <FaEdit />
                            </Button>
                        </ButtonGroup>
                    ),
            },
        ],
        [deleteDeployDirectoryMutation, deployDirectories, onEdit],
    );
    const actions = useMemo(
        () => (
            <ButtonGroup>
                <Button onClick={onCreate}>Create</Button>
            </ButtonGroup>
        ),
        [onCreate],
    );

    return (
        <AppDataTable striped actions={actions} data={props.deployDirectories} columns={columns} />
    );
}
