import { useMemo } from "react";
import Badge from "react-bootstrap/Badge";
import isNil from "lodash/isNil";
import { useGlobalEnum } from "@/store/games/slices";
import { TableCell } from "../../types";

export const EnumTableCell: TableCell = (props) => {
    if (!props.templateParam.meta.enumId) {
        throw new Error(`Enum parameter ${props.templateParam.name} doesn't reference a enum`);
    }

    const value = parseInt(props.tableCellProps.row.values[props.templateParam.guid]);
    const globalEnum = useGlobalEnum(props.templateParam.meta.enumId);
    const selectedEnumValue = useMemo(() => {
        return globalEnum?.meta.enum?.enumValues.find((enumPair) => enumPair.value === value);
    }, [globalEnum, value]);
    if (isNil(value) || !globalEnum || isNil(selectedEnumValue))
        return <Badge bg="danger">Incorrect enum value</Badge>;
    const displayedValue = `${globalEnum.name}.${selectedEnumValue.name}`;

    return <span className="form-control-plaintext font-monospace">{displayedValue}</span>;
};
