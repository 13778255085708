import Button from "react-bootstrap/Button";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import styled from "styled-components";
import { ALLOW_DISABLING_PARAMETERS, useProjectHasFlag } from "@/store/games/slices";

const DisableButton = styled(Button)`
    width: 1.5rem;
    min-width: 1.5rem;
    height: 1.5rem;
    min-height: 1.5rem;
    line-height: 1;
    padding: 0;
    vertical-align: middle;
`;

type Props = {
    className?: string;
    onClick: () => void;
    value: boolean;
};

export function DisableValueBtn(props: Props) {
    const isFeatureEnabled = useProjectHasFlag(ALLOW_DISABLING_PARAMETERS);
    return (
        <DisableButton
            size="sm"
            className={props.className}
            onClick={props.onClick}
            disabled={!isFeatureEnabled}
            title={props.value ? "disabled" : "enabled"}
        >
            {props.value ? <FaEyeSlash /> : <FaEye />}
        </DisableButton>
    );
}
