import Badge from "react-bootstrap/Badge";
import FormText from "react-bootstrap/FormText";
import Spinner from "react-bootstrap/Spinner";
import { useTemplate2 } from "@/store/games/slices";
import { useEditEntityFormController } from "@/components/forms/EditEntityForm/hooks/useEditEntityFormController";
import { FormGroup } from "@/fields/components/FormGroup";
import { FormFieldProps } from "../../types";
import { EntityRefSelector } from "./EntityRefSelector";

export function EntityRefFormField(props: FormFieldProps) {
    const templateId = props.templateParameter.meta.templateId;
    if (!templateId) {
        throw new Error(`Parameter ${props.templateParameter.name} doesn't reference a template`);
    }
    const [referencedTemplate, loading] = useTemplate2(templateId);
    const { field, fieldState } = useEditEntityFormController(props, "value");

    if (loading) return <Spinner size="sm" />;

    if (!referencedTemplate?.guid) {
        return <Badge bg="danger">Missing reference template</Badge>;
    }

    return (
        <FormGroup {...props} fieldState={fieldState}>
            <EntityRefSelector
                templateId={referencedTemplate.guid}
                gameId={props.templateParameter.gameId}
                clearable={!props.templateParameter.meta.isRequired}
                creatable
                {...field}
            />
            {fieldState.error && (
                <FormText className="text-danger">{fieldState.error.message}</FormText>
            )}
        </FormGroup>
    );
}
