import { gql } from "@apollo/client";
import { JOB_FRAGMENT } from "@/graphql/fragments/job.fragment";
import { RemoveGameMutation, RemoveGameMutationVariables } from "@/gql";
import { makeGqlMutationHook } from "../util";

export const REMOVE_GAME = gql`
    ${JOB_FRAGMENT}

    mutation removeGame($gameIds: [String!]!) {
        result: removeGame(gameIds: $gameIds) {
            ...Job
        }
    }
`;

export const useRemoveGameMutation = makeGqlMutationHook<
    RemoveGameMutation,
    RemoveGameMutationVariables
>(REMOVE_GAME);
