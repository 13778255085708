import { useCallback, useState } from "react";
import Alert from "react-bootstrap/Alert";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Spinner from "react-bootstrap/Spinner";
import { useUpdatePasswordMutation } from "@/graphql/auth";
import { useAuth } from "@/hooks/useAuth";
import { ChangePassword, ChangePasswordForm } from "@/components/forms/ChangePasswordForm";

export function EditPasswordSection() {
    const { user, refetch } = useAuth();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<Error | null>(null);
    const [updatePasswordMutation] = useUpdatePasswordMutation();
    const onPasswordChange = useCallback(
        async (variables: ChangePassword) => {
            try {
                setIsLoading(true);
                setError(null);
                await updatePasswordMutation({ variables });
                await refetch();
            } catch (error: any) {
                setError(error);
            } finally {
                setIsLoading(false);
            }
        },
        [refetch, updatePasswordMutation],
    );

    if (!user) return <Spinner />;

    return (
        <Row className="gy-4 mb-4">
            <Col md={12}>
                <h3 className="mb-0">Password</h3>
            </Col>
            <Col md={12}>
                <ChangePasswordForm onSubmit={onPasswordChange} isLoading={isLoading} />
            </Col>

            {error && (
                <Col md={12}>
                    <Alert variant="danger">
                        <Alert.Heading>Error</Alert.Heading>
                        <p>{error.message}</p>
                    </Alert>
                </Col>
            )}
        </Row>
    );
}
