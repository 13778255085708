import { gql } from "@apollo/client";
import { RequestPasswordResetMutation, RequestPasswordResetMutationVariables } from "@/gql";
import { makeGqlMutationHook } from "../util";

const REQUEST_PASSWORD_RESET = gql`
    mutation requestPasswordReset($email: String!) {
        result: requestPasswordReset(email: $email) {
            isOk
            info
        }
    }
`;

export const useRequestPasswordReset = makeGqlMutationHook<
    RequestPasswordResetMutation,
    RequestPasswordResetMutationVariables
>(REQUEST_PASSWORD_RESET);
