import { DataType } from "@/gql";
import { createDatatype } from "../../createDatatype";
import { EntityRefFieldComponent } from "./EntityRefFieldComponent";
import { EntityRefReadonlyComponent } from "./EntityRefReadonlyComponent";
import { EntityRefContextActions } from "./EntityRefContextActions";

export const entityRefField = createDatatype<string | null>({
    type: DataType.EntityRef,
    defaultValue: () => null,
    inputComponent: EntityRefFieldComponent,
    readonlyComponent: EntityRefReadonlyComponent,
    contextActions: EntityRefContextActions,
    tableCellDisplayOptions: {
        minWidth: "450px",
        maxWidth: "750px",
    },
});
