import { useDebugValue, useMemo } from "react";
import keyBy from "lodash/keyBy";
import { useAuth } from "@/hooks/useAuth";
// import { layoutWithBreadcrumbs } from "@/layouts/LayoutWithBreadcrumbs";
import { useAppSelector } from "@/hooks";
import { gameDetailsSelector } from "@/store/games/selectors";
import { checkIfRBACValid } from "./RBAC.utils";

export const useHasPermissions = <P extends string = string>(
    requiredPermissions: P[],
    oneOf = false,
) => {
    useDebugValue(useHasPermissions.name);
    const { user } = useAuth();
    const { guid: gameId } = useAppSelector(gameDetailsSelector);
    // const { gameId } = useParams();
    const gamePermissions = useMemo(() => {
        if (!user) return [];
        if (!user.permissions) return [];
        return user.permissions.find((p) => p.gameId === gameId)?.permissions ?? [];
    }, [gameId, user]);
    // const gamePermissions = useMemo(() => {
    //     if (!user) return [];
    //
    //     const usersPermissions = mapValues(keyBy(userPermissions, "userId"), "permissions");
    //
    //     return usersPermissions[user.guid] ?? [];
    // }, [user]);
    const existingPermissionsNorm = useMemo(() => {
        return keyBy(gamePermissions) as Record<P, P>;
    }, [gamePermissions]);

    if (!user) return true;
    if (gamePermissions.length === 0) return false;

    const hasRequiredPermissions = checkIfRBACValid<P>({
        required: requiredPermissions,
        existing: existingPermissionsNorm,
        oneOf,
    });
    return hasRequiredPermissions;
};
