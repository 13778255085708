import { useCallback, useMemo } from "react";
import { bootstrapDialog, create, NiceModalHocProps, show, useModal } from "@ebay/nice-modal-react";
import Modal from "react-bootstrap/Modal";
import omit from "lodash/omit";
import { DataType, TemplateParam } from "@/gql";
import {
    useCreateTemplateParamMutation,
    useUpdateTemplateParamMutation,
} from "@/graphql/template-params";
import { GlobalParamForm } from "../forms/GlobalParamForm";
import { TemplateParamCreationAttributes } from "../forms/EditTemplateParamForm";

interface Props {
    gameId: string;
    type: DataType;
    param?: TemplateParam;
}

export function GlobalParamModalComponent(props: Props) {
    const { param, type, gameId } = props;
    const modal = useModal();
    const dialog = useMemo(() => bootstrapDialog(modal), [modal]);
    const isEditMode = param !== undefined;

    const [
        createTemplateParam,
        { loading: createTemplateParamLoading, error: createTemplateParamError },
    ] = useCreateTemplateParamMutation();
    const [
        updateTemplateParam,
        { loading: updateTemplateParamLoading, error: updateTemplateParamError },
    ] = useUpdateTemplateParamMutation();
    const isLoading = createTemplateParamLoading || updateTemplateParamLoading;
    const error = useMemo((): Error | null => {
        if (!createTemplateParamError && !updateTemplateParamError) return null;

        return new Error(createTemplateParamError?.message || updateTemplateParamError?.message);
    }, [createTemplateParamError, updateTemplateParamError]);

    const onSubmit = useCallback(
        async (paramAttributes: TemplateParamCreationAttributes) => {
            const variables = {
                ...paramAttributes,
                templateId: "",
                gameId,
                isGlobal: true,
                consumers: paramAttributes.consumers?.map((consumer) => consumer.guid) ?? [],
            };
            let promise;

            if (isEditMode) {
                promise = updateTemplateParam({ variables });
            } else {
                promise = createTemplateParam({ variables: omit(variables, "guid") });
            }

            const { errors } = await promise;

            if (!errors) {
                modal.resolve(true);
                return modal.hide();
            }
        },
        [createTemplateParam, gameId, isEditMode, modal, updateTemplateParam],
    );

    return (
        <Modal {...dialog} backdrop keyboard size="xl">
            <Modal.Header>
                <Modal.Title>Edit global parameter</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <GlobalParamForm
                    onSubmit={onSubmit}
                    isLoading={isLoading}
                    error={error}
                    param={param}
                    type={type}
                />
            </Modal.Body>
        </Modal>
    );
}

const GlobalParamModal = create(GlobalParamModalComponent);

export function showGlobalParamModal(props: Props) {
    return show<boolean, Props & NiceModalHocProps, Props>(GlobalParamModal, props);
}
