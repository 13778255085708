import { gql } from "@apollo/client";
import { makeGqlQueryLazyHook } from "@/graphql/util";
import { CountEntityReferencesQuery, CountEntityReferencesQueryVariables } from "@/gql";

const SCHEMA = gql`
    query countEntityReferences($gameId: String!, $guid: String!, $force: Boolean) {
        countEntityReferences(gameId: $gameId, guid: $guid, force: $force) {
            entityParameters {
                guid
                entityId
            }
            templateParameters {
                guid
                templateId
                name
            }
        }
    }
`;

export const useCountEntityReferences = makeGqlQueryLazyHook<
    CountEntityReferencesQuery,
    CountEntityReferencesQueryVariables
>(SCHEMA, {
    fetchPolicy: "network-only",
});
