import { gql } from "@apollo/client";
import { UpdateEmailMutation, UpdateEmailMutationVariables } from "@/gql";
import { AUTH_USER_FRAGMENT } from "@/graphql/fragments/user.fragment";
import { makeGqlMutationHook } from "../util";

const SCHEMA = gql`
    ${AUTH_USER_FRAGMENT}

    mutation updateEmail($email: String!) {
        result: updateEmail(email: $email) {
            ...AuthUser
        }
    }
`;

export const useUpdateEmailMutation = makeGqlMutationHook<
    UpdateEmailMutation,
    UpdateEmailMutationVariables
>(SCHEMA);
