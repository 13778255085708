import { gql } from "@apollo/client";
import { GetUserQuery } from "@/gql";
import { makeGqlQueryHook } from "../util";

const SCHEMA = gql`
    query getUser {
        result: user {
            guid
            firstname
            lastname
            email
            canDoWizardry
            permissions: userPermissions {
                gameId
                permissions
            }
        }
    }
`;

export const useGetUser = makeGqlQueryHook<GetUserQuery>(SCHEMA);
