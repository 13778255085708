import { gql } from "@apollo/client";
import { RemoveConsumerMutation, RemoveConsumerMutationVariables } from "@/gql";
import { makeGqlMutationHook } from "../util";
import { CONSUMER_POPULATED_FRAGMENT } from "../fragments/consumer-populated.fragment";

const REMOVE_CONSUMER = gql`
    ${CONSUMER_POPULATED_FRAGMENT}

    mutation removeConsumer($guid: String!, $gameId: String!) {
        result: removeConsumer(guid: $guid, gameId: $gameId) {
            ...ConsumerPopulated
        }
    }
`;

export const useRemoveConsumerMutation = makeGqlMutationHook<
    RemoveConsumerMutation,
    RemoveConsumerMutationVariables
>(REMOVE_CONSUMER);
