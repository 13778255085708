import { createRoute } from "@tanstack/react-router";
import { useFieldArray, useForm } from "react-hook-form";
import Form from "react-bootstrap/Form";
import { useCallback } from "react";
import Button from "react-bootstrap/Button";
import { useSnackbar } from "notistack";
import { DeployFlag } from "@/gql";
import { useGetProjectDeployFlags, useSetProjectDeployFlags } from "@/graphql/game";
import { projectSettings } from "./ProjectSettings";

type FormData = {
    gameId: string;
    flags: Array<DeployFlag & { enabled: boolean }>;
};

export default function DeployFlagsPage() {
    const { gameId } = deployFlagsRoute.useParams();
    const { enqueueSnackbar } = useSnackbar();
    const [getProjectDeployFlags, { loading: getProjectDeployFlagsLoading }] =
        useGetProjectDeployFlags();
    const [setProjectDeployFlags, { loading: setProjectDeployFlagsLoading }] =
        useSetProjectDeployFlags();
    const loading = getProjectDeployFlagsLoading || setProjectDeployFlagsLoading;
    const getFormState = useCallback(async () => {
        const result = await getProjectDeployFlags({
            variables: { gameId },
        });

        if (!result.data) {
            return { gameId, flags: [] };
        }

        const {
            deployFlags,
            project: {
                meta: { deployFlags: projectDeployFlags },
            },
        } = result.data;

        return {
            gameId,
            flags: deployFlags.map((flag) => ({
                ...flag,
                enabled: projectDeployFlags.includes(flag.value),
            })),
        };
    }, [gameId, getProjectDeployFlags]);
    const form = useForm<FormData>({
        defaultValues: getFormState,
        mode: "onChange",
        reValidateMode: "onChange",
    });
    const onSubmit = useCallback(
        async (data: FormData) => {
            const flags = data.flags.filter((flag) => flag.enabled).map((flag) => flag.value);

            const result = await setProjectDeployFlags({
                variables: { gameId, flags },
            });

            if (result.data) {
                enqueueSnackbar("Saved", { variant: "success" });
                form.reset(await getFormState());
            }
        },
        [enqueueSnackbar, form, gameId, getFormState, setProjectDeployFlags],
    );
    const arrayField = useFieldArray({
        control: form.control,
        name: "flags",
    });

    return (
        <Form onSubmit={form.handleSubmit(onSubmit)}>
            {arrayField.fields.map((field, index) => (
                <div key={field.id} className="mb-3">
                    <Form.Check type="checkbox">
                        <Form.Check.Input
                            type="checkbox"
                            {...form.register(`flags.${index}.enabled`, { disabled: loading })}
                        />
                        <Form.Check.Label>
                            {field.value} - {field.name}
                        </Form.Check.Label>
                    </Form.Check>
                    {field.description && <Form.Text>{field.description}</Form.Text>}
                </div>
            ))}

            <Button
                type="submit"
                disabled={loading || !form.formState.isValid || !form.formState.isDirty}
            >
                Save
            </Button>
        </Form>
    );
}

export const deployFlagsRoute = createRoute({
    getParentRoute: () => projectSettings,
    path: "deploy-flags",
    component: DeployFlagsPage,
});
