import { Outlet, createRoute } from "@tanstack/react-router";
import Nav from "react-bootstrap/Nav";
import Stack from "react-bootstrap/Stack";
import { NavItem } from "@/components/common/NavItem";
import { layoutWithBreadcrumbs } from "@/layouts/LayoutWithBreadcrumbs";
import { glossariesPageRoute } from "@/pages/translation/GlossariesPage";
import { localesPageRoute } from "@/pages/translation/LocalesPage";
import { translationsPageRoute } from "@/pages/translation/TranslationsPage";

export default function TranslationPageLayout() {
    return (
        <Stack gap={4}>
            <Nav variant="pills">
                <NavItem to={translationsPageRoute.to}>Translations</NavItem>
                <NavItem to={localesPageRoute.to}>Locales</NavItem>
                <NavItem to={glossariesPageRoute.to}>Glossaries</NavItem>
            </Nav>
            <Outlet />
        </Stack>
    );
}

export const translationPageLayoutRoute = createRoute({
    getParentRoute: () => layoutWithBreadcrumbs,
    path: "translation",
    component: TranslationPageLayout,
});
